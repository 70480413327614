import * as React from 'react';
import { useEffect, useState } from 'react';

import { InventoryItemFragment, OrderTypeEnum, useOrdersQuery, useReturnInventoryQuery } from '@portal/schema';

import { Spacing } from '@shared/components/bootstrap';
import { Flash } from '@shared/components/helpers';
import { FlashKind } from '@shared/types';

import { Spinner } from '@portal/components/helpers';
import { Inventory } from '@portal/components/helpers/inventory';
import { Actions } from '@portal/components/helpers/inventory/actions';
import { Filters, Mode } from '@portal/components/helpers/inventory/filters';
import { ReturnInventory, isSelected } from '@portal/components/return_inventory';
import { useDebounce } from '@shared/hooks';

import { Header } from '@portal/components/helpers/header';
import { User__Role } from '@admin/schema';
import { IMPERSONATOR } from '@portal/config/impersonator';
import { IStepProps } from '../form';
import { Base } from './base';
import { ShippingAvailableNotice } from './shipping_available_notice';

const DELAYED_ITEMS_SELECTED =
  'The previously delayed items have been preselected for return. Feel free to add ' +
  'any additional items that you may need back.';

export const Items: React.FC<
  {
    selections: InventoryItemFragment[];
    preselectedItemIDs: string[];
    type?: OrderTypeEnum;
    onChange(_: InventoryItemFragment[]): void;
    onNext?(): void;
    onPrev?(): void;
  } & IStepProps
> = ({ selections, preselectedItemIDs, onChange, onNext, onPrev, orderSubtype, type }) => {
  const [mode, setMode] = useState<Mode>(Mode.All);
  const [queryString, setQueryString] = useState<string>();
  const selectedIDs = new Set(selections.map(({ id }) => id));

  const { data: activeOrdersData, loading: activeOrdersLoading } = useOrdersQuery({
    variables: { onlyActive: true },
  });

  const { data, loading } = useReturnInventoryQuery({
    variables: {
      query: useDebounce(queryString),
    },
  });

  const inventory = data?.inventory;

  const allItems = inventory?.reduce((acc, item) => {
    if (item.__typename === 'Item') {
      acc.push(item);
    } else if (item.__typename === 'ItemGroup') {
      acc = acc.concat(item.items);
    }
    return acc;
  }, [] as InventoryItemFragment[]);

  const customerSelectedItemCount = inventory?.filter((item) => isSelected(item, selectedIDs)).length;

  useEffect(() => {
    if (preselectedItemIDs.length <= 0 || !allItems || selectedIDs.size > 0) {
      return;
    }
    const preselected = allItems.filter((item) => preselectedItemIDs.includes(item.id));
    onChange(preselected);
    if (preselected.length) {
      setMode(Mode.Selected);
    }
  }, [inventory]);

  if (loading || activeOrdersLoading || !inventory) {
    return <Spinner />;
  }

  const shippingAvailable =
    type &&
    type !== OrderTypeEnum.PickupReturn &&
    inventory.some((item) => (item as InventoryItemFragment).shippingEligible);

  const hasActivePrimaryOrder = activeOrdersData && activeOrdersData.orders.filter((order) => order.primary).length > 0;
  const skipPrimaryOrdersCheck = !!(IMPERSONATOR && !IMPERSONATOR.roles.includes(User__Role.L1Agent));
  const disablePrimaryOrders = hasActivePrimaryOrder && !skipPrimaryOrdersCheck;

  return (
    <>
      {preselectedItemIDs && preselectedItemIDs.length > 0 && (
        <Flash
          flash={{
            message: DELAYED_ITEMS_SELECTED,
            kind: FlashKind.Info,
          }}
        />
      )}
      <Base onNext={onNext} onPrev={onPrev} valid={!!selectedIDs.size} orderSubtype={orderSubtype}>
        {shippingAvailable && <ShippingAvailableNotice />}
        <Header tag="h3">Select the items you want back from storage</Header>
        <>
          <Spacing mb={3}>
            <Filters all={inventory.length} selected={customerSelectedItemCount} mode={mode} onChange={setMode} />
          </Spacing>
          <Spacing mb={3}>
            <Inventory.Search loading={loading} query={queryString} onSearch={setQueryString} />
          </Spacing>
          <Spacing mb={3}>
            <Actions
              selectedIDs={selectedIDs}
              items={allItems}
              onChange={onChange}
              customerItemCount={inventory.length}
            />
          </Spacing>
          <ReturnInventory
            mode={mode}
            inventory={inventory}
            selections={selections}
            onChange={onChange}
            disableShippingTag={!shippingAvailable}
            disablePrimaryOrders={disablePrimaryOrders}
          />
        </>
      </Base>
    </>
  );
};
