import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { Button, COLORS, Text } from '@clutter/clean';
import { orderURL } from '@portal/config/routes';
import { IOrder } from '@portal/types';

import { Spacer } from '@shared/components/helpers';

import { client } from '@portal/libraries/apollo';
import { useCancellationPolicyQuery } from '@portal/schema';

const Title = styled(Text.Title)`
  margin-bottom: 24px;
`;

const Body = styled(Text.Body)`
  color: ${COLORS.hippo};
  white-space: pre-wrap;
`;

export const Cancel = ({ order }: { order: IOrder }) => {
  const { cancelable } = order.permissions;

  const { data } = useCancellationPolicyQuery({ client, variables: { orderID: order.id }, skip: !cancelable });

  return (
    <>
      <Title size="small">Need to cancel?</Title>
      {cancelable && data ? (
        <>
          <Link to={orderURL(order.id, 'cancel')}>
            <Button kind="destructive">Cancel</Button>
          </Link>
          <Spacer height="1rem" />
          <Body>{data.cancellationPolicy}</Body>
          {order.permissions.chargeLateFee && (
            <Body>$25 late fee for each extra day that your items were held in storage.</Body>
          )}
        </>
      ) : (
        <Body>
          This appointment cannot be canceled online. Please contact customer support if you need to modify your
          appointment time. Fees may apply.
        </Body>
      )}
    </>
  );
};
