import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  Date: string;
  /** An ISO 8601-encoded datetime */
  DateTime: string;
  Duration: string;
  /** Represents an email */
  Email: string;
  Hash: any;
  /** Represents untyped JSON */
  JSON: any;
  /** Represents a phone number */
  PhoneNumber: string;
};

export type AcceptClaimOfferInput = {
  address?: InputMaybe<NoDetailAddressInput>;
  email?: InputMaybe<Scalars['String']>;
  kind: ClaimPaymentOptionKind;
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

/** Autogenerated return type of AcceptClaimOffer */
export type AcceptClaimOfferPayload = {
  __typename?: 'AcceptClaimOfferPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of AcceptOffer */
export type AcceptOfferPayload = {
  __typename?: 'AcceptOfferPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  storageRate?: Maybe<Scalars['Float']>;
};

export type Account = {
  __typename?: 'Account';
  approvedPaymentPlanOffer?: Maybe<PaymentPlan__Offer>;
  bannedFromBooking: Scalars['Boolean'];
  baseAppointmentFee: Scalars['String'];
  contractStartDate?: Maybe<Scalars['Date']>;
  coupon?: Maybe<Coupon>;
  creditBalance?: Maybe<Scalars['Float']>;
  currentStorageSubscription?: Maybe<Subscription>;
  customer: Customer;
  /**
   * A total of the delinquency on an account. Note this is not intended
   * to be called across multiple accounts.
   */
  delinquentBalance?: Maybe<Scalars['Float']>;
  /** Cost information about the final return appointment. */
  finalOrderCostDetails: LaborCostDetails;
  firstOnboarding?: Maybe<Order>;
  flexerAndMoverOnly: Scalars['Boolean'];
  id: Scalars['ID'];
  ingestedDocuments: Array<ActiveStorageAttachment>;
  laborRate: Pricing__LaborRate;
  latestProtectionSubscription?: Maybe<Subscription>;
  latestSelfStoragePolicy?: Maybe<SelfStorage__Policy>;
  onboardable: Scalars['Boolean'];
  /** Cost information about the onboarding appointment. */
  onboardingCostDetails: LaborCostDetails;
  perItemFee: Scalars['String'];
  perItemPricing: Scalars['Boolean'];
  preferences: Account__PreferencesType;
  pricedAvailability: Scalars['Boolean'];
  rateGroup?: Maybe<Pricing__RateGroup>;
  region: Region;
  requestableItemsExist: Scalars['Boolean'];
  rescheduleOfferDetails?: Maybe<RescheduleOffer__DetailsType>;
  /** The set of active and pending self storage subscriptions on the account. */
  selfStorageSubscriptions: Array<SelfStorage__Subscription>;
  state: Account__State;
  storageTerm?: Maybe<Pricing__StorageTerm>;
  /** The set of active and pending subscriptions on the account. */
  subscriptionSet: SubscriptionSet;
  /** @deprecated Sub pickup and sub return cost details can be different. Use subsequent_pickup_cost_details and subsequent_return_cost_details instead. */
  subsequentOrderCostDetails: LaborCostDetails;
  /** Cost information about subsequent pickup appointments. */
  subsequentPickupCostDetails: LaborCostDetails;
  /** Cost information about subsequent return appointments. */
  subsequentReturnCostDetails: LaborCostDetails;
  summaryForSuggestedUpgradeDowngrade?: Maybe<UpgradeDowngradeSummary>;
  suspended: Scalars['Boolean'];
  termCommitment?: Maybe<Account__TermCommitment>;
  unacknowledgedDocuments: Array<Document>;
  unscheduledStorageTreasuresAuctionsWon: Array<StorageTreasures__AuctionType>;
  /** The set of active and pending self storage subscriptions with upcoming rental rates on the account. */
  upcomingSelfStorageSubscriptions: Array<SelfStorage__UpcomingSubscription>;
  /** The set of active and pending subscriptions on the account with upcoming subscription totals. */
  upcomingSubscriptionSet: UpcomingSubscriptionSet;
  usage: Usage;
};

export type AccountMutationInterface = {
  buildAccountCancelIntent?: Maybe<Account__BuildCancelIntentPayload>;
  chargeEarlyAccountClosureFee?: Maybe<Account__ChargeEarlyClosureFeePayload>;
};

export type AccountMutationInterfaceBuildAccountCancelIntentArgs = {
  orderID?: InputMaybe<Scalars['ID']>;
  reason: Account__CancelIntent__Reason;
};

export type AccountMutationInterfaceChargeEarlyAccountClosureFeeArgs = {
  scheduled?: InputMaybe<Scalars['DateTime']>;
  sourceID: Scalars['ID'];
};

export type AccountPackage = {
  __typename?: 'AccountPackage';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  kind: PackageKindEnum;
  name: Scalars['String'];
};

export type AccountResolutionMutationInterface = {
  accountResolutionOfferAccept?: Maybe<AccountResolution__OfferAcceptPayload>;
  accountResolutionOfferCreate?: Maybe<AccountResolution__OfferCreatePayload>;
  accountResolutionOfferReject?: Maybe<AccountResolution__OfferRejectPayload>;
};

export type AccountResolutionMutationInterfaceAccountResolutionOfferAcceptArgs = {
  category: Scalars['String'];
  offerID: Scalars['ID'];
  reasonID: Scalars['ID'];
};

export type AccountResolutionMutationInterfaceAccountResolutionOfferCreateArgs = {
  reasonID: Scalars['ID'];
};

export type AccountResolutionMutationInterfaceAccountResolutionOfferRejectArgs = {
  offerID: Scalars['ID'];
};

export type AccountResolutionQueryInterface = {
  accountResolutionReason: AccountResolution__Reason;
};

export type AccountResolutionQueryInterfaceAccountResolutionReasonArgs = {
  id?: InputMaybe<Scalars['ID']>;
  rootName?: InputMaybe<Scalars['String']>;
};

export type AccountResolution__CustomerTicket = {
  __typename?: 'AccountResolution__CustomerTicket';
  category: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  reason?: Maybe<AccountResolution__Reason>;
  state: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
};

export type AccountResolution__Offer = {
  __typename?: 'AccountResolution__Offer';
  id: Scalars['ID'];
  kind: AccountResolution__OfferKind;
  metadata: Scalars['JSON'];
};

/** Autogenerated return type of AccountResolution__OfferAccept */
export type AccountResolution__OfferAcceptPayload = {
  __typename?: 'AccountResolution__OfferAcceptPayload';
  customerTicket?: Maybe<AccountResolution__CustomerTicket>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of AccountResolution__OfferCreate */
export type AccountResolution__OfferCreatePayload = {
  __typename?: 'AccountResolution__OfferCreatePayload';
  error?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  offer?: Maybe<AccountResolution__Offer>;
  status: Status;
};

export enum AccountResolution__OfferKind {
  FinalMonthProration = 'FINAL_MONTH_PRORATION',
  WaiveRateAdjustment = 'WAIVE_RATE_ADJUSTMENT',
}

/** Autogenerated return type of AccountResolution__OfferReject */
export type AccountResolution__OfferRejectPayload = {
  __typename?: 'AccountResolution__OfferRejectPayload';
  error?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  status: Status;
};

export type AccountResolution__Reason = {
  __typename?: 'AccountResolution__Reason';
  canonicalName: Scalars['String'];
  children: Array<AccountResolution__ReasonChild>;
  copy: Scalars['String'];
  id: Scalars['ID'];
  offerKind?: Maybe<AccountResolution__OfferKind>;
  parentID?: Maybe<Scalars['ID']>;
  zendeskCopy?: Maybe<Scalars['String']>;
};

export type AccountResolution__ReasonChild = {
  __typename?: 'AccountResolution__ReasonChild';
  canonicalName: Scalars['String'];
  copy: Scalars['String'];
  id: Scalars['ID'];
  parentID: Scalars['ID'];
  position: Scalars['Int'];
};

/** Autogenerated return type of Account__BuildCancelIntent */
export type Account__BuildCancelIntentPayload = {
  __typename?: 'Account__BuildCancelIntentPayload';
  accountCancelIntent?: Maybe<Account__CancelIntent>;
  error?: Maybe<Scalars['String']>;
  retentionOffer?: Maybe<Retention__Offer>;
  status: Status;
};

export type Account__CancelIntent = {
  __typename?: 'Account__CancelIntent';
  id: Scalars['ID'];
  reason: Account__CancelIntent__Reason;
};

export enum Account__CancelIntent__Reason {
  AppointmentPrice = 'APPOINTMENT_PRICE',
  NegativeCx = 'NEGATIVE_CX',
  NothingToStore = 'NOTHING_TO_STORE',
  PayToVacate = 'PAY_TO_VACATE',
  PrefersSelfStorage = 'PREFERS_SELF_STORAGE',
  StoragePrice = 'STORAGE_PRICE',
}

/** Autogenerated return type of Account__ChargeEarlyClosureFee */
export type Account__ChargeEarlyClosureFeePayload = {
  __typename?: 'Account__ChargeEarlyClosureFeePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Account__PreferencesType = {
  __typename?: 'Account__PreferencesType';
  emailReceipts: Scalars['Boolean'];
  smsNotifications: Scalars['Boolean'];
};

export type Account__ReferralHistory = {
  __typename?: 'Account__ReferralHistory';
  creditsEarned: Scalars['Float'];
  referralCode: Scalars['String'];
  referralsCount: Scalars['Int'];
};

export enum Account__State {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED',
}

export type Account__TermCommitment = {
  __typename?: 'Account__TermCommitment';
  contractEndDate?: Maybe<Scalars['Date']>;
  fulfilled: Scalars['Boolean'];
  term: Scalars['Int'];
  type: Account__TermCommitment__Type;
};

export type Account__TermCommitmentBalance = {
  __typename?: 'Account__TermCommitmentBalance';
  amountDue: Scalars['Float'];
  owedInvoices: Array<Billing__EarlyClosureFeeInvoice>;
  paidInvoices: Array<Billing__Invoice>;
  term: Scalars['Int'];
};

export enum Account__TermCommitment__Type {
  PaymentPlanMonthlyFeeTerm = 'PAYMENT_PLAN_MONTHLY_FEE_TERM',
  PaymentPlanStorageTerm = 'PAYMENT_PLAN_STORAGE_TERM',
  StorageTerm = 'STORAGE_TERM',
}

export type AcknowledgeDocumentsInput = {
  documentID: Scalars['ID'];
  kind: DocumentAcknowledgmentKind;
};

/** Autogenerated return type of AcknowledgeDocuments */
export type AcknowledgeDocumentsPayload = {
  __typename?: 'AcknowledgeDocumentsPayload';
  status: Status;
};

/** Autogenerated return type of ActivateAccount */
export type ActivateAccountPayload = {
  __typename?: 'ActivateAccountPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type ActiveStorageAttachment = {
  __typename?: 'ActiveStorageAttachment';
  filename: Scalars['String'];
  id: Scalars['String'];
  imgixURL: Scalars['String'];
  url: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  aptsuite?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  details?: Maybe<AddressDetail>;
  /** The street, apartment number, city, state, and zip code of the address. */
  formatted: Scalars['String'];
  /** The street, city, state, and zip code of the address. */
  formattedWithoutApartmentNumber: Scalars['String'];
  id: Scalars['ID'];
  kinds: Array<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  map: Scalars['String'];
  neighborhood?: Maybe<Scalars['String']>;
  outsideServiceArea: Scalars['Boolean'];
  region?: Maybe<Region>;
  safeAndSanitary?: Maybe<Scalars['Boolean']>;
  schedulable: Scalars['Boolean'];
  serviceable: Scalars['Boolean'];
  state: Scalars['String'];
  stateAbbreviation: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressDetail = {
  __typename?: 'AddressDetail';
  accessUnknown: Scalars['Boolean'];
  additionalNotes?: Maybe<Scalars['String']>;
  buildingClosingHour?: Maybe<Scalars['Int']>;
  buildingOpeningHour?: Maybe<Scalars['Int']>;
  buildingRestrictionsAnswer?: Maybe<AddressDetailBuildingRestrictionsAnswer>;
  buildingSubtype?: Maybe<Scalars['String']>;
  buildingType?: Maybe<Scalars['String']>;
  code: Scalars['Boolean'];
  codeValue?: Maybe<Scalars['String']>;
  elevator: Scalars['Boolean'];
  environment?: Maybe<AddressDetailEnvironmentEnum>;
  floor?: Maybe<Scalars['Int']>;
  frontDoorSteps?: Maybe<Scalars['Int']>;
  gated: Scalars['Boolean'];
  id: Scalars['ID'];
  loadingDock: Scalars['Boolean'];
  longCarry: Scalars['Boolean'];
  maxTruckHeight?: Maybe<Scalars['Int']>;
  maxTruckHeightUnknown: Scalars['Boolean'];
  paperwork: Scalars['Boolean'];
  paperworkUnknown: Scalars['Boolean'];
  parkingAlley: Scalars['Boolean'];
  parkingDriveway: Scalars['Boolean'];
  parkingInFront: Scalars['Boolean'];
  parkingInstructions?: Maybe<Scalars['String']>;
  parkingLatitude?: Maybe<Scalars['Float']>;
  parkingLongitude?: Maybe<Scalars['Float']>;
  parkingLot: Scalars['Boolean'];
  parkingOnSpecificStreet?: Maybe<Scalars['String']>;
  parkingPermit: Scalars['Boolean'];
  parkingStreet: Scalars['Boolean'];
  parkingUnknown: Scalars['Boolean'];
  secureLocationDetails?: Maybe<Scalars['String']>;
  serviceElevator: Scalars['Boolean'];
  serviceEntrance: Scalars['Boolean'];
  squareFeet?: Maybe<Scalars['Int']>;
  stairs: Scalars['Boolean'];
  steep: Scalars['Boolean'];
  stories?: Maybe<Scalars['Int']>;
  unitSize?: Maybe<AddressDetailUnitSizeEnum>;
};

export enum AddressDetailBuildingRestrictionsAnswer {
  No = 'NO',
  Unknown = 'UNKNOWN',
  Yes = 'YES',
}

export enum AddressDetailEnvironmentEnum {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
}

export type AddressDetailInput = {
  accessUnknown?: InputMaybe<Scalars['Boolean']>;
  additionalNotes?: InputMaybe<Scalars['String']>;
  buildingClosingHour?: InputMaybe<Scalars['Int']>;
  buildingOpeningHour?: InputMaybe<Scalars['Int']>;
  buildingRestrictionsAnswer?: InputMaybe<AddressDetailBuildingRestrictionsAnswer>;
  buildingSubtype?: InputMaybe<Scalars['String']>;
  buildingType?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['Boolean']>;
  codeValue?: InputMaybe<Scalars['String']>;
  elevator?: InputMaybe<Scalars['Boolean']>;
  environment?: InputMaybe<AddressDetailEnvironmentEnum>;
  floor?: InputMaybe<Scalars['Int']>;
  frontDoorSteps?: InputMaybe<Scalars['Int']>;
  gated?: InputMaybe<Scalars['Boolean']>;
  loadingDock?: InputMaybe<Scalars['Boolean']>;
  longCarry?: InputMaybe<Scalars['Boolean']>;
  maxTruckHeight?: InputMaybe<Scalars['Int']>;
  maxTruckHeightUnknown?: InputMaybe<Scalars['Boolean']>;
  paperwork?: InputMaybe<Scalars['Boolean']>;
  paperworkUnknown?: InputMaybe<Scalars['Boolean']>;
  parkingAlley?: InputMaybe<Scalars['Boolean']>;
  parkingDriveway?: InputMaybe<Scalars['Boolean']>;
  parkingInFront?: InputMaybe<Scalars['Boolean']>;
  parkingInstructions?: InputMaybe<Scalars['String']>;
  parkingLatitude?: InputMaybe<Scalars['Float']>;
  parkingLongitude?: InputMaybe<Scalars['Float']>;
  parkingLot?: InputMaybe<Scalars['Boolean']>;
  parkingOnSpecificStreet?: InputMaybe<Scalars['String']>;
  parkingPermit?: InputMaybe<Scalars['Boolean']>;
  parkingStreet?: InputMaybe<Scalars['Boolean']>;
  parkingUnknown?: InputMaybe<Scalars['Boolean']>;
  secureLocationDetails?: InputMaybe<Scalars['String']>;
  serviceElevator?: InputMaybe<Scalars['Boolean']>;
  serviceEntrance?: InputMaybe<Scalars['Boolean']>;
  squareFeet?: InputMaybe<Scalars['Int']>;
  stairs?: InputMaybe<Scalars['Boolean']>;
  steep?: InputMaybe<Scalars['Boolean']>;
  stories?: InputMaybe<Scalars['Int']>;
  unitSize?: InputMaybe<AddressDetailUnitSizeEnum>;
};

export enum AddressDetailUnitSizeEnum {
  Size_5X5 = 'SIZE_5X5',
  Size_5X10 = 'SIZE_5X10',
  Size_10X10 = 'SIZE_10X10',
  Size_10X15 = 'SIZE_10X15',
  Size_10X20 = 'SIZE_10X20',
  Size_10X25 = 'SIZE_10X25',
  Size_10X30 = 'SIZE_10X30',
  SizeLocker = 'SIZE_LOCKER',
}

export type AddressDetailsUpdateInput = {
  addressInput: AddressInput;
  destinationAddressInput?: InputMaybe<AddressInput>;
  orderID: Scalars['ID'];
};

/** Autogenerated return type of AddressDetailsUpdate */
export type AddressDetailsUpdatePayload = {
  __typename?: 'AddressDetailsUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type AddressInput = {
  aptsuite?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  details: AddressDetailInput;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

/** Autogenerated return type of AddressUpdate */
export type AddressUpdatePayload = {
  __typename?: 'AddressUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Admin__Document = {
  __typename?: 'Admin__Document';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  version: Scalars['Int'];
};

/** Autogenerated return type of AttachClaimIssueSelectionAttachment */
export type AttachClaimIssueSelectionAttachmentPayload = {
  __typename?: 'AttachClaimIssueSelectionAttachmentPayload';
  attachment?: Maybe<ActiveStorageAttachment>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of AttachItemSelectionAttachment */
export type AttachItemSelectionAttachmentPayload = {
  __typename?: 'AttachItemSelectionAttachmentPayload';
  attachment?: Maybe<ActiveStorageAttachment>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of AttachPropertySelectionAttachment */
export type AttachPropertySelectionAttachmentPayload = {
  __typename?: 'AttachPropertySelectionAttachmentPayload';
  attachment?: Maybe<ActiveStorageAttachment>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type AvailabilitiesInput = {
  from: Scalars['DateTime'];
  kind: AvailabilitiesInputKind;
  moverOverride?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OrderInput>;
  orderID?: InputMaybe<Scalars['ID']>;
  subscriptions?: InputMaybe<Array<SubscriptionInput>>;
  till: Scalars['DateTime'];
};

export enum AvailabilitiesInputKind {
  Booking = 'BOOKING',
  Rescheduling = 'RESCHEDULING',
}

export type Availability = {
  __typename?: 'Availability';
  appointmentFee?: Maybe<Scalars['String']>;
  available: Scalars['Boolean'];
  datetime: Scalars['DateTime'];
  duration?: Maybe<Scalars['Duration']>;
  feeAmount?: Maybe<MonetaryAmount>;
  laborRate?: Maybe<Pricing__LaborRate>;
  perMoverHourAdjustmentAmount?: Maybe<Scalars['Float']>;
  waitlistable: Scalars['Boolean'];
};

export type AvailabilityMutationInterface = {
  availabilityWaitlistRequestCreate?: Maybe<Availability__WaitlistRequestCreatePayload>;
  availabilityWaitlistRequestDestroy?: Maybe<Availability__WaitlistRequestDestroyPayload>;
};

export type AvailabilityMutationInterfaceAvailabilityWaitlistRequestCreateArgs = {
  input: Availability__WaitlistRequestCreateInput;
};

export type AvailabilityMutationInterfaceAvailabilityWaitlistRequestDestroyArgs = {
  input: Availability__WaitlistRequestDestroyInput;
};

export type AvailabilityTier = {
  __typename?: 'AvailabilityTier';
  description: Scalars['String'];
  label: Scalars['String'];
  price: Scalars['String'];
  type: AvailabilityTierTypeEnum;
};

export enum AvailabilityTierTypeEnum {
  Base = 'BASE',
  Priority = 'PRIORITY',
  Standard = 'STANDARD',
}

export type AvailabilityTiersInputType = {
  kind: AvailabilitiesInputKind;
  order?: InputMaybe<OrderInput>;
  orderID?: InputMaybe<Scalars['ID']>;
};

export type Availability__WaitlistRequest = {
  __typename?: 'Availability__WaitlistRequest';
  date: Scalars['Date'];
  id: Scalars['ID'];
};

export type Availability__WaitlistRequestCreateInput = {
  date: Scalars['Date'];
  orderID: Scalars['ID'];
};

/** Autogenerated return type of Availability__WaitlistRequestCreate */
export type Availability__WaitlistRequestCreatePayload = {
  __typename?: 'Availability__WaitlistRequestCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  waitlistRequest?: Maybe<Availability__WaitlistRequest>;
};

export type Availability__WaitlistRequestDestroyInput = {
  IDs: Array<Scalars['ID']>;
  orderID: Scalars['ID'];
};

/** Autogenerated return type of Availability__WaitlistRequestDestroy */
export type Availability__WaitlistRequestDestroyPayload = {
  __typename?: 'Availability__WaitlistRequestDestroyPayload';
  deleted: Array<Availability__WaitlistRequest>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Barcode = {
  __typename?: 'Barcode';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type BillingMutationInterface = {
  buildPrepayment?: Maybe<Billing__BuildPrepaymentPayload>;
  buildSource?: Maybe<Billing__BuildSourcePayload>;
  defaultSource?: Maybe<Billing__DefaultSourcePayload>;
  destroySource?: Maybe<Billing__DestroySourcePayload>;
  sendReceipt?: Maybe<Billing__SendReceiptPayload>;
  swapPaymentMethod?: Maybe<Billing__SwapPaymentMethodPayload>;
};

export type BillingMutationInterfaceBuildPrepaymentArgs = {
  amount: Scalars['Float'];
};

export type BillingMutationInterfaceBuildSourceArgs = {
  input: Billing__BuildSourceInput;
};

export type BillingMutationInterfaceDefaultSourceArgs = {
  id: Scalars['ID'];
};

export type BillingMutationInterfaceDestroySourceArgs = {
  id: Scalars['ID'];
};

export type BillingMutationInterfaceSendReceiptArgs = {
  invoiceID: Scalars['ID'];
};

export type BillingMutationInterfaceSwapPaymentMethodArgs = {
  input: Billing__SwapPaymentMethodInput;
};

export type BillingQueryInterface = {
  defaultSource?: Maybe<Billing__Source>;
  invoice: Billing__Invoice;
  invoices: Array<Billing__Invoice>;
  paginatedInvoiceList: Billing__PaginatedInvoice;
  sources: Array<Billing__Source>;
  upcomingInvoices: Array<Billing__UpcomingInvoice>;
};

export type BillingQueryInterfaceInvoiceArgs = {
  invoiceID: Scalars['ID'];
};

export type BillingQueryInterfaceInvoicesArgs = {
  status?: InputMaybe<Billing__Invoice__Status>;
};

export type BillingQueryInterfacePaginatedInvoiceListArgs = {
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of Billing__BuildPrepayment */
export type Billing__BuildPrepaymentPayload = {
  __typename?: 'Billing__BuildPrepaymentPayload';
  account?: Maybe<Account>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Billing__BuildSourceInput = {
  makeDefault: Scalars['Boolean'];
  signedAccountID?: InputMaybe<Scalars['String']>;
  signedOrderID?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

/** Autogenerated return type of Billing__BuildSource */
export type Billing__BuildSourcePayload = {
  __typename?: 'Billing__BuildSourcePayload';
  error?: Maybe<Scalars['String']>;
  source?: Maybe<Billing__Source>;
  status: Status;
};

export type Billing__Charge = {
  __typename?: 'Billing__Charge';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  source: Billing__Source;
  status: Billing__Charge__Status;
};

export enum Billing__Charge__Status {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
}

/** Autogenerated return type of Billing__DefaultSource */
export type Billing__DefaultSourcePayload = {
  __typename?: 'Billing__DefaultSourcePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Billing__DestroySource */
export type Billing__DestroySourcePayload = {
  __typename?: 'Billing__DestroySourcePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Billing__EarlyClosureFeeInvoice = {
  __typename?: 'Billing__EarlyClosureFeeInvoice';
  amountDue: Scalars['Float'];
  date: Scalars['Date'];
  summary: Scalars['String'];
};

export type Billing__Invoice = {
  __typename?: 'Billing__Invoice';
  amountDue: Scalars['Float'];
  amountOwed: Scalars['Float'];
  appliedCredits: Scalars['Float'];
  charges: Array<Billing__Charge>;
  date?: Maybe<Scalars['DateTime']>;
  discountAmount: Scalars['Float'];
  /** The display ID of this invoice as it may have been superseded if the invoice was split. */
  displayID: Scalars['ID'];
  displayName: Scalars['String'];
  displayStatus: Billing__Invoice__Status;
  failedAt?: Maybe<Scalars['Date']>;
  forgiven: Scalars['Boolean'];
  forgivenessReasonKind?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceItems: Array<Billing__InvoiceItem>;
  lines: Array<Scalars['String']>;
  lumpSumInvoice?: Maybe<Billing__Invoice>;
  offlinePayments: Array<Billing__OfflinePayment>;
  order?: Maybe<Order>;
  paidAt?: Maybe<Scalars['Date']>;
  payable: Scalars['Boolean'];
  rebillable: Scalars['Boolean'];
  refundedAt?: Maybe<Scalars['Date']>;
  refunds: Array<Billing__Refund>;
  selfStorageSubscription?: Maybe<SelfStorage__Subscription>;
  settledInvoices?: Maybe<Array<Billing__Invoice>>;
  status: Billing__Invoice__Status;
  stripeCreatedAt?: Maybe<Scalars['DateTime']>;
  subscription?: Maybe<Subscription>;
  subtotal: Scalars['Float'];
  summary?: Maybe<Scalars['String']>;
  swapPaymentInvoices: Array<Billing__Invoice>;
  tax: Scalars['Float'];
  total: Scalars['Float'];
};

export type Billing__InvoiceEventType = {
  __typename?: 'Billing__InvoiceEventType';
  completed: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoice: Billing__Invoice;
};

export type Billing__InvoiceItem = {
  __typename?: 'Billing__InvoiceItem';
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  displayDescription: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  unitPrice?: Maybe<Scalars['Float']>;
};

export enum Billing__Invoice__Status {
  Failed = 'FAILED',
  FinalAttemptFailed = 'FINAL_ATTEMPT_FAILED',
  NotAttempted = 'NOT_ATTEMPTED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
}

export type Billing__Invoiceable = Order | SelfStorage__AdjustedRateRental | UpcomingSubscription;

export type Billing__OfflinePayment = {
  __typename?: 'Billing__OfflinePayment';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  kind: Billing__OfflinePayment__Kind;
  summary?: Maybe<Scalars['String']>;
};

export enum Billing__OfflinePayment__Kind {
  Ach = 'ACH',
  Cash = 'CASH',
  Check = 'CHECK',
  MoneyOrder = 'MONEY_ORDER',
  Other = 'OTHER',
}

export type Billing__PaginatedInvoice = {
  __typename?: 'Billing__PaginatedInvoice';
  pagination: Pagination;
  results: Array<Billing__Invoice>;
};

export type Billing__Prepayment = {
  __typename?: 'Billing__Prepayment';
  amount: Scalars['Float'];
  dueAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type Billing__Refund = {
  __typename?: 'Billing__Refund';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  source: Billing__Source;
};

/** Autogenerated return type of Billing__SendReceipt */
export type Billing__SendReceiptPayload = {
  __typename?: 'Billing__SendReceiptPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Billing__Source = {
  __typename?: 'Billing__Source';
  brand?: Maybe<Scalars['String']>;
  default: Scalars['Boolean'];
  expiration?: Maybe<Scalars['String']>;
  expired: Scalars['Boolean'];
  fundingType: Billing__Source__FundingType;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  number: Scalars['String'];
};

export enum Billing__Source__FundingType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN',
}

export type Billing__SwapPaymentMethodInput = {
  invoiceID: Scalars['ID'];
  sourceID: Scalars['ID'];
};

/** Autogenerated return type of Billing__SwapPaymentMethod */
export type Billing__SwapPaymentMethodPayload = {
  __typename?: 'Billing__SwapPaymentMethodPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  swapped?: Maybe<Scalars['Boolean']>;
};

export type Billing__UpcomingInvoice = {
  __typename?: 'Billing__UpcomingInvoice';
  billDate: Scalars['Date'];
  estimatedAmount: Scalars['String'];
  invoiceable: Billing__Invoiceable;
};

export type BookingDisclaimer = {
  __typename?: 'BookingDisclaimer';
  accountTerminationDisclaimer?: Maybe<Scalars['String']>;
  authHoldDisclaimer?: Maybe<Scalars['String']>;
  orderCancellationDisclaimer?: Maybe<Scalars['String']>;
  orderLaborDisclaimer?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of BuildAddress */
export type BuildAddressPayload = {
  __typename?: 'BuildAddressPayload';
  address?: Maybe<Address>;
  error?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of BuildClaimItemSelectionBatch */
export type BuildClaimItemSelectionBatchPayload = {
  __typename?: 'BuildClaimItemSelectionBatchPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type BuildClaimItemSelectionInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  itemID?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of BuildClaim */
export type BuildClaimPayload = {
  __typename?: 'BuildClaimPayload';
  claim?: Maybe<Claim>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type BuildClaimPropertySelectionInput = {
  declaration?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<BuildClaimPropertySelectionOwnerInput>;
  ownership?: InputMaybe<ClaimPropertySelectionOwnership>;
};

export type BuildClaimPropertySelectionOwnerInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

/** Autogenerated return type of BuildClaimPropertySelection */
export type BuildClaimPropertySelectionPayload = {
  __typename?: 'BuildClaimPropertySelectionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type BuildCustomerTicketInput = {
  category: Scalars['String'];
  contactPreference?: InputMaybe<CustomerTicket__ContactPreference>;
  description: Scalars['String'];
  noteBody?: InputMaybe<Scalars['String']>;
  offerID?: InputMaybe<Scalars['ID']>;
  reasonID?: InputMaybe<Scalars['ID']>;
  signedIDs: Array<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  subCategory?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of BuildCustomerTicket */
export type BuildCustomerTicketPayload = {
  __typename?: 'BuildCustomerTicketPayload';
  customerTicketID?: Maybe<Scalars['ID']>;
  error?: Maybe<Scalars['String']>;
  status: Status;
  zendeskTicketID?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of BuildOrderCOIRequest */
export type BuildOrderCoiRequestPayload = {
  __typename?: 'BuildOrderCOIRequestPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of BuildOrder */
export type BuildOrderPayload = {
  __typename?: 'BuildOrderPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type BuildReviewInput = {
  comments?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Rating>;
  reasons?: InputMaybe<Scalars['Hash']>;
  reviewable: Review__ReviewableInput;
  schedulingID?: InputMaybe<Scalars['ID']>;
  score?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of BuildReview */
export type BuildReviewPayload = {
  __typename?: 'BuildReviewPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of CancelOrder */
export type CancelOrderPayload = {
  __typename?: 'CancelOrderPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Claim = {
  __typename?: 'Claim';
  closedAt?: Maybe<Scalars['DateTime']>;
  hasDamagedItem: Scalars['Boolean'];
  hasDamagedProperty: Scalars['Boolean'];
  hasIssue: Scalars['Boolean'];
  hasLostItem: Scalars['Boolean'];
  id: Scalars['ID'];
  issueSelection?: Maybe<ClaimIssueSelection>;
  itemSelections: Array<ClaimItemSelection>;
  offer?: Maybe<ClaimOffer>;
  openedAt?: Maybe<Scalars['DateTime']>;
  paymentOption?: Maybe<ClaimPaymentOption>;
  propertySelection?: Maybe<ClaimPropertySelection>;
  settlementAgreement?: Maybe<ActiveStorageAttachment>;
  status: ClaimStatus;
  step?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ClaimIssueSelection = {
  __typename?: 'ClaimIssueSelection';
  compensationRequested?: Maybe<Scalars['Boolean']>;
  declaration?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind?: Maybe<ClaimIssueSelectionKind>;
  photos: Array<ActiveStorageAttachment>;
};

export enum ClaimIssueSelectionKind {
  Appointment = 'APPOINTMENT',
  Billing = 'BILLING',
  Other = 'OTHER',
}

export type ClaimItemSelection = {
  __typename?: 'ClaimItemSelection';
  declaration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  item?: Maybe<Item>;
  kind: ClaimItemSelectionKind;
  name?: Maybe<Scalars['String']>;
  photos: Array<ActiveStorageAttachment>;
  productURL?: Maybe<Scalars['String']>;
  receipts: Array<ActiveStorageAttachment>;
  tracked: Scalars['Boolean'];
};

export enum ClaimItemSelectionAttachmentName {
  Photos = 'PHOTOS',
  Receipts = 'RECEIPTS',
}

export type ClaimItemSelectionInput = {
  declaration?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  productURL?: InputMaybe<Scalars['String']>;
};

export enum ClaimItemSelectionKind {
  Damaged = 'DAMAGED',
  Lost = 'LOST',
}

export type ClaimKindsInput = {
  hasDamagedItem: Scalars['Boolean'];
  hasDamagedProperty: Scalars['Boolean'];
  hasIssue: Scalars['Boolean'];
  hasLostItem: Scalars['Boolean'];
  uuid: Scalars['String'];
};

export type ClaimMutationInterface = {
  attachClaimIssueSelectionAttachment?: Maybe<AttachClaimIssueSelectionAttachmentPayload>;
  claimIssueSelectionDestroy?: Maybe<Claim__IssueSelectionDestroyPayload>;
  claimIssueSelectionUpdate?: Maybe<Claim__IssueSelectionUpdatePayload>;
  detachClaimIssueSelectionAttachment?: Maybe<DetachClaimIssueSelectionAttachmentPayload>;
};

export type ClaimMutationInterfaceAttachClaimIssueSelectionAttachmentArgs = {
  signedID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type ClaimMutationInterfaceClaimIssueSelectionDestroyArgs = {
  uuid: Scalars['ID'];
};

export type ClaimMutationInterfaceClaimIssueSelectionUpdateArgs = {
  input: Claim__IssueSelectionUpdateInput;
  uuid: Scalars['String'];
};

export type ClaimMutationInterfaceDetachClaimIssueSelectionAttachmentArgs = {
  attachmentID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type ClaimOffer = {
  __typename?: 'ClaimOffer';
  createdAt: Scalars['DateTime'];
  details: Scalars['String'];
  expiry: Scalars['DateTime'];
  id: Scalars['ID'];
  totalAmount: Scalars['Float'];
  user: User;
};

export type ClaimPaymentOption = {
  __typename?: 'ClaimPaymentOption';
  address?: Maybe<Address>;
  email?: Maybe<Scalars['String']>;
  kind: ClaimPaymentOptionKind;
  name: Scalars['String'];
};

export enum ClaimPaymentOptionKind {
  Check = 'CHECK',
  Credit = 'CREDIT',
  Paypal = 'PAYPAL',
  Refund = 'REFUND',
}

export type ClaimPropertyOwner = {
  __typename?: 'ClaimPropertyOwner';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type ClaimPropertySelection = {
  __typename?: 'ClaimPropertySelection';
  declaration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<ClaimPropertyOwner>;
  ownership?: Maybe<ClaimPropertySelectionOwnership>;
  photos: Array<ActiveStorageAttachment>;
  quotes: Array<ActiveStorageAttachment>;
};

export enum ClaimPropertySelectionAttachmentName {
  Photos = 'PHOTOS',
  Quotes = 'QUOTES',
}

export enum ClaimPropertySelectionOwnership {
  Customer = 'CUSTOMER',
  Landlord = 'LANDLORD',
}

export type ClaimQueryInterface = {
  claim?: Maybe<Claim>;
  claimPreview: Scalars['String'];
  claims: Array<Claim>;
};

export type ClaimQueryInterfaceClaimArgs = {
  uuid: Scalars['String'];
};

export type ClaimQueryInterfaceClaimPreviewArgs = {
  address?: InputMaybe<NoDetailAddressInput>;
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum ClaimStatus {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  InReview = 'IN_REVIEW',
}

/** Autogenerated return type of Claim__IssueSelectionDestroy */
export type Claim__IssueSelectionDestroyPayload = {
  __typename?: 'Claim__IssueSelectionDestroyPayload';
  claimIssueSelection?: Maybe<ClaimIssueSelection>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Claim__IssueSelectionUpdateInput = {
  compensationRequested?: InputMaybe<Scalars['Boolean']>;
  declaration?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<ClaimIssueSelectionKind>;
};

/** Autogenerated return type of Claim__IssueSelectionUpdate */
export type Claim__IssueSelectionUpdatePayload = {
  __typename?: 'Claim__IssueSelectionUpdatePayload';
  claimIssueSelection?: Maybe<ClaimIssueSelection>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type ComplianceMutationInterface = {
  shipmentValuationUpdate?: Maybe<Compliance__ShipmentValuationUpdatePayload>;
};

export type ComplianceMutationInterfaceShipmentValuationUpdateArgs = {
  input: Compliance__ShipmentValuationUpdateInput;
};

export type ComplianceQueryInterface = {
  correctCustomerLoggedIn: Scalars['Boolean'];
  declaredCoverage?: Maybe<ShipmentValuationDeclaration>;
  needSignature?: Maybe<Scalars['String']>;
  offerInTruckProtection: Scalars['Boolean'];
  orderSignatures?: Maybe<Order>;
  shipmentValuationCoverages: Array<ShipmentValuationCoverage>;
};

export type ComplianceQueryInterfaceCorrectCustomerLoggedInArgs = {
  input: SignatureRequestInput;
};

export type ComplianceQueryInterfaceDeclaredCoverageArgs = {
  orderID: Scalars['ID'];
};

export type ComplianceQueryInterfaceOfferInTruckProtectionArgs = {
  input: SignatureRequestInput;
};

export type ComplianceQueryInterfaceOrderSignaturesArgs = {
  input: SignatureRequestInput;
};

export type ComplianceQueryInterfaceShipmentValuationCoveragesArgs = {
  orderID: Scalars['ID'];
};

export type Compliance__ShipmentValuationUpdateInput = {
  coverageID?: InputMaybe<Scalars['String']>;
  declaredValue?: InputMaybe<Scalars['Float']>;
  orderID: Scalars['String'];
};

/** Autogenerated return type of Compliance__ShipmentValuationUpdate */
export type Compliance__ShipmentValuationUpdatePayload = {
  __typename?: 'Compliance__ShipmentValuationUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of ConfirmOrder */
export type ConfirmOrderPayload = {
  __typename?: 'ConfirmOrderPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<ConfirmedOrder>;
  status: Status;
};

/** Autogenerated return type of ConfirmUpgradeDowngradeForSuggestedPricing */
export type ConfirmUpgradeDowngradeForSuggestedPricingPayload = {
  __typename?: 'ConfirmUpgradeDowngradeForSuggestedPricingPayload';
  account: Account;
};

/** A partial order representation suitable for usage with signed links */
export type ConfirmedOrder = {
  __typename?: 'ConfirmedOrder';
  accountReferralCode: Scalars['String'];
  id: Scalars['ID'];
  scheduled: Scalars['DateTime'];
};

export type Coupon = {
  __typename?: 'Coupon';
  amount: Scalars['Float'];
  discountDescription: Scalars['String'];
  discountDisclaimer?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  percent: Scalars['Float'];
};

/** Autogenerated return type of CouponApply */
export type CouponApplyPayload = {
  __typename?: 'CouponApplyPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export enum Currency {
  Cad = 'CAD',
  Usd = 'USD',
}

export type CustomDimensionInput = {
  height?: InputMaybe<Scalars['Float']>;
  length: Scalars['Float'];
  width: Scalars['Float'];
};

export type Customer = {
  __typename?: 'Customer';
  email: Scalars['Email'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialSetupToken?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['PhoneNumber'];
};

export type CustomerInitialSetupInput = {
  initialSetupToken: Scalars['String'];
  password: Scalars['String'];
};

/** Autogenerated return type of CustomerInitialSetup */
export type CustomerInitialSetupPayload = {
  __typename?: 'CustomerInitialSetupPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type CustomerItem = Item | ItemGroup;

export type CustomerItemInput = {
  contents?: InputMaybe<Scalars['String']>;
  heroImageID?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum CustomerItemTypeEnum {
  Item = 'Item',
  ItemGroup = 'ItemGroup',
}

export type CustomerMutationInterface = {
  customerNameUpdate?: Maybe<Customer__NameUpdatePayload>;
  customerVerifiedEmailUpdate?: Maybe<Customer__VerifiedEmailUpdatePayload>;
  customerVerifiedPhoneUpdate?: Maybe<Customer__VerifiedPhoneUpdatePayload>;
};

export type CustomerMutationInterfaceCustomerNameUpdateArgs = {
  name: Scalars['String'];
};

export type CustomerMutationInterfaceCustomerVerifiedEmailUpdateArgs = {
  verifiedEmail: Scalars['String'];
};

export type CustomerMutationInterfaceCustomerVerifiedPhoneUpdateArgs = {
  verifiedPhoneNumber: Scalars['String'];
};

export enum CustomerTicket__ContactPreference {
  Chat = 'CHAT',
  Email = 'EMAIL',
  Phone = 'PHONE',
}

/** Autogenerated return type of Customer__NameUpdate */
export type Customer__NameUpdatePayload = {
  __typename?: 'Customer__NameUpdatePayload';
  customer: Customer;
};

/** Autogenerated return type of Customer__VerifiedEmailUpdate */
export type Customer__VerifiedEmailUpdatePayload = {
  __typename?: 'Customer__VerifiedEmailUpdatePayload';
  customer: Customer;
};

/** Autogenerated return type of Customer__VerifiedPhoneUpdate */
export type Customer__VerifiedPhoneUpdatePayload = {
  __typename?: 'Customer__VerifiedPhoneUpdatePayload';
  customer: Customer;
};

export type Delinquency = {
  __typename?: 'Delinquency';
  balance: Scalars['Float'];
};

/** Autogenerated return type of DestroyAddress */
export type DestroyAddressPayload = {
  __typename?: 'DestroyAddressPayload';
  status: Status;
};

/** Autogenerated return type of DestroyClaimItemSelection */
export type DestroyClaimItemSelectionPayload = {
  __typename?: 'DestroyClaimItemSelectionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DestroyClaim */
export type DestroyClaimPayload = {
  __typename?: 'DestroyClaimPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DestroyClaimPropertySelection */
export type DestroyClaimPropertySelectionPayload = {
  __typename?: 'DestroyClaimPropertySelectionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DetachClaimIssueSelectionAttachment */
export type DetachClaimIssueSelectionAttachmentPayload = {
  __typename?: 'DetachClaimIssueSelectionAttachmentPayload';
  attachment?: Maybe<ActiveStorageAttachment>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DetachItemSelectionAttachment */
export type DetachItemSelectionAttachmentPayload = {
  __typename?: 'DetachItemSelectionAttachmentPayload';
  attachment?: Maybe<ActiveStorageAttachment>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of DetachPropertySelectionAttachment */
export type DetachPropertySelectionAttachmentPayload = {
  __typename?: 'DetachPropertySelectionAttachmentPayload';
  attachment: ActiveStorageAttachment;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type DisposalQueryInterface = {
  disposalCategoryPricing: Array<Disposal__CategoryPricingDescriptor>;
  disposalItemPricing: Array<Disposal__ItemPricingDescriptor>;
};

export type DisposalQueryInterfaceDisposalItemPricingArgs = {
  itemIds: Array<Scalars['ID']>;
};

export type Disposal__CategoryPricingDescriptor = {
  __typename?: 'Disposal__CategoryPricingDescriptor';
  category: Scalars['String'];
  id: Scalars['ID'];
  minimum: Scalars['Float'];
};

/** Autogenerated return type of Disposal__Confirm */
export type Disposal__ConfirmPayload = {
  __typename?: 'Disposal__ConfirmPayload';
  disposal?: Maybe<Order>;
  error?: Maybe<Scalars['String']>;
  signingURL?: Maybe<Scalars['String']>;
  status: Status;
};

export type Disposal__Confirm__StoragePlanInput = {
  planID: Scalars['ID'];
  quoteID: Scalars['ID'];
};

export type Disposal__ItemPricingDescriptor = {
  __typename?: 'Disposal__ItemPricingDescriptor';
  amount: Scalars['Float'];
  category: Scalars['String'];
  id: Scalars['ID'];
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum DocumentAcknowledgmentKind {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export enum DoorToDoor__BundleEnum {
  Basic = 'BASIC',
  PickupAndPacking = 'PICKUP_AND_PACKING',
}

export type DropOff__Access = {
  __typename?: 'DropOff__Access';
  customerCode: Scalars['String'];
  id: Scalars['ID'];
  revealedAt?: Maybe<Scalars['DateTime']>;
};

export type DropOff__Appointment = {
  __typename?: 'DropOff__Appointment';
  access?: Maybe<DropOff__Access>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  direction: DropOff__AppointmentDirectionEnum;
  expectedAt: Scalars['DateTime'];
  facility: DropOff__Facility;
  id: Scalars['ID'];
  order: Order;
  state: DropOff__AppointmentStateEnum;
  status: OrderStatusEnum;
  storageArrangement?: Maybe<DropOff__StorageArrangement>;
};

export enum DropOff__AppointmentDirectionEnum {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND',
}

export enum DropOff__AppointmentStateEnum {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type DropOff__Facility = {
  __typename?: 'DropOff__Facility';
  address: Address;
  available: Scalars['Boolean'];
  fulfiller: DropOff__Fulfiller;
  hasEmployees: Scalars['Boolean'];
  hours: Array<Scalars['String']>;
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  region: Region;
  restrictedToOnboarding: Scalars['Boolean'];
};

export type DropOff__FacilityAvailableArgs = {
  cuft?: InputMaybe<Scalars['Float']>;
};

export type DropOff__Fulfiller = DropOff__ThirdPartySelfStorageFacility | SelfStorage__Facility;

export type DropOff__StorageArrangement = DropOff__ThirdPartySelfStorageUnit | SelfStorage__Hold;

export type DropOff__ThirdPartySelfStorageFacility = {
  __typename?: 'DropOff__ThirdPartySelfStorageFacility';
  accessCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  operator: Scalars['String'];
};

export type DropOff__ThirdPartySelfStorageUnit = {
  __typename?: 'DropOff__ThirdPartySelfStorageUnit';
  directions?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EstimatedItem = {
  __typename?: 'EstimatedItem';
  categoryDisplayName?: Maybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  cuft?: Maybe<Scalars['Int']>;
  estimatedMovers?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  length?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  width?: Maybe<Scalars['Int']>;
};

export type EstimatedItemInput = {
  _destroy?: InputMaybe<Scalars['Boolean']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  customCategoryName?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  length?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Int'];
  sizingSelection?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type EstimationCategory = {
  __typename?: 'EstimationCategory';
  cuft: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
  numMovers?: Maybe<Scalars['Int']>;
  packable: Scalars['Boolean'];
};

export type EstimationInput = {
  categoryID: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};

export type EstimationMutationInterface = {
  estimationAIClassificationSkip?: Maybe<Estimation__AiClassificationSkipPayload>;
  estimationAiClassificationCreate?: Maybe<Estimation__AiClassificationCreatePayload>;
  estimationCreateBoxEstimatedItems?: Maybe<Estimation__CreateBoxEstimatedItemsPayload>;
  estimationCreateItems?: Maybe<Estimation__CreateItemsPayload>;
  estimationCreateRooms?: Maybe<Estimation__CreateRoomsPayload>;
  estimationDeleteRoom?: Maybe<Estimation__DeleteRoomPayload>;
  estimationRenameRoom?: Maybe<Estimation__RenameRoomPayload>;
  estimationRoomPackingRequested?: Maybe<Estimation__RoomPackingRequestedPayload>;
  estimationStatusCreate?: Maybe<Estimation__StatusCreatePayload>;
  estimationUploadCreate?: Maybe<Estimation__UploadCreatePayload>;
  estimationUploadDestroy?: Maybe<Estimation__UploadDestroyPayload>;
};

export type EstimationMutationInterfaceEstimationAiClassificationSkipArgs = {
  aiClassificationID: Scalars['ID'];
};

export type EstimationMutationInterfaceEstimationAiClassificationCreateArgs = {
  orderID: Scalars['ID'];
};

export type EstimationMutationInterfaceEstimationCreateBoxEstimatedItemsArgs = {
  boxCategoryName: Scalars['String'];
  orderID: Scalars['ID'];
};

export type EstimationMutationInterfaceEstimationCreateItemsArgs = {
  input: Estimation__CreateItemsInputType;
};

export type EstimationMutationInterfaceEstimationCreateRoomsArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
  allItemsInListedRooms?: InputMaybe<Scalars['Boolean']>;
  deactivateExistingRooms?: InputMaybe<Scalars['Boolean']>;
  orderID: Scalars['ID'];
  roomInputs: Array<Estimation__SelectedRoomInputType>;
};

export type EstimationMutationInterfaceEstimationDeleteRoomArgs = {
  id: Scalars['ID'];
};

export type EstimationMutationInterfaceEstimationRenameRoomArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EstimationMutationInterfaceEstimationRoomPackingRequestedArgs = {
  id: Scalars['ID'];
  packingRequested: Scalars['Boolean'];
};

export type EstimationMutationInterfaceEstimationStatusCreateArgs = {
  input: Estimation__StatusCreateInputType;
};

export type EstimationMutationInterfaceEstimationUploadCreateArgs = {
  orderID: Scalars['ID'];
  signedID: Scalars['ID'];
};

export type EstimationMutationInterfaceEstimationUploadDestroyArgs = {
  orderID: Scalars['ID'];
  uploadID: Scalars['ID'];
};

export type EstimationQueryInterface = {
  estimationAiClassification: Estimation__AiClassificationType;
  estimationBoxItemCategories: Array<Estimation__CategoryType>;
  estimationItemCategoryThemes: Array<Estimation__CategoryThemeType>;
  estimationItems: Array<Estimation__EstimatedItemType>;
  estimationOtherItemCategories: Array<Estimation__CategoryType>;
  estimationRoomCategories: Array<Estimation__RoomCategoryType>;
  estimationSelectedBoxEstimatedItem?: Maybe<Estimation__EstimatedItemType>;
  estimationSelectedOtherEstimatedItem?: Maybe<Estimation__EstimatedItemType>;
  estimationSelectedRooms: Array<Estimation__SelectedRoomType>;
  estimationStatus?: Maybe<Estimation__Status>;
  estimationUploads: Array<Estimation__UploadType>;
  virtualWalkthroughEstimationItems: Array<Estimation__EstimatedItemType>;
  virtualWalkthroughItemCategories: Array<Estimation__CategoryType>;
  virtualWalkthroughItemCategorySearch: Array<Estimation__CategoryType>;
  virtualWalkthroughOtherItemCategories: Array<Estimation__CategoryType>;
};

export type EstimationQueryInterfaceEstimationAiClassificationArgs = {
  id: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationItemCategoryThemesArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
};

export type EstimationQueryInterfaceEstimationItemsArgs = {
  algorithmName: Estimation__AlgorithmName;
  resourceID: Scalars['ID'];
  resourceType: Estimation__ResourceType;
};

export type EstimationQueryInterfaceEstimationRoomCategoriesArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
};

export type EstimationQueryInterfaceEstimationSelectedBoxEstimatedItemArgs = {
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationSelectedOtherEstimatedItemArgs = {
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationSelectedRoomsArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationStatusArgs = {
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceEstimationUploadsArgs = {
  orderID: Scalars['ID'];
};

export type EstimationQueryInterfaceVirtualWalkthroughEstimationItemsArgs = {
  resourceID: Scalars['ID'];
};

export type EstimationQueryInterfaceVirtualWalkthroughItemCategorySearchArgs = {
  query: Scalars['String'];
};

export type EstimationSelection = {
  __typename?: 'EstimationSelection';
  category: EstimationCategory;
  categoryID: Scalars['ID'];
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  selected: Scalars['Boolean'];
};

/** Autogenerated return type of Estimation__AIClassificationCreate */
export type Estimation__AiClassificationCreatePayload = {
  __typename?: 'Estimation__AIClassificationCreatePayload';
  aiClassification?: Maybe<Estimation__AiClassificationType>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Estimation__AIClassificationSkip */
export type Estimation__AiClassificationSkipPayload = {
  __typename?: 'Estimation__AIClassificationSkipPayload';
  aiClassification?: Maybe<Array<Estimation__AiClassificationType>>;
  status: Status;
};

export enum Estimation__AiClassificationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Skipped = 'SKIPPED',
}

export type Estimation__AiClassificationType = {
  __typename?: 'Estimation__AIClassificationType';
  estimatedItems: Array<Estimation__EstimatedItemType>;
  id: Scalars['ID'];
  status: Estimation__AiClassificationStatus;
};

export type Estimation__Algorithm = {
  __typename?: 'Estimation__Algorithm';
  id: Scalars['ID'];
  name: Estimation__AlgorithmName;
};

export enum Estimation__AlgorithmName {
  MovingDisassemblyFlow = 'MOVING_DISASSEMBLY_FLOW',
  OnsiteItemization = 'ONSITE_ITEMIZATION',
  V1 = 'V1',
  V2 = 'V2',
  VirtualWalkthrough = 'VIRTUAL_WALKTHROUGH',
}

export type Estimation__CategoryThemeType = {
  __typename?: 'Estimation__CategoryThemeType';
  categories: Array<Estimation__CategoryType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
};

export type Estimation__CategoryType = {
  __typename?: 'Estimation__CategoryType';
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<Estimation__CategoryType>;
  position: Scalars['Int'];
};

/** Autogenerated return type of Estimation__CreateBoxEstimatedItems */
export type Estimation__CreateBoxEstimatedItemsPayload = {
  __typename?: 'Estimation__CreateBoxEstimatedItemsPayload';
  error?: Maybe<Scalars['String']>;
  estimatedItems?: Maybe<Array<Estimation__EstimatedItemType>>;
  status: Status;
};

export type Estimation__CreateItemsInputType = {
  algorithmName: Estimation__AlgorithmName;
  itemInputs: Array<Estimation__ItemInputType>;
  itemsRequireMoreThanOneMover?: InputMaybe<Order__Metadata__ItemsRequireMoreThanOneMover>;
  otherItemCategoryName?: InputMaybe<Scalars['String']>;
  resourceID: Scalars['ID'];
  resourceType: Estimation__ResourceType;
};

/** Autogenerated return type of Estimation__CreateItems */
export type Estimation__CreateItemsPayload = {
  __typename?: 'Estimation__CreateItemsPayload';
  error?: Maybe<Scalars['String']>;
  estimatedItems?: Maybe<Array<Estimation__EstimatedItemType>>;
  status: Status;
};

/** Autogenerated return type of Estimation__CreateRooms */
export type Estimation__CreateRoomsPayload = {
  __typename?: 'Estimation__CreateRoomsPayload';
  error?: Maybe<Scalars['String']>;
  selectedRooms?: Maybe<Array<Estimation__SelectedRoomType>>;
  status: Status;
};

/** Autogenerated return type of Estimation__DeleteRoom */
export type Estimation__DeleteRoomPayload = {
  __typename?: 'Estimation__DeleteRoomPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Estimation__EstimatedItemType = {
  __typename?: 'Estimation__EstimatedItemType';
  category: Estimation__CategoryType;
  disassemblyQuantity?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type Estimation__ItemInputType = {
  categoryID: Scalars['ID'];
  disassemblyQuantity?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Int'];
};

/** Autogenerated return type of Estimation__RenameRoom */
export type Estimation__RenameRoomPayload = {
  __typename?: 'Estimation__RenameRoomPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum Estimation__ResourceType {
  Order = 'ORDER',
  Room = 'ROOM',
}

export type Estimation__RoomCategoryType = {
  __typename?: 'Estimation__RoomCategoryType';
  id: Scalars['ID'];
  itemCategories: Array<Estimation__CategoryType>;
  name: Scalars['String'];
};

/** Autogenerated return type of Estimation__RoomPackingRequested */
export type Estimation__RoomPackingRequestedPayload = {
  __typename?: 'Estimation__RoomPackingRequestedPayload';
  error?: Maybe<Scalars['String']>;
  selectedRoom?: Maybe<Estimation__SelectedRoomType>;
  status: Status;
};

export type Estimation__SelectedRoomInputType = {
  count: Scalars['Int'];
  roomCategoryID: Scalars['ID'];
};

export type Estimation__SelectedRoomType = {
  __typename?: 'Estimation__SelectedRoomType';
  estimatedItems: Array<Estimation__EstimatedItemType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  packingRequested: Scalars['Boolean'];
  roomCategory: Estimation__RoomCategoryType;
};

export type Estimation__Status = {
  __typename?: 'Estimation__Status';
  complete: Scalars['Boolean'];
  estimationCalculatedCuft: Scalars['Float'];
  fitsPlanSize?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  planSizeComparison?: Maybe<Scalars['String']>;
  planSizeCuft: Scalars['Int'];
  planSizeName: Scalars['String'];
};

export type Estimation__StatusCreateInputType = {
  orderID: Scalars['ID'];
  status: Scalars['Boolean'];
};

/** Autogenerated return type of Estimation__StatusCreate */
export type Estimation__StatusCreatePayload = {
  __typename?: 'Estimation__StatusCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Estimation__UploadCreate */
export type Estimation__UploadCreatePayload = {
  __typename?: 'Estimation__UploadCreatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  upload?: Maybe<Estimation__UploadType>;
};

/** Autogenerated return type of Estimation__UploadDestroy */
export type Estimation__UploadDestroyPayload = {
  __typename?: 'Estimation__UploadDestroyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
  upload?: Maybe<Estimation__UploadType>;
};

export type Estimation__UploadMediaType = {
  __typename?: 'Estimation__UploadMediaType';
  filename: Scalars['String'];
  id: Scalars['String'];
  imgixURL: Scalars['String'];
  isImage: Scalars['Boolean'];
  isVideo: Scalars['Boolean'];
  url: Scalars['String'];
};

export type Estimation__UploadType = {
  __typename?: 'Estimation__UploadType';
  id: Scalars['ID'];
  media: Estimation__UploadMediaType;
};

export type Facility = Warehouse;

export type FacilityQueryInterface = {
  facility?: Maybe<Facility>;
};

export type FacilityQueryInterfaceFacilityArgs = {
  itemIDs: Array<Scalars['ID']>;
};

export type FinancingMutationInterface = {
  submitFinancingCheckout?: Maybe<Financing__SubmitCheckoutPayload>;
  switchFinancingIntent?: Maybe<Financing__SwitchIntentPayload>;
};

export type FinancingMutationInterfaceSubmitFinancingCheckoutArgs = {
  input: Financing__CheckoutInput;
};

export type FinancingMutationInterfaceSwitchFinancingIntentArgs = {
  intended: Scalars['Boolean'];
  orderID: Scalars['ID'];
};

export type Financing__CheckoutInput = {
  affirmID: Scalars['ID'];
  orderID: Scalars['ID'];
  taxAmount: Scalars['Float'];
  total: Scalars['Float'];
  uuid: Scalars['ID'];
};

export type Financing__Context = {
  __typename?: 'Financing__Context';
  discounts: Array<Financing__Context__Discount>;
  items: Array<Financing__Context__Item>;
  taxAmount: Scalars['Int'];
  total: Scalars['Int'];
};

export type Financing__Context__Discount = {
  __typename?: 'Financing__Context__Discount';
  amount: Scalars['Int'];
  code: Scalars['String'];
  displayName: Scalars['String'];
};

export type Financing__Context__Item = {
  __typename?: 'Financing__Context__Item';
  displayName: Scalars['String'];
  imageURL: Scalars['String'];
  quantity: Scalars['Int'];
  sku: Scalars['String'];
  unitPrice: Scalars['Int'];
  url: Scalars['String'];
};

export enum Financing__FailureKind {
  PastDue = 'PAST_DUE',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Retriable = 'RETRIABLE',
}

export type Financing__Intent = {
  __typename?: 'Financing__Intent';
  estimatedAmount: Scalars['Float'];
  id: Scalars['ID'];
  intended: Scalars['Boolean'];
};

/** Autogenerated return type of Financing__SubmitCheckout */
export type Financing__SubmitCheckoutPayload = {
  __typename?: 'Financing__SubmitCheckoutPayload';
  failureKind?: Maybe<Financing__FailureKind>;
  status: Status;
};

/** Autogenerated return type of Financing__SwitchIntent */
export type Financing__SwitchIntentPayload = {
  __typename?: 'Financing__SwitchIntentPayload';
  intent: Financing__Intent;
};

export type FlowMutationInterface = {
  createStepTransition?: Maybe<Flow__CreateStepTransitionPayload>;
};

export type FlowMutationInterfaceCreateStepTransitionArgs = {
  input: Flow__StepTransitionInputType;
};

/** Autogenerated return type of Flow__CreateStepTransition */
export type Flow__CreateStepTransitionPayload = {
  __typename?: 'Flow__CreateStepTransitionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Flow__StepTransitionInputType = {
  actionName: Scalars['String'];
  allData: Scalars['JSON'];
  data?: InputMaybe<Scalars['JSON']>;
  flowInstanceUuid: Scalars['String'];
  flowName: Scalars['String'];
  flowVersion: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  pageUuid?: InputMaybe<Scalars['String']>;
  position: Scalars['Int'];
  resourceToken: Scalars['String'];
  resourceType: Scalars['String'];
};

export type GiftCard = {
  __typename?: 'GiftCard';
  claimCode?: Maybe<Scalars['String']>;
  status: GiftCardStatus;
};

export enum GiftCardStatus {
  Canceled = 'CANCELED',
  Failure = 'FAILURE',
  NotApplicable = 'NOT_APPLICABLE',
  NoEmail = 'NO_EMAIL',
  Success = 'SUCCESS',
  TooLate = 'TOO_LATE',
  TooSoon = 'TOO_SOON',
}

export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  size: Scalars['String'];
  source: Scalars['String'];
  state: ImageState;
};

export enum ImageState {
  /** INITIALIZED */
  Initialized = 'initialized',
  /** REMOVED */
  Removed = 'removed',
  /** TRANSFERRING */
  Transferring = 'transferring',
  /** UPLOADED */
  Uploaded = 'uploaded',
}

export type Item = {
  __typename?: 'Item';
  accountID: Scalars['ID'];
  barcode: Barcode;
  category?: Maybe<ItemCategory>;
  categoryName?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cuft?: Maybe<Scalars['Float']>;
  customCategoryName?: Maybe<Scalars['String']>;
  delayed: Scalars['Boolean'];
  dimensionsOverwritten: Scalars['Boolean'];
  group?: Maybe<ItemGroup>;
  height?: Maybe<Scalars['Int']>;
  hero: Scalars['Int'];
  id: Scalars['ID'];
  images: Array<Image>;
  length?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numMovers?: Maybe<Scalars['Int']>;
  ordered: Scalars['Boolean'];
  orders: Array<Order>;
  pickedUpAt?: Maybe<Scalars['DateTime']>;
  returned: Scalars['Boolean'];
  returnedAt?: Maybe<Scalars['DateTime']>;
  selectableForReturn: Scalars['Boolean'];
  shipment?: Maybe<Shipment>;
  shippingEligible: Scalars['Boolean'];
  sizingSelection?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type ItemOrderedArgs = {
  direction: OrderDirection;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  categoryDefaults?: Maybe<ItemCategoryDefault>;
  deletedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  kind?: Maybe<Scalars['String']>;
  moveAndPackVisible: Scalars['Boolean'];
  name: Scalars['String'];
  packingStandard?: Maybe<PackingStandard>;
  parentID?: Maybe<Scalars['ID']>;
  requiredPlanID?: Maybe<Scalars['ID']>;
  sizingMetric?: Maybe<SizingMetric>;
  synonyms?: Maybe<Array<Scalars['String']>>;
  type: Scalars['String'];
};

export type ItemCategoryDefault = {
  __typename?: 'ItemCategoryDefault';
  defaults: Array<ItemCategoryDefaultValue>;
};

export type ItemCategoryDefaultValue = {
  __typename?: 'ItemCategoryDefaultValue';
  cuft: Scalars['Float'];
  height: Scalars['Float'];
  length: Scalars['Float'];
  numMovers?: Maybe<Scalars['Int']>;
  numMoversIfDisassembled?: Maybe<Scalars['Int']>;
  numMoversWithDolly?: Maybe<Scalars['Int']>;
  sizingSelection: Scalars['String'];
  width: Scalars['Float'];
};

export type ItemFiltersInput = {
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ItemStatus>;
};

export type ItemGroup = {
  __typename?: 'ItemGroup';
  category?: Maybe<ItemCategory>;
  categoryName?: Maybe<Scalars['String']>;
  contents?: Maybe<Scalars['String']>;
  cuft?: Maybe<Scalars['Float']>;
  heroImage?: Maybe<Image>;
  id: Scalars['ID'];
  items: Array<Item>;
  name?: Maybe<Scalars['String']>;
  pickedUpAt?: Maybe<Scalars['DateTime']>;
  requestableItems: Array<Item>;
  returnedAt?: Maybe<Scalars['DateTime']>;
  uuid: Scalars['String'];
};

export type ItemGroupRequestableItemsArgs = {
  orderID?: InputMaybe<Scalars['ID']>;
};

export type ItemInput = {
  contents?: InputMaybe<Scalars['String']>;
  hero: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type ItemPricing = {
  __typename?: 'ItemPricing';
  /** Unpersisted account packages on the order */
  accountPackages: Array<AccountPackage>;
  /** Appointment fee charged to user. Formatted like "$49" */
  appointmentFee?: Maybe<Scalars['String']>;
  /** Formatted like "$49" */
  baseAppointmentFee: Scalars['String'];
  pickupEstimate?: Maybe<PickupEstimate>;
  pickupLargeEstimate?: Maybe<PickupEstimate>;
  /** Formatted like "$9" */
  pickupLargeItemFee?: Maybe<Scalars['String']>;
  /** Formatted like "$9" */
  pickupPerItemFee?: Maybe<Scalars['String']>;
  /** Can be either a price or range like "$50" or "$50-$79" */
  price: Scalars['String'];
  /** Formatted like "$9" */
  returnLargeItemFee?: Maybe<Scalars['String']>;
  /** Formatted like "$9" */
  returnPerItemFee?: Maybe<Scalars['String']>;
  returnedItemsCount: Scalars['Int'];
  returnedLargeItemsCount: Scalars['Int'];
};

export type ItemPricingInput = {
  kind: ItemPricingInputKind;
  order?: InputMaybe<OrderInput>;
  orderEdits?: InputMaybe<ItemPricingInputOrderEdits>;
  orderID?: InputMaybe<Scalars['ID']>;
};

export enum ItemPricingInputKind {
  Booking = 'BOOKING',
  Editing = 'EDITING',
}

export type ItemPricingInputOrderEdits = {
  estimatedItems?: InputMaybe<Array<EstimatedItemInput>>;
  estimations?: InputMaybe<Array<EstimationInput>>;
  itemIDs?: InputMaybe<Array<Scalars['ID']>>;
  rooms?: InputMaybe<Array<RoomInput>>;
  scheduled?: InputMaybe<Scalars['DateTime']>;
};

export enum ItemStatus {
  Available = 'AVAILABLE',
  Delayed = 'DELAYED',
  Requested = 'REQUESTED',
  Returned = 'RETURNED',
  Viewable = 'VIEWABLE',
}

/** Information about labor pricing for a type of labor. */
export type LaborCostDetails = {
  __typename?: 'LaborCostDetails';
  /** The sum of all appointment fees based on order subtype. */
  appointmentFeeCost: Scalars['Float'];
  /**
   * A human-readable description of the lower-end tier conditions and
   * its cost. For example, "Free up to 8 hours", "1 free 1 hour appointment
   * per month", or "The first 1 hour is $15/hour per mover".
   */
  discountTierDescription?: Maybe<Scalars['String']>;
  /**
   * The service charge and rate of labor after any discounted time has elapsed.
   * For example, "Free", "$65/hour per mover", or "$65/hour per mover (45 minute minimum)"
   * or "$49 service charge + $65/hour per mover".
   */
  formattedCost: Scalars['String'];
  id: Scalars['ID'];
  /**
   *           The labor rate for the specified subtype or order. If the order is
   * specified, the total labor rate will be returned.
   */
  laborCost: Scalars['String'];
};

export type LaborRate = {
  __typename?: 'LaborRate';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type LocatablePoint = {
  __typename?: 'LocatablePoint';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type MfaMutationInterface = {
  mfaCodeCheck?: Maybe<Mfa__CodeCheckPayload>;
  mfaRequestSend?: Maybe<Mfa__RequestSendPayload>;
};

export type MfaMutationInterfaceMfaCodeCheckArgs = {
  code: Scalars['String'];
  requestId: Scalars['ID'];
};

export type MfaMutationInterfaceMfaRequestSendArgs = {
  verificationKind: Mfa__VerificationKind;
  verificationValue: Scalars['String'];
};

/** Autogenerated return type of MFA__CodeCheck */
export type Mfa__CodeCheckPayload = {
  __typename?: 'MFA__CodeCheckPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Mfa__Request = {
  __typename?: 'MFA__Request';
  id: Scalars['ID'];
  verificationKind?: Maybe<Mfa__VerificationKind>;
  verificationMethod?: Maybe<Mfa__VerificationMethod>;
  verificationValue?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of MFA__RequestSend */
export type Mfa__RequestSendPayload = {
  __typename?: 'MFA__RequestSendPayload';
  error?: Maybe<Scalars['String']>;
  mfaRequest?: Maybe<Mfa__Request>;
  status: Status;
};

export enum Mfa__VerificationKind {
  BookingWithExistingAccount = 'BOOKING_WITH_EXISTING_ACCOUNT',
  ContractorEncryptedPasswordUpdate = 'CONTRACTOR_ENCRYPTED_PASSWORD_UPDATE',
  EmailUpdate = 'EMAIL_UPDATE',
  PhoneUpdate = 'PHONE_UPDATE',
}

export enum Mfa__VerificationMethod {
  Email = 'EMAIL',
  ManagerTicket = 'MANAGER_TICKET',
  Sms = 'SMS',
}

export type MarketingMutationInterface = {
  couponApply?: Maybe<CouponApplyPayload>;
};

export type MarketingMutationInterfaceCouponApplyArgs = {
  orderID: Scalars['ID'];
  promoCode: Scalars['String'];
};

export type MarketingQueryInterface = {
  marketingShare?: Maybe<Marketing__Share>;
  referralCode?: Maybe<Scalars['String']>;
};

export type MarketingQueryInterfaceMarketingShareArgs = {
  reviewableID: Scalars['ID'];
};

export type MarketingQueryInterfaceReferralCodeArgs = {
  reviewableID: Scalars['ID'];
};

export type Marketing__Share = {
  __typename?: 'Marketing__Share';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type MonetaryAmount = {
  __typename?: 'MonetaryAmount';
  currency: Currency;
  formatted: Scalars['String'];
  value: Scalars['Float'];
};

export type MovingConfiguration = {
  __typename?: 'MovingConfiguration';
  id: Scalars['ID'];
  laborRate: LaborRate;
  requiredLaborDuration: Scalars['Int'];
  travelRateMultiplier: Scalars['Float'];
};

export type MovingConfigurationLaborRateArgs = {
  movers: Scalars['Int'];
};

export type MovingMutationInterface = {
  materialPackageSetEntriesFetch?: Maybe<Moving__MaterialPackageSetEntriesFetchPayload>;
  movingCancelIntentCreate?: Maybe<Moving__CancelIntentCreatePayload>;
  movingEstimationsUpdate?: Maybe<Moving__EstimationsUpdatePayload>;
  movingPackingHelpUpdate?: Maybe<Moving__PackingHelpUpdatePayload>;
  movingPackingMaterialsUpdate?: Maybe<Moving__PackingMaterialsUpdatePayload>;
  movingPrepaymentPay?: Maybe<Moving__PrepaymentPayPayload>;
  movingQuoteRegenerate?: Maybe<Moving__QuoteRegeneratePayload>;
  movingQuoteRegenerateWithItems?: Maybe<Moving__QuoteRegenerateWithItemsPayload>;
  movingServicePackageUpdate?: Maybe<Moving__ServicePackageUpdatePayload>;
  movingStorageAdd?: Maybe<Moving__StorageAddPayload>;
};

export type MovingMutationInterfaceMaterialPackageSetEntriesFetchArgs = {
  orderID: Scalars['ID'];
};

export type MovingMutationInterfaceMovingCancelIntentCreateArgs = {
  orderID: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  reason: Moving__CancelIntent__Reason;
};

export type MovingMutationInterfaceMovingEstimationsUpdateArgs = {
  input: Moving__EstimationsUpdateInputType;
  rescheduleInput?: InputMaybe<Moving__RescheduleInputType>;
};

export type MovingMutationInterfaceMovingPackingHelpUpdateArgs = {
  orderID: Scalars['ID'];
  packingHelp: Moving__PackingEnum;
};

export type MovingMutationInterfaceMovingPackingMaterialsUpdateArgs = {
  action?: InputMaybe<Moving__PackingMaterialsUpdateAction>;
  orderID: Scalars['ID'];
  packageSetEntryID?: InputMaybe<Scalars['ID']>;
};

export type MovingMutationInterfaceMovingPrepaymentPayArgs = {
  orderID: Scalars['ID'];
  sourceID: Scalars['ID'];
};

export type MovingMutationInterfaceMovingQuoteRegenerateArgs = {
  orderID: Scalars['ID'];
  quoteInput?: InputMaybe<Moving__QuoteRegenerateInput>;
  source: Moving__QuoteSourceEnum;
};

export type MovingMutationInterfaceMovingQuoteRegenerateWithItemsArgs = {
  estimationInput: Estimation__CreateItemsInputType;
};

export type MovingMutationInterfaceMovingServicePackageUpdateArgs = {
  input: Moving__ServicePackageUpdate__Input;
};

export type MovingMutationInterfaceMovingStorageAddArgs = {
  input: Moving__StorageAddInputType;
  parentID?: InputMaybe<Scalars['ID']>;
};

export type MovingOperation = {
  __typename?: 'MovingOperation';
  destinationAddress: Address;
  id: Scalars['ID'];
  /** This is the minimum cargo weight, in pounds, that will be billed for a long-distance order. */
  longDistanceMinimumCargoWeightInPounds?: Maybe<Scalars['Int']>;
  /** This is the minimum fee for a long-distance order. */
  longDistanceMinimumTransportFee?: Maybe<Scalars['Int']>;
  /**
   * This is the transportation rate associated with a long-distance move. If the
   * move is not long-distance, then this will not be present.
   */
  longDistanceTransportationRate?: Maybe<Moving__LongDistanceTransportationRate>;
  moveSize?: Maybe<Moving__MoveSizeEnum>;
  movingConfiguration: MovingConfiguration;
  movingQuote: Moving__Quote;
  originAddress: Address;
  packing?: Maybe<Moving__PackingEnum>;
  walkthroughEstimatedDuration?: Maybe<Scalars['Int']>;
};

export type MovingQueryInterface = {
  movingConfiguration: MovingConfiguration;
};

export type MovingQueryInterfaceMovingConfigurationArgs = {
  orderID: Scalars['ID'];
};

export type Moving__AddressUpdateInput = {
  addressID: Scalars['ID'];
  addressInput: AddressInput;
  addressKind: Scalars['String'];
  orderID: Scalars['ID'];
};

/** Autogenerated return type of Moving__CancelIntentCreate */
export type Moving__CancelIntentCreatePayload = {
  __typename?: 'Moving__CancelIntentCreatePayload';
  error?: Maybe<Scalars['String']>;
  retentionOffer?: Maybe<Retention__Offer>;
  status: Status;
};

export enum Moving__CancelIntent__Reason {
  CompetitorPrice = 'COMPETITOR_PRICE',
  CompetitorReviews = 'COMPETITOR_REVIEWS',
  DateUnavailable = 'DATE_UNAVAILABLE',
  DontNeedMove = 'DONT_NEED_MOVE',
  MoverCount = 'MOVER_COUNT',
  Other = 'OTHER',
  PricingHourlyMinimum = 'PRICING_HOURLY_MINIMUM',
  PricingHourlyRate = 'PRICING_HOURLY_RATE',
  QuoteChanged = 'QUOTE_CHANGED',
  SelfMove = 'SELF_MOVE',
}

export type Moving__EstimationsUpdateInputType = {
  orderID: Scalars['ID'];
  quoteID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of Moving__EstimationsUpdate */
export type Moving__EstimationsUpdatePayload = {
  __typename?: 'Moving__EstimationsUpdatePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Moving__FlatRateDetails = {
  __typename?: 'Moving__FlatRateDetails';
  laborAmount: Scalars['Float'];
  materialsAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
  transportFeeAmount: Scalars['Float'];
};

export type Moving__LongDistanceTransportationRate = {
  __typename?: 'Moving__LongDistanceTransportationRate';
  /** A description of how the transportation fee will be charged. */
  description: Scalars['String'];
  id: Scalars['ID'];
};

/** Autogenerated return type of Moving__MaterialPackageSetEntriesFetch */
export type Moving__MaterialPackageSetEntriesFetchPayload = {
  __typename?: 'Moving__MaterialPackageSetEntriesFetchPayload';
  error?: Maybe<Scalars['String']>;
  materialPackageSetEntries: Array<Moving__PackageSetEntry>;
  status: Status;
};

export enum Moving__MoveSizeEnum {
  Everything = 'EVERYTHING',
  Few = 'FEW',
  Half = 'HALF',
}

export type Moving__MoverCountOptionType = {
  __typename?: 'Moving__MoverCountOptionType';
  flatRateDetails?: Maybe<Moving__FlatRateDetails>;
  laborRate?: Maybe<LaborRate>;
  moverCount: Scalars['Int'];
  recommended: Scalars['Boolean'];
  truckCount: Scalars['Int'];
  whiteGloveBundle?: Maybe<Moving__WhiteGloveBundleEnum>;
};

export type Moving__PackageSetEntry = {
  __typename?: 'Moving__PackageSetEntry';
  fixedCost: Scalars['Float'];
  id: Scalars['ID'];
  package: Package;
  packageSet?: Maybe<PackageSet>;
};

export enum Moving__PackingEnum {
  AllItems = 'ALL_ITEMS',
  EverythingIsPacked = 'EVERYTHING_IS_PACKED',
  SomeItems = 'SOME_ITEMS',
}

/** Autogenerated return type of Moving__PackingHelpUpdate */
export type Moving__PackingHelpUpdatePayload = {
  __typename?: 'Moving__PackingHelpUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export enum Moving__PackingMaterialsUpdateAction {
  Delete = 'DELETE',
  Update = 'UPDATE',
}

/** Autogenerated return type of Moving__PackingMaterialsUpdate */
export type Moving__PackingMaterialsUpdatePayload = {
  __typename?: 'Moving__PackingMaterialsUpdatePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Moving__PrepaymentPay */
export type Moving__PrepaymentPayPayload = {
  __typename?: 'Moving__PrepaymentPayPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Moving__PricingSummary = {
  __typename?: 'Moving__PricingSummary';
  fixedDuration?: Maybe<Scalars['Duration']>;
  /** This should only be used for booked moves, since an unbooked move has multiple flat-rate options to choose from. */
  flatRateAmount?: Maybe<Scalars['Float']>;
  laborRate: LaborRate;
  materialPackageSetEntry?: Maybe<Moving__PackageSetEntry>;
  materialsAmount?: Maybe<Scalars['Float']>;
  totalFixedAmount?: Maybe<Scalars['Float']>;
};

export type Moving__Quote = {
  __typename?: 'Moving__Quote';
  cuftSource: Moving__QuoteCuftSourceEnum;
  /** Distance in meters */
  deadtimeDriveDistance?: Maybe<Scalars['Int']>;
  deadtimeDriveDuration?: Maybe<Scalars['Int']>;
  deadtimeDuration: Scalars['Int'];
  dispatchDurations?: Maybe<Array<Scalars['Int']>>;
  /** Distance in meters */
  driveDistance?: Maybe<Scalars['Int']>;
  drivetimeDuration?: Maybe<Scalars['Int']>;
  eligibility: Scalars['Boolean'];
  estimatedCuft: Scalars['Int'];
  estimatedOrderCost?: Maybe<Scalars['Float']>;
  estimatedOrderDuration?: Maybe<Scalars['Int']>;
  estimatedPackDuration?: Maybe<Scalars['Int']>;
  estimatedUnpackDuration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  laborRate?: Maybe<LaborRate>;
  materialPackageSetEntry?: Maybe<Moving__PackageSetEntry>;
  minimumOrderCost?: Maybe<Scalars['Float']>;
  moverSelection?: Maybe<Scalars['Int']>;
  movingConfiguration?: Maybe<MovingConfiguration>;
  orderDurations?: Maybe<Array<Scalars['Int']>>;
  packDurations: Array<Scalars['Int']>;
  reasons: Array<Scalars['String']>;
  reservationDepositAmount?: Maybe<Scalars['Float']>;
  suggestedMoverCount?: Maybe<Scalars['Int']>;
  truckCount?: Maybe<Scalars['Int']>;
  unpackDurations: Array<Scalars['Int']>;
  whiteGloveMoverCountOptions: Array<Moving__MoverCountOptionType>;
};

export enum Moving__QuoteCuftSourceEnum {
  Items = 'ITEMS',
  Rooms = 'ROOMS',
}

export type Moving__QuoteRegenerateInput = {
  moverOverride?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of Moving__QuoteRegenerate */
export type Moving__QuoteRegeneratePayload = {
  __typename?: 'Moving__QuoteRegeneratePayload';
  error?: Maybe<Scalars['String']>;
  movingQuote?: Maybe<Moving__Quote>;
  status: Status;
};

/** Autogenerated return type of Moving__QuoteRegenerateWithItems */
export type Moving__QuoteRegenerateWithItemsPayload = {
  __typename?: 'Moving__QuoteRegenerateWithItemsPayload';
  error?: Maybe<Scalars['String']>;
  estimatedItems?: Maybe<Array<Estimation__EstimatedItemType>>;
  movingQuote?: Maybe<Moving__Quote>;
  status: Status;
};

export enum Moving__QuoteSourceEnum {
  AccountPortalAddressModify = 'ACCOUNT_PORTAL_ADDRESS_MODIFY',
  AccountPortalMoverOverride = 'ACCOUNT_PORTAL_MOVER_OVERRIDE',
  AccountPortalServicePackageUpdate = 'ACCOUNT_PORTAL_SERVICE_PACKAGE_UPDATE',
  AccountPortalVirtualWalkthrough = 'ACCOUNT_PORTAL_VIRTUAL_WALKTHROUGH',
  AdminAddressModify = 'ADMIN_ADDRESS_MODIFY',
  AdminOrderModify = 'ADMIN_ORDER_MODIFY',
  LandingCheckout = 'LANDING_CHECKOUT',
  ThirdPartyLeadEmail = 'THIRD_PARTY_LEAD_EMAIL',
}

export type Moving__RescheduleInputType = {
  duration: Scalars['Duration'];
  scheduled: Scalars['DateTime'];
};

/** Autogenerated return type of Moving__ServicePackageUpdate */
export type Moving__ServicePackageUpdatePayload = {
  __typename?: 'Moving__ServicePackageUpdatePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type Moving__ServicePackageUpdate__Input = {
  bundle: Moving__WhiteGloveBundleEnum;
  moverCount: Scalars['Int'];
  orderID: Scalars['ID'];
};

export type Moving__StorageAddInputType = {
  planID: Scalars['ID'];
  pricing: Moving__StorageAddPricingInputType;
  protectionPlanID?: InputMaybe<Scalars['ID']>;
  rateGroupID: Scalars['ID'];
};

/** Autogenerated return type of Moving__StorageAdd */
export type Moving__StorageAddPayload = {
  __typename?: 'Moving__StorageAddPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Moving__StorageAddPricingInputType = {
  appointmentQuoteID?: InputMaybe<Scalars['ID']>;
  laborBillingFormat: Pricing__LaborBillingFormat;
  laborPolicyID: Scalars['ID'];
  laborRateID: Scalars['ID'];
  packageSetEntries?: InputMaybe<Array<PackageSetEntryInput>>;
  quoteID: Scalars['ID'];
};

export enum Moving__WhiteGloveBundleEnum {
  Basic = 'BASIC',
  FullService = 'FULL_SERVICE',
  PackingHelp = 'PACKING_HELP',
}

export type Mutation = AccountMutationInterface &
  AccountResolutionMutationInterface &
  AvailabilityMutationInterface &
  BillingMutationInterface &
  ClaimMutationInterface &
  ComplianceMutationInterface &
  CustomerMutationInterface &
  EstimationMutationInterface &
  FinancingMutationInterface &
  FlowMutationInterface &
  MfaMutationInterface &
  MarketingMutationInterface &
  MovingMutationInterface &
  OnboardingMutationInterface &
  OrderMutationInterface &
  PaymentPlanMutationInterface &
  PricingMutationInterface &
  RetentionMutationInterface &
  ReviewsMutationInterface &
  SettingsMutationInterface &
  ShippingMutationInterface &
  TermCommitmentMutationInterface & {
    __typename?: 'Mutation';
    acceptClaimOffer?: Maybe<AcceptClaimOfferPayload>;
    acceptPaymentPlanOffer?: Maybe<PaymentPlans__Offer__AcceptPayload>;
    acceptRetentionOffer?: Maybe<AcceptOfferPayload>;
    accountResolutionOfferAccept?: Maybe<AccountResolution__OfferAcceptPayload>;
    accountResolutionOfferCreate?: Maybe<AccountResolution__OfferCreatePayload>;
    accountResolutionOfferReject?: Maybe<AccountResolution__OfferRejectPayload>;
    acknowledgeDocuments?: Maybe<AcknowledgeDocumentsPayload>;
    activateAccount?: Maybe<ActivateAccountPayload>;
    addressDetailsUpdate?: Maybe<AddressDetailsUpdatePayload>;
    attachClaimIssueSelectionAttachment?: Maybe<AttachClaimIssueSelectionAttachmentPayload>;
    attachItemSelectionAttachment?: Maybe<AttachItemSelectionAttachmentPayload>;
    attachPropertySelectionAttachment?: Maybe<AttachPropertySelectionAttachmentPayload>;
    availabilityWaitlistRequestCreate?: Maybe<Availability__WaitlistRequestCreatePayload>;
    availabilityWaitlistRequestDestroy?: Maybe<Availability__WaitlistRequestDestroyPayload>;
    buildAccountCancelIntent?: Maybe<Account__BuildCancelIntentPayload>;
    buildAddress?: Maybe<BuildAddressPayload>;
    buildClaim?: Maybe<BuildClaimPayload>;
    buildClaimItemSelectionBatch?: Maybe<BuildClaimItemSelectionBatchPayload>;
    buildClaimPropertySelection?: Maybe<BuildClaimPropertySelectionPayload>;
    buildCustomerTicket?: Maybe<BuildCustomerTicketPayload>;
    buildOnboardingCancelIntent?: Maybe<Onboarding__BuildCancelIntentPayload>;
    buildOrder?: Maybe<BuildOrderPayload>;
    buildOrderCoiRequest?: Maybe<BuildOrderCoiRequestPayload>;
    buildPrepayment?: Maybe<Billing__BuildPrepaymentPayload>;
    buildReview?: Maybe<BuildReviewPayload>;
    buildSource?: Maybe<Billing__BuildSourcePayload>;
    buildTermCommitmentCustomerTicket?: Maybe<TermCommitment__BuildCustomerTicketPayload>;
    cancelOrder?: Maybe<CancelOrderPayload>;
    chargeEarlyAccountClosureFee?: Maybe<Account__ChargeEarlyClosureFeePayload>;
    claimIssueSelectionDestroy?: Maybe<Claim__IssueSelectionDestroyPayload>;
    claimIssueSelectionUpdate?: Maybe<Claim__IssueSelectionUpdatePayload>;
    confirmOrder?: Maybe<ConfirmOrderPayload>;
    confirmUpgradeDowngradeForSuggestedPricing?: Maybe<ConfirmUpgradeDowngradeForSuggestedPricingPayload>;
    couponApply?: Maybe<CouponApplyPayload>;
    createStepTransition?: Maybe<Flow__CreateStepTransitionPayload>;
    customerInitialSetup?: Maybe<CustomerInitialSetupPayload>;
    customerNameUpdate?: Maybe<Customer__NameUpdatePayload>;
    customerVerifiedEmailUpdate?: Maybe<Customer__VerifiedEmailUpdatePayload>;
    customerVerifiedPhoneUpdate?: Maybe<Customer__VerifiedPhoneUpdatePayload>;
    defaultSource?: Maybe<Billing__DefaultSourcePayload>;
    destroyAddress?: Maybe<DestroyAddressPayload>;
    destroyClaim?: Maybe<DestroyClaimPayload>;
    destroyClaimItemSelection?: Maybe<DestroyClaimItemSelectionPayload>;
    destroyClaimPropertySelection?: Maybe<DestroyClaimPropertySelectionPayload>;
    destroySource?: Maybe<Billing__DestroySourcePayload>;
    detachClaimIssueSelectionAttachment?: Maybe<DetachClaimIssueSelectionAttachmentPayload>;
    detachItemSelectionAttachment?: Maybe<DetachItemSelectionAttachmentPayload>;
    detachPropertySelectionAttachment?: Maybe<DetachPropertySelectionAttachmentPayload>;
    disposalAttach?: Maybe<Portal__DisposalAttachPayload>;
    disposalConfirm?: Maybe<Disposal__ConfirmPayload>;
    estimationAIClassificationSkip?: Maybe<Estimation__AiClassificationSkipPayload>;
    estimationAiClassificationCreate?: Maybe<Estimation__AiClassificationCreatePayload>;
    estimationCreateBoxEstimatedItems?: Maybe<Estimation__CreateBoxEstimatedItemsPayload>;
    estimationCreateItems?: Maybe<Estimation__CreateItemsPayload>;
    estimationCreateRooms?: Maybe<Estimation__CreateRoomsPayload>;
    estimationDeleteRoom?: Maybe<Estimation__DeleteRoomPayload>;
    estimationRenameRoom?: Maybe<Estimation__RenameRoomPayload>;
    estimationRoomPackingRequested?: Maybe<Estimation__RoomPackingRequestedPayload>;
    estimationStatusCreate?: Maybe<Estimation__StatusCreatePayload>;
    estimationUploadCreate?: Maybe<Estimation__UploadCreatePayload>;
    estimationUploadDestroy?: Maybe<Estimation__UploadDestroyPayload>;
    materialPackageSetEntriesFetch?: Maybe<Moving__MaterialPackageSetEntriesFetchPayload>;
    mfaCodeCheck?: Maybe<Mfa__CodeCheckPayload>;
    mfaRequestSend?: Maybe<Mfa__RequestSendPayload>;
    movingAddressUpdate?: Maybe<AddressUpdatePayload>;
    movingCancelIntentCreate?: Maybe<Moving__CancelIntentCreatePayload>;
    movingEstimationsUpdate?: Maybe<Moving__EstimationsUpdatePayload>;
    movingPackingHelpUpdate?: Maybe<Moving__PackingHelpUpdatePayload>;
    movingPackingMaterialsUpdate?: Maybe<Moving__PackingMaterialsUpdatePayload>;
    movingPrepaymentPay?: Maybe<Moving__PrepaymentPayPayload>;
    movingQuoteRegenerate?: Maybe<Moving__QuoteRegeneratePayload>;
    movingQuoteRegenerateWithItems?: Maybe<Moving__QuoteRegenerateWithItemsPayload>;
    movingServicePackageUpdate?: Maybe<Moving__ServicePackageUpdatePayload>;
    movingStorageAdd?: Maybe<Moving__StorageAddPayload>;
    orderRescheduleOfferItemAccept?: Maybe<Order__RescheduleOfferItemAcceptPayload>;
    pickupServicePackageUpdate?: Maybe<PickupServicePackageUpdatePayload>;
    rescheduleOrder?: Maybe<RescheduleOrderPayload>;
    resolveDelinquency?: Maybe<ResolveDelinquencyPayload>;
    reviewSave: ReviewSavePayload;
    saveItem?: Maybe<SaveItemPayload>;
    sendReceipt?: Maybe<Billing__SendReceiptPayload>;
    share?: Maybe<SharePayload>;
    shipmentItemQuotesUpsert?: Maybe<Shipping__ShipmentItemQuotesUpsertPayload>;
    shipmentValuationUpdate?: Maybe<Compliance__ShipmentValuationUpdatePayload>;
    submitClaim?: Maybe<SubmitClaimPayload>;
    submitFinancingCheckout?: Maybe<Financing__SubmitCheckoutPayload>;
    swapPaymentMethod?: Maybe<Billing__SwapPaymentMethodPayload>;
    switchFinancingIntent?: Maybe<Financing__SwitchIntentPayload>;
    trackClaim?: Maybe<TrackClaimPayload>;
    updateClaimItemSelection?: Maybe<UpdateClaimItemSelectionPayload>;
    updateCustomerItem?: Maybe<UpdateCustomerItemPayload>;
    updateNotificationPreferences?: Maybe<Settings__UpdateNotificationPreferencesPayload>;
    updateOrderAddress?: Maybe<UpdateOrderAddressPayload>;
    updateOrderContact?: Maybe<UpdateOrderContactPayload>;
    updatePassword?: Maybe<Settings__UpdatePasswordPayload>;
    updatePickupInventory?: Maybe<UpdatePickupInventoryPayload>;
    updatePlan?: Maybe<Pricing__UpdatePlanPayload>;
    updateReturnInventory?: Maybe<UpdateReturnInventoryPayload>;
    virtualWalkthroughSubmit?: Maybe<VirtualWalkthroughSubmitPayload>;
  };

export type MutationAcceptClaimOfferArgs = {
  input: AcceptClaimOfferInput;
};

export type MutationAcceptPaymentPlanOfferArgs = {
  offerID: Scalars['ID'];
  sourceID: Scalars['ID'];
};

export type MutationAcceptRetentionOfferArgs = {
  offerID: Scalars['ID'];
};

export type MutationAccountResolutionOfferAcceptArgs = {
  category: Scalars['String'];
  offerID: Scalars['ID'];
  reasonID: Scalars['ID'];
};

export type MutationAccountResolutionOfferCreateArgs = {
  reasonID: Scalars['ID'];
};

export type MutationAccountResolutionOfferRejectArgs = {
  offerID: Scalars['ID'];
};

export type MutationAcknowledgeDocumentsArgs = {
  input: Array<AcknowledgeDocumentsInput>;
};

export type MutationActivateAccountArgs = {
  email: Scalars['String'];
};

export type MutationAddressDetailsUpdateArgs = {
  input: AddressDetailsUpdateInput;
};

export type MutationAttachClaimIssueSelectionAttachmentArgs = {
  signedID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationAttachItemSelectionAttachmentArgs = {
  name?: InputMaybe<ClaimItemSelectionAttachmentName>;
  selectionID: Scalars['ID'];
  signedID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationAttachPropertySelectionAttachmentArgs = {
  name: ClaimPropertySelectionAttachmentName;
  signedID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationAvailabilityWaitlistRequestCreateArgs = {
  input: Availability__WaitlistRequestCreateInput;
};

export type MutationAvailabilityWaitlistRequestDestroyArgs = {
  input: Availability__WaitlistRequestDestroyInput;
};

export type MutationBuildAccountCancelIntentArgs = {
  orderID?: InputMaybe<Scalars['ID']>;
  reason: Account__CancelIntent__Reason;
};

export type MutationBuildAddressArgs = {
  input: AddressInput;
};

export type MutationBuildClaimArgs = {
  input: ClaimKindsInput;
};

export type MutationBuildClaimItemSelectionBatchArgs = {
  input: Array<BuildClaimItemSelectionInput>;
  kind: ClaimItemSelectionKind;
  uuid: Scalars['String'];
};

export type MutationBuildClaimPropertySelectionArgs = {
  input: BuildClaimPropertySelectionInput;
  uuid: Scalars['String'];
};

export type MutationBuildCustomerTicketArgs = {
  input: BuildCustomerTicketInput;
};

export type MutationBuildOnboardingCancelIntentArgs = {
  orderID: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  reason: Onboarding__CancelIntent__Reason;
};

export type MutationBuildOrderArgs = {
  input: OrderInput;
  skipBookableCheck?: InputMaybe<Scalars['Boolean']>;
  sourceID?: InputMaybe<Scalars['ID']>;
};

export type MutationBuildOrderCoiRequestArgs = {
  input: OrderCoiRequestInput;
};

export type MutationBuildPrepaymentArgs = {
  amount: Scalars['Float'];
};

export type MutationBuildReviewArgs = {
  input: BuildReviewInput;
};

export type MutationBuildSourceArgs = {
  input: Billing__BuildSourceInput;
};

export type MutationCancelOrderArgs = {
  orderID: Scalars['ID'];
  skipCancellationFee?: InputMaybe<Scalars['Boolean']>;
};

export type MutationChargeEarlyAccountClosureFeeArgs = {
  scheduled?: InputMaybe<Scalars['DateTime']>;
  sourceID: Scalars['ID'];
};

export type MutationClaimIssueSelectionDestroyArgs = {
  uuid: Scalars['ID'];
};

export type MutationClaimIssueSelectionUpdateArgs = {
  input: Claim__IssueSelectionUpdateInput;
  uuid: Scalars['String'];
};

export type MutationConfirmOrderArgs = {
  token: Scalars['ID'];
};

export type MutationCouponApplyArgs = {
  orderID: Scalars['ID'];
  promoCode: Scalars['String'];
};

export type MutationCreateStepTransitionArgs = {
  input: Flow__StepTransitionInputType;
};

export type MutationCustomerInitialSetupArgs = {
  input: CustomerInitialSetupInput;
};

export type MutationCustomerNameUpdateArgs = {
  name: Scalars['String'];
};

export type MutationCustomerVerifiedEmailUpdateArgs = {
  verifiedEmail: Scalars['String'];
};

export type MutationCustomerVerifiedPhoneUpdateArgs = {
  verifiedPhoneNumber: Scalars['String'];
};

export type MutationDefaultSourceArgs = {
  id: Scalars['ID'];
};

export type MutationDestroyAddressArgs = {
  id: Scalars['String'];
};

export type MutationDestroyClaimArgs = {
  uuid: Scalars['ID'];
};

export type MutationDestroyClaimItemSelectionArgs = {
  selectionID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationDestroyClaimPropertySelectionArgs = {
  uuid: Scalars['ID'];
};

export type MutationDestroySourceArgs = {
  id: Scalars['ID'];
};

export type MutationDetachClaimIssueSelectionAttachmentArgs = {
  attachmentID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationDetachItemSelectionAttachmentArgs = {
  attachmentID: Scalars['ID'];
  name: ClaimItemSelectionAttachmentName;
  selectionID: Scalars['ID'];
  uuid: Scalars['ID'];
};

export type MutationDetachPropertySelectionAttachmentArgs = {
  attachmentID: Scalars['ID'];
  name: ClaimPropertySelectionAttachmentName;
  uuid: Scalars['ID'];
};

export type MutationDisposalAttachArgs = {
  parentID: Scalars['ID'];
};

export type MutationDisposalConfirmArgs = {
  disposalItemIDs: Array<Scalars['ID']>;
  signedDisposalID: Scalars['String'];
  storageItemIDs: Array<Scalars['ID']>;
  storagePlanConfig?: InputMaybe<Disposal__Confirm__StoragePlanInput>;
};

export type MutationEstimationAiClassificationSkipArgs = {
  aiClassificationID: Scalars['ID'];
};

export type MutationEstimationAiClassificationCreateArgs = {
  orderID: Scalars['ID'];
};

export type MutationEstimationCreateBoxEstimatedItemsArgs = {
  boxCategoryName: Scalars['String'];
  orderID: Scalars['ID'];
};

export type MutationEstimationCreateItemsArgs = {
  input: Estimation__CreateItemsInputType;
};

export type MutationEstimationCreateRoomsArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
  allItemsInListedRooms?: InputMaybe<Scalars['Boolean']>;
  deactivateExistingRooms?: InputMaybe<Scalars['Boolean']>;
  orderID: Scalars['ID'];
  roomInputs: Array<Estimation__SelectedRoomInputType>;
};

export type MutationEstimationDeleteRoomArgs = {
  id: Scalars['ID'];
};

export type MutationEstimationRenameRoomArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationEstimationRoomPackingRequestedArgs = {
  id: Scalars['ID'];
  packingRequested: Scalars['Boolean'];
};

export type MutationEstimationStatusCreateArgs = {
  input: Estimation__StatusCreateInputType;
};

export type MutationEstimationUploadCreateArgs = {
  orderID: Scalars['ID'];
  signedID: Scalars['ID'];
};

export type MutationEstimationUploadDestroyArgs = {
  orderID: Scalars['ID'];
  uploadID: Scalars['ID'];
};

export type MutationMaterialPackageSetEntriesFetchArgs = {
  orderID: Scalars['ID'];
};

export type MutationMfaCodeCheckArgs = {
  code: Scalars['String'];
  requestId: Scalars['ID'];
};

export type MutationMfaRequestSendArgs = {
  verificationKind: Mfa__VerificationKind;
  verificationValue: Scalars['String'];
};

export type MutationMovingAddressUpdateArgs = {
  input: Moving__AddressUpdateInput;
};

export type MutationMovingCancelIntentCreateArgs = {
  orderID: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  reason: Moving__CancelIntent__Reason;
};

export type MutationMovingEstimationsUpdateArgs = {
  input: Moving__EstimationsUpdateInputType;
  rescheduleInput?: InputMaybe<Moving__RescheduleInputType>;
};

export type MutationMovingPackingHelpUpdateArgs = {
  orderID: Scalars['ID'];
  packingHelp: Moving__PackingEnum;
};

export type MutationMovingPackingMaterialsUpdateArgs = {
  action?: InputMaybe<Moving__PackingMaterialsUpdateAction>;
  orderID: Scalars['ID'];
  packageSetEntryID?: InputMaybe<Scalars['ID']>;
};

export type MutationMovingPrepaymentPayArgs = {
  orderID: Scalars['ID'];
  sourceID: Scalars['ID'];
};

export type MutationMovingQuoteRegenerateArgs = {
  orderID: Scalars['ID'];
  quoteInput?: InputMaybe<Moving__QuoteRegenerateInput>;
  source: Moving__QuoteSourceEnum;
};

export type MutationMovingQuoteRegenerateWithItemsArgs = {
  estimationInput: Estimation__CreateItemsInputType;
};

export type MutationMovingServicePackageUpdateArgs = {
  input: Moving__ServicePackageUpdate__Input;
};

export type MutationMovingStorageAddArgs = {
  input: Moving__StorageAddInputType;
  parentID?: InputMaybe<Scalars['ID']>;
};

export type MutationOrderRescheduleOfferItemAcceptArgs = {
  input: Order__RescheduleOfferItemAcceptInput;
};

export type MutationPickupServicePackageUpdateArgs = {
  input: PickupServicePackageUpdate__Input;
};

export type MutationRescheduleOrderArgs = {
  input: RescheduleOrderInput;
};

export type MutationResolveDelinquencyArgs = {
  invoiceIDs: Array<Scalars['ID']>;
  sourceID: Scalars['ID'];
};

export type MutationReviewSaveArgs = {
  input: ReviewInput;
};

export type MutationSaveItemArgs = {
  input: ItemInput;
  uuid: Scalars['ID'];
};

export type MutationSendReceiptArgs = {
  invoiceID: Scalars['ID'];
};

export type MutationShareArgs = {
  emails: Array<Scalars['String']>;
  source: Scalars['String'];
};

export type MutationShipmentItemQuotesUpsertArgs = {
  inputs: Array<Shipping__ShipmentItemQuoteInput>;
};

export type MutationShipmentValuationUpdateArgs = {
  input: Compliance__ShipmentValuationUpdateInput;
};

export type MutationSubmitClaimArgs = {
  uuid: Scalars['String'];
};

export type MutationSubmitFinancingCheckoutArgs = {
  input: Financing__CheckoutInput;
};

export type MutationSwapPaymentMethodArgs = {
  input: Billing__SwapPaymentMethodInput;
};

export type MutationSwitchFinancingIntentArgs = {
  intended: Scalars['Boolean'];
  orderID: Scalars['ID'];
};

export type MutationTrackClaimArgs = {
  step: Scalars['String'];
  uuid: Scalars['ID'];
};

export type MutationUpdateClaimItemSelectionArgs = {
  input: ClaimItemSelectionInput;
};

export type MutationUpdateCustomerItemArgs = {
  input: CustomerItemInput;
  type: CustomerItemTypeEnum;
  uuid: Scalars['ID'];
};

export type MutationUpdateNotificationPreferencesArgs = {
  input: Settings__UpdateNotificationPreferencesInput;
};

export type MutationUpdateOrderAddressArgs = {
  addressInput: AddressInput;
  orderID: Scalars['ID'];
};

export type MutationUpdateOrderContactArgs = {
  input: OrderContactInput;
};

export type MutationUpdatePasswordArgs = {
  input: Settings__UpdatePasswordInput;
};

export type MutationUpdatePickupInventoryArgs = {
  input: PickupInventoryInput;
  orderID: Scalars['ID'];
};

export type MutationUpdatePlanArgs = {
  input: Pricing__UpdatePlanInput;
};

export type MutationUpdateReturnInventoryArgs = {
  input: ReturnInventoryInput;
  orderID: Scalars['ID'];
};

export type MutationVirtualWalkthroughSubmitArgs = {
  input: VirtualWalkthroughSubmitInput;
};

export type NoDetailAddressInput = {
  aptsuite?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type OnboardingMutationInterface = {
  buildOnboardingCancelIntent?: Maybe<Onboarding__BuildCancelIntentPayload>;
};

export type OnboardingMutationInterfaceBuildOnboardingCancelIntentArgs = {
  orderID: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  reason: Onboarding__CancelIntent__Reason;
};

/** Autogenerated return type of Onboarding__BuildCancelIntent */
export type Onboarding__BuildCancelIntentPayload = {
  __typename?: 'Onboarding__BuildCancelIntentPayload';
  cancelIntent?: Maybe<Onboarding__CancelIntent>;
  error?: Maybe<Scalars['String']>;
  retentionOffer?: Maybe<Retention__Offer>;
  status: Status;
};

export type Onboarding__CancelIntent = {
  __typename?: 'Onboarding__CancelIntent';
  id: Scalars['ID'];
  reason: Onboarding__CancelIntent__Reason;
};

export enum Onboarding__CancelIntent__Reason {
  Accident = 'ACCIDENT',
  DifferentStorageCompany = 'DIFFERENT_STORAGE_COMPANY',
  DontNeedStorage = 'DONT_NEED_STORAGE',
  FoundFreeStorage = 'FOUND_FREE_STORAGE',
  Other = 'OTHER',
  PickupDateUnavailable = 'PICKUP_DATE_UNAVAILABLE',
}

export type Order = {
  __typename?: 'Order';
  account: Account;
  accountCancelIntent?: Maybe<Account__CancelIntent>;
  accountID: Scalars['ID'];
  accountPackages: Array<AccountPackage>;
  address: Address;
  /** This returns all successors of the order. This field does not support batch loading. */
  allSuccessors: Array<Order>;
  /** The pickup associated with this order if present, either as a sibling or a parent */
  associatedPickup?: Maybe<Order>;
  baseLaborRate?: Maybe<Pricing__LaborRate>;
  bookedAt: Scalars['DateTime'];
  bookingPartner?: Maybe<Partnerships__Partner>;
  /** The child disposal associated with this order, if present */
  childDisposal?: Maybe<Order>;
  coiRequests: Array<OrderCoiRequest>;
  consumerBrochure?: Maybe<Document>;
  contact?: Maybe<Order__Contact>;
  coupon?: Maybe<Coupon>;
  cuft?: Maybe<Scalars['Float']>;
  cuftPerHourPerMover?: Maybe<Scalars['Float']>;
  /**
   * This returns the items that the customer sees. This means it will return
   * ungrouped items and groups as a whole. Currently, this should only be called
   * with a single order, to avoid n+1 queries.
   */
  customerItems: Array<CustomerItem>;
  customerPinCode: Scalars['String'];
  depositPercent: Scalars['Float'];
  disposalConfirmationSignedOrderId: Scalars['String'];
  /** A URL linking to order documents that the customer needs to sign */
  documentHubURL?: Maybe<Scalars['String']>;
  dropOffAppointment?: Maybe<DropOff__Appointment>;
  ended?: Maybe<Scalars['DateTime']>;
  estimatedCuft: Scalars['Float'];
  estimatedCuftPerHourPerMover?: Maybe<Scalars['Float']>;
  estimatedItems: Array<EstimatedItem>;
  estimationAlgorithm?: Maybe<Estimation__Algorithm>;
  facilityWarehouse?: Maybe<Warehouse>;
  failedPrepayment?: Maybe<Billing__Prepayment>;
  financingContext: Financing__Context;
  financingIntent?: Maybe<Financing__Intent>;
  formattedArrivalWindow: Scalars['String'];
  formattedDepositInterval?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ingestedDocuments: Array<ActiveStorageAttachment>;
  internalScheduled?: Maybe<Scalars['DateTime']>;
  isActive: Scalars['Boolean'];
  items: Array<Item>;
  laborCostDetails?: Maybe<LaborCostDetails>;
  laborRate: LaborRate;
  maintenance: Scalars['Boolean'];
  metadata?: Maybe<OrderMetadata>;
  movers: Scalars['Int'];
  moving: Scalars['Boolean'];
  movingMaterialPackageSetEntries: Array<Moving__PackageSetEntry>;
  movingOperation?: Maybe<MovingOperation>;
  movingPricingSummary?: Maybe<Moving__PricingSummary>;
  movingSelections?: Maybe<Array<Selection>>;
  /**
   * This indicates whether the order is a component of an order that takes place
   * over multiple days. This field does not support batch loading.
   */
  multiDay: Scalars['Boolean'];
  name: Scalars['String'];
  /**
   * This returns the first non-travel order for this order and all of its
   * non-travel successors. This field does not support batch loading.
   */
  nonTravelSourceAndSuccessors: Array<Order>;
  number: Scalars['String'];
  paperworkRequired: Scalars['Boolean'];
  /** This is the parent of the order. If there is no parent, it will be nil. */
  parent?: Maybe<Order>;
  parentID?: Maybe<Scalars['ID']>;
  perItemPricingInfo?: Maybe<ItemPricing>;
  permissions: OrderPermissions;
  physicallySigned: Scalars['Boolean'];
  pickupServicePackageOptions: Array<Pricing__Bundle>;
  pickupVehicleKind?: Maybe<Order__PickupVehicleKind>;
  primary: Scalars['Boolean'];
  region?: Maybe<Region>;
  requiredLaborDuration?: Maybe<Scalars['Duration']>;
  rescheduleOfferCoupon?: Maybe<Coupon>;
  retailDelivery?: Maybe<Order>;
  retailDeliveryItems: Array<Retail__DeliveryItem>;
  scheduled: Scalars['DateTime'];
  serviceType?: Maybe<OrderServiceTypeEnum>;
  services: Array<OrderService>;
  shipped: Scalars['Boolean'];
  /** Signature requests where the role is `customer` or `signer` */
  signatureRequests: Array<SignatureRequest>;
  signatures: Array<Signature>;
  /**
   * This is the child pickup associated with the order. If there is no child
   * pickup or if the child pickup is canceled, this will be nil.
   */
  simultaneousPickup?: Maybe<Order>;
  slaWindowEnd: Scalars['DateTime'];
  slaWindowSize: Scalars['Int'];
  started: Scalars['Boolean'];
  state: OrderStateEnum;
  status: OrderStatusEnum;
  storage: Scalars['Boolean'];
  storageAddOnEligible: Scalars['Boolean'];
  subtype: OrderSubtypeEnum;
  tags: Array<Scalars['String']>;
  type: OrderTypeEnum;
  user?: Maybe<User>;
  waitlistRequests: Array<Availability__WaitlistRequest>;
  window: Scalars['Duration'];
};

export type OrderCoiRequest = {
  __typename?: 'OrderCOIRequest';
  additionalInsured: Scalars['String'];
  addressID?: Maybe<Scalars['ID']>;
  buildingManagerEmail?: Maybe<Scalars['String']>;
  buildingManagerName?: Maybe<Scalars['String']>;
  certificateHolder: Scalars['String'];
  certificateOfInsurance?: Maybe<ActiveStorageAttachment>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  order: Order;
  state: OrderCoiRequestStateEnum;
  tenantName?: Maybe<Scalars['String']>;
};

export type OrderCoiRequestInput = {
  additionalInsured: Scalars['String'];
  addressID: Scalars['ID'];
  buildingManagerEmail?: InputMaybe<Scalars['String']>;
  buildingManagerName?: InputMaybe<Scalars['String']>;
  certificateHolder: Scalars['String'];
  certificateOfInsuranceId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
  tenantName?: InputMaybe<Scalars['String']>;
};

export enum OrderCoiRequestStateEnum {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Submitted = 'SUBMITTED',
}

export type OrderContactInput = {
  contact: Order__ContactInput;
  orderID: Scalars['ID'];
  pickupVehicleKind?: InputMaybe<Order__PickupVehicleKind>;
};

export enum OrderDirection {
  Incoming = 'INCOMING',
  Outgoing = 'OUTGOING',
}

export type OrderEligibleServiceType = {
  __typename?: 'OrderEligibleServiceType';
  eligible: Scalars['Boolean'];
  price?: Maybe<Scalars['String']>;
  serviceType: OrderServiceTypeEnum;
};

export type OrderInput = {
  accountCancelIntentID?: InputMaybe<Scalars['ID']>;
  address?: InputMaybe<AddressInput>;
  addressID?: InputMaybe<Scalars['ID']>;
  confirmedDate?: InputMaybe<Scalars['Boolean']>;
  contact?: InputMaybe<Order__ContactInput>;
  customerShipmentPrice?: InputMaybe<Scalars['Float']>;
  duration?: InputMaybe<Scalars['Duration']>;
  estimatedItems?: InputMaybe<Array<EstimatedItemInput>>;
  estimations?: InputMaybe<Array<EstimationInput>>;
  isFullMoveOut?: InputMaybe<Scalars['Boolean']>;
  itemIDs?: InputMaybe<Array<Scalars['ID']>>;
  laborRateID?: InputMaybe<Scalars['ID']>;
  maxCustomerShipmentPrice?: InputMaybe<Scalars['Float']>;
  needsPackingHelp?: InputMaybe<Scalars['Boolean']>;
  perMoverHourAdjustmentAmount?: InputMaybe<Scalars['Float']>;
  pickupVehicleKind?: InputMaybe<Order__PickupVehicleKind>;
  regionID?: InputMaybe<Scalars['ID']>;
  requestedMovers?: InputMaybe<Scalars['Int']>;
  rooms?: InputMaybe<Array<RoomInput>>;
  scheduled?: InputMaybe<Scalars['DateTime']>;
  serviceType?: InputMaybe<OrderServiceTypeEnum>;
  shipments?: InputMaybe<Array<ShipmentInput>>;
  /** The auction that was won, necessitating the order to pick up the items. */
  storageTreasuresAuctionID?: InputMaybe<Scalars['ID']>;
  type: OrderTypeEnum;
  waitlistedDates?: InputMaybe<Array<Scalars['Date']>>;
  /**
   * This allows an agent to waive documents on an order. Currently, only disposals
   * are supported. Calling this for other order types will not have the desired effect.
   */
  waiveDocuments?: InputMaybe<Scalars['Boolean']>;
  /**
   * This allows an agent to waive fees on an order. Currently, only disposals are
   * supported. Calling this for other order types will not have the desired effect.
   */
  waiveFees?: InputMaybe<Scalars['Boolean']>;
};

export type OrderMetadata = {
  __typename?: 'OrderMetadata';
  allItemsInListedRooms?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  itemsRequireMoreThanOneMover?: Maybe<Order__Metadata__ItemsRequireMoreThanOneMover>;
};

export type OrderMutationInterface = {
  orderRescheduleOfferItemAccept?: Maybe<Order__RescheduleOfferItemAcceptPayload>;
};

export type OrderMutationInterfaceOrderRescheduleOfferItemAcceptArgs = {
  input: Order__RescheduleOfferItemAcceptInput;
};

export type OrderPermissions = {
  __typename?: 'OrderPermissions';
  active: Scalars['Boolean'];
  addDisposal: Scalars['Boolean'];
  cancelable: Scalars['Boolean'];
  cancellationFeeDisclaimer?: Maybe<Scalars['String']>;
  changeablePickupInventory: Scalars['Boolean'];
  changeableReturnInventory: Scalars['Boolean'];
  /** Deprecated: Use 'cancellation_fee_disclaimer' instead */
  chargeForCancellation: Scalars['Boolean'];
  chargeLateFee: Scalars['Boolean'];
  customerChangeableMoverCount: Scalars['Boolean'];
  id: Scalars['ID'];
  landingCheckoutPageTestEligible: Scalars['Boolean'];
  locked: Scalars['Boolean'];
  priceMatchGuaranteeEligible: Scalars['Boolean'];
  relocatable: Scalars['Boolean'];
  reschedulable: Scalars['Boolean'];
  whiteGloveTestEligible: Scalars['Boolean'];
};

export type OrderService = {
  __typename?: 'OrderService';
  accountID: Scalars['ID'];
  cuft?: Maybe<Scalars['Float']>;
  cuftPerHourPerMover?: Maybe<Scalars['Float']>;
  ended?: Maybe<Scalars['DateTime']>;
  estimatedCuft: Scalars['Float'];
  estimatedCuftPerHourPerMover?: Maybe<Scalars['Float']>;
  estimatedItems: Array<EstimatedItem>;
  estimations: Array<EstimationSelection>;
  id: Scalars['ID'];
  isFullMoveOut?: Maybe<Scalars['Boolean']>;
  items: Array<Item>;
  needsPackingHelp?: Maybe<Scalars['Boolean']>;
  parentID?: Maybe<Scalars['ID']>;
  requestedMovers?: Maybe<Scalars['Int']>;
  rooms: Array<RoomSelection>;
  serviceType?: Maybe<OrderServiceTypeEnum>;
  started?: Maybe<Scalars['DateTime']>;
  subtype: OrderSubtypeEnum;
  type: OrderTypeEnum;
};

export enum OrderServiceTypeEnum {
  Auction = 'AUCTION',
  /** @deprecated The capsule service is no longer offered. Do not remove from display components */
  Capsule = 'CAPSULE',
  CurbsidePickup = 'CURBSIDE_PICKUP',
  Disposal = 'DISPOSAL',
  /** @deprecated The doorstep service is no longer offered. Do not remove from display components */
  Doorstep = 'DOORSTEP',
  /** @deprecated The drop_off service is no longer offered. Do not remove from display components */
  DropOff = 'DROP_OFF',
  Facility = 'FACILITY',
  FullService = 'FULL_SERVICE',
  LongDistance = 'LONG_DISTANCE',
  Shipment = 'SHIPMENT',
  ThirdPartyReturn = 'THIRD_PARTY_RETURN',
}

export enum OrderStateEnum {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Enroute = 'ENROUTE',
  Graded = 'GRADED',
  Packed = 'PACKED',
  Palletized = 'PALLETIZED',
  Pending = 'PENDING',
  Prepared = 'PREPARED',
  Returned = 'RETURNED',
  Unconfirmed = 'UNCONFIRMED',
}

export enum OrderStatusEnum {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Scheduled = 'SCHEDULED',
}

export enum OrderSubtypeEnum {
  Cancelation = 'CANCELATION',
  Final = 'FINAL',
  None = 'NONE',
  Onboarding = 'ONBOARDING',
  Packing = 'PACKING',
  Subsequent = 'SUBSEQUENT',
  SupplyKit = 'SUPPLY_KIT',
  Travel = 'TRAVEL',
  Unpacking = 'UNPACKING',
}

export enum OrderTypeEnum {
  Disposal = 'DISPOSAL',
  Move = 'MOVE',
  Pickup = 'PICKUP',
  PickupReturn = 'PICKUP_RETURN',
  Restock = 'RESTOCK',
  RetailDelivery = 'RETAIL_DELIVERY',
  Return = 'RETURN',
  SelfStorageShuttle = 'SELF_STORAGE_SHUTTLE',
  TaskOrder = 'TASK_ORDER',
}

export type Order__Contact = {
  __typename?: 'Order__Contact';
  id: Scalars['ID'];
  kind?: Maybe<Order__ContactKind>;
  name?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
};

export type Order__ContactInput = {
  kind?: InputMaybe<Order__ContactKind>;
  name: Scalars['String'];
  phone: Scalars['String'];
};

export enum Order__ContactKind {
  Individual = 'INDIVIDUAL',
  MovingCompany = 'MOVING_COMPANY',
}

export enum Order__Metadata__ItemsRequireMoreThanOneMover {
  No = 'NO',
  NotSure = 'NOT_SURE',
  Yes = 'YES',
}

export enum Order__PickupVehicleKind {
  PersonalVehicle = 'PERSONAL_VEHICLE',
  ProfessionalMovingTruck = 'PROFESSIONAL_MOVING_TRUCK',
  RentalTruck = 'RENTAL_TRUCK',
}

export type Order__RescheduleOfferItemAcceptInput = {
  duration: Scalars['Duration'];
  laborRateID?: InputMaybe<Scalars['ID']>;
  offerItemSignedID: Scalars['String'];
  perMoverHourAdjustmentAmount?: InputMaybe<Scalars['Float']>;
  rescheduledTo: Scalars['DateTime'];
};

/** Autogenerated return type of Order__RescheduleOfferItemAccept */
export type Order__RescheduleOfferItemAcceptPayload = {
  __typename?: 'Order__RescheduleOfferItemAcceptPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Package = {
  __typename?: 'Package';
  fullPack: Scalars['Boolean'];
  id: Scalars['ID'];
  kind: PackageKindEnum;
  name: Scalars['String'];
};

export enum PackageKindEnum {
  AuctionFee = 'AUCTION_FEE',
  Core = 'CORE',
  DisposalDonation = 'DISPOSAL_DONATION',
  EarlyReturn = 'EARLY_RETURN',
  ExtendedServiceArea = 'EXTENDED_SERVICE_AREA',
  FinalReturn = 'FINAL_RETURN',
  FlatRateService = 'FLAT_RATE_SERVICE',
  FlexibleScheduling = 'FLEXIBLE_SCHEDULING',
  GasFee = 'GAS_FEE',
  LongDistanceTransport = 'LONG_DISTANCE_TRANSPORT',
  LongTerm = 'LONG_TERM',
  OnboardingService = 'ONBOARDING_SERVICE',
  OrderFee = 'ORDER_FEE',
  PackingSupplies = 'PACKING_SUPPLIES',
  Plus = 'PLUS',
  PreferredSchedulingFee = 'PREFERRED_SCHEDULING_FEE',
  SmallAppointment = 'SMALL_APPOINTMENT',
  TimeSlotSelectionFee = 'TIME_SLOT_SELECTION_FEE',
  TruckAndDriver = 'TRUCK_AND_DRIVER',
  WarehouseService = 'WAREHOUSE_SERVICE',
}

export type PackageSet = {
  __typename?: 'PackageSet';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PackageSetEntry = {
  __typename?: 'PackageSetEntry';
  amount?: Maybe<Scalars['Int']>;
  cappedAtOnboardingAmount: Scalars['Boolean'];
  costPerCuft: Scalars['Float'];
  fixedCost: Scalars['Float'];
  id: Scalars['ID'];
  minimumTermMonths: Scalars['Int'];
  orderSubtype: Scalars['String'];
  package: Package;
  packageSet?: Maybe<PackageSet>;
  storageCostMultiplier: Scalars['Float'];
};

export type PackageSetEntryAmountArgs = {
  cuft: Scalars['Float'];
  storagePrice?: InputMaybe<Scalars['Float']>;
};

export type PackageSetEntryInput = {
  amount: Scalars['Int'];
  id: Scalars['ID'];
};

export type PackingStandard = {
  __typename?: 'PackingStandard';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  document: Admin__Document;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PaginatedCustomerItem = {
  __typename?: 'PaginatedCustomerItem';
  pagination: Pagination;
  results: Array<CustomerItem>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  nextPage?: Maybe<Scalars['Int']>;
  prevPage?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type Partnerships__Partner = {
  __typename?: 'Partnerships__Partner';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PaymentPlanMutationInterface = {
  acceptPaymentPlanOffer?: Maybe<PaymentPlans__Offer__AcceptPayload>;
};

export type PaymentPlanMutationInterfaceAcceptPaymentPlanOfferArgs = {
  offerID: Scalars['ID'];
  sourceID: Scalars['ID'];
};

export type PaymentPlanQueryInterface = {
  paymentPlanOffer: PaymentPlan__Offer;
};

export type PaymentPlanQueryInterfacePaymentPlanOfferArgs = {
  id: Scalars['ID'];
};

export type PaymentPlan__Offer = {
  __typename?: 'PaymentPlan__Offer';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  account: Account;
  createdAt: Scalars['DateTime'];
  expired: Scalars['Boolean'];
  expiry: Scalars['DateTime'];
  id: Scalars['ID'];
  monthlyFee?: Maybe<Scalars['Float']>;
  monthlyFeeTerm?: Maybe<Scalars['Int']>;
  oneTimeFee?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Billing__Source>;
  settledInvoices: Array<Billing__Invoice>;
  state: PaymentPlan__Offer__State;
  storageTermEndDate?: Maybe<Scalars['Date']>;
  type: PaymentPlan__Offer__Type;
};

export enum PaymentPlan__Offer__State {
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
}

export enum PaymentPlan__Offer__Type {
  AutopayOptIn = 'AUTOPAY_OPT_IN',
  LumpSum = 'LUMP_SUM',
  PayToStay = 'PAY_TO_STAY',
  PayToVacate = 'PAY_TO_VACATE',
  SelfStorageAuction = 'SELF_STORAGE_AUCTION',
}

/** Autogenerated return type of PaymentPlans__Offer__Accept */
export type PaymentPlans__Offer__AcceptPayload = {
  __typename?: 'PaymentPlans__Offer__AcceptPayload';
  error?: Maybe<Scalars['String']>;
  offer?: Maybe<PaymentPlan__Offer>;
  status: Status;
};

export type PickupEstimate = {
  __typename?: 'PickupEstimate';
  /** The highest number of estimated items */
  highestEstimatedItems?: Maybe<Scalars['Int']>;
  /** The lowest number of estimated items */
  lowestEstimatedItems?: Maybe<Scalars['Int']>;
};

export type PickupInventoryInput = {
  estimatedItems: Array<EstimatedItemInput>;
  estimations: Array<EstimationInput>;
  isFullMoveOut: Scalars['Boolean'];
  needsPackingHelp?: InputMaybe<Scalars['Boolean']>;
  requestedMovers: Scalars['Int'];
  rooms: Array<RoomInput>;
};

/** Autogenerated return type of PickupServicePackageUpdate */
export type PickupServicePackageUpdatePayload = {
  __typename?: 'PickupServicePackageUpdatePayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type PickupServicePackageUpdate__Input = {
  orderID: Scalars['ID'];
  servicePackage: DoorToDoor__BundleEnum;
};

/** Autogenerated return type of Portal__DisposalAttach */
export type Portal__DisposalAttachPayload = {
  __typename?: 'Portal__DisposalAttachPayload';
  disposal: Order;
  status: Status;
};

export type PricingMutationInterface = {
  updatePlan?: Maybe<Pricing__UpdatePlanPayload>;
};

export type PricingMutationInterfaceUpdatePlanArgs = {
  input: Pricing__UpdatePlanInput;
};

export type PricingQueryInterface = {
  appointmentFeeOptions: Array<Pricing__Set__AppointmentFees>;
  laborBillingFormat?: Maybe<Pricing__LaborBillingFormat>;
  pricingPerItemFee?: Maybe<Pricing__PerItemFee>;
  pricingPolicyNoticeInfo: Pricing__PolicyNoticeInfo;
  pricingSet?: Maybe<Pricing__Set>;
};

export type PricingQueryInterfaceAppointmentFeeOptionsArgs = {
  cuft: Scalars['Float'];
  pricingSetID?: InputMaybe<Scalars['ID']>;
  rateGroupID: Scalars['ID'];
};

export type PricingQueryInterfacePricingPerItemFeeArgs = {
  input: Pricing__PerItemFeeInput;
};

export type PricingQueryInterfacePricingSetArgs = {
  parentOrderID?: InputMaybe<Scalars['ID']>;
  signedParentOrderID?: InputMaybe<Scalars['String']>;
  signedParentOrderPurpose?: InputMaybe<Scalars['String']>;
};

export type Pricing__Bundle = {
  __typename?: 'Pricing__Bundle';
  id: Scalars['ID'];
  kind: DoorToDoor__BundleEnum;
  name: Scalars['String'];
  packageSetEntry?: Maybe<PackageSetEntry>;
};

export enum Pricing__LaborBillingFormat {
  PerAppointmentHour = 'PER_APPOINTMENT_HOUR',
  PerMoverHour = 'PER_MOVER_HOUR',
}

export type Pricing__LaborPolicy = {
  __typename?: 'Pricing__LaborPolicy';
  id: Scalars['ID'];
  laborBillingFormat: Pricing__LaborBillingFormat;
  laborRate: Pricing__LaborRate;
  perItemPricing: Scalars['Boolean'];
  perMoverHour: Scalars['Boolean'];
};

export type Pricing__LaborPolicyLaborRateArgs = {
  cuft: Scalars['Float'];
};

export type Pricing__LaborRate = {
  __typename?: 'Pricing__LaborRate';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Pricing__PerItemFee = {
  __typename?: 'Pricing__PerItemFee';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
};

export type Pricing__PerItemFeeInput = {
  orderSubtype: OrderSubtypeEnum;
  orderType: OrderTypeEnum;
};

export type Pricing__Plan = Pricing__ProtectionPlan | Pricing__StoragePlan;

export type Pricing__Plan__Dimensions = {
  __typename?: 'Pricing__Plan__Dimensions';
  height?: Maybe<Scalars['Float']>;
  length: Scalars['Float'];
  width: Scalars['Float'];
};

export enum Pricing__Plan__ProtectionClassification {
  Premium = 'PREMIUM',
  Standard = 'STANDARD',
}

export enum Pricing__Plan__Type {
  ProtectionPlan = 'PROTECTION_PLAN',
  StoragePlan = 'STORAGE_PLAN',
}

/** Appointment pricing information for an account */
export type Pricing__PolicyNoticeInfo = {
  __typename?: 'Pricing__PolicyNoticeInfo';
  disposalDonationCostPerCuft: Scalars['String'];
  facilityAppointmentCostPerCuft: Scalars['String'];
  id: Scalars['ID'];
  subsequentPickupRate: Scalars['String'];
  subsequentReturnRate: Scalars['String'];
};

/** maps to a Pricing resource */
export type Pricing__Price = {
  __typename?: 'Pricing__Price';
  amount: Scalars['Float'];
  id: Scalars['ID'];
  plan: Pricing__Plan;
};

export type Pricing__ProtectionPlan = {
  __typename?: 'Pricing__ProtectionPlan';
  classification?: Maybe<Pricing__Plan__ProtectionClassification>;
  id: Scalars['ID'];
  maxCovered: Scalars['Int'];
  name: Scalars['String'];
  type: Pricing__Plan__Type;
};

export type Pricing__RateGroup = {
  __typename?: 'Pricing__RateGroup';
  commitmentType?: Maybe<Pricing__RateGroup__Commitment>;
  id: Scalars['ID'];
  kind: RateGroupKindEnum;
  serviceType?: Maybe<Pricing__RateGroup__OrderService>;
};

export enum Pricing__RateGroup__Commitment {
  Flexer = 'FLEXER',
  Mover = 'MOVER',
  Saver = 'SAVER',
}

export enum Pricing__RateGroup__OrderService {
  /** @deprecated The capsule is no longer offered */
  Capsule = 'CAPSULE',
  CurbsidePickup = 'CURBSIDE_PICKUP',
  /** @deprecated The drop_off is no longer offered */
  DropOff = 'DROP_OFF',
  FullService = 'FULL_SERVICE',
}

export type Pricing__Set = {
  __typename?: 'Pricing__Set';
  id: Scalars['ID'];
  laborEntries: Array<Pricing__Set__LaborEntry>;
  protectionPriceEntries: Array<Pricing__Set__PriceEntry>;
  /** Pricing sets will have a quote when generated through the PricingSetService */
  quoteID?: Maybe<Scalars['ID']>;
  storagePriceEntries: Array<Pricing__Set__PriceEntry>;
};

export type Pricing__SetStoragePriceEntriesArgs = {
  rateGroupKind?: InputMaybe<RateGroupKindEnum>;
};

export type Pricing__Set__AppointmentFees = {
  __typename?: 'Pricing__Set__AppointmentFees';
  laborEntry: Pricing__Set__LaborEntry;
  packageSetEntries: Array<Pricing__Set__PackageSetEntry>;
  serviceType: Pricing__RateGroup__OrderService;
};

export type Pricing__Set__LaborEntry = {
  __typename?: 'Pricing__Set__LaborEntry';
  id: Scalars['ID'];
  laborPolicy: Pricing__LaborPolicy;
};

export type Pricing__Set__PackageSetEntry = {
  __typename?: 'Pricing__Set__PackageSetEntry';
  amount: Scalars['Int'];
  id: Scalars['ID'];
  orderSubtype: Scalars['String'];
};

export type Pricing__Set__PackageSetEntryAmountArgs = {
  cuft: Scalars['Float'];
};

export type Pricing__Set__PriceEntry = {
  __typename?: 'Pricing__Set__PriceEntry';
  id: Scalars['ID'];
  price: Pricing__Price;
  rateGroup?: Maybe<Pricing__RateGroup>;
  storageTerm?: Maybe<Pricing__StorageTerm>;
};

export type Pricing__StoragePlan = {
  __typename?: 'Pricing__StoragePlan';
  cuft: Scalars['Int'];
  dimensions?: Maybe<Pricing__Plan__Dimensions>;
  formattedName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Pricing__Plan__Type;
};

export type Pricing__StorageTerm = {
  __typename?: 'Pricing__StorageTerm';
  id: Scalars['ID'];
  minimumMonths: Scalars['Int'];
  name: Scalars['String'];
};

export type Pricing__UpdatePlanInput = {
  laborPricingGroupEntryID: Scalars['ID'];
  laborRateID: Scalars['ID'];
  protectionPricingGroupEntryID: Scalars['ID'];
  storagePricingGroupEntryID: Scalars['ID'];
};

/** Autogenerated return type of Pricing__UpdatePlan */
export type Pricing__UpdatePlanPayload = {
  __typename?: 'Pricing__UpdatePlanPayload';
  account?: Maybe<Account>;
  allowsServicePackageSelection?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Query = AccountResolutionQueryInterface &
  BillingQueryInterface &
  ClaimQueryInterface &
  ComplianceQueryInterface &
  DisposalQueryInterface &
  EstimationQueryInterface &
  FacilityQueryInterface &
  MarketingQueryInterface &
  MovingQueryInterface &
  PaymentPlanQueryInterface &
  PricingQueryInterface &
  ReviewQueryInterface &
  ShippingQueryInterface &
  ZendeskQueryInterface & {
    __typename?: 'Query';
    account: Account;
    accountResolutionReason: AccountResolution__Reason;
    address: Address;
    addresses: Array<Address>;
    appointmentFeeOptions: Array<Pricing__Set__AppointmentFees>;
    availabilities: Array<Availability>;
    availabilityTiers: Array<AvailabilityTier>;
    availableForMovers?: Maybe<Scalars['Boolean']>;
    cancellationPolicy?: Maybe<Scalars['String']>;
    claim?: Maybe<Claim>;
    claimPreview: Scalars['String'];
    claims: Array<Claim>;
    classify?: Maybe<Order>;
    correctCustomerLoggedIn: Scalars['Boolean'];
    currentStorageSubscription?: Maybe<Subscription>;
    customItemCategory: ItemCategory;
    customer?: Maybe<Customer>;
    customerItem: CustomerItem;
    customerPrice: Array<Shipping__ShippingOption>;
    declaredCoverage?: Maybe<ShipmentValuationDeclaration>;
    defaultSource?: Maybe<Billing__Source>;
    delinquency: Delinquency;
    disclaimersForBookingOrder: BookingDisclaimer;
    disposalCategoryPricing: Array<Disposal__CategoryPricingDescriptor>;
    disposalItemPricing: Array<Disposal__ItemPricingDescriptor>;
    easypostInboundShippingOptions: Array<Shipping__EasyPostInboundShipment>;
    easypostOutboundShippingOptions: Array<Shipping__EasyPostOutboundShipment>;
    estimationAiClassification: Estimation__AiClassificationType;
    estimationBoxItemCategories: Array<Estimation__CategoryType>;
    estimationCategories: Array<EstimationCategory>;
    estimationItemCategoryThemes: Array<Estimation__CategoryThemeType>;
    estimationItems: Array<Estimation__EstimatedItemType>;
    estimationOtherItemCategories: Array<Estimation__CategoryType>;
    estimationRoomCategories: Array<Estimation__RoomCategoryType>;
    estimationSelectedBoxEstimatedItem?: Maybe<Estimation__EstimatedItemType>;
    estimationSelectedOtherEstimatedItem?: Maybe<Estimation__EstimatedItemType>;
    estimationSelectedRooms: Array<Estimation__SelectedRoomType>;
    estimationStatus?: Maybe<Estimation__Status>;
    estimationUploads: Array<Estimation__UploadType>;
    extendedServiceAreaOrder: Scalars['Boolean'];
    facility?: Maybe<Facility>;
    firstTimeCustomer?: Maybe<Customer>;
    inboundShippingEligible: Scalars['Boolean'];
    invoice: Billing__Invoice;
    invoices: Array<Billing__Invoice>;
    item: Item;
    itemPricing?: Maybe<ItemPricing>;
    items: Array<Item>;
    itemsCount: Scalars['Int'];
    laborBillingFormat?: Maybe<Pricing__LaborBillingFormat>;
    marketingShare?: Maybe<Marketing__Share>;
    movingConfiguration: MovingConfiguration;
    needSignature?: Maybe<Scalars['String']>;
    offerInTruckProtection: Scalars['Boolean'];
    onboardingIncentiveGiftCard: GiftCard;
    order: Order;
    orderBaseLaborRate?: Maybe<Pricing__LaborRate>;
    orderEligibleServiceTypes: Array<OrderEligibleServiceType>;
    orderSignatures?: Maybe<Order>;
    orders: Array<Order>;
    outboundShipmentMinDays: Scalars['Int'];
    paginatedCustomerItems: PaginatedCustomerItem;
    paginatedInvoiceList: Billing__PaginatedInvoice;
    paymentPlanOffer: PaymentPlan__Offer;
    payments: Array<Billing__InvoiceEventType>;
    pricingPerItemFee?: Maybe<Pricing__PerItemFee>;
    pricingPolicyNoticeInfo: Pricing__PolicyNoticeInfo;
    pricingSet?: Maybe<Pricing__Set>;
    referralCode?: Maybe<Scalars['String']>;
    referralHistory: Account__ReferralHistory;
    reonboardable: Scalars['Boolean'];
    /**
     * A link to the checkout for a particular product, defaulting to storage. Will
     * return null if the customer is not eligible to book.
     */
    reonboardingCheckoutLink?: Maybe<Scalars['String']>;
    requestable: Array<CustomerItem>;
    rescheduleOfferDetails?: Maybe<RescheduleOffer__DetailsType>;
    reviewReasons: Review__Reasons;
    reviewable?: Maybe<Review__Reviewable>;
    roomCategories: Array<RoomCategory>;
    shipmentLabels: Array<Shipping__EasyPostShipmentLabel>;
    shipmentValuationCoverages: Array<ShipmentValuationCoverage>;
    sources: Array<Billing__Source>;
    submittedWalkthroughCsatReview: Scalars['Boolean'];
    termCommitmentBalance?: Maybe<Account__TermCommitmentBalance>;
    termsForBookingOrder: Scalars['String'];
    timeZone: Scalars['String'];
    uncancellableShipmentOrderIds: Array<Scalars['ID']>;
    upcomingInvoices: Array<Billing__UpcomingInvoice>;
    upcomingStorageOnboarding?: Maybe<Order>;
    validPhoneNumber: Scalars['Boolean'];
    virtualWalkthroughEstimationItems: Array<Estimation__EstimatedItemType>;
    virtualWalkthroughItemCategories: Array<Estimation__CategoryType>;
    virtualWalkthroughItemCategorySearch: Array<Estimation__CategoryType>;
    virtualWalkthroughOtherItemCategories: Array<Estimation__CategoryType>;
    waitlistEligible: Scalars['Boolean'];
    zendeskCopy?: Maybe<Scalars['String']>;
    zipClassification: ZipClassification;
  };

export type QueryAccountResolutionReasonArgs = {
  id?: InputMaybe<Scalars['ID']>;
  rootName?: InputMaybe<Scalars['String']>;
};

export type QueryAddressArgs = {
  addressID: Scalars['ID'];
};

export type QueryAppointmentFeeOptionsArgs = {
  cuft: Scalars['Float'];
  pricingSetID?: InputMaybe<Scalars['ID']>;
  rateGroupID: Scalars['ID'];
};

export type QueryAvailabilitiesArgs = {
  input: AvailabilitiesInput;
};

export type QueryAvailabilityTiersArgs = {
  input: AvailabilityTiersInputType;
};

export type QueryAvailableForMoversArgs = {
  input: AvailabilitiesInput;
};

export type QueryCancellationPolicyArgs = {
  orderID: Scalars['ID'];
};

export type QueryClaimArgs = {
  uuid: Scalars['String'];
};

export type QueryClaimPreviewArgs = {
  address?: InputMaybe<NoDetailAddressInput>;
  name?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type QueryClassifyArgs = {
  cancelable: Scalars['Boolean'];
  orderID?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<OrderTypeEnum>;
};

export type QueryCorrectCustomerLoggedInArgs = {
  input: SignatureRequestInput;
};

export type QueryCustomerItemArgs = {
  type?: InputMaybe<CustomerItemTypeEnum>;
  uuid: Scalars['ID'];
};

export type QueryCustomerPriceArgs = {
  orderInput?: InputMaybe<OrderInput>;
  shippingOptions: Array<Shipping__ShippingOptionInput>;
};

export type QueryDeclaredCoverageArgs = {
  orderID: Scalars['ID'];
};

export type QueryDisclaimersForBookingOrderArgs = {
  input: OrderInput;
};

export type QueryDisposalItemPricingArgs = {
  itemIds: Array<Scalars['ID']>;
};

export type QueryEasypostInboundShippingOptionsArgs = {
  input: Shipping__EasyPostShipmentInput;
};

export type QueryEasypostOutboundShippingOptionsArgs = {
  input: Shipping__EasyPostShipmentInput;
};

export type QueryEstimationAiClassificationArgs = {
  id: Scalars['ID'];
};

export type QueryEstimationItemCategoryThemesArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
};

export type QueryEstimationItemsArgs = {
  algorithmName: Estimation__AlgorithmName;
  resourceID: Scalars['ID'];
  resourceType: Estimation__ResourceType;
};

export type QueryEstimationRoomCategoriesArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
};

export type QueryEstimationSelectedBoxEstimatedItemArgs = {
  orderID: Scalars['ID'];
};

export type QueryEstimationSelectedOtherEstimatedItemArgs = {
  orderID: Scalars['ID'];
};

export type QueryEstimationSelectedRoomsArgs = {
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
  orderID: Scalars['ID'];
};

export type QueryEstimationStatusArgs = {
  orderID: Scalars['ID'];
};

export type QueryEstimationUploadsArgs = {
  orderID: Scalars['ID'];
};

export type QueryExtendedServiceAreaOrderArgs = {
  addressID: Scalars['ID'];
  itemIDs: Array<Scalars['ID']>;
  orderSubtype: OrderSubtypeEnum;
};

export type QueryFacilityArgs = {
  itemIDs: Array<Scalars['ID']>;
};

export type QueryFirstTimeCustomerArgs = {
  initialSetupToken: Scalars['String'];
};

export type QueryInvoiceArgs = {
  invoiceID: Scalars['ID'];
};

export type QueryInvoicesArgs = {
  status?: InputMaybe<Billing__Invoice__Status>;
};

export type QueryItemArgs = {
  uuid: Scalars['ID'];
};

export type QueryItemPricingArgs = {
  input: ItemPricingInput;
};

export type QueryItemsArgs = {
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ItemStatus>;
};

export type QueryItemsCountArgs = {
  filters: ItemFiltersInput;
};

export type QueryMarketingShareArgs = {
  reviewableID: Scalars['ID'];
};

export type QueryMovingConfigurationArgs = {
  orderID: Scalars['ID'];
};

export type QueryOfferInTruckProtectionArgs = {
  input: SignatureRequestInput;
};

export type QueryOrderArgs = {
  orderID?: InputMaybe<Scalars['ID']>;
  signedOrderID?: InputMaybe<Scalars['String']>;
  signedOrderPurpose?: InputMaybe<Scalars['String']>;
};

export type QueryOrderBaseLaborRateArgs = {
  input: OrderInput;
};

export type QueryOrderEligibleServiceTypesArgs = {
  orderInput: OrderInput;
  skipPrimaryOrderCheck?: InputMaybe<Scalars['Boolean']>;
};

export type QueryOrderSignaturesArgs = {
  input: SignatureRequestInput;
};

export type QueryOrdersArgs = {
  onlyActive: Scalars['Boolean'];
  onlyCustomerSource?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<OrderStateEnum>;
};

export type QueryOutboundShipmentMinDaysArgs = {
  itemIDs: Array<Scalars['ID']>;
};

export type QueryPaginatedCustomerItemsArgs = {
  filters: ItemFiltersInput;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
};

export type QueryPaginatedInvoiceListArgs = {
  numPer?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryPaymentPlanOfferArgs = {
  id: Scalars['ID'];
};

export type QueryPaymentsArgs = {
  attemptIDs: Array<Scalars['ID']>;
};

export type QueryPricingPerItemFeeArgs = {
  input: Pricing__PerItemFeeInput;
};

export type QueryPricingSetArgs = {
  parentOrderID?: InputMaybe<Scalars['ID']>;
  signedParentOrderID?: InputMaybe<Scalars['String']>;
  signedParentOrderPurpose?: InputMaybe<Scalars['String']>;
};

export type QueryReferralCodeArgs = {
  reviewableID: Scalars['ID'];
};

export type QueryReonboardingCheckoutLinkArgs = {
  type?: InputMaybe<OrderTypeEnum>;
};

export type QueryRequestableArgs = {
  orderID?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
};

export type QueryRescheduleOfferDetailsArgs = {
  token: Scalars['String'];
};

export type QueryReviewReasonsArgs = {
  reviewableType: Review__ReviewableEnum;
  schedulingID?: InputMaybe<Scalars['ID']>;
};

export type QueryReviewableArgs = {
  reviewableID: Scalars['ID'];
};

export type QueryShipmentLabelsArgs = {
  orderId: Scalars['ID'];
};

export type QueryShipmentValuationCoveragesArgs = {
  orderID: Scalars['ID'];
};

export type QuerySubmittedWalkthroughCsatReviewArgs = {
  orderID: Scalars['ID'];
};

export type QueryTermCommitmentBalanceArgs = {
  scheduled?: InputMaybe<Scalars['DateTime']>;
};

export type QueryTermsForBookingOrderArgs = {
  input: OrderInput;
};

export type QueryTimeZoneArgs = {
  input: TimeZoneInput;
};

export type QueryUncancellableShipmentOrderIdsArgs = {
  orderIds: Array<Scalars['ID']>;
};

export type QueryValidPhoneNumberArgs = {
  phoneNumber: Scalars['PhoneNumber'];
};

export type QueryVirtualWalkthroughEstimationItemsArgs = {
  resourceID: Scalars['ID'];
};

export type QueryVirtualWalkthroughItemCategorySearchArgs = {
  query: Scalars['String'];
};

export type QueryZendeskCopyArgs = {
  articleName: Zendesk__ArticleName;
};

export type QueryZipClassificationArgs = {
  zip: Scalars['String'];
};

export enum RateGroupKindEnum {
  /** @deprecated The Capsule Mover rate group is no longer offered */
  CapsuleMover = 'CAPSULE_MOVER',
  CurbsideFlexer = 'CURBSIDE_FLEXER',
  CurbsideMover = 'CURBSIDE_MOVER',
  CurbsideSaver = 'CURBSIDE_SAVER',
  /** @deprecated The Drop-off Flexer rate group is no longer offered */
  DropOffFlexer = 'DROP_OFF_FLEXER',
  /** @deprecated The Drop-off Mover rate group is no longer offered */
  DropOffMover = 'DROP_OFF_MOVER',
  /** @deprecated The Drop-off Saver rate group is no longer offered */
  DropOffSaver = 'DROP_OFF_SAVER',
  Flexer = 'FLEXER',
  LongTerm = 'LONG_TERM',
  Mover = 'MOVER',
  Saver = 'SAVER',
  ShortTerm = 'SHORT_TERM',
}

export enum Rating {
  Good = 'GOOD',
  Poor = 'POOR',
}

export type Region = {
  __typename?: 'Region';
  center: LocatablePoint;
  coordinates: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  hexColor: Scalars['String'];
  id: Scalars['ID'];
  movingExtendedServiceAreaPostalCodes: Array<Scalars['String']>;
  name: Scalars['String'];
  parent?: Maybe<Region>;
  parentID?: Maybe<Scalars['ID']>;
  storageExtendedServiceAreaPostalCodes: Array<Scalars['String']>;
  tz: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  zipCodes: Array<Scalars['String']>;
};

export type RescheduleOffer__DetailsType = {
  __typename?: 'RescheduleOffer__DetailsType';
  availabilities?: Maybe<Array<Availability>>;
  coupon?: Maybe<Coupon>;
  order: Order;
  orderRescheduleOfferItemID: Scalars['ID'];
  token: Scalars['String'];
};

export type RescheduleOrderInput = {
  duration: Scalars['Duration'];
  laborRateID?: InputMaybe<Scalars['ID']>;
  orderID: Scalars['ID'];
  perMoverHourAdjustmentAmount?: InputMaybe<Scalars['Float']>;
  scheduled: Scalars['DateTime'];
};

/** Autogenerated return type of RescheduleOrder */
export type RescheduleOrderPayload = {
  __typename?: 'RescheduleOrderPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

/** Autogenerated return type of ResolveDelinquency */
export type ResolveDelinquencyPayload = {
  __typename?: 'ResolveDelinquencyPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Retail__DeliveryItem = {
  __typename?: 'Retail__DeliveryItem';
  id: Scalars['ID'];
  /** The number of material kits associated with the delivery item */
  quantity: Scalars['Int'];
  summedComponentSummaries: Array<Scalars['String']>;
  /** The material kit associated with the delivery item */
  unit: Retail__MaterialKit;
};

export type Retail__MaterialKit = {
  __typename?: 'Retail__MaterialKit';
  id: Scalars['ID'];
  /** The items belonging to the kit */
  items: Array<Retail__MaterialKitItem>;
  /** The name of the kit */
  name: Scalars['String'];
};

export type Retail__MaterialKitItem = {
  __typename?: 'Retail__MaterialKitItem';
  id: Scalars['ID'];
  /** The material of the item (e.g., 'Tape', 'Bubble Wrap') */
  material: Scalars['String'];
  /** The material kit the items belong to */
  materialKit: Retail__MaterialKit;
  /** The number of items */
  quantity: Scalars['Int'];
  /** A pluraized description of the items (e.g., '1x roll of tape', '2x rolls of tape') */
  summary: Scalars['String'];
  /** The unit the items come in (e.g., 'Sheet', 'Roll') */
  unit?: Maybe<Scalars['String']>;
};

export type RetentionMutationInterface = {
  acceptRetentionOffer?: Maybe<AcceptOfferPayload>;
};

export type RetentionMutationInterfaceAcceptRetentionOfferArgs = {
  offerID: Scalars['ID'];
};

export type Retention__Offer = {
  __typename?: 'Retention__Offer';
  detail: Retention__OfferDetail;
  id: Scalars['ID'];
};

export type Retention__OfferAction = {
  __typename?: 'Retention__OfferAction';
  discountAmount?: Maybe<Scalars['Int']>;
  discountDuration?: Maybe<Scalars['String']>;
  discountPercent?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
};

export type Retention__OfferDetail = {
  __typename?: 'Retention__OfferDetail';
  action: Retention__OfferAction;
  /** @deprecated use action { discountAmount } instead */
  discountAmount?: Maybe<Scalars['Int']>;
  /** @deprecated use action { discountPercent } instead */
  discountPercent?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  type: Retention__OfferDetail__Type;
  waiveRateAdjustment: Scalars['Boolean'];
};

export enum Retention__OfferDetail__Type {
  AccountCancellation = 'ACCOUNT_CANCELLATION',
  OrderCancellation = 'ORDER_CANCELLATION',
}

export type ReturnInventoryInput = {
  itemIDs?: InputMaybe<Array<Scalars['ID']>>;
};

export type ReviewInput = {
  comments?: InputMaybe<Scalars['String']>;
  rating: Rating;
  reasons: Scalars['Hash'];
  url: Scalars['String'];
};

export type ReviewQueryInterface = {
  reviewReasons: Review__Reasons;
  reviewable?: Maybe<Review__Reviewable>;
};

export type ReviewQueryInterfaceReviewReasonsArgs = {
  reviewableType: Review__ReviewableEnum;
  schedulingID?: InputMaybe<Scalars['ID']>;
};

export type ReviewQueryInterfaceReviewableArgs = {
  reviewableID: Scalars['ID'];
};

/** Autogenerated return type of ReviewSave */
export type ReviewSavePayload = {
  __typename?: 'ReviewSavePayload';
  status: Status;
};

export type Review__Reasons = {
  __typename?: 'Review__Reasons';
  good: Array<Scalars['String']>;
  poor: Array<Scalars['String']>;
};

export type Review__Reviewable =
  | Review__Reviewable__Base
  | Review__Reviewable__Order
  | Review__Reviewable__SelfStorageRental;

export enum Review__ReviewableEnum {
  Account = 'ACCOUNT',
  AccountResolutionReason = 'ACCOUNT_RESOLUTION_REASON',
  Claim = 'CLAIM',
  CustomerTicket = 'CUSTOMER_TICKET',
  DropOffAppointment = 'DROP_OFF_APPOINTMENT',
  Item = 'ITEM',
  ItemGroup = 'ITEM_GROUP',
  LogisticsOutboundLoad = 'LOGISTICS_OUTBOUND_LOAD',
  Order = 'ORDER',
  SelfStorageRental = 'SELF_STORAGE_RENTAL',
}

export type Review__ReviewableInput = {
  id: Scalars['ID'];
  type: Review__ReviewableEnum;
};

export type Review__Reviewable__Base = {
  __typename?: 'Review__Reviewable__Base';
  id: Scalars['ID'];
  type: Review__ReviewableEnum;
};

export type Review__Reviewable__Order = {
  __typename?: 'Review__Reviewable__Order';
  accountID: Scalars['ID'];
  id: Scalars['ID'];
  type: Review__ReviewableEnum;
};

export type Review__Reviewable__SelfStorageRental = {
  __typename?: 'Review__Reviewable__SelfStorageRental';
  facilityName: Scalars['String'];
  id: Scalars['ID'];
  type: Review__ReviewableEnum;
};

export type ReviewsMutationInterface = {
  reviewSave: ReviewSavePayload;
};

export type ReviewsMutationInterfaceReviewSaveArgs = {
  input: ReviewInput;
};

export type RoomCategory = {
  __typename?: 'RoomCategory';
  countable: Scalars['Boolean'];
  cuft: Scalars['Float'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RoomInput = {
  categoryID: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Int']>;
  selected?: InputMaybe<Scalars['Boolean']>;
};

export type RoomSelection = {
  __typename?: 'RoomSelection';
  category: RoomCategory;
  categoryID: Scalars['ID'];
  id: Scalars['ID'];
  quantity: Scalars['Int'];
  selected: Scalars['Boolean'];
};

/** Autogenerated return type of SaveItem */
export type SaveItemPayload = {
  __typename?: 'SaveItemPayload';
  item: Item;
};

export type Selection = {
  __typename?: 'Selection';
  id: Scalars['ID'];
  movingCategory: Category;
  quantity: Scalars['Int'];
};

export type SelfStorage__AdjustedRateRental = {
  __typename?: 'SelfStorage__AdjustedRateRental';
  discount: Scalars['Float'];
  id: Scalars['ID'];
  subscriptionItems: Array<SelfStorage__UpcomingSubscriptionItem>;
  unit: SelfStorage__Unit;
};

export type SelfStorage__Classification = {
  __typename?: 'SelfStorage__Classification';
  id: Scalars['ID'];
  kind: Scalars['String'];
  length: Scalars['Float'];
  width: Scalars['Float'];
};

export type SelfStorage__Facility = {
  __typename?: 'SelfStorage__Facility';
  accessCode?: Maybe<Scalars['String']>;
  address: Address;
  id: Scalars['ID'];
  name: Scalars['String'];
  officeHours: Array<Scalars['String']>;
};

export type SelfStorage__Hold = {
  __typename?: 'SelfStorage__Hold';
  id: Scalars['ID'];
  unit: SelfStorage__Unit;
};

export type SelfStorage__Policy = {
  __typename?: 'SelfStorage__Policy';
  amount: Scalars['Float'];
  company: Scalars['String'];
  from: Scalars['Date'];
  id: Scalars['ID'];
  kind: SelfStorage__Policy__Kind;
  number: Scalars['String'];
  till: Scalars['Date'];
};

export enum SelfStorage__Policy__Kind {
  AutoInsurance = 'AUTO_INSURANCE',
  Commercial = 'COMMERCIAL',
  GeneralLiability = 'GENERAL_LIABILITY',
  Homeowner = 'HOMEOWNER',
  Renter = 'RENTER',
  UmbrellaCoverage = 'UMBRELLA_COVERAGE',
}

export type SelfStorage__Rental = {
  __typename?: 'SelfStorage__Rental';
  id: Scalars['ID'];
  subscriptionItems: Array<SelfStorage__SubscriptionItem>;
  unit: SelfStorage__Unit;
};

export type SelfStorage__Subscription = {
  __typename?: 'SelfStorage__Subscription';
  billing: SelfStorage__Subscription__Billing;
  billsOn?: Maybe<Scalars['DateTime']>;
  defaultSource?: Maybe<Billing__Source>;
  facility: SelfStorage__Facility;
  id: Scalars['ID'];
  rentals: Array<SelfStorage__Rental>;
};

export type SelfStorage__SubscriptionItem = {
  __typename?: 'SelfStorage__SubscriptionItem';
  id: Scalars['ID'];
  kind: SelfStorage__SubscriptionItem__Kind;
  rate: Scalars['Float'];
  summary: Scalars['String'];
};

export enum SelfStorage__SubscriptionItem__Kind {
  Fee = 'FEE',
  Protection = 'PROTECTION',
  Unit = 'UNIT',
}

export enum SelfStorage__Subscription__Billing {
  ChargeAutomatically = 'CHARGE_AUTOMATICALLY',
  SendInvoice = 'SEND_INVOICE',
}

export type SelfStorage__Unit = {
  __typename?: 'SelfStorage__Unit';
  classification: SelfStorage__Classification;
  directions?: Maybe<Scalars['String']>;
  facility: SelfStorage__Facility;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type SelfStorage__UpcomingSubscription = {
  __typename?: 'SelfStorage__UpcomingSubscription';
  billing: SelfStorage__Subscription__Billing;
  billsOn?: Maybe<Scalars['DateTime']>;
  defaultSource?: Maybe<Billing__Source>;
  facility: SelfStorage__Facility;
  id: Scalars['ID'];
  rentals: Array<SelfStorage__AdjustedRateRental>;
};

export type SelfStorage__UpcomingSubscriptionItem = {
  __typename?: 'SelfStorage__UpcomingSubscriptionItem';
  id: Scalars['ID'];
  kind: SelfStorage__SubscriptionItem__Kind;
  rate: Scalars['Float'];
  summary: Scalars['String'];
};

export type SettingsMutationInterface = {
  updateNotificationPreferences?: Maybe<Settings__UpdateNotificationPreferencesPayload>;
  updatePassword?: Maybe<Settings__UpdatePasswordPayload>;
};

export type SettingsMutationInterfaceUpdateNotificationPreferencesArgs = {
  input: Settings__UpdateNotificationPreferencesInput;
};

export type SettingsMutationInterfaceUpdatePasswordArgs = {
  input: Settings__UpdatePasswordInput;
};

export type Settings__UpdateNotificationPreferencesInput = {
  emailReceipts: Scalars['Boolean'];
};

/** Autogenerated return type of Settings__UpdateNotificationPreferences */
export type Settings__UpdateNotificationPreferencesPayload = {
  __typename?: 'Settings__UpdateNotificationPreferencesPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Settings__UpdatePasswordInput = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};

/** Autogenerated return type of Settings__UpdatePassword */
export type Settings__UpdatePasswordPayload = {
  __typename?: 'Settings__UpdatePasswordPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of Share */
export type SharePayload = {
  __typename?: 'SharePayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Shipment = {
  __typename?: 'Shipment';
  id: Scalars['ID'];
  trackingUrl: Scalars['String'];
};

export type ShipmentInput = {
  carrier: Scalars['String'];
  contents?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  easyPostRateId: Scalars['String'];
  easyPostShipmentId: Scalars['String'];
  height: Scalars['Float'];
  length: Scalars['Float'];
  rate: Scalars['Float'];
  service: Scalars['String'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
};

export type ShipmentValuationCoverage = {
  __typename?: 'ShipmentValuationCoverage';
  deductible: Scalars['Int'];
  id: Scalars['ID'];
  key: Scalars['String'];
  maximumDeclaredValue?: Maybe<Scalars['Float']>;
  minimumDeclaredValue: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['Float'];
  summary?: Maybe<Scalars['String']>;
};

export type ShipmentValuationDeclaration = {
  __typename?: 'ShipmentValuationDeclaration';
  chargeAmount: Scalars['Float'];
  coverage: ShipmentValuationCoverage;
  id: Scalars['ID'];
  orderId: Scalars['ID'];
  value: Scalars['Float'];
};

export type ShippingMutationInterface = {
  shipmentItemQuotesUpsert?: Maybe<Shipping__ShipmentItemQuotesUpsertPayload>;
};

export type ShippingMutationInterfaceShipmentItemQuotesUpsertArgs = {
  inputs: Array<Shipping__ShipmentItemQuoteInput>;
};

export type ShippingQueryInterface = {
  customerPrice: Array<Shipping__ShippingOption>;
  easypostInboundShippingOptions: Array<Shipping__EasyPostInboundShipment>;
  easypostOutboundShippingOptions: Array<Shipping__EasyPostOutboundShipment>;
  inboundShippingEligible: Scalars['Boolean'];
  outboundShipmentMinDays: Scalars['Int'];
  shipmentLabels: Array<Shipping__EasyPostShipmentLabel>;
  uncancellableShipmentOrderIds: Array<Scalars['ID']>;
};

export type ShippingQueryInterfaceCustomerPriceArgs = {
  orderInput?: InputMaybe<OrderInput>;
  shippingOptions: Array<Shipping__ShippingOptionInput>;
};

export type ShippingQueryInterfaceEasypostInboundShippingOptionsArgs = {
  input: Shipping__EasyPostShipmentInput;
};

export type ShippingQueryInterfaceEasypostOutboundShippingOptionsArgs = {
  input: Shipping__EasyPostShipmentInput;
};

export type ShippingQueryInterfaceOutboundShipmentMinDaysArgs = {
  itemIDs: Array<Scalars['ID']>;
};

export type ShippingQueryInterfaceShipmentLabelsArgs = {
  orderId: Scalars['ID'];
};

export type ShippingQueryInterfaceUncancellableShipmentOrderIdsArgs = {
  orderIds: Array<Scalars['ID']>;
};

export type Shipping__EasyPostInboundItemInput = {
  height: Scalars['Int'];
  length: Scalars['Int'];
  name: Scalars['String'];
  width: Scalars['Int'];
};

export type Shipping__EasyPostInboundShipment = {
  __typename?: 'Shipping__EasyPostInboundShipment';
  id: Scalars['ID'];
  itemName: Scalars['String'];
  rates: Array<Shipping__EasyPostRate>;
  weight: Scalars['Int'];
};

export type Shipping__EasyPostOutboundItemInput = {
  heavy: Scalars['Boolean'];
  itemID: Scalars['ID'];
};

export type Shipping__EasyPostOutboundShipment = {
  __typename?: 'Shipping__EasyPostOutboundShipment';
  id: Scalars['ID'];
  itemID: Scalars['ID'];
  rates: Array<Shipping__EasyPostRate>;
};

export type Shipping__EasyPostRate = {
  __typename?: 'Shipping__EasyPostRate';
  carrier: Scalars['String'];
  currency: Scalars['String'];
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryDays?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  mode: Scalars['String'];
  rate: Scalars['Float'];
  service: Scalars['String'];
};

export type Shipping__EasyPostShipmentInput = {
  addressID: Scalars['ID'];
  inboundItems?: InputMaybe<Array<Shipping__EasyPostInboundItemInput>>;
  outboundItems?: InputMaybe<Array<Shipping__EasyPostOutboundItemInput>>;
};

export type Shipping__EasyPostShipmentLabel = {
  __typename?: 'Shipping__EasyPostShipmentLabel';
  carrier: Scalars['String'];
  easyPostShipmentId: Scalars['String'];
  shipmentContents: Scalars['String'];
  shipmentLabel?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Shipping__ShipmentItemQuoteInput = {
  carrier: Scalars['String'];
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  deliveryDays?: InputMaybe<Scalars['Int']>;
  fragile: Scalars['Boolean'];
  heavy: Scalars['Boolean'];
  itemID: Scalars['ID'];
  rate: Scalars['Float'];
  service: Scalars['String'];
};

/** Autogenerated return type of Shipping__ShipmentItemQuotesUpsert */
export type Shipping__ShipmentItemQuotesUpsertPayload = {
  __typename?: 'Shipping__ShipmentItemQuotesUpsertPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Shipping__ShippingOption = {
  __typename?: 'Shipping__ShippingOption';
  carrier: Scalars['String'];
  deliveryDays: Scalars['Int'];
  maxPrice: Scalars['Float'];
  price: Scalars['Float'];
  service: Scalars['String'];
};

export type Shipping__ShippingOptionInput = {
  carrier: Scalars['String'];
  deliveryDays: Scalars['Int'];
  price: Scalars['Float'];
  service: Scalars['String'];
};

export type Signature = {
  __typename?: 'Signature';
  agreement?: Maybe<ActiveStorageAttachment>;
  contractUrl?: Maybe<Scalars['String']>;
  documentKind: Scalars['String'];
  id: Scalars['ID'];
};

export type SignatureRequest = {
  __typename?: 'SignatureRequest';
  createdAt?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kind: SignatureRequestKindEnum;
  signedAt?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SignatureRequestInput = {
  orderID?: InputMaybe<Scalars['ID']>;
  signedOrderID?: InputMaybe<Scalars['String']>;
};

export enum SignatureRequestKindEnum {
  InAdvance = 'IN_ADVANCE',
  PostJob = 'POST_JOB',
  PreJob = 'PRE_JOB',
}

export type SizingMetric = {
  __typename?: 'SizingMetric';
  enumerations: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  range?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export enum Status {
  Ok = 'OK',
  Unauthorized = 'UNAUTHORIZED',
  Unprocessable = 'UNPROCESSABLE',
}

export type StorageTreasures__AuctionInvoiceType = {
  __typename?: 'StorageTreasures__AuctionInvoiceType';
  clutterAmount: Scalars['Float'];
  id: Scalars['ID'];
};

export type StorageTreasures__AuctionType = {
  __typename?: 'StorageTreasures__AuctionType';
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  items: Array<Item>;
  storageTreasuresLink: Scalars['String'];
  warehouse: Warehouse;
  winnerInvoice?: Maybe<StorageTreasures__AuctionInvoiceType>;
  /** The order scheduled by the auction winner to pick up the items. */
  winnerOrder?: Maybe<Order>;
};

/** Autogenerated return type of SubmitClaim */
export type SubmitClaimPayload = {
  __typename?: 'SubmitClaimPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Subscription = {
  __typename?: 'Subscription';
  cubicFootage?: Maybe<Scalars['Int']>;
  currentPeriodEnd?: Maybe<Scalars['Date']>;
  /**
   * The dimensions (length, width, height) of this subscription. Will be
   * null if this is not a storage subscription.
   */
  dimensions?: Maybe<Pricing__Plan__Dimensions>;
  discount: Scalars['Float'];
  formattedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pricing: Pricing__Price;
  quantity: Scalars['Int'];
  tax: Scalars['Float'];
  total: Scalars['Float'];
};

export type SubscriptionInput = {
  customDimension?: InputMaybe<CustomDimensionInput>;
  planID: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SubscriptionSet = {
  __typename?: 'SubscriptionSet';
  /** The minimum period-end date of all the subscriptions in this set. */
  nextPeriodEnd?: Maybe<Scalars['DateTime']>;
  protectionSubscription?: Maybe<Subscription>;
  storageSubscriptions: Array<Subscription>;
};

export type TermCommitmentMutationInterface = {
  buildTermCommitmentCustomerTicket?: Maybe<TermCommitment__BuildCustomerTicketPayload>;
};

/** Autogenerated return type of TermCommitment__BuildCustomerTicket */
export type TermCommitment__BuildCustomerTicketPayload = {
  __typename?: 'TermCommitment__BuildCustomerTicketPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type TimeZoneInput = {
  addressID?: InputMaybe<Scalars['ID']>;
  warehouseID?: InputMaybe<Scalars['ID']>;
};

/** Autogenerated return type of TrackClaim */
export type TrackClaimPayload = {
  __typename?: 'TrackClaimPayload';
  status: Status;
};

export type UpcomingSubscription = {
  __typename?: 'UpcomingSubscription';
  cubicFootage?: Maybe<Scalars['Int']>;
  currentPeriodEnd?: Maybe<Scalars['Date']>;
  /**
   * The dimensions (length, width, height) of this subscription. Will be
   * null if this is not a storage subscription.
   */
  dimensions?: Maybe<Pricing__Plan__Dimensions>;
  discount: Scalars['Float'];
  formattedName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pricing: Pricing__Price;
  quantity: Scalars['Int'];
  tax: Scalars['Float'];
  total: Scalars['Float'];
};

export type UpcomingSubscriptionSet = {
  __typename?: 'UpcomingSubscriptionSet';
  /** The minimum period-end date of all the subscriptions in this set. */
  nextPeriodEnd?: Maybe<Scalars['DateTime']>;
  protectionSubscription?: Maybe<UpcomingSubscription>;
  storageSubscriptions: Array<UpcomingSubscription>;
};

/** Autogenerated return type of UpdateClaimItemSelection */
export type UpdateClaimItemSelectionPayload = {
  __typename?: 'UpdateClaimItemSelectionPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of UpdateCustomerItem */
export type UpdateCustomerItemPayload = {
  __typename?: 'UpdateCustomerItemPayload';
  item: CustomerItem;
};

/** Autogenerated return type of UpdateOrderAddress */
export type UpdateOrderAddressPayload = {
  __typename?: 'UpdateOrderAddressPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of UpdateOrderContact */
export type UpdateOrderContactPayload = {
  __typename?: 'UpdateOrderContactPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

/** Autogenerated return type of UpdatePickupInventory */
export type UpdatePickupInventoryPayload = {
  __typename?: 'UpdatePickupInventoryPayload';
  error?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status: Status;
};

/** Autogenerated return type of UpdateReturnInventory */
export type UpdateReturnInventoryPayload = {
  __typename?: 'UpdateReturnInventoryPayload';
  error?: Maybe<Scalars['String']>;
  order?: Maybe<Order>;
  status: Status;
};

export type UpgradeDowngradeEntry = {
  __typename?: 'UpgradeDowngradeEntry';
  price: Pricing__Price;
  quantity: Scalars['Int'];
  total: Scalars['Float'];
};

export type UpgradeDowngradeSummary = {
  __typename?: 'UpgradeDowngradeSummary';
  current: Array<UpgradeDowngradeEntry>;
  proposed: Array<UpgradeDowngradeEntry>;
  savings: Scalars['Float'];
};

export type Usage = {
  __typename?: 'Usage';
  kind: Usage__Kind;
  summary: Scalars['String'];
  total: Scalars['Float'];
  used: Scalars['Float'];
};

export enum Usage__Kind {
  Cuft = 'CUFT',
  Item = 'ITEM',
}

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VirtualWalkthroughSubmitInput = {
  customerNotes?: InputMaybe<Scalars['String']>;
  orderID: Scalars['ID'];
};

/** Autogenerated return type of VirtualWalkthroughSubmit */
export type VirtualWalkthroughSubmitPayload = {
  __typename?: 'VirtualWalkthroughSubmitPayload';
  error?: Maybe<Scalars['String']>;
  status: Status;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  accessHours: Array<Scalars['String']>;
  accessInstructions: Scalars['String'];
  address: Address;
  id: Scalars['ID'];
};

export type ZendeskQueryInterface = {
  zendeskCopy?: Maybe<Scalars['String']>;
};

export type ZendeskQueryInterfaceZendeskCopyArgs = {
  articleName: Zendesk__ArticleName;
};

export enum Zendesk__ArticleName {
  DisposalExpectations = 'DISPOSAL_EXPECTATIONS',
  FacilityExpectations = 'FACILITY_EXPECTATIONS',
  ShippingProhibitedItems = 'SHIPPING_PROHIBITED_ITEMS',
}

export type ZipClassification = {
  __typename?: 'ZipClassification';
  fullServiceEligible: Scalars['Boolean'];
  inServiceArea: Scalars['Boolean'];
  selfStorageEligible: Scalars['Boolean'];
  valid: Scalars['Boolean'];
};

export type AddressDetailsUpdateMutationVariables = Exact<{
  input: AddressDetailsUpdateInput;
}>;

export type AddressDetailsUpdateMutation = {
  __typename?: 'Mutation';
  addressDetailsUpdate?: { __typename?: 'AddressDetailsUpdatePayload'; error?: string | null; status: Status } | null;
};

export type CustomerQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; id: string; name: string; phone: string } };
};

export type AccountDetailFragment = {
  __typename?: 'Account';
  id: string;
  state: Account__State;
  onboardable: boolean;
  contractStartDate?: string | null;
  perItemPricing: boolean;
  requestableItemsExist: boolean;
  laborRate: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string };
  region: { __typename?: 'Region'; id: string; name: string; tz: string };
  rateGroup?: {
    __typename?: 'Pricing__RateGroup';
    id: string;
    commitmentType?: Pricing__RateGroup__Commitment | null;
    serviceType?: Pricing__RateGroup__OrderService | null;
  } | null;
  onboardingCostDetails: {
    __typename?: 'LaborCostDetails';
    id: string;
    formattedCost: string;
    discountTierDescription?: string | null;
    appointmentFeeCost: number;
  };
  subsequentPickupCostDetails: {
    __typename?: 'LaborCostDetails';
    id: string;
    formattedCost: string;
    discountTierDescription?: string | null;
    appointmentFeeCost: number;
  };
  subsequentReturnCostDetails: {
    __typename?: 'LaborCostDetails';
    id: string;
    formattedCost: string;
    discountTierDescription?: string | null;
    appointmentFeeCost: number;
  };
  finalOrderCostDetails: {
    __typename?: 'LaborCostDetails';
    id: string;
    formattedCost: string;
    discountTierDescription?: string | null;
    appointmentFeeCost: number;
  };
  upcomingSubscriptionSet: {
    __typename?: 'UpcomingSubscriptionSet';
    nextPeriodEnd?: string | null;
    storageSubscriptions: Array<{
      __typename?: 'UpcomingSubscription';
      id: string;
      name: string;
      cubicFootage?: number | null;
      quantity: number;
      discount: number;
      tax: number;
      total: number;
      pricing: {
        __typename?: 'Pricing__Price';
        id: string;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
    }>;
    protectionSubscription?: {
      __typename?: 'UpcomingSubscription';
      id: string;
      name: string;
      cubicFootage?: number | null;
      quantity: number;
      discount: number;
      tax: number;
      total: number;
      pricing: {
        __typename?: 'Pricing__Price';
        id: string;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
    } | null;
  };
  termCommitment?: { __typename?: 'Account__TermCommitment'; term: number } | null;
  upcomingSelfStorageSubscriptions: Array<{
    __typename?: 'SelfStorage__UpcomingSubscription';
    id: string;
    billing: SelfStorage__Subscription__Billing;
    billsOn?: string | null;
    facility: {
      __typename?: 'SelfStorage__Facility';
      id: string;
      name: string;
      officeHours: Array<string>;
      address: { __typename?: 'Address'; street: string; city: string; state: string; zip: string };
    };
    rentals: Array<{
      __typename?: 'SelfStorage__AdjustedRateRental';
      id: string;
      discount: number;
      subscriptionItems: Array<{
        __typename?: 'SelfStorage__UpcomingSubscriptionItem';
        id: string;
        summary: string;
        rate: number;
        kind: SelfStorage__SubscriptionItem__Kind;
      }>;
      unit: {
        __typename?: 'SelfStorage__Unit';
        id: string;
        name: string;
        classification: {
          __typename?: 'SelfStorage__Classification';
          id: string;
          width: number;
          length: number;
          kind: string;
        };
      };
    }>;
    source?: {
      __typename?: 'Billing__Source';
      id: string;
      number: string;
      brand?: string | null;
      name?: string | null;
      expiration?: string | null;
      expired: boolean;
      default: boolean;
      fundingType: Billing__Source__FundingType;
    } | null;
  }>;
  unscheduledStorageTreasuresAuctionsWon: Array<{
    __typename?: 'StorageTreasures__AuctionType';
    id: string;
    endTime: string;
    storageTreasuresLink: string;
    items: Array<{
      __typename: 'Item';
      id: string;
      uuid: string;
      cuft?: number | null;
      hero: number;
      name?: string | null;
      contents?: string | null;
      selectableForReturn: boolean;
      shippingEligible: boolean;
      category?: { __typename?: 'ItemCategory'; name: string } | null;
      barcode: { __typename?: 'Barcode'; id: string; value: string };
      images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
    }>;
    warehouse: {
      __typename?: 'Warehouse';
      id: string;
      accessHours: Array<string>;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
        region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
      };
    };
    winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
  }>;
};

export type BalanceQueryVariables = Exact<{ [key: string]: never }>;

export type BalanceQuery = { __typename?: 'Query'; account: { __typename?: 'Account'; creditBalance?: number | null } };

export type AccountDetailQueryVariables = Exact<{ [key: string]: never }>;

export type AccountDetailQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    state: Account__State;
    onboardable: boolean;
    contractStartDate?: string | null;
    perItemPricing: boolean;
    requestableItemsExist: boolean;
    laborRate: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string };
    region: { __typename?: 'Region'; id: string; name: string; tz: string };
    rateGroup?: {
      __typename?: 'Pricing__RateGroup';
      id: string;
      commitmentType?: Pricing__RateGroup__Commitment | null;
      serviceType?: Pricing__RateGroup__OrderService | null;
    } | null;
    onboardingCostDetails: {
      __typename?: 'LaborCostDetails';
      id: string;
      formattedCost: string;
      discountTierDescription?: string | null;
      appointmentFeeCost: number;
    };
    subsequentPickupCostDetails: {
      __typename?: 'LaborCostDetails';
      id: string;
      formattedCost: string;
      discountTierDescription?: string | null;
      appointmentFeeCost: number;
    };
    subsequentReturnCostDetails: {
      __typename?: 'LaborCostDetails';
      id: string;
      formattedCost: string;
      discountTierDescription?: string | null;
      appointmentFeeCost: number;
    };
    finalOrderCostDetails: {
      __typename?: 'LaborCostDetails';
      id: string;
      formattedCost: string;
      discountTierDescription?: string | null;
      appointmentFeeCost: number;
    };
    upcomingSubscriptionSet: {
      __typename?: 'UpcomingSubscriptionSet';
      nextPeriodEnd?: string | null;
      storageSubscriptions: Array<{
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      }>;
      protectionSubscription?: {
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      } | null;
    };
    termCommitment?: { __typename?: 'Account__TermCommitment'; term: number } | null;
    upcomingSelfStorageSubscriptions: Array<{
      __typename?: 'SelfStorage__UpcomingSubscription';
      id: string;
      billing: SelfStorage__Subscription__Billing;
      billsOn?: string | null;
      facility: {
        __typename?: 'SelfStorage__Facility';
        id: string;
        name: string;
        officeHours: Array<string>;
        address: { __typename?: 'Address'; street: string; city: string; state: string; zip: string };
      };
      rentals: Array<{
        __typename?: 'SelfStorage__AdjustedRateRental';
        id: string;
        discount: number;
        subscriptionItems: Array<{
          __typename?: 'SelfStorage__UpcomingSubscriptionItem';
          id: string;
          summary: string;
          rate: number;
          kind: SelfStorage__SubscriptionItem__Kind;
        }>;
        unit: {
          __typename?: 'SelfStorage__Unit';
          id: string;
          name: string;
          classification: {
            __typename?: 'SelfStorage__Classification';
            id: string;
            width: number;
            length: number;
            kind: string;
          };
        };
      }>;
      source?: {
        __typename?: 'Billing__Source';
        id: string;
        number: string;
        brand?: string | null;
        name?: string | null;
        expiration?: string | null;
        expired: boolean;
        default: boolean;
        fundingType: Billing__Source__FundingType;
      } | null;
    }>;
    unscheduledStorageTreasuresAuctionsWon: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      endTime: string;
      storageTreasuresLink: string;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        cuft?: number | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
      warehouse: {
        __typename?: 'Warehouse';
        id: string;
        accessHours: Array<string>;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
          details?: {
            __typename?: 'AddressDetail';
            buildingType?: string | null;
            buildingSubtype?: string | null;
            floor?: number | null;
            stories?: number | null;
            frontDoorSteps?: number | null;
            stairs: boolean;
            squareFeet?: number | null;
            elevator: boolean;
            paperwork: boolean;
            gated: boolean;
            steep: boolean;
            serviceElevator: boolean;
            serviceEntrance: boolean;
            loadingDock: boolean;
            code: boolean;
            codeValue?: string | null;
            parkingStreet: boolean;
            parkingDriveway: boolean;
            parkingLot: boolean;
            parkingAlley: boolean;
            parkingPermit: boolean;
            parkingInFront: boolean;
            parkingOnSpecificStreet?: string | null;
            parkingInstructions?: string | null;
            maxTruckHeight?: number | null;
            maxTruckHeightUnknown: boolean;
            accessUnknown: boolean;
            paperworkUnknown: boolean;
            parkingUnknown: boolean;
            environment?: AddressDetailEnvironmentEnum | null;
            additionalNotes?: string | null;
            buildingOpeningHour?: number | null;
            buildingClosingHour?: number | null;
            buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
            parkingLatitude?: number | null;
            parkingLongitude?: number | null;
            secureLocationDetails?: string | null;
            longCarry: boolean;
            unitSize?: AddressDetailUnitSizeEnum | null;
          } | null;
          region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
        };
      };
      winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
    }>;
  };
  upcomingStorageOnboarding?: {
    __typename?: 'Order';
    id: string;
    serviceType?: OrderServiceTypeEnum | null;
    started: boolean;
    tags: Array<string>;
    parent?: {
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      laborCostDetails?: {
        __typename?: 'LaborCostDetails';
        id: string;
        formattedCost: string;
        discountTierDescription?: string | null;
        appointmentFeeCost: number;
      } | null;
    } | null;
  } | null;
};

export type UpcomingStorageOnboardingFragment = {
  __typename?: 'Order';
  id: string;
  serviceType?: OrderServiceTypeEnum | null;
  started: boolean;
  tags: Array<string>;
  parent?: {
    __typename?: 'Order';
    id: string;
    type: OrderTypeEnum;
    laborCostDetails?: {
      __typename?: 'LaborCostDetails';
      id: string;
      formattedCost: string;
      discountTierDescription?: string | null;
      appointmentFeeCost: number;
    } | null;
  } | null;
};

export type UpcomingSubscriptionDetailsFragment = {
  __typename?: 'UpcomingSubscription';
  id: string;
  name: string;
  cubicFootage?: number | null;
  quantity: number;
  discount: number;
  tax: number;
  total: number;
  pricing: {
    __typename?: 'Pricing__Price';
    id: string;
    plan:
      | {
          __typename?: 'Pricing__ProtectionPlan';
          id: string;
          name: string;
          classification?: Pricing__Plan__ProtectionClassification | null;
          maxCovered: number;
        }
      | {
          __typename?: 'Pricing__StoragePlan';
          id: string;
          cuft: number;
          name: string;
          type: Pricing__Plan__Type;
          dimensions?: {
            __typename?: 'Pricing__Plan__Dimensions';
            length: number;
            width: number;
            height?: number | null;
          } | null;
        };
  };
};

export type AccountResolutionOfferCreateMutationVariables = Exact<{
  reasonID: Scalars['ID'];
}>;

export type AccountResolutionOfferCreateMutation = {
  __typename?: 'Mutation';
  offerCreate?: {
    __typename?: 'AccountResolution__OfferCreatePayload';
    status: Status;
    error?: string | null;
    note?: string | null;
    offer?: {
      __typename?: 'AccountResolution__Offer';
      id: string;
      kind: AccountResolution__OfferKind;
      metadata: any;
    } | null;
  } | null;
};

export type AccountResolutionOfferAcceptMutationVariables = Exact<{
  offerID: Scalars['ID'];
  reasonID: Scalars['ID'];
  category: Scalars['String'];
}>;

export type AccountResolutionOfferAcceptMutation = {
  __typename?: 'Mutation';
  offerAccept?: {
    __typename?: 'AccountResolution__OfferAcceptPayload';
    status: Status;
    error?: string | null;
    customerTicket?: { __typename?: 'AccountResolution__CustomerTicket'; id: string } | null;
  } | null;
};

export type AccountResolutionOfferRejectMutationVariables = Exact<{
  offerID: Scalars['ID'];
}>;

export type AccountResolutionOfferRejectMutation = {
  __typename?: 'Mutation';
  offerReject?: {
    __typename?: 'AccountResolution__OfferRejectPayload';
    status: Status;
    error?: string | null;
    note?: string | null;
  } | null;
};

export type AccountResolutionReasonQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  rootName?: InputMaybe<Scalars['String']>;
}>;

export type AccountResolutionReasonQuery = {
  __typename?: 'Query';
  reason: {
    __typename?: 'AccountResolution__Reason';
    id: string;
    parentID?: string | null;
    copy: string;
    offerKind?: AccountResolution__OfferKind | null;
    zendeskCopy?: string | null;
    canonicalName: string;
    children: Array<{
      __typename?: 'AccountResolution__ReasonChild';
      id: string;
      parentID: string;
      position: number;
      copy: string;
      canonicalName: string;
    }>;
  };
};

export type BuildAddressMutationVariables = Exact<{
  input: AddressInput;
}>;

export type BuildAddressMutation = {
  __typename?: 'Mutation';
  buildAddress?: {
    __typename?: 'BuildAddressPayload';
    error?: string | null;
    address?: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
      region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
    } | null;
  } | null;
};

export type DestroyAddressMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DestroyAddressMutation = {
  __typename?: 'Mutation';
  destroyAddress?: { __typename?: 'DestroyAddressPayload'; status: Status } | null;
};

export type AddressListQueryVariables = Exact<{ [key: string]: never }>;

export type AddressListQuery = {
  __typename?: 'Query';
  addresses: Array<{
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    formatted: string;
    formattedWithoutApartmentNumber: string;
    latitude?: number | null;
    longitude?: number | null;
    serviceable: boolean;
    businessName?: string | null;
    outsideServiceArea: boolean;
    stateAbbreviation: string;
    details?: {
      __typename?: 'AddressDetail';
      buildingType?: string | null;
      buildingSubtype?: string | null;
      floor?: number | null;
      stories?: number | null;
      frontDoorSteps?: number | null;
      stairs: boolean;
      squareFeet?: number | null;
      elevator: boolean;
      paperwork: boolean;
      gated: boolean;
      steep: boolean;
      serviceElevator: boolean;
      serviceEntrance: boolean;
      loadingDock: boolean;
      code: boolean;
      codeValue?: string | null;
      parkingStreet: boolean;
      parkingDriveway: boolean;
      parkingLot: boolean;
      parkingAlley: boolean;
      parkingPermit: boolean;
      parkingInFront: boolean;
      parkingOnSpecificStreet?: string | null;
      parkingInstructions?: string | null;
      maxTruckHeight?: number | null;
      maxTruckHeightUnknown: boolean;
      accessUnknown: boolean;
      paperworkUnknown: boolean;
      parkingUnknown: boolean;
      environment?: AddressDetailEnvironmentEnum | null;
      additionalNotes?: string | null;
      buildingOpeningHour?: number | null;
      buildingClosingHour?: number | null;
      buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
      parkingLatitude?: number | null;
      parkingLongitude?: number | null;
      secureLocationDetails?: string | null;
      longCarry: boolean;
      unitSize?: AddressDetailUnitSizeEnum | null;
    } | null;
    region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
  }>;
};

export type AddressQueryVariables = Exact<{
  addressID: Scalars['ID'];
}>;

export type AddressQuery = {
  __typename?: 'Query';
  address: {
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    formatted: string;
    formattedWithoutApartmentNumber: string;
    latitude?: number | null;
    longitude?: number | null;
    serviceable: boolean;
    businessName?: string | null;
    outsideServiceArea: boolean;
    stateAbbreviation: string;
    details?: {
      __typename?: 'AddressDetail';
      buildingType?: string | null;
      buildingSubtype?: string | null;
      floor?: number | null;
      stories?: number | null;
      frontDoorSteps?: number | null;
      stairs: boolean;
      squareFeet?: number | null;
      elevator: boolean;
      paperwork: boolean;
      gated: boolean;
      steep: boolean;
      serviceElevator: boolean;
      serviceEntrance: boolean;
      loadingDock: boolean;
      code: boolean;
      codeValue?: string | null;
      parkingStreet: boolean;
      parkingDriveway: boolean;
      parkingLot: boolean;
      parkingAlley: boolean;
      parkingPermit: boolean;
      parkingInFront: boolean;
      parkingOnSpecificStreet?: string | null;
      parkingInstructions?: string | null;
      maxTruckHeight?: number | null;
      maxTruckHeightUnknown: boolean;
      accessUnknown: boolean;
      paperworkUnknown: boolean;
      parkingUnknown: boolean;
      environment?: AddressDetailEnvironmentEnum | null;
      additionalNotes?: string | null;
      buildingOpeningHour?: number | null;
      buildingClosingHour?: number | null;
      buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
      parkingLatitude?: number | null;
      parkingLongitude?: number | null;
      secureLocationDetails?: string | null;
      longCarry: boolean;
      unitSize?: AddressDetailUnitSizeEnum | null;
    } | null;
  };
};

export type ZipClassificationQueryVariables = Exact<{
  zip: Scalars['String'];
}>;

export type ZipClassificationQuery = {
  __typename?: 'Query';
  zipClassification: { __typename?: 'ZipClassification'; fullServiceEligible: boolean };
};

export type CustomerNameQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerNameQuery = {
  __typename?: 'Query';
  customer?: { __typename?: 'Customer'; id: string; name: string } | null;
};

export type AccountNoticesQueryVariables = Exact<{ [key: string]: never }>;

export type AccountNoticesQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    delinquentBalance?: number | null;
    approvedPaymentPlanOffer?: { __typename?: 'PaymentPlan__Offer'; id: string } | null;
  };
};

export type BillingBannerQueryVariables = Exact<{ [key: string]: never }>;

export type BillingBannerQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    creditBalance?: number | null;
    delinquentBalance?: number | null;
    approvedPaymentPlanOffer?: { __typename?: 'PaymentPlan__Offer'; id: string } | null;
  };
};

export type DisplaySourceFragment = {
  __typename?: 'Billing__Source';
  id: string;
  brand?: string | null;
  number: string;
};

export type BillingHistoryQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  numPer?: InputMaybe<Scalars['Int']>;
}>;

export type BillingHistoryQuery = {
  __typename?: 'Query';
  paginatedInvoiceList: {
    __typename?: 'Billing__PaginatedInvoice';
    results: Array<{
      __typename?: 'Billing__Invoice';
      id: string;
      date?: string | null;
      amountDue: number;
      total: number;
      displayStatus: Billing__Invoice__Status;
      forgiven: boolean;
      forgivenessReasonKind?: string | null;
      refundedAt?: string | null;
      paidAt?: string | null;
      failedAt?: string | null;
      displayName: string;
      order?: {
        __typename?: 'Order';
        id: string;
        number: string;
        scheduled: string;
        bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
      } | null;
      lumpSumInvoice?: {
        __typename?: 'Billing__Invoice';
        id: string;
        total: number;
        summary?: string | null;
        paidAt?: string | null;
        amountDue: number;
        charges: Array<{
          __typename?: 'Billing__Charge';
          id: string;
          amount: number;
          date: string;
          status: Billing__Charge__Status;
          source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
        }>;
        refunds: Array<{
          __typename?: 'Billing__Refund';
          id: string;
          date: string;
          amount: number;
          source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
        }>;
      } | null;
    }>;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type Billing__InvoiceFragment = {
  __typename?: 'Billing__Invoice';
  id: string;
  date?: string | null;
  amountDue: number;
  total: number;
  displayStatus: Billing__Invoice__Status;
  forgiven: boolean;
  forgivenessReasonKind?: string | null;
  refundedAt?: string | null;
  paidAt?: string | null;
  failedAt?: string | null;
  displayName: string;
  order?: {
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
  } | null;
  lumpSumInvoice?: {
    __typename?: 'Billing__Invoice';
    id: string;
    total: number;
    summary?: string | null;
    paidAt?: string | null;
    amountDue: number;
    charges: Array<{
      __typename?: 'Billing__Charge';
      id: string;
      amount: number;
      date: string;
      status: Billing__Charge__Status;
      source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
    }>;
    refunds: Array<{
      __typename?: 'Billing__Refund';
      id: string;
      date: string;
      amount: number;
      source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
    }>;
  } | null;
};

export type Billing__OrderFragment = {
  __typename?: 'Order';
  id: string;
  number: string;
  scheduled: string;
  bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
};

export type PaymentSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentSourcesQuery = {
  __typename?: 'Query';
  sources: Array<{
    __typename?: 'Billing__Source';
    id: string;
    number: string;
    brand?: string | null;
    name?: string | null;
    expiration?: string | null;
    expired: boolean;
    default: boolean;
    fundingType: Billing__Source__FundingType;
  }>;
  account: { __typename?: 'Account'; delinquentBalance?: number | null };
};

type Billing__Invoiceable_Order_Fragment = {
  __typename?: 'Order';
  id: string;
  parentID?: string | null;
  serviceType?: OrderServiceTypeEnum | null;
  type: OrderTypeEnum;
  bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
};

type Billing__Invoiceable_SelfStorage__AdjustedRateRental_Fragment = {
  __typename?: 'SelfStorage__AdjustedRateRental';
  id: string;
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    name: string;
    facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      width: number;
      length: number;
      kind: string;
    };
  };
};

type Billing__Invoiceable_UpcomingSubscription_Fragment = {
  __typename?: 'UpcomingSubscription';
  id: string;
  dimensions?: {
    __typename?: 'Pricing__Plan__Dimensions';
    length: number;
    width: number;
    height?: number | null;
  } | null;
  pricing: {
    __typename?: 'Pricing__Price';
    id: string;
    plan:
      | { __typename?: 'Pricing__ProtectionPlan'; id: string; name: string }
      | { __typename?: 'Pricing__StoragePlan'; id: string; name: string };
  };
};

export type Billing__InvoiceableFragment =
  | Billing__Invoiceable_Order_Fragment
  | Billing__Invoiceable_SelfStorage__AdjustedRateRental_Fragment
  | Billing__Invoiceable_UpcomingSubscription_Fragment;

export type InvoiceableOrderFragment = {
  __typename?: 'Order';
  id: string;
  parentID?: string | null;
  serviceType?: OrderServiceTypeEnum | null;
  type: OrderTypeEnum;
  bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
};

export type BuildPrepaymentMutationVariables = Exact<{
  amount: Scalars['Float'];
}>;

export type BuildPrepaymentMutation = {
  __typename?: 'Mutation';
  buildPrepayment?: {
    __typename?: 'Billing__BuildPrepaymentPayload';
    status: Status;
    error?: string | null;
    account?: { __typename?: 'Account'; id: string; creditBalance?: number | null } | null;
  } | null;
};

export type PrepayFormQueryVariables = Exact<{ [key: string]: never }>;

export type PrepayFormQuery = {
  __typename?: 'Query';
  defaultSource?: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string } | null;
};

export type BillingUpcomingQueryVariables = Exact<{ [key: string]: never }>;

export type BillingUpcomingQuery = {
  __typename?: 'Query';
  storageCheckoutLink?: string | null;
  upcomingInvoices: Array<{
    __typename?: 'Billing__UpcomingInvoice';
    estimatedAmount: string;
    billDate: string;
    invoiceable:
      | {
          __typename?: 'Order';
          id: string;
          parentID?: string | null;
          serviceType?: OrderServiceTypeEnum | null;
          type: OrderTypeEnum;
          bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
        }
      | {
          __typename?: 'SelfStorage__AdjustedRateRental';
          id: string;
          unit: {
            __typename?: 'SelfStorage__Unit';
            id: string;
            name: string;
            facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
            classification: {
              __typename?: 'SelfStorage__Classification';
              id: string;
              width: number;
              length: number;
              kind: string;
            };
          };
        }
      | {
          __typename?: 'UpcomingSubscription';
          id: string;
          dimensions?: {
            __typename?: 'Pricing__Plan__Dimensions';
            length: number;
            width: number;
            height?: number | null;
          } | null;
          pricing: {
            __typename?: 'Pricing__Price';
            id: string;
            plan:
              | { __typename?: 'Pricing__ProtectionPlan'; id: string; name: string }
              | { __typename?: 'Pricing__StoragePlan'; id: string; name: string };
          };
        };
  }>;
  account: { __typename?: 'Account'; id: string; delinquentBalance?: number | null };
};

export type Billing__UpcomingInvoiceFragment = {
  __typename?: 'Billing__UpcomingInvoice';
  estimatedAmount: string;
  billDate: string;
  invoiceable:
    | {
        __typename?: 'Order';
        id: string;
        parentID?: string | null;
        serviceType?: OrderServiceTypeEnum | null;
        type: OrderTypeEnum;
        bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
      }
    | {
        __typename?: 'SelfStorage__AdjustedRateRental';
        id: string;
        unit: {
          __typename?: 'SelfStorage__Unit';
          id: string;
          name: string;
          facility: { __typename?: 'SelfStorage__Facility'; id: string; name: string };
          classification: {
            __typename?: 'SelfStorage__Classification';
            id: string;
            width: number;
            length: number;
            kind: string;
          };
        };
      }
    | {
        __typename?: 'UpcomingSubscription';
        id: string;
        dimensions?: {
          __typename?: 'Pricing__Plan__Dimensions';
          length: number;
          width: number;
          height?: number | null;
        } | null;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | { __typename?: 'Pricing__ProtectionPlan'; id: string; name: string }
            | { __typename?: 'Pricing__StoragePlan'; id: string; name: string };
        };
      };
};

export type BuildClaimMutationVariables = Exact<{
  input: ClaimKindsInput;
}>;

export type BuildClaimMutation = {
  __typename?: 'Mutation';
  buildClaim?: {
    __typename?: 'BuildClaimPayload';
    error?: string | null;
    status: Status;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      status: ClaimStatus;
      step?: string | null;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      issueSelection?: {
        __typename?: 'ClaimIssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: ClaimIssueSelectionKind | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'ClaimItemSelection';
        id: string;
        kind: ClaimItemSelectionKind;
        tracked: boolean;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        receipts: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        item?: {
          __typename: 'Item';
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          contents?: string | null;
          selectableForReturn: boolean;
          shippingEligible: boolean;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'ClaimPropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: ClaimPropertySelectionOwnership | null;
        owner?: { __typename?: 'ClaimPropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        quotes: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      } | null;
      offer?: {
        __typename?: 'ClaimOffer';
        id: string;
        details: string;
        createdAt: string;
        expiry: string;
        totalAmount: number;
        user: { __typename?: 'User'; id: string; name: string };
      } | null;
      paymentOption?: {
        __typename?: 'ClaimPaymentOption';
        kind: ClaimPaymentOptionKind;
        name: string;
        email?: string | null;
        address?: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
        } | null;
      } | null;
      settlementAgreement?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
    } | null;
  } | null;
};

export type BuildClaimItemSelectionBatchMutationVariables = Exact<{
  uuid: Scalars['String'];
  kind: ClaimItemSelectionKind;
  input: Array<BuildClaimItemSelectionInput> | BuildClaimItemSelectionInput;
}>;

export type BuildClaimItemSelectionBatchMutation = {
  __typename?: 'Mutation';
  buildClaimItemSelectionBatch?: {
    __typename?: 'BuildClaimItemSelectionBatchPayload';
    status: Status;
    error?: string | null;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      status: ClaimStatus;
      step?: string | null;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      itemSelections: Array<{
        __typename?: 'ClaimItemSelection';
        id: string;
        kind: ClaimItemSelectionKind;
        tracked: boolean;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        receipts: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        item?: {
          __typename: 'Item';
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          contents?: string | null;
          selectableForReturn: boolean;
          shippingEligible: boolean;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'ClaimPropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: ClaimPropertySelectionOwnership | null;
        owner?: { __typename?: 'ClaimPropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        quotes: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      } | null;
    } | null;
  } | null;
};

export type ClaimQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ClaimQuery = {
  __typename?: 'Query';
  claim?: {
    __typename?: 'Claim';
    id: string;
    uuid: string;
    status: ClaimStatus;
    step?: string | null;
    hasIssue: boolean;
    hasLostItem: boolean;
    hasDamagedItem: boolean;
    hasDamagedProperty: boolean;
    openedAt?: string | null;
    closedAt?: string | null;
    issueSelection?: {
      __typename?: 'ClaimIssueSelection';
      id: string;
      compensationRequested?: boolean | null;
      declaration?: number | null;
      description?: string | null;
      kind?: ClaimIssueSelectionKind | null;
      photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    } | null;
    itemSelections: Array<{
      __typename?: 'ClaimItemSelection';
      id: string;
      kind: ClaimItemSelectionKind;
      tracked: boolean;
      name?: string | null;
      declaration?: number | null;
      productURL?: string | null;
      photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      receipts: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      item?: {
        __typename: 'Item';
        id: string;
        uuid: string;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      } | null;
    }>;
    propertySelection?: {
      __typename?: 'ClaimPropertySelection';
      id: string;
      name?: string | null;
      declaration?: number | null;
      ownership?: ClaimPropertySelectionOwnership | null;
      owner?: { __typename?: 'ClaimPropertyOwner'; name: string; email: string; phone: string } | null;
      photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      quotes: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    } | null;
    offer?: {
      __typename?: 'ClaimOffer';
      id: string;
      details: string;
      createdAt: string;
      expiry: string;
      totalAmount: number;
      user: { __typename?: 'User'; id: string; name: string };
    } | null;
    paymentOption?: {
      __typename?: 'ClaimPaymentOption';
      kind: ClaimPaymentOptionKind;
      name: string;
      email?: string | null;
      address?: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
      } | null;
    } | null;
    settlementAgreement?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
  } | null;
};

export type DestroyClaimItemSelectionMutationVariables = Exact<{
  uuid: Scalars['ID'];
  selectionID: Scalars['ID'];
}>;

export type DestroyClaimItemSelectionMutation = {
  __typename?: 'Mutation';
  destroyClaimItemSelection?: {
    __typename?: 'DestroyClaimItemSelectionPayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type DestroyClaimPropertySelectionMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type DestroyClaimPropertySelectionMutation = {
  __typename?: 'Mutation';
  destroyClaimPropertySelection?: {
    __typename?: 'DestroyClaimPropertySelectionPayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type ClaimIssueSelectionDestroyMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type ClaimIssueSelectionDestroyMutation = {
  __typename?: 'Mutation';
  claimIssueSelectionDestroy?: {
    __typename?: 'Claim__IssueSelectionDestroyPayload';
    status: Status;
    error?: string | null;
    claimIssueSelection?: {
      __typename?: 'ClaimIssueSelection';
      id: string;
      compensationRequested?: boolean | null;
      declaration?: number | null;
      description?: string | null;
      kind?: ClaimIssueSelectionKind | null;
      photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    } | null;
  } | null;
};

export type DetailedClaimFragment = {
  __typename?: 'Claim';
  id: string;
  uuid: string;
  status: ClaimStatus;
  step?: string | null;
  hasIssue: boolean;
  hasLostItem: boolean;
  hasDamagedItem: boolean;
  hasDamagedProperty: boolean;
  openedAt?: string | null;
  closedAt?: string | null;
  issueSelection?: {
    __typename?: 'ClaimIssueSelection';
    id: string;
    compensationRequested?: boolean | null;
    declaration?: number | null;
    description?: string | null;
    kind?: ClaimIssueSelectionKind | null;
    photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
  } | null;
  itemSelections: Array<{
    __typename?: 'ClaimItemSelection';
    id: string;
    kind: ClaimItemSelectionKind;
    tracked: boolean;
    name?: string | null;
    declaration?: number | null;
    productURL?: string | null;
    photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    receipts: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    item?: {
      __typename: 'Item';
      id: string;
      uuid: string;
      hero: number;
      name?: string | null;
      contents?: string | null;
      selectableForReturn: boolean;
      shippingEligible: boolean;
      barcode: { __typename?: 'Barcode'; id: string; value: string };
      images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
    } | null;
  }>;
  propertySelection?: {
    __typename?: 'ClaimPropertySelection';
    id: string;
    name?: string | null;
    declaration?: number | null;
    ownership?: ClaimPropertySelectionOwnership | null;
    owner?: { __typename?: 'ClaimPropertyOwner'; name: string; email: string; phone: string } | null;
    photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    quotes: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
  } | null;
  offer?: {
    __typename?: 'ClaimOffer';
    id: string;
    details: string;
    createdAt: string;
    expiry: string;
    totalAmount: number;
    user: { __typename?: 'User'; id: string; name: string };
  } | null;
  paymentOption?: {
    __typename?: 'ClaimPaymentOption';
    kind: ClaimPaymentOptionKind;
    name: string;
    email?: string | null;
    address?: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
    } | null;
  } | null;
  settlementAgreement?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
};

export type LatestProtectionSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type LatestProtectionSubscriptionQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    latestProtectionSubscription?: {
      __typename?: 'Subscription';
      id: string;
      name: string;
      cubicFootage?: number | null;
      quantity: number;
      discount: number;
      tax: number;
      total: number;
    } | null;
  };
};

export type AttachClaimIssueSelectionAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID'];
  signedID: Scalars['ID'];
}>;

export type AttachClaimIssueSelectionAttachmentMutation = {
  __typename?: 'Mutation';
  attachClaimIssueSelectionAttachment?: {
    __typename?: 'AttachClaimIssueSelectionAttachmentPayload';
    status: Status;
    error?: string | null;
    attachment?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string } | null;
  } | null;
};

export type DetachClaimIssueSelectionAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID'];
  attachmentID: Scalars['ID'];
}>;

export type DetachClaimIssueSelectionAttachmentMutation = {
  __typename?: 'Mutation';
  detachClaimIssueSelectionAttachment?: {
    __typename?: 'DetachClaimIssueSelectionAttachmentPayload';
    status: Status;
    error?: string | null;
    attachment?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string } | null;
  } | null;
};

export type ClaimIssueSelectionUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: Claim__IssueSelectionUpdateInput;
}>;

export type ClaimIssueSelectionUpdateMutation = {
  __typename?: 'Mutation';
  claimIssueSelectionUpdate?: {
    __typename?: 'Claim__IssueSelectionUpdatePayload';
    error?: string | null;
    status: Status;
    claimIssueSelection?: {
      __typename?: 'ClaimIssueSelection';
      id: string;
      compensationRequested?: boolean | null;
      declaration?: number | null;
      description?: string | null;
      kind?: ClaimIssueSelectionKind | null;
      photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
    } | null;
  } | null;
};

export type AttachItemSelectionAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID'];
  selectionID: Scalars['ID'];
  signedID: Scalars['ID'];
  name: ClaimItemSelectionAttachmentName;
}>;

export type AttachItemSelectionAttachmentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'AttachItemSelectionAttachmentPayload';
    status: Status;
    error?: string | null;
    attachment?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string } | null;
  } | null;
};

export type DetachItemSelectionAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID'];
  selectionID: Scalars['ID'];
  attachmentID: Scalars['ID'];
  name: ClaimItemSelectionAttachmentName;
}>;

export type DetachItemSelectionAttachmentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DetachItemSelectionAttachmentPayload';
    status: Status;
    error?: string | null;
    attachment?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string } | null;
  } | null;
};

export type UpdateClaimItemSelectionMutationVariables = Exact<{
  input: ClaimItemSelectionInput;
}>;

export type UpdateClaimItemSelectionMutation = {
  __typename?: 'Mutation';
  updateClaimItemSelection?: {
    __typename?: 'UpdateClaimItemSelectionPayload';
    error?: string | null;
    status: Status;
  } | null;
};

export type ClaimsQueryVariables = Exact<{ [key: string]: never }>;

export type ClaimsQuery = {
  __typename?: 'Query';
  claims: Array<{
    __typename?: 'Claim';
    id: string;
    uuid: string;
    status: ClaimStatus;
    step?: string | null;
    hasIssue: boolean;
    hasLostItem: boolean;
    hasDamagedItem: boolean;
    hasDamagedProperty: boolean;
    openedAt?: string | null;
    closedAt?: string | null;
  }>;
};

export type DestroyClaimMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type DestroyClaimMutation = {
  __typename?: 'Mutation';
  destroyClaim?: { __typename?: 'DestroyClaimPayload'; status: Status } | null;
};

export type AttachPropertySelectionAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID'];
  signedID: Scalars['ID'];
  name: ClaimPropertySelectionAttachmentName;
}>;

export type AttachPropertySelectionAttachmentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'AttachPropertySelectionAttachmentPayload';
    status: Status;
    error?: string | null;
    attachment?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string } | null;
  } | null;
};

export type DetachPropertySelectionAttachmentMutationVariables = Exact<{
  uuid: Scalars['ID'];
  attachmentID: Scalars['ID'];
  name: ClaimPropertySelectionAttachmentName;
}>;

export type DetachPropertySelectionAttachmentMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'DetachPropertySelectionAttachmentPayload';
    status: Status;
    error?: string | null;
    attachment: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string };
  } | null;
};

export type BuildClaimPropertySelectionMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: BuildClaimPropertySelectionInput;
}>;

export type BuildClaimPropertySelectionMutation = {
  __typename?: 'Mutation';
  buildClaimPropertySelection?: {
    __typename?: 'BuildClaimPropertySelectionPayload';
    error?: string | null;
    status: Status;
  } | null;
};

export type SubmitClaimMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SubmitClaimMutation = {
  __typename?: 'Mutation';
  submitClaim?: { __typename?: 'SubmitClaimPayload'; error?: string | null; status: Status } | null;
};

export type TrackClaimMutationVariables = Exact<{
  uuid: Scalars['ID'];
  step: Scalars['String'];
}>;

export type TrackClaimMutation = {
  __typename?: 'Mutation';
  trackClaim?: { __typename?: 'TrackClaimPayload'; status: Status } | null;
};

export type AcceptClaimOfferMutationVariables = Exact<{
  input: AcceptClaimOfferInput;
}>;

export type AcceptClaimOfferMutation = {
  __typename?: 'Mutation';
  acceptClaimOffer?: {
    __typename?: 'AcceptClaimOfferPayload';
    status: Status;
    error?: string | null;
    claim?: {
      __typename?: 'Claim';
      id: string;
      uuid: string;
      status: ClaimStatus;
      step?: string | null;
      hasIssue: boolean;
      hasLostItem: boolean;
      hasDamagedItem: boolean;
      hasDamagedProperty: boolean;
      openedAt?: string | null;
      closedAt?: string | null;
      issueSelection?: {
        __typename?: 'ClaimIssueSelection';
        id: string;
        compensationRequested?: boolean | null;
        declaration?: number | null;
        description?: string | null;
        kind?: ClaimIssueSelectionKind | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      } | null;
      itemSelections: Array<{
        __typename?: 'ClaimItemSelection';
        id: string;
        kind: ClaimItemSelectionKind;
        tracked: boolean;
        name?: string | null;
        declaration?: number | null;
        productURL?: string | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        receipts: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        item?: {
          __typename: 'Item';
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          contents?: string | null;
          selectableForReturn: boolean;
          shippingEligible: boolean;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        } | null;
      }>;
      propertySelection?: {
        __typename?: 'ClaimPropertySelection';
        id: string;
        name?: string | null;
        declaration?: number | null;
        ownership?: ClaimPropertySelectionOwnership | null;
        owner?: { __typename?: 'ClaimPropertyOwner'; name: string; email: string; phone: string } | null;
        photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
        quotes: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
      } | null;
      offer?: {
        __typename?: 'ClaimOffer';
        id: string;
        details: string;
        createdAt: string;
        expiry: string;
        totalAmount: number;
        user: { __typename?: 'User'; id: string; name: string };
      } | null;
      paymentOption?: {
        __typename?: 'ClaimPaymentOption';
        kind: ClaimPaymentOptionKind;
        name: string;
        email?: string | null;
        address?: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
        } | null;
      } | null;
      settlementAgreement?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
    } | null;
  } | null;
};

export type ClaimPreviewQueryVariables = Exact<{
  uuid: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<NoDetailAddressInput>;
}>;

export type ClaimPreviewQuery = { __typename?: 'Query'; claimPreview: string };

export type AcknowledgeDocumentsMutationVariables = Exact<{
  input: Array<AcknowledgeDocumentsInput> | AcknowledgeDocumentsInput;
}>;

export type AcknowledgeDocumentsMutation = {
  __typename?: 'Mutation';
  acknowledgeDocuments?: { __typename?: 'AcknowledgeDocumentsPayload'; status: Status } | null;
};

export type UnacknowledgedDocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type UnacknowledgedDocumentsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    unacknowledgedDocuments: Array<{ __typename?: 'Document'; id: string; name: string; url?: string | null }>;
  };
};

export type ConfirmUpgradeDowngradeForSuggestedPricingMutationVariables = Exact<{ [key: string]: never }>;

export type ConfirmUpgradeDowngradeForSuggestedPricingMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'ConfirmUpgradeDowngradeForSuggestedPricingPayload';
    account: {
      __typename?: 'Account';
      id: string;
      usage: { __typename?: 'Usage'; used: number; total: number; kind: Usage__Kind };
      upcomingSubscriptionSet: {
        __typename?: 'UpcomingSubscriptionSet';
        storageSubscriptions: Array<{
          __typename?: 'UpcomingSubscription';
          id: string;
          name: string;
          cubicFootage?: number | null;
          quantity: number;
          discount: number;
          tax: number;
          total: number;
          pricing: {
            __typename?: 'Pricing__Price';
            id: string;
            plan:
              | {
                  __typename?: 'Pricing__ProtectionPlan';
                  id: string;
                  name: string;
                  classification?: Pricing__Plan__ProtectionClassification | null;
                  maxCovered: number;
                }
              | {
                  __typename?: 'Pricing__StoragePlan';
                  id: string;
                  cuft: number;
                  name: string;
                  type: Pricing__Plan__Type;
                  dimensions?: {
                    __typename?: 'Pricing__Plan__Dimensions';
                    length: number;
                    width: number;
                    height?: number | null;
                  } | null;
                };
          };
        }>;
        protectionSubscription?: {
          __typename?: 'UpcomingSubscription';
          id: string;
          name: string;
          cubicFootage?: number | null;
          quantity: number;
          discount: number;
          tax: number;
          total: number;
          pricing: {
            __typename?: 'Pricing__Price';
            id: string;
            plan:
              | {
                  __typename?: 'Pricing__ProtectionPlan';
                  id: string;
                  name: string;
                  classification?: Pricing__Plan__ProtectionClassification | null;
                  maxCovered: number;
                }
              | {
                  __typename?: 'Pricing__StoragePlan';
                  id: string;
                  cuft: number;
                  name: string;
                  type: Pricing__Plan__Type;
                  dimensions?: {
                    __typename?: 'Pricing__Plan__Dimensions';
                    length: number;
                    width: number;
                    height?: number | null;
                  } | null;
                };
          };
        } | null;
      };
      summaryForSuggestedUpgradeDowngrade?: {
        __typename?: 'UpgradeDowngradeSummary';
        savings: number;
        current: Array<{
          __typename?: 'UpgradeDowngradeEntry';
          total: number;
          quantity: number;
          price: {
            __typename?: 'Pricing__Price';
            id: string;
            amount: number;
            plan:
              | {
                  __typename?: 'Pricing__ProtectionPlan';
                  id: string;
                  name: string;
                  classification?: Pricing__Plan__ProtectionClassification | null;
                  maxCovered: number;
                }
              | {
                  __typename?: 'Pricing__StoragePlan';
                  id: string;
                  cuft: number;
                  name: string;
                  type: Pricing__Plan__Type;
                  dimensions?: {
                    __typename?: 'Pricing__Plan__Dimensions';
                    length: number;
                    width: number;
                    height?: number | null;
                  } | null;
                };
          };
        }>;
        proposed: Array<{
          __typename?: 'UpgradeDowngradeEntry';
          total: number;
          quantity: number;
          price: {
            __typename?: 'Pricing__Price';
            id: string;
            amount: number;
            plan:
              | {
                  __typename?: 'Pricing__ProtectionPlan';
                  id: string;
                  name: string;
                  classification?: Pricing__Plan__ProtectionClassification | null;
                  maxCovered: number;
                }
              | {
                  __typename?: 'Pricing__StoragePlan';
                  id: string;
                  cuft: number;
                  name: string;
                  type: Pricing__Plan__Type;
                  dimensions?: {
                    __typename?: 'Pricing__Plan__Dimensions';
                    length: number;
                    width: number;
                    height?: number | null;
                  } | null;
                };
          };
        }>;
      } | null;
    };
  } | null;
};

export type OnboardingIncentiveGiftCardQueryQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingIncentiveGiftCardQueryQuery = {
  __typename?: 'Query';
  onboardingIncentiveGiftCard: { __typename?: 'GiftCard'; status: GiftCardStatus; claimCode?: string | null };
};

export type AvailabilitiesQueryVariables = Exact<{
  input: AvailabilitiesInput;
}>;

export type AvailabilitiesQuery = {
  __typename?: 'Query';
  availabilities: Array<{
    __typename?: 'Availability';
    datetime: string;
    duration?: string | null;
    available: boolean;
    appointmentFee?: string | null;
    perMoverHourAdjustmentAmount?: number | null;
    waitlistable: boolean;
    laborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
    feeAmount?: { __typename?: 'MonetaryAmount'; formatted: string; value: number } | null;
  }>;
};

export type ItemsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ItemStatus>;
}>;

export type ItemsQuery = {
  __typename?: 'Query';
  items: Array<{
    __typename: 'Item';
    cuft?: number | null;
    id: string;
    uuid: string;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    shippingEligible: boolean;
    category?: { __typename?: 'ItemCategory'; name: string } | null;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type PageReviewMutationVariables = Exact<{
  input: ReviewInput;
}>;

export type PageReviewMutation = {
  __typename?: 'Mutation';
  reviewSave: { __typename?: 'ReviewSavePayload'; status: Status };
};

export type TimeZoneQueryVariables = Exact<{
  input: TimeZoneInput;
}>;

export type TimeZoneQuery = { __typename?: 'Query'; timeZone: string };

export type ValidPhoneNumberQueryVariables = Exact<{
  phoneNumber: Scalars['PhoneNumber'];
}>;

export type ValidPhoneNumberQuery = { __typename?: 'Query'; validPhoneNumber: boolean };

export type WaitlistEligibleQueryVariables = Exact<{ [key: string]: never }>;

export type WaitlistEligibleQuery = { __typename?: 'Query'; eligible: boolean };

export type CustomerEmailQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerEmailQuery = {
  __typename?: 'Query';
  customer?: { __typename?: 'Customer'; id: string; email: string } | null;
};

export type SendReceiptMutationVariables = Exact<{
  invoiceID: Scalars['ID'];
}>;

export type SendReceiptMutation = {
  __typename?: 'Mutation';
  sendReceipt?: { __typename?: 'Billing__SendReceiptPayload'; status: Status; error?: string | null } | null;
};

export type InvoiceDetailFragment = {
  __typename?: 'Billing__Invoice';
  displayID: string;
  stripeCreatedAt?: string | null;
  amountOwed: number;
  appliedCredits: number;
  discountAmount: number;
  subtotal: number;
  tax: number;
  lines: Array<string>;
  rebillable: boolean;
  id: string;
  date?: string | null;
  amountDue: number;
  total: number;
  displayStatus: Billing__Invoice__Status;
  forgiven: boolean;
  forgivenessReasonKind?: string | null;
  refundedAt?: string | null;
  paidAt?: string | null;
  failedAt?: string | null;
  displayName: string;
  invoiceItems: Array<{
    __typename?: 'Billing__InvoiceItem';
    id: string;
    type: string;
    amount: number;
    description?: string | null;
    displayDescription: string;
    displayName: string;
    quantity?: number | null;
    unitPrice?: number | null;
  }>;
  subscription?: { __typename?: 'Subscription'; id: string } | null;
  selfStorageSubscription?: { __typename?: 'SelfStorage__Subscription'; id: string } | null;
  charges: Array<{
    __typename?: 'Billing__Charge';
    id: string;
    amount: number;
    date: string;
    status: Billing__Charge__Status;
    source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
  }>;
  offlinePayments: Array<{
    __typename?: 'Billing__OfflinePayment';
    id: string;
    date: string;
    amount: number;
    kind: Billing__OfflinePayment__Kind;
    summary?: string | null;
  }>;
  refunds: Array<{
    __typename?: 'Billing__Refund';
    id: string;
    date: string;
    amount: number;
    source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
  }>;
  settledInvoices?: Array<{
    __typename?: 'Billing__Invoice';
    id: string;
    total: number;
    summary?: string | null;
    paidAt?: string | null;
    amountDue: number;
    charges: Array<{
      __typename?: 'Billing__Charge';
      id: string;
      amount: number;
      date: string;
      status: Billing__Charge__Status;
      source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
    }>;
    refunds: Array<{
      __typename?: 'Billing__Refund';
      id: string;
      date: string;
      amount: number;
      source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
    }>;
  }> | null;
  swapPaymentInvoices: Array<{
    __typename?: 'Billing__Invoice';
    id: string;
    total: number;
    summary?: string | null;
    paidAt?: string | null;
    amountDue: number;
    charges: Array<{
      __typename?: 'Billing__Charge';
      id: string;
      amount: number;
      date: string;
      status: Billing__Charge__Status;
      source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
    }>;
    refunds: Array<{
      __typename?: 'Billing__Refund';
      id: string;
      date: string;
      amount: number;
      source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
    }>;
  }>;
  order?: {
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
  } | null;
  lumpSumInvoice?: {
    __typename?: 'Billing__Invoice';
    id: string;
    total: number;
    summary?: string | null;
    paidAt?: string | null;
    amountDue: number;
    charges: Array<{
      __typename?: 'Billing__Charge';
      id: string;
      amount: number;
      date: string;
      status: Billing__Charge__Status;
      source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
    }>;
    refunds: Array<{
      __typename?: 'Billing__Refund';
      id: string;
      date: string;
      amount: number;
      source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
    }>;
  } | null;
};

export type InvoiceDetailQueryVariables = Exact<{
  invoiceID: Scalars['ID'];
}>;

export type InvoiceDetailQuery = {
  __typename?: 'Query';
  invoice: {
    __typename?: 'Billing__Invoice';
    displayID: string;
    stripeCreatedAt?: string | null;
    amountOwed: number;
    appliedCredits: number;
    discountAmount: number;
    subtotal: number;
    tax: number;
    lines: Array<string>;
    rebillable: boolean;
    id: string;
    date?: string | null;
    amountDue: number;
    total: number;
    displayStatus: Billing__Invoice__Status;
    forgiven: boolean;
    forgivenessReasonKind?: string | null;
    refundedAt?: string | null;
    paidAt?: string | null;
    failedAt?: string | null;
    displayName: string;
    invoiceItems: Array<{
      __typename?: 'Billing__InvoiceItem';
      id: string;
      type: string;
      amount: number;
      description?: string | null;
      displayDescription: string;
      displayName: string;
      quantity?: number | null;
      unitPrice?: number | null;
    }>;
    subscription?: { __typename?: 'Subscription'; id: string } | null;
    selfStorageSubscription?: { __typename?: 'SelfStorage__Subscription'; id: string } | null;
    charges: Array<{
      __typename?: 'Billing__Charge';
      id: string;
      amount: number;
      date: string;
      status: Billing__Charge__Status;
      source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
    }>;
    offlinePayments: Array<{
      __typename?: 'Billing__OfflinePayment';
      id: string;
      date: string;
      amount: number;
      kind: Billing__OfflinePayment__Kind;
      summary?: string | null;
    }>;
    refunds: Array<{
      __typename?: 'Billing__Refund';
      id: string;
      date: string;
      amount: number;
      source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
    }>;
    settledInvoices?: Array<{
      __typename?: 'Billing__Invoice';
      id: string;
      total: number;
      summary?: string | null;
      paidAt?: string | null;
      amountDue: number;
      charges: Array<{
        __typename?: 'Billing__Charge';
        id: string;
        amount: number;
        date: string;
        status: Billing__Charge__Status;
        source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
      }>;
      refunds: Array<{
        __typename?: 'Billing__Refund';
        id: string;
        date: string;
        amount: number;
        source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
      }>;
    }> | null;
    swapPaymentInvoices: Array<{
      __typename?: 'Billing__Invoice';
      id: string;
      total: number;
      summary?: string | null;
      paidAt?: string | null;
      amountDue: number;
      charges: Array<{
        __typename?: 'Billing__Charge';
        id: string;
        amount: number;
        date: string;
        status: Billing__Charge__Status;
        source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
      }>;
      refunds: Array<{
        __typename?: 'Billing__Refund';
        id: string;
        date: string;
        amount: number;
        source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
      }>;
    }>;
    order?: {
      __typename?: 'Order';
      id: string;
      number: string;
      scheduled: string;
      bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
    } | null;
    lumpSumInvoice?: {
      __typename?: 'Billing__Invoice';
      id: string;
      total: number;
      summary?: string | null;
      paidAt?: string | null;
      amountDue: number;
      charges: Array<{
        __typename?: 'Billing__Charge';
        id: string;
        amount: number;
        date: string;
        status: Billing__Charge__Status;
        source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
      }>;
      refunds: Array<{
        __typename?: 'Billing__Refund';
        id: string;
        date: string;
        amount: number;
        source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
      }>;
    } | null;
  };
};

export type SwapPaymentMethodMutationVariables = Exact<{
  input: Billing__SwapPaymentMethodInput;
}>;

export type SwapPaymentMethodMutation = {
  __typename?: 'Mutation';
  swapPaymentMethod?: {
    __typename?: 'Billing__SwapPaymentMethodPayload';
    status: Status;
    error?: string | null;
    swapped?: boolean | null;
  } | null;
};

export type CustomerItemQueryVariables = Exact<{
  uuid: Scalars['ID'];
  type?: InputMaybe<CustomerItemTypeEnum>;
}>;

export type CustomerItemQuery = {
  __typename?: 'Query';
  customerItem:
    | {
        __typename: 'Item';
        id: string;
        uuid: string;
        hero: number;
        name?: string | null;
        cuft?: number | null;
        contents?: string | null;
        pickedUpAt?: string | null;
        returnedAt?: string | null;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
      }
    | {
        __typename: 'ItemGroup';
        id: string;
        uuid: string;
        name?: string | null;
        contents?: string | null;
        cuft?: number | null;
        pickedUpAt?: string | null;
        returnedAt?: string | null;
        heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        items: Array<{
          __typename?: 'Item';
          id: string;
          uuid: string;
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        }>;
      };
};

type CustomerItem_Item_Fragment = {
  __typename: 'Item';
  id: string;
  uuid: string;
  hero: number;
  name?: string | null;
  cuft?: number | null;
  contents?: string | null;
  pickedUpAt?: string | null;
  returnedAt?: string | null;
  category?: { __typename?: 'ItemCategory'; name: string } | null;
  images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  barcode: { __typename?: 'Barcode'; id: string; value: string };
};

type CustomerItem_ItemGroup_Fragment = {
  __typename: 'ItemGroup';
  id: string;
  uuid: string;
  name?: string | null;
  contents?: string | null;
  cuft?: number | null;
  pickedUpAt?: string | null;
  returnedAt?: string | null;
  heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
  category?: { __typename?: 'ItemCategory'; name: string } | null;
  items: Array<{
    __typename?: 'Item';
    id: string;
    uuid: string;
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type CustomerItemFragment = CustomerItem_Item_Fragment | CustomerItem_ItemGroup_Fragment;

export type UpdateCustomerItemMutationVariables = Exact<{
  uuid: Scalars['ID'];
  type: CustomerItemTypeEnum;
  input: CustomerItemInput;
}>;

export type UpdateCustomerItemMutation = {
  __typename?: 'Mutation';
  updateCustomerItem?: {
    __typename?: 'UpdateCustomerItemPayload';
    item:
      | { __typename?: 'Item'; uuid: string; name?: string | null; contents?: string | null; hero: number }
      | {
          __typename?: 'ItemGroup';
          uuid: string;
          name?: string | null;
          contents?: string | null;
          heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
        };
  } | null;
};

export type ItemQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type ItemQuery = {
  __typename?: 'Query';
  item: {
    __typename?: 'Item';
    id: string;
    uuid: string;
    name?: string | null;
    contents?: string | null;
    hero: number;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
    orders: Array<{
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      number: string;
      scheduled: string;
      address: {
        __typename?: 'Address';
        id: string;
        aptsuite?: string | null;
        street: string;
        city: string;
        state: string;
        zip: string;
      };
    }>;
  };
};

export type InventoryQueryVariables = Exact<{ [key: string]: never }>;

export type InventoryQuery = {
  __typename?: 'Query';
  needSignature?: string | null;
  account: {
    __typename?: 'Account';
    state: Account__State;
    onboardable: boolean;
    requestableItemsExist: boolean;
    rescheduleOfferDetails?: {
      __typename?: 'RescheduleOffer__DetailsType';
      token: string;
      coupon?: {
        __typename?: 'Coupon';
        id: string;
        amount: number;
        percent: number;
        discountDescription: string;
      } | null;
    } | null;
    unscheduledStorageTreasuresAuctionsWon: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      endTime: string;
      storageTreasuresLink: string;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        cuft?: number | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
      warehouse: {
        __typename?: 'Warehouse';
        id: string;
        accessHours: Array<string>;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
          details?: {
            __typename?: 'AddressDetail';
            buildingType?: string | null;
            buildingSubtype?: string | null;
            floor?: number | null;
            stories?: number | null;
            frontDoorSteps?: number | null;
            stairs: boolean;
            squareFeet?: number | null;
            elevator: boolean;
            paperwork: boolean;
            gated: boolean;
            steep: boolean;
            serviceElevator: boolean;
            serviceEntrance: boolean;
            loadingDock: boolean;
            code: boolean;
            codeValue?: string | null;
            parkingStreet: boolean;
            parkingDriveway: boolean;
            parkingLot: boolean;
            parkingAlley: boolean;
            parkingPermit: boolean;
            parkingInFront: boolean;
            parkingOnSpecificStreet?: string | null;
            parkingInstructions?: string | null;
            maxTruckHeight?: number | null;
            maxTruckHeightUnknown: boolean;
            accessUnknown: boolean;
            paperworkUnknown: boolean;
            parkingUnknown: boolean;
            environment?: AddressDetailEnvironmentEnum | null;
            additionalNotes?: string | null;
            buildingOpeningHour?: number | null;
            buildingClosingHour?: number | null;
            buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
            parkingLatitude?: number | null;
            parkingLongitude?: number | null;
            secureLocationDetails?: string | null;
            longCarry: boolean;
            unitSize?: AddressDetailUnitSizeEnum | null;
          } | null;
          region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
        };
      };
      winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
    }>;
  };
  orders: Array<{
    __typename?: 'Order';
    id: string;
    documentHubURL?: string | null;
    number: string;
    scheduled: string;
    window: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    status: OrderStatusEnum;
    serviceType?: OrderServiceTypeEnum | null;
  }>;
};

export type InventoryContentQueryVariables = Exact<{
  filters: ItemFiltersInput;
  query?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  per: Scalars['Int'];
}>;

export type InventoryContentQuery = {
  __typename?: 'Query';
  available: number;
  requested: number;
  returned: number;
  delayed: number;
  paginatedCustomerItems: {
    __typename?: 'PaginatedCustomerItem';
    results: Array<
      | {
          __typename: 'Item';
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          cuft?: number | null;
          contents?: string | null;
          pickedUpAt?: string | null;
          returnedAt?: string | null;
          category?: { __typename?: 'ItemCategory'; name: string } | null;
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
        }
      | {
          __typename: 'ItemGroup';
          id: string;
          uuid: string;
          name?: string | null;
          contents?: string | null;
          cuft?: number | null;
          pickedUpAt?: string | null;
          returnedAt?: string | null;
          heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
          category?: { __typename?: 'ItemCategory'; name: string } | null;
          items: Array<{
            __typename?: 'Item';
            id: string;
            uuid: string;
            images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
          }>;
        }
    >;
    pagination: {
      __typename?: 'Pagination';
      currentPage: number;
      nextPage?: number | null;
      prevPage?: number | null;
      totalPages: number;
      totalCount: number;
    };
  };
};

export type InventoryListItemFragment = {
  __typename: 'Item';
  id: string;
  uuid: string;
  hero: number;
  name?: string | null;
  cuft?: number | null;
  contents?: string | null;
  pickedUpAt?: string | null;
  returnedAt?: string | null;
  category?: { __typename?: 'ItemCategory'; name: string } | null;
  images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  barcode: { __typename?: 'Barcode'; id: string; value: string };
};

export type InventoryListItemGroupFragment = {
  __typename: 'ItemGroup';
  id: string;
  uuid: string;
  name?: string | null;
  contents?: string | null;
  cuft?: number | null;
  pickedUpAt?: string | null;
  returnedAt?: string | null;
  heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
  category?: { __typename?: 'ItemCategory'; name: string } | null;
  items: Array<{
    __typename?: 'Item';
    id: string;
    uuid: string;
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type CustomerInitialSetupMutationVariables = Exact<{
  input: CustomerInitialSetupInput;
}>;

export type CustomerInitialSetupMutation = {
  __typename?: 'Mutation';
  customerInitialSetup?: { __typename?: 'CustomerInitialSetupPayload'; error?: string | null; status: Status } | null;
};

export type FirstTimeCustomerQueryVariables = Exact<{
  initialSetupToken: Scalars['String'];
}>;

export type FirstTimeCustomerQuery = {
  __typename?: 'Query';
  firstTimeCustomer?: { __typename?: 'Customer'; id: string } | null;
};

export type MovingAddressUpdateMutationVariables = Exact<{
  input: Moving__AddressUpdateInput;
}>;

export type MovingAddressUpdateMutation = {
  __typename?: 'Mutation';
  movingAddressUpdate?: { __typename?: 'AddressUpdatePayload'; error?: string | null; status: Status } | null;
};

export type ReonboardingCheckoutLinksQueryVariables = Exact<{ [key: string]: never }>;

export type ReonboardingCheckoutLinksQuery = {
  __typename?: 'Query';
  movingLink?: string | null;
  storageLink?: string | null;
};

export type OrdersAccountQueryVariables = Exact<{ [key: string]: never }>;

export type OrdersAccountQuery = {
  __typename?: 'Query';
  needSignature?: string | null;
  inboundShippingEligible: boolean;
  account: {
    __typename?: 'Account';
    bannedFromBooking: boolean;
    suspended: boolean;
    state: Account__State;
    termCommitment?: {
      __typename?: 'Account__TermCommitment';
      term: number;
      contractEndDate?: string | null;
      type: Account__TermCommitment__Type;
      fulfilled: boolean;
    } | null;
    unscheduledStorageTreasuresAuctionsWon: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      endTime: string;
      storageTreasuresLink: string;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        cuft?: number | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
      warehouse: {
        __typename?: 'Warehouse';
        id: string;
        accessHours: Array<string>;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
          details?: {
            __typename?: 'AddressDetail';
            buildingType?: string | null;
            buildingSubtype?: string | null;
            floor?: number | null;
            stories?: number | null;
            frontDoorSteps?: number | null;
            stairs: boolean;
            squareFeet?: number | null;
            elevator: boolean;
            paperwork: boolean;
            gated: boolean;
            steep: boolean;
            serviceElevator: boolean;
            serviceEntrance: boolean;
            loadingDock: boolean;
            code: boolean;
            codeValue?: string | null;
            parkingStreet: boolean;
            parkingDriveway: boolean;
            parkingLot: boolean;
            parkingAlley: boolean;
            parkingPermit: boolean;
            parkingInFront: boolean;
            parkingOnSpecificStreet?: string | null;
            parkingInstructions?: string | null;
            maxTruckHeight?: number | null;
            maxTruckHeightUnknown: boolean;
            accessUnknown: boolean;
            paperworkUnknown: boolean;
            parkingUnknown: boolean;
            environment?: AddressDetailEnvironmentEnum | null;
            additionalNotes?: string | null;
            buildingOpeningHour?: number | null;
            buildingClosingHour?: number | null;
            buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
            parkingLatitude?: number | null;
            parkingLongitude?: number | null;
            secureLocationDetails?: string | null;
            longCarry: boolean;
            unitSize?: AddressDetailUnitSizeEnum | null;
          } | null;
          region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
        };
      };
      winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
    }>;
  };
};

export type AcceptRetentionOfferMutationVariables = Exact<{
  offerID: Scalars['ID'];
}>;

export type AcceptRetentionOfferMutation = {
  __typename?: 'Mutation';
  accept?: {
    __typename?: 'AcceptOfferPayload';
    status: Status;
    error?: string | null;
    storageRate?: number | null;
  } | null;
};

export type CurrentStorageSubscriptionQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentStorageSubscriptionQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    subscriptionSet: {
      __typename?: 'SubscriptionSet';
      storageSubscriptions: Array<{ __typename?: 'Subscription'; id: string; total: number }>;
    };
  };
};

export type UncancellableShipmentOrderIDsQueryVariables = Exact<{
  orderIDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UncancellableShipmentOrderIDsQuery = { __typename?: 'Query'; uncancellableShipmentOrderIds: Array<string> };

export type UpdateOrderAddressMutationVariables = Exact<{
  orderID: Scalars['ID'];
  addressInput: AddressInput;
}>;

export type UpdateOrderAddressMutation = {
  __typename?: 'Mutation';
  result?: { __typename?: 'UpdateOrderAddressPayload'; error?: string | null; status: Status } | null;
};

export type OrderFormAddressSelectorQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderFormAddressSelectorQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
    };
    permissions: { __typename?: 'OrderPermissions'; id: string; relocatable: boolean };
  };
};

export type AvailabilityTiersQueryVariables = Exact<{
  input: AvailabilityTiersInputType;
}>;

export type AvailabilityTiersQuery = {
  __typename?: 'Query';
  availabilityTiers: Array<{
    __typename?: 'AvailabilityTier';
    type: AvailabilityTierTypeEnum;
    label: string;
    description: string;
    price: string;
  }>;
};

export type BuildOnboardingCancelIntentMutationVariables = Exact<{
  reason: Onboarding__CancelIntent__Reason;
  orderID: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
}>;

export type BuildOnboardingCancelIntentMutation = {
  __typename?: 'Mutation';
  build?: {
    __typename?: 'Onboarding__BuildCancelIntentPayload';
    status: Status;
    error?: string | null;
    cancelIntent?: {
      __typename?: 'Onboarding__CancelIntent';
      id: string;
      reason: Onboarding__CancelIntent__Reason;
    } | null;
    retentionOffer?: {
      __typename?: 'Retention__Offer';
      id: string;
      detail: {
        __typename?: 'Retention__OfferDetail';
        id: string;
        type: Retention__OfferDetail__Type;
        waiveRateAdjustment: boolean;
        action: {
          __typename?: 'Retention__OfferAction';
          id: string;
          discountAmount?: number | null;
          discountPercent?: number | null;
          discountDuration?: string | null;
        };
      };
    } | null;
  } | null;
};

export type MovingCancelIntentCreateMutationVariables = Exact<{
  reason: Moving__CancelIntent__Reason;
  orderID: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
}>;

export type MovingCancelIntentCreateMutation = {
  __typename?: 'Mutation';
  create?: {
    __typename?: 'Moving__CancelIntentCreatePayload';
    status: Status;
    error?: string | null;
    retentionOffer?: {
      __typename?: 'Retention__Offer';
      id: string;
      detail: {
        __typename?: 'Retention__OfferDetail';
        id: string;
        type: Retention__OfferDetail__Type;
        waiveRateAdjustment: boolean;
        action: {
          __typename?: 'Retention__OfferAction';
          id: string;
          discountAmount?: number | null;
          discountPercent?: number | null;
          discountDuration?: string | null;
        };
      };
    } | null;
  } | null;
};

export type StorageSubscriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type StorageSubscriptionsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    upcomingSubscriptionSet: {
      __typename?: 'UpcomingSubscriptionSet';
      storageSubscriptions: Array<{
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
      }>;
    };
  };
};

export type CancelOrderMutationVariables = Exact<{
  orderID: Scalars['ID'];
  skipCancellationFee?: InputMaybe<Scalars['Boolean']>;
}>;

export type CancelOrderMutation = {
  __typename?: 'Mutation';
  cancelOrder?: { __typename?: 'CancelOrderPayload'; error?: string | null; status: Status } | null;
};

export type BuildOrderCoiRequestMutationVariables = Exact<{
  input: OrderCoiRequestInput;
}>;

export type BuildOrderCoiRequestMutation = {
  __typename?: 'Mutation';
  buildOrderCoiRequest?: { __typename?: 'BuildOrderCOIRequestPayload'; error?: string | null; status: Status } | null;
};

export type OrderCertificateOfInsuranceQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderCertificateOfInsuranceQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    serviceType?: OrderServiceTypeEnum | null;
    account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; id: string; email: string } };
  };
  addresses: Array<{ __typename?: 'Address'; id: string; formatted: string }>;
};

export type ConfirmOrderMutationVariables = Exact<{
  token: Scalars['ID'];
}>;

export type ConfirmOrderMutation = {
  __typename?: 'Mutation';
  confirm?: {
    __typename?: 'ConfirmOrderPayload';
    status: Status;
    error?: string | null;
    order?: { __typename?: 'ConfirmedOrder'; id: string; scheduled: string; accountReferralCode: string } | null;
  } | null;
};

export type UpdateOrderContactMutationVariables = Exact<{
  input: OrderContactInput;
}>;

export type UpdateOrderContactMutation = {
  __typename?: 'Mutation';
  updateOrderContact?: {
    __typename?: 'UpdateOrderContactPayload';
    error?: string | null;
    status: Status;
    order?: {
      __typename?: 'Order';
      id: string;
      contact?: {
        __typename?: 'Order__Contact';
        id: string;
        name?: string | null;
        phone: string;
        kind?: Order__ContactKind | null;
      } | null;
    } | null;
  } | null;
};

export type OrderFormContactQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderFormContactQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    serviceType?: OrderServiceTypeEnum | null;
    pickupVehicleKind?: Order__PickupVehicleKind | null;
    contact?: {
      __typename?: 'Order__Contact';
      id: string;
      name?: string | null;
      phone: string;
      kind?: Order__ContactKind | null;
    } | null;
    permissions: { __typename?: 'OrderPermissions'; id: string; locked: boolean };
  };
  account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; id: string; name: string; phone: string } };
};

export type OrderDetailsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderDetailsQuery = {
  __typename?: 'Query';
  estimationCategories: Array<{
    __typename?: 'EstimationCategory';
    id: string;
    name: string;
    packable: boolean;
    cuft: number;
    numMovers?: number | null;
  }>;
  roomCategories: Array<{ __typename?: 'RoomCategory'; id: string; name: string; countable: boolean; cuft: number }>;
  account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; id: string; name: string; phone: string } };
  order: {
    __typename?: 'Order';
    id: string;
    movers: number;
    shipped: boolean;
    pickupVehicleKind?: Order__PickupVehicleKind | null;
    formattedArrivalWindow: string;
    customerPinCode: string;
    multiDay: boolean;
    documentHubURL?: string | null;
    number: string;
    scheduled: string;
    window: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    status: OrderStatusEnum;
    serviceType?: OrderServiceTypeEnum | null;
    dropOffAppointment?: {
      __typename?: 'DropOff__Appointment';
      id: string;
      expectedAt: string;
      status: OrderStatusEnum;
      facility: {
        __typename?: 'DropOff__Facility';
        fulfiller:
          | { __typename?: 'DropOff__ThirdPartySelfStorageFacility'; id: string; operator: string }
          | { __typename?: 'SelfStorage__Facility'; id: string };
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
        };
      };
    } | null;
    account: {
      __typename?: 'Account';
      customer: {
        __typename?: 'Customer';
        id: string;
        firstName?: string | null;
        name: string;
        phone: string;
        email: string;
      };
    };
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
    };
    contact?: {
      __typename?: 'Order__Contact';
      id: string;
      name?: string | null;
      phone: string;
      kind?: Order__ContactKind | null;
    } | null;
    services: Array<{
      __typename?: 'OrderService';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      isFullMoveOut?: boolean | null;
      needsPackingHelp?: boolean | null;
      requestedMovers?: number | null;
      estimatedItems: Array<{
        __typename?: 'EstimatedItem';
        id: string;
        categoryId: string;
        quantity: number;
        categoryDisplayName?: string | null;
        notes?: string | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        cuft?: number | null;
        estimatedMovers?: number | null;
      }>;
      rooms: Array<{
        __typename?: 'RoomSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      estimations: Array<{
        __typename?: 'EstimationSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
    }>;
    permissions: {
      __typename?: 'OrderPermissions';
      id: string;
      active: boolean;
      locked: boolean;
      cancelable: boolean;
      relocatable: boolean;
      reschedulable: boolean;
      addDisposal: boolean;
      changeablePickupInventory: boolean;
      changeableReturnInventory: boolean;
      chargeForCancellation: boolean;
      cancellationFeeDisclaimer?: string | null;
      chargeLateFee: boolean;
    };
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
    laborCostDetails?: {
      __typename?: 'LaborCostDetails';
      id: string;
      formattedCost: string;
      discountTierDescription?: string | null;
      appointmentFeeCost: number;
    } | null;
    coupon?: { __typename?: 'Coupon'; id: string; amount: number; percent: number; discountDescription: string } | null;
    perItemPricingInfo?: {
      __typename?: 'ItemPricing';
      pickupPerItemFee?: string | null;
      returnPerItemFee?: string | null;
      pickupLargeItemFee?: string | null;
      returnLargeItemFee?: string | null;
      appointmentFee?: string | null;
      returnedItemsCount: number;
      returnedLargeItemsCount: number;
      price: string;
      pickupEstimate?: {
        __typename?: 'PickupEstimate';
        lowestEstimatedItems?: number | null;
        highestEstimatedItems?: number | null;
      } | null;
      pickupLargeEstimate?: {
        __typename?: 'PickupEstimate';
        lowestEstimatedItems?: number | null;
        highestEstimatedItems?: number | null;
      } | null;
      accountPackages: Array<{
        __typename?: 'AccountPackage';
        id: string;
        name: string;
        amount: number;
        kind: PackageKindEnum;
      }>;
    } | null;
    accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
    accountPackages: Array<{ __typename?: 'AccountPackage'; amount: number; name: string }>;
    coiRequests: Array<{
      __typename?: 'OrderCOIRequest';
      id: string;
      state: OrderCoiRequestStateEnum;
      createdAt: string;
      certificateOfInsurance?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
    }>;
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      originAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
      };
      destinationAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
      };
      movingConfiguration: { __typename?: 'MovingConfiguration'; id: string; travelRateMultiplier: number };
    } | null;
    retailDelivery?: { __typename?: 'Order'; id: string; state: OrderStateEnum; scheduled: string } | null;
    retailDeliveryItems: Array<{ __typename?: 'Retail__DeliveryItem'; id: string; quantity: number }>;
    bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
    facilityWarehouse?: {
      __typename?: 'Warehouse';
      id: string;
      accessHours: Array<string>;
      accessInstructions: string;
    } | null;
    waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
    nonTravelSourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      scheduled: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      window: string;
    }>;
  };
};

export type CancellationPolicyQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type CancellationPolicyQuery = { __typename?: 'Query'; cancellationPolicy?: string | null };

export type ItemPricingQueryVariables = Exact<{
  input: ItemPricingInput;
}>;

export type ItemPricingQuery = {
  __typename?: 'Query';
  itemPricing?: {
    __typename?: 'ItemPricing';
    pickupPerItemFee?: string | null;
    returnPerItemFee?: string | null;
    pickupLargeItemFee?: string | null;
    returnLargeItemFee?: string | null;
    appointmentFee?: string | null;
    returnedItemsCount: number;
    returnedLargeItemsCount: number;
    price: string;
    pickupEstimate?: {
      __typename?: 'PickupEstimate';
      lowestEstimatedItems?: number | null;
      highestEstimatedItems?: number | null;
    } | null;
    pickupLargeEstimate?: {
      __typename?: 'PickupEstimate';
      lowestEstimatedItems?: number | null;
      highestEstimatedItems?: number | null;
    } | null;
    accountPackages: Array<{
      __typename?: 'AccountPackage';
      id: string;
      name: string;
      amount: number;
      kind: PackageKindEnum;
    }>;
  } | null;
};

export type PlanDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type PlanDetailsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    subscriptionSet: {
      __typename?: 'SubscriptionSet';
      storageSubscriptions: Array<{
        __typename?: 'Subscription';
        id: string;
        cubicFootage?: number | null;
        name: string;
        dimensions?: {
          __typename?: 'Pricing__Plan__Dimensions';
          length: number;
          width: number;
          height?: number | null;
        } | null;
      }>;
    };
  };
};

export type DisposalCategoryPricingQueryVariables = Exact<{ [key: string]: never }>;

export type DisposalCategoryPricingQuery = {
  __typename?: 'Query';
  disposalCategoryPricing: Array<{
    __typename?: 'Disposal__CategoryPricingDescriptor';
    category: string;
    minimum: number;
  }>;
};

export type AttachedDisposalConfirmationMutationVariables = Exact<{
  signedDisposalID: Scalars['String'];
  disposalItemIDs: Array<Scalars['ID']> | Scalars['ID'];
  storageItemIDs: Array<Scalars['ID']> | Scalars['ID'];
  storagePlanConfig?: InputMaybe<Disposal__Confirm__StoragePlanInput>;
}>;

export type AttachedDisposalConfirmationMutation = {
  __typename?: 'Mutation';
  disposalConfirm?: {
    __typename?: 'Disposal__ConfirmPayload';
    status: Status;
    error?: string | null;
    signingURL?: string | null;
    disposal?: { __typename?: 'Order'; id: string } | null;
  } | null;
};

export type DisposalConfirmationQueryVariables = Exact<{
  signedOrderID: Scalars['String'];
  signedOrderPurpose: Scalars['String'];
}>;

export type DisposalConfirmationQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    ended?: string | null;
    account: {
      __typename?: 'Account';
      id: string;
      currentStorageSubscription?: {
        __typename?: 'Subscription';
        id: string;
        formattedName?: string | null;
        cubicFootage?: number | null;
        pricing: { __typename?: 'Pricing__Price'; amount: number };
      } | null;
      rateGroup?: { __typename?: 'Pricing__RateGroup'; id: string; kind: RateGroupKindEnum } | null;
    };
    associatedPickup?: {
      __typename?: 'Order';
      id: string;
      customerItems: Array<
        | {
            __typename: 'Item';
            id: string;
            uuid: string;
            hero: number;
            name?: string | null;
            cuft?: number | null;
            contents?: string | null;
            pickedUpAt?: string | null;
            returnedAt?: string | null;
            category?: { __typename?: 'ItemCategory'; name: string } | null;
            images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
            barcode: { __typename?: 'Barcode'; id: string; value: string };
          }
        | {
            __typename: 'ItemGroup';
            id: string;
            uuid: string;
            name?: string | null;
            contents?: string | null;
            cuft?: number | null;
            pickedUpAt?: string | null;
            returnedAt?: string | null;
            heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
            category?: { __typename?: 'ItemCategory'; name: string } | null;
            items: Array<{
              __typename?: 'Item';
              id: string;
              uuid: string;
              images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
            }>;
          }
      >;
    } | null;
    customerItems: Array<
      | {
          __typename: 'Item';
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          cuft?: number | null;
          contents?: string | null;
          pickedUpAt?: string | null;
          returnedAt?: string | null;
          category?: { __typename?: 'ItemCategory'; name: string } | null;
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
        }
      | {
          __typename: 'ItemGroup';
          id: string;
          uuid: string;
          name?: string | null;
          contents?: string | null;
          cuft?: number | null;
          pickedUpAt?: string | null;
          returnedAt?: string | null;
          heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
          category?: { __typename?: 'ItemCategory'; name: string } | null;
          items: Array<{
            __typename?: 'Item';
            id: string;
            uuid: string;
            images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
          }>;
        }
    >;
    parent?: {
      __typename?: 'Order';
      id: string;
      disposalConfirmationSignedOrderId: string;
      type: OrderTypeEnum;
    } | null;
  };
};

export type DisposalItemPricingQueryVariables = Exact<{
  itemIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type DisposalItemPricingQuery = {
  __typename?: 'Query';
  disposalItemPricing: Array<{
    __typename?: 'Disposal__ItemPricingDescriptor';
    id: string;
    category: string;
    amount: number;
  }>;
};

export type DisposalUpdatedStoragePricingQueryVariables = Exact<{
  signedParentOrderID: Scalars['String'];
  signedParentOrderPurpose: Scalars['String'];
  rateGroupKind: RateGroupKindEnum;
}>;

export type DisposalUpdatedStoragePricingQuery = {
  __typename?: 'Query';
  pricingSet?: {
    __typename?: 'Pricing__Set';
    id: string;
    quoteID?: string | null;
    storagePriceEntries: Array<{
      __typename?: 'Pricing__Set__PriceEntry';
      price: {
        __typename?: 'Pricing__Price';
        amount: number;
        plan:
          | { __typename?: 'Pricing__ProtectionPlan' }
          | { __typename?: 'Pricing__StoragePlan'; id: string; formattedName: string; cuft: number };
      };
    }>;
  } | null;
};

export type OrderDocumentsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderDocumentsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    state: OrderStateEnum;
    nonTravelSourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      coiRequests: Array<{
        __typename?: 'OrderCOIRequest';
        id: string;
        certificateOfInsurance?: {
          __typename?: 'ActiveStorageAttachment';
          id: string;
          filename: string;
          url: string;
        } | null;
      }>;
      signatures: Array<{
        __typename?: 'Signature';
        id: string;
        contractUrl?: string | null;
        documentKind: string;
        agreement?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string } | null;
      }>;
      ingestedDocuments: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
    }>;
  };
};

export type EstimationCreateRoomsMutationVariables = Exact<{
  orderID: Scalars['ID'];
  roomInputs: Array<Estimation__SelectedRoomInputType> | Estimation__SelectedRoomInputType;
  algorithmName?: InputMaybe<Estimation__AlgorithmName>;
  deactivateExistingRooms?: InputMaybe<Scalars['Boolean']>;
  allItemsInListedRooms?: InputMaybe<Scalars['Boolean']>;
}>;

export type EstimationCreateRoomsMutation = {
  __typename?: 'Mutation';
  estimationCreateRooms?: {
    __typename?: 'Estimation__CreateRoomsPayload';
    error?: string | null;
    status: Status;
    selectedRooms?: Array<{
      __typename?: 'Estimation__SelectedRoomType';
      id: string;
      name: string;
      roomCategory: { __typename?: 'Estimation__RoomCategoryType'; id: string; name: string };
      estimatedItems: Array<{
        __typename?: 'Estimation__EstimatedItemType';
        id: string;
        quantity: number;
        category: { __typename?: 'Estimation__CategoryType'; id: string; name: string };
      }>;
    }> | null;
  } | null;
};

export type EstimationStatusCreateMutationVariables = Exact<{
  input: Estimation__StatusCreateInputType;
}>;

export type EstimationStatusCreateMutation = {
  __typename?: 'Mutation';
  estimationStatusCreate?: {
    __typename?: 'Estimation__StatusCreatePayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type CreateStepTransitionMutationVariables = Exact<{
  input: Flow__StepTransitionInputType;
}>;

export type CreateStepTransitionMutation = {
  __typename?: 'Mutation';
  createStepTransition?: {
    __typename?: 'Flow__CreateStepTransitionPayload';
    error?: string | null;
    status: Status;
  } | null;
};

export type EstimationCreateBoxEstimatedItemsMutationVariables = Exact<{
  orderID: Scalars['ID'];
  boxCategoryName: Scalars['String'];
}>;

export type EstimationCreateBoxEstimatedItemsMutation = {
  __typename?: 'Mutation';
  estimationCreateBoxEstimatedItems?: {
    __typename?: 'Estimation__CreateBoxEstimatedItemsPayload';
    error?: string | null;
    status: Status;
    estimatedItems?: Array<{
      __typename?: 'Estimation__EstimatedItemType';
      id: string;
      quantity: number;
      category: { __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number };
    }> | null;
  } | null;
};

export type EstimationCreateItemsMutationVariables = Exact<{
  input: Estimation__CreateItemsInputType;
}>;

export type EstimationCreateItemsMutation = {
  __typename?: 'Mutation';
  estimationCreateItems?: {
    __typename?: 'Estimation__CreateItemsPayload';
    error?: string | null;
    status: Status;
    estimatedItems?: Array<{
      __typename?: 'Estimation__EstimatedItemType';
      id: string;
      quantity: number;
      category: { __typename?: 'Estimation__CategoryType'; id: string; name: string };
    }> | null;
  } | null;
};

export type MovingMaterialPackageSetEntriesFetchMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingMaterialPackageSetEntriesFetchMutation = {
  __typename?: 'Mutation';
  materialPackageSetEntriesFetch?: {
    __typename?: 'Moving__MaterialPackageSetEntriesFetchPayload';
    error?: string | null;
    status: Status;
    materialPackageSetEntries: Array<{
      __typename?: 'Moving__PackageSetEntry';
      id: string;
      fixedCost: number;
      package: { __typename?: 'Package'; name: string };
    }>;
  } | null;
};

export type MovingPackingMaterialsUpdateMutationVariables = Exact<{
  orderID: Scalars['ID'];
  packageSetEntryID?: InputMaybe<Scalars['ID']>;
  action?: InputMaybe<Moving__PackingMaterialsUpdateAction>;
}>;

export type MovingPackingMaterialsUpdateMutation = {
  __typename?: 'Mutation';
  movingPackingMaterialsUpdate?: {
    __typename?: 'Moving__PackingMaterialsUpdatePayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type HydrateEstimationFlowQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type HydrateEstimationFlowQuery = {
  __typename?: 'Query';
  estimationBoxItemCategories: Array<{
    __typename?: 'Estimation__CategoryType';
    id: string;
    position: number;
    name: string;
  }>;
  estimationItemCategoryThemes: Array<{
    __typename?: 'Estimation__CategoryThemeType';
    id: string;
    name: string;
    position: number;
    categories: Array<{ __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number }>;
  }>;
  estimationRoomCategories: Array<{
    __typename?: 'Estimation__RoomCategoryType';
    id: string;
    name: string;
    itemCategories: Array<{ __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number }>;
  }>;
  estimationSelectedBoxEstimatedItem?: {
    __typename?: 'Estimation__EstimatedItemType';
    id: string;
    quantity: number;
    category: { __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number };
  } | null;
  estimationSelectedRooms: Array<{
    __typename?: 'Estimation__SelectedRoomType';
    id: string;
    name: string;
    roomCategory: { __typename?: 'Estimation__RoomCategoryType'; id: string; name: string };
    estimatedItems: Array<{
      __typename?: 'Estimation__EstimatedItemType';
      id: string;
      quantity: number;
      category: { __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number };
    }>;
  }>;
  order: {
    __typename?: 'Order';
    type: OrderTypeEnum;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
    };
  };
};

export type EstimationAiClassificationCreateMutationVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type EstimationAiClassificationCreateMutation = {
  __typename?: 'Mutation';
  estimationAiClassificationCreate?: {
    __typename?: 'Estimation__AIClassificationCreatePayload';
    error?: string | null;
    status: Status;
    aiClassification?: { __typename?: 'Estimation__AIClassificationType'; id: string } | null;
  } | null;
};

export type EstimationAiClassificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type EstimationAiClassificationQuery = {
  __typename?: 'Query';
  estimationAiClassification: {
    __typename?: 'Estimation__AIClassificationType';
    id: string;
    status: Estimation__AiClassificationStatus;
    estimatedItems: Array<{
      __typename?: 'Estimation__EstimatedItemType';
      id: string;
      quantity: number;
      category: {
        __typename?: 'Estimation__CategoryType';
        id: string;
        name: string;
        position: number;
        parent?: { __typename?: 'Estimation__CategoryType'; id: string; name: string } | null;
      };
    }>;
  };
};

export type EstimationAiClassificationSkipMutationVariables = Exact<{
  aiClassificationID: Scalars['ID'];
}>;

export type EstimationAiClassificationSkipMutation = {
  __typename?: 'Mutation';
  estimationAIClassificationSkip?: { __typename?: 'Estimation__AIClassificationSkipPayload'; status: Status } | null;
};

export type ItemCategorySearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type ItemCategorySearchQuery = {
  __typename?: 'Query';
  itemCategorySearch: Array<{ __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number }>;
};

export type MovingNextLaborRateQueryVariables = Exact<{
  orderID: Scalars['ID'];
  movers: Scalars['Int'];
}>;

export type MovingNextLaborRateQuery = {
  __typename?: 'Query';
  movingConfiguration: {
    __typename?: 'MovingConfiguration';
    id: string;
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
  };
};

export type ItemInventoryFeaturedCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ItemInventoryFeaturedCategoriesQuery = {
  __typename?: 'Query';
  virtualWalkthroughItemCategories: Array<{
    __typename?: 'Estimation__CategoryType';
    id: string;
    name: string;
    position: number;
  }>;
  virtualWalkthroughOtherItemCategories: Array<{
    __typename?: 'Estimation__CategoryType';
    id: string;
    name: string;
    position: number;
  }>;
};

export type HydrateVirtualWalkthroughFlowQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type HydrateVirtualWalkthroughFlowQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    estimatedCuft: number;
    type: OrderTypeEnum;
    movers: number;
    multiDay: boolean;
    id: string;
    scheduled: string;
    window: string;
    status: OrderStatusEnum;
    subtype: OrderSubtypeEnum;
    serviceType?: OrderServiceTypeEnum | null;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
    };
    metadata?: {
      __typename?: 'OrderMetadata';
      itemsRequireMoreThanOneMover?: Order__Metadata__ItemsRequireMoreThanOneMover | null;
    } | null;
    movingOperation?: {
      __typename?: 'MovingOperation';
      destinationAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
      };
      movingQuote: {
        __typename?: 'Moving__Quote';
        id: string;
        eligibility: boolean;
        reasons: Array<string>;
        truckCount?: number | null;
        moverSelection?: number | null;
        suggestedMoverCount?: number | null;
        deadtimeDuration: number;
        drivetimeDuration?: number | null;
        estimatedPackDuration?: number | null;
        estimatedUnpackDuration?: number | null;
        estimatedOrderDuration?: number | null;
        minimumOrderCost?: number | null;
        estimatedOrderCost?: number | null;
        cuftSource: Moving__QuoteCuftSourceEnum;
        estimatedCuft: number;
        laborRate?: { __typename?: 'LaborRate'; id: string; amount: number; name: string } | null;
        movingConfiguration?: {
          __typename?: 'MovingConfiguration';
          id: string;
          requiredLaborDuration: number;
          travelRateMultiplier: number;
        } | null;
      };
    } | null;
    permissions: {
      __typename?: 'OrderPermissions';
      id: string;
      chargeLateFee: boolean;
      reschedulable: boolean;
      priceMatchGuaranteeEligible: boolean;
      whiteGloveTestEligible: boolean;
    };
    nonTravelSourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      scheduled: string;
      window: string;
    }>;
    movingPricingSummary?: { __typename?: 'Moving__PricingSummary'; flatRateAmount?: number | null } | null;
    services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
    rescheduleOfferCoupon?: {
      __typename?: 'Coupon';
      id: string;
      amount: number;
      percent: number;
      discountDescription: string;
    } | null;
    facilityWarehouse?: {
      __typename?: 'Warehouse';
      id: string;
      accessHours: Array<string>;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
        region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
      };
    } | null;
    dropOffAppointment?: {
      __typename?: 'DropOff__Appointment';
      id: string;
      expectedAt: string;
      facility: { __typename?: 'DropOff__Facility'; id: string; address: { __typename?: 'Address'; id: string } };
    } | null;
    accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
    baseLaborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
    waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
    account: { __typename?: 'Account'; id: string; state: Account__State };
  };
  virtualWalkthroughEstimationItems: Array<{
    __typename?: 'Estimation__EstimatedItemType';
    id: string;
    quantity: number;
    category: {
      __typename?: 'Estimation__CategoryType';
      id: string;
      name: string;
      position: number;
      parent?: { __typename?: 'Estimation__CategoryType'; id: string; name: string } | null;
    };
  }>;
  virtualWalkthroughOtherItemCategories: Array<{
    __typename?: 'Estimation__CategoryType';
    id: string;
    name: string;
    position: number;
  }>;
};

export type MovingQuoteRegenerateWithItemsMutationVariables = Exact<{
  estimationInput: Estimation__CreateItemsInputType;
}>;

export type MovingQuoteRegenerateWithItemsMutation = {
  __typename?: 'Mutation';
  movingQuoteRegenerateWithItems?: {
    __typename?: 'Moving__QuoteRegenerateWithItemsPayload';
    error?: string | null;
    status: Status;
    estimatedItems?: Array<{
      __typename?: 'Estimation__EstimatedItemType';
      id: string;
      quantity: number;
      category: { __typename?: 'Estimation__CategoryType'; id: string; name: string };
    }> | null;
    movingQuote?: {
      __typename?: 'Moving__Quote';
      id: string;
      eligibility: boolean;
      reasons: Array<string>;
      truckCount?: number | null;
      moverSelection?: number | null;
      suggestedMoverCount?: number | null;
      deadtimeDuration: number;
      drivetimeDuration?: number | null;
      estimatedPackDuration?: number | null;
      estimatedUnpackDuration?: number | null;
      estimatedOrderDuration?: number | null;
      minimumOrderCost?: number | null;
      estimatedOrderCost?: number | null;
      cuftSource: Moving__QuoteCuftSourceEnum;
      estimatedCuft: number;
      laborRate?: { __typename?: 'LaborRate'; id: string; amount: number; name: string } | null;
      movingConfiguration?: {
        __typename?: 'MovingConfiguration';
        id: string;
        requiredLaborDuration: number;
        travelRateMultiplier: number;
      } | null;
    } | null;
  } | null;
};

export type EstimationFlowStatusQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type EstimationFlowStatusQuery = {
  __typename?: 'Query';
  estimationStatus?: {
    __typename?: 'Estimation__Status';
    id: string;
    complete: boolean;
    estimationCalculatedCuft: number;
    planSizeName: string;
    planSizeCuft: number;
    planSizeComparison?: string | null;
    fitsPlanSize?: boolean | null;
  } | null;
};

export type MoveReviewQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MoveReviewQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    movers: number;
    scheduled: string;
    window: string;
    multiDay: boolean;
    account: {
      __typename?: 'Account';
      id: string;
      customer: { __typename?: 'Customer'; id: string; email: string; name: string; phone: string };
    };
    address: {
      __typename?: 'Address';
      stateAbbreviation: string;
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
    };
    contact?: { __typename?: 'Order__Contact'; id: string; name?: string | null; phone: string } | null;
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
    movingOperation?: {
      __typename?: 'MovingOperation';
      destinationAddress: {
        __typename?: 'Address';
        stateAbbreviation: string;
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
      };
    } | null;
    movingPricingSummary?: {
      __typename?: 'Moving__PricingSummary';
      flatRateAmount?: number | null;
      materialPackageSetEntry?: {
        __typename?: 'Moving__PackageSetEntry';
        id: string;
        fixedCost: number;
        package: { __typename?: 'Package'; id: string; name: string };
      } | null;
    } | null;
    nonTravelSourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      scheduled: string;
      window: string;
    }>;
    permissions: { __typename?: 'OrderPermissions'; id: string; whiteGloveTestEligible: boolean };
  };
};

export type WalkthroughCsatReviewQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type WalkthroughCsatReviewQuery = { __typename?: 'Query'; submittedWalkthroughCsatReview: boolean };

export type EstimationUploadCreateMutationVariables = Exact<{
  orderID: Scalars['ID'];
  signedID: Scalars['ID'];
}>;

export type EstimationUploadCreateMutation = {
  __typename?: 'Mutation';
  estimationUploadCreate?: {
    __typename?: 'Estimation__UploadCreatePayload';
    error?: string | null;
    status: Status;
    upload?: {
      __typename?: 'Estimation__UploadType';
      id: string;
      media: {
        __typename?: 'Estimation__UploadMediaType';
        id: string;
        imgixURL: string;
        isImage: boolean;
        isVideo: boolean;
      };
    } | null;
  } | null;
};

export type EstimationUploadDestroyMutationVariables = Exact<{
  orderID: Scalars['ID'];
  uploadID: Scalars['ID'];
}>;

export type EstimationUploadDestroyMutation = {
  __typename?: 'Mutation';
  estimationUploadDestroy?: {
    __typename?: 'Estimation__UploadDestroyPayload';
    error?: string | null;
    status: Status;
    upload?: { __typename?: 'Estimation__UploadType'; id: string } | null;
  } | null;
};

export type EstimationUploadsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type EstimationUploadsQuery = {
  __typename?: 'Query';
  estimationUploads: Array<{
    __typename?: 'Estimation__UploadType';
    id: string;
    media: {
      __typename?: 'Estimation__UploadMediaType';
      id: string;
      imgixURL: string;
      isImage: boolean;
      isVideo: boolean;
    };
  }>;
};

export type FinancingCheckoutQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type FinancingCheckoutQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    financingContext: {
      __typename?: 'Financing__Context';
      total: number;
      taxAmount: number;
      items: Array<{
        __typename?: 'Financing__Context__Item';
        sku: string;
        displayName: string;
        unitPrice: number;
        quantity: number;
        imageURL: string;
        url: string;
      }>;
      discounts: Array<{
        __typename?: 'Financing__Context__Discount';
        code: string;
        amount: number;
        displayName: string;
      }>;
    };
  };
  customer?: { __typename?: 'Customer'; id: string; name: string; phone: string; email: string } | null;
  addresses: Array<{
    __typename?: 'Address';
    id: string;
    aptsuite?: string | null;
    street: string;
    city: string;
    state: string;
    country?: string | null;
    zip: string;
  }>;
};

export type SubmitFinancingCheckoutMutationVariables = Exact<{
  input: Financing__CheckoutInput;
}>;

export type SubmitFinancingCheckoutMutation = {
  __typename?: 'Mutation';
  submitFinancingCheckout?: {
    __typename?: 'Financing__SubmitCheckoutPayload';
    status: Status;
    failureKind?: Financing__FailureKind | null;
  } | null;
};

export type OrderFinancingIntentQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderFinancingIntentQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    state: OrderStateEnum;
    financingIntent?: {
      __typename?: 'Financing__Intent';
      id: string;
      intended: boolean;
      estimatedAmount: number;
    } | null;
  };
};

export type SwitchFinancingIntentMutationVariables = Exact<{
  orderID: Scalars['ID'];
  intended: Scalars['Boolean'];
}>;

export type SwitchFinancingIntentMutation = {
  __typename?: 'Mutation';
  switchFinancingIntent?: {
    __typename?: 'Financing__SwitchIntentPayload';
    intent: { __typename?: 'Financing__Intent'; id: string; intended: boolean };
  } | null;
};

export type OnboardingAccountDetailQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingAccountDetailQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    upcomingSubscriptionSet: {
      __typename?: 'UpcomingSubscriptionSet';
      nextPeriodEnd?: string | null;
      storageSubscriptions: Array<{
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      }>;
      protectionSubscription?: {
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      } | null;
    };
    termCommitment?: { __typename?: 'Account__TermCommitment'; term: number } | null;
    rescheduleOfferDetails?: {
      __typename?: 'RescheduleOffer__DetailsType';
      token: string;
      coupon?: {
        __typename?: 'Coupon';
        id: string;
        amount: number;
        percent: number;
        discountDescription: string;
      } | null;
    } | null;
  };
};

export type AvailableForMoversQueryVariables = Exact<{
  input: AvailabilitiesInput;
}>;

export type AvailableForMoversQuery = { __typename?: 'Query'; availableForMovers?: boolean | null };

export type MovingEstimationsUpdateMutationVariables = Exact<{
  input: Moving__EstimationsUpdateInputType;
  rescheduleInput?: InputMaybe<Moving__RescheduleInputType>;
}>;

export type MovingEstimationsUpdateMutation = {
  __typename?: 'Mutation';
  movingEstimationsUpdate?: {
    __typename?: 'Moving__EstimationsUpdatePayload';
    error?: string | null;
    status: Status;
    order?: { __typename?: 'Order'; id: string; movers: number; scheduled: string } | null;
  } | null;
};

export type CouponApplyMutationVariables = Exact<{
  promoCode: Scalars['String'];
  orderID: Scalars['ID'];
}>;

export type CouponApplyMutation = {
  __typename?: 'Mutation';
  couponApply?: {
    __typename?: 'CouponApplyPayload';
    status: Status;
    error?: string | null;
    order?: {
      __typename?: 'Order';
      id: string;
      account: {
        __typename?: 'Account';
        id: string;
        coupon?: {
          __typename?: 'Coupon';
          id: string;
          discountDescription: string;
          discountDisclaimer?: string | null;
        } | null;
      };
      coupon?: {
        __typename?: 'Coupon';
        id: string;
        discountDescription: string;
        discountDisclaimer?: string | null;
      } | null;
    } | null;
  } | null;
};

export type MovingQuoteRegenerateMutationVariables = Exact<{
  orderID: Scalars['ID'];
  source: Moving__QuoteSourceEnum;
  quoteInput?: InputMaybe<Moving__QuoteRegenerateInput>;
}>;

export type MovingQuoteRegenerateMutation = {
  __typename?: 'Mutation';
  movingQuoteRegenerate?: {
    __typename?: 'Moving__QuoteRegeneratePayload';
    error?: string | null;
    status: Status;
    movingQuote?: {
      __typename?: 'Moving__Quote';
      id: string;
      eligibility: boolean;
      reasons: Array<string>;
      truckCount?: number | null;
      moverSelection?: number | null;
      suggestedMoverCount?: number | null;
      deadtimeDuration: number;
      drivetimeDuration?: number | null;
      estimatedPackDuration?: number | null;
      estimatedUnpackDuration?: number | null;
      estimatedOrderDuration?: number | null;
      minimumOrderCost?: number | null;
      estimatedOrderCost?: number | null;
      cuftSource: Moving__QuoteCuftSourceEnum;
      estimatedCuft: number;
      laborRate?: { __typename?: 'LaborRate'; id: string; amount: number; name: string } | null;
      movingConfiguration?: {
        __typename?: 'MovingConfiguration';
        id: string;
        requiredLaborDuration: number;
        travelRateMultiplier: number;
      } | null;
    } | null;
  } | null;
};

export type AttachDisposalMutationVariables = Exact<{
  parentId: Scalars['ID'];
}>;

export type AttachDisposalMutation = {
  __typename?: 'Mutation';
  disposalAttach?: { __typename?: 'Portal__DisposalAttachPayload'; status: Status } | null;
};

export type EstimationFlowCompletionQueryVariables = Exact<{
  resourceID: Scalars['ID'];
  resourceType: Estimation__ResourceType;
  algorithmName: Estimation__AlgorithmName;
}>;

export type EstimationFlowCompletionQuery = {
  __typename?: 'Query';
  estimationSelectedRooms: Array<{
    __typename?: 'Estimation__SelectedRoomType';
    id: string;
    name: string;
    roomCategory: { __typename?: 'Estimation__RoomCategoryType'; id: string; name: string };
    estimatedItems: Array<{
      __typename?: 'Estimation__EstimatedItemType';
      id: string;
      quantity: number;
      category: { __typename?: 'Estimation__CategoryType'; id: string; name: string; position: number };
    }>;
  }>;
  estimationItems: Array<{
    __typename?: 'Estimation__EstimatedItemType';
    id: string;
    quantity: number;
    category: { __typename?: 'Estimation__CategoryType'; id: string; name: string };
  }>;
  estimationStatus?: {
    __typename?: 'Estimation__Status';
    id: string;
    complete: boolean;
    fitsPlanSize?: boolean | null;
  } | null;
};

export type AppointmentHubQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type AppointmentHubQuery = {
  __typename?: 'Query';
  estimationCategories: Array<{
    __typename?: 'EstimationCategory';
    id: string;
    name: string;
    packable: boolean;
    cuft: number;
    numMovers?: number | null;
  }>;
  roomCategories: Array<{ __typename?: 'RoomCategory'; id: string; name: string; countable: boolean; cuft: number }>;
  order: {
    __typename?: 'Order';
    started: boolean;
    movers: number;
    depositPercent: number;
    formattedDepositInterval?: string | null;
    requiredLaborDuration?: string | null;
    multiDay: boolean;
    storageAddOnEligible: boolean;
    tags: Array<string>;
    formattedArrivalWindow: string;
    id: string;
    documentHubURL?: string | null;
    number: string;
    scheduled: string;
    window: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    status: OrderStatusEnum;
    serviceType?: OrderServiceTypeEnum | null;
    bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
    estimationAlgorithm?: { __typename?: 'Estimation__Algorithm'; name: Estimation__AlgorithmName } | null;
    dropOffAppointment?: {
      __typename?: 'DropOff__Appointment';
      id: string;
      state: DropOff__AppointmentStateEnum;
      expectedAt: string;
      status: OrderStatusEnum;
      direction: DropOff__AppointmentDirectionEnum;
      facility: {
        __typename?: 'DropOff__Facility';
        id: string;
        hasEmployees: boolean;
        instructions?: string | null;
        fulfiller:
          | {
              __typename?: 'DropOff__ThirdPartySelfStorageFacility';
              id: string;
              accessCode?: string | null;
              operator: string;
            }
          | { __typename?: 'SelfStorage__Facility'; id: string; accessCode?: string | null };
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
        };
      };
      access?: { __typename?: 'DropOff__Access'; id: string; customerCode: string; revealedAt?: string | null } | null;
      storageArrangement?:
        | { __typename?: 'DropOff__ThirdPartySelfStorageUnit'; id: string; name: string; directions?: string | null }
        | {
            __typename?: 'SelfStorage__Hold';
            unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string; directions?: string | null };
          }
        | null;
      order: { __typename?: 'Order'; type: OrderTypeEnum; subtype: OrderSubtypeEnum };
    } | null;
    consumerBrochure?: { __typename?: 'Document'; id: string; url?: string | null } | null;
    account: {
      __typename?: 'Account';
      id: string;
      state: Account__State;
      coupon?: {
        __typename?: 'Coupon';
        id: string;
        discountDescription: string;
        discountDisclaimer?: string | null;
      } | null;
      customer: { __typename?: 'Customer'; id: string; firstName?: string | null; name: string; phone: string };
    };
    movingPricingSummary?: {
      __typename?: 'Moving__PricingSummary';
      totalFixedAmount?: number | null;
      fixedDuration?: string | null;
      materialsAmount?: number | null;
      flatRateAmount?: number | null;
      materialPackageSetEntry?: {
        __typename?: 'Moving__PackageSetEntry';
        id: string;
        fixedCost: number;
        package: { __typename?: 'Package'; name: string };
      } | null;
      laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
    } | null;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
    };
    contact?: { __typename?: 'Order__Contact'; name?: string | null; phone: string } | null;
    services: Array<{
      __typename?: 'OrderService';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      isFullMoveOut?: boolean | null;
      needsPackingHelp?: boolean | null;
      requestedMovers?: number | null;
      estimatedItems: Array<{
        __typename?: 'EstimatedItem';
        id: string;
        categoryId: string;
        quantity: number;
        categoryDisplayName?: string | null;
        notes?: string | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        cuft?: number | null;
        estimatedMovers?: number | null;
      }>;
      rooms: Array<{
        __typename?: 'RoomSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      estimations: Array<{
        __typename?: 'EstimationSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
    }>;
    permissions: {
      __typename?: 'OrderPermissions';
      id: string;
      active: boolean;
      addDisposal: boolean;
      locked: boolean;
      cancelable: boolean;
      relocatable: boolean;
      reschedulable: boolean;
      changeablePickupInventory: boolean;
      changeableReturnInventory: boolean;
      chargeForCancellation: boolean;
      chargeLateFee: boolean;
      customerChangeableMoverCount: boolean;
      priceMatchGuaranteeEligible: boolean;
      landingCheckoutPageTestEligible: boolean;
      whiteGloveTestEligible: boolean;
    };
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
    perItemPricingInfo?: {
      __typename?: 'ItemPricing';
      pickupPerItemFee?: string | null;
      returnPerItemFee?: string | null;
      pickupLargeItemFee?: string | null;
      returnLargeItemFee?: string | null;
      appointmentFee?: string | null;
      returnedItemsCount: number;
      returnedLargeItemsCount: number;
      price: string;
      pickupEstimate?: {
        __typename?: 'PickupEstimate';
        lowestEstimatedItems?: number | null;
        highestEstimatedItems?: number | null;
      } | null;
      pickupLargeEstimate?: {
        __typename?: 'PickupEstimate';
        lowestEstimatedItems?: number | null;
        highestEstimatedItems?: number | null;
      } | null;
      accountPackages: Array<{
        __typename?: 'AccountPackage';
        id: string;
        name: string;
        amount: number;
        kind: PackageKindEnum;
      }>;
    } | null;
    accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
    accountPackages: Array<{
      __typename?: 'AccountPackage';
      id: string;
      amount: number;
      name: string;
      kind: PackageKindEnum;
    }>;
    coiRequests: Array<{
      __typename?: 'OrderCOIRequest';
      id: string;
      state: OrderCoiRequestStateEnum;
      createdAt: string;
      addressID?: string | null;
      certificateOfInsurance?: { __typename?: 'ActiveStorageAttachment'; id: string; url: string } | null;
    }>;
    coupon?: {
      __typename?: 'Coupon';
      id: string;
      discountDescription: string;
      discountDisclaimer?: string | null;
    } | null;
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      moveSize?: Moving__MoveSizeEnum | null;
      packing?: Moving__PackingEnum | null;
      walkthroughEstimatedDuration?: number | null;
      longDistanceMinimumCargoWeightInPounds?: number | null;
      longDistanceMinimumTransportFee?: number | null;
      originAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
      };
      destinationAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
      };
      movingConfiguration: { __typename?: 'MovingConfiguration'; id: string; travelRateMultiplier: number };
      longDistanceTransportationRate?: {
        __typename?: 'Moving__LongDistanceTransportationRate';
        id: string;
        description: string;
      } | null;
    } | null;
    movingSelections?: Array<{
      __typename?: 'Selection';
      id: string;
      quantity: number;
      movingCategory: { __typename?: 'Category'; id: string; name: string };
    }> | null;
    retailDelivery?: { __typename?: 'Order'; id: string; state: OrderStateEnum; scheduled: string } | null;
    retailDeliveryItems: Array<{ __typename?: 'Retail__DeliveryItem'; id: string; quantity: number }>;
    financingIntent?: { __typename?: 'Financing__Intent'; id: string; intended: boolean } | null;
    financingContext: { __typename?: 'Financing__Context'; total: number };
    failedPrepayment?: { __typename?: 'Billing__Prepayment'; id: string; amount: number; dueAt: string } | null;
    laborCostDetails?: {
      __typename?: 'LaborCostDetails';
      id: string;
      appointmentFeeCost: number;
      laborCost: string;
    } | null;
    simultaneousPickup?: { __typename?: 'Order'; id: string } | null;
    nonTravelSourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      scheduled: string;
      window: string;
    }>;
    waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
    pickupServicePackageOptions: Array<{
      __typename?: 'Pricing__Bundle';
      id: string;
      kind: DoorToDoor__BundleEnum;
      name: string;
      packageSetEntry?: { __typename?: 'PackageSetEntry'; id: string; fixedCost: number } | null;
    }>;
  };
  currentStorageSubscription?: {
    __typename?: 'Subscription';
    id: string;
    formattedName?: string | null;
    cubicFootage?: number | null;
  } | null;
};

export type OrdersListQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']>;
  onlyCustomerSource?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrdersListQuery = {
  __typename?: 'Query';
  orders: Array<{
    __typename?: 'Order';
    isActive: boolean;
    shipped: boolean;
    multiDay: boolean;
    formattedArrivalWindow: string;
    id: string;
    documentHubURL?: string | null;
    number: string;
    scheduled: string;
    window: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    status: OrderStatusEnum;
    serviceType?: OrderServiceTypeEnum | null;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
    };
    facilityWarehouse?: { __typename?: 'Warehouse'; id: string; accessHours: Array<string> } | null;
    bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
    services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
    accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
    nonTravelSourceAndSuccessors: Array<{
      __typename?: 'Order';
      id: string;
      isActive: boolean;
      scheduled: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      window: string;
    }>;
    waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
    permissions: { __typename?: 'OrderPermissions'; id: string; cancelable: boolean; reschedulable: boolean };
  }>;
  account: {
    __typename?: 'Account';
    id: string;
    onboardable: boolean;
    requestableItemsExist: boolean;
    unscheduledStorageTreasuresAuctionsWon: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      endTime: string;
      storageTreasuresLink: string;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        cuft?: number | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
      warehouse: {
        __typename?: 'Warehouse';
        id: string;
        accessHours: Array<string>;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
          details?: {
            __typename?: 'AddressDetail';
            buildingType?: string | null;
            buildingSubtype?: string | null;
            floor?: number | null;
            stories?: number | null;
            frontDoorSteps?: number | null;
            stairs: boolean;
            squareFeet?: number | null;
            elevator: boolean;
            paperwork: boolean;
            gated: boolean;
            steep: boolean;
            serviceElevator: boolean;
            serviceEntrance: boolean;
            loadingDock: boolean;
            code: boolean;
            codeValue?: string | null;
            parkingStreet: boolean;
            parkingDriveway: boolean;
            parkingLot: boolean;
            parkingAlley: boolean;
            parkingPermit: boolean;
            parkingInFront: boolean;
            parkingOnSpecificStreet?: string | null;
            parkingInstructions?: string | null;
            maxTruckHeight?: number | null;
            maxTruckHeightUnknown: boolean;
            accessUnknown: boolean;
            paperworkUnknown: boolean;
            parkingUnknown: boolean;
            environment?: AddressDetailEnvironmentEnum | null;
            additionalNotes?: string | null;
            buildingOpeningHour?: number | null;
            buildingClosingHour?: number | null;
            buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
            parkingLatitude?: number | null;
            parkingLongitude?: number | null;
            secureLocationDetails?: string | null;
            longCarry: boolean;
            unitSize?: AddressDetailUnitSizeEnum | null;
          } | null;
          region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
        };
      };
      winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
    }>;
  };
};

export type OrdersQueryVariables = Exact<{
  onlyActive?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  storageCheckoutLink?: string | null;
  orders: Array<{
    __typename?: 'Order';
    shipped: boolean;
    primary: boolean;
    formattedArrivalWindow: string;
    id: string;
    documentHubURL?: string | null;
    number: string;
    scheduled: string;
    window: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    status: OrderStatusEnum;
    serviceType?: OrderServiceTypeEnum | null;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
    };
    bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
    services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
    dropOffAppointment?: {
      __typename?: 'DropOff__Appointment';
      id: string;
      state: DropOff__AppointmentStateEnum;
      expectedAt: string;
      status: OrderStatusEnum;
      direction: DropOff__AppointmentDirectionEnum;
      facility: {
        __typename?: 'DropOff__Facility';
        id: string;
        hasEmployees: boolean;
        instructions?: string | null;
        fulfiller:
          | {
              __typename?: 'DropOff__ThirdPartySelfStorageFacility';
              id: string;
              accessCode?: string | null;
              operator: string;
            }
          | { __typename?: 'SelfStorage__Facility'; id: string; accessCode?: string | null };
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
        };
      };
      access?: { __typename?: 'DropOff__Access'; id: string; customerCode: string; revealedAt?: string | null } | null;
      storageArrangement?:
        | { __typename?: 'DropOff__ThirdPartySelfStorageUnit'; id: string; name: string; directions?: string | null }
        | {
            __typename?: 'SelfStorage__Hold';
            unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string; directions?: string | null };
          }
        | null;
      order: { __typename?: 'Order'; type: OrderTypeEnum; subtype: OrderSubtypeEnum };
    } | null;
    accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
  }>;
  account: {
    __typename?: 'Account';
    onboardable: boolean;
    requestableItemsExist: boolean;
    unscheduledStorageTreasuresAuctionsWon: Array<{
      __typename?: 'StorageTreasures__AuctionType';
      id: string;
      endTime: string;
      storageTreasuresLink: string;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        cuft?: number | null;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
      warehouse: {
        __typename?: 'Warehouse';
        id: string;
        accessHours: Array<string>;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
          details?: {
            __typename?: 'AddressDetail';
            buildingType?: string | null;
            buildingSubtype?: string | null;
            floor?: number | null;
            stories?: number | null;
            frontDoorSteps?: number | null;
            stairs: boolean;
            squareFeet?: number | null;
            elevator: boolean;
            paperwork: boolean;
            gated: boolean;
            steep: boolean;
            serviceElevator: boolean;
            serviceEntrance: boolean;
            loadingDock: boolean;
            code: boolean;
            codeValue?: string | null;
            parkingStreet: boolean;
            parkingDriveway: boolean;
            parkingLot: boolean;
            parkingAlley: boolean;
            parkingPermit: boolean;
            parkingInFront: boolean;
            parkingOnSpecificStreet?: string | null;
            parkingInstructions?: string | null;
            maxTruckHeight?: number | null;
            maxTruckHeightUnknown: boolean;
            accessUnknown: boolean;
            paperworkUnknown: boolean;
            parkingUnknown: boolean;
            environment?: AddressDetailEnvironmentEnum | null;
            additionalNotes?: string | null;
            buildingOpeningHour?: number | null;
            buildingClosingHour?: number | null;
            buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
            parkingLatitude?: number | null;
            parkingLongitude?: number | null;
            secureLocationDetails?: string | null;
            longCarry: boolean;
            unitSize?: AddressDetailUnitSizeEnum | null;
          } | null;
          region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
        };
      };
      winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
    }>;
  };
};

export type AppointmentDetailsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type AppointmentDetailsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    movers: number;
    account: { __typename?: 'Account'; id: string };
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      packing?: Moving__PackingEnum | null;
      moveSize?: Moving__MoveSizeEnum | null;
      originAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
      };
      destinationAddress: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
      };
      movingQuote: { __typename?: 'Moving__Quote'; id: string };
    } | null;
    movingSelections?: Array<{
      __typename?: 'Selection';
      id: string;
      quantity: number;
      movingCategory: { __typename?: 'Category'; id: string; name: string };
    }> | null;
    movingPricingSummary?: {
      __typename?: 'Moving__PricingSummary';
      materialPackageSetEntry?: { __typename?: 'Moving__PackageSetEntry'; id: string } | null;
    } | null;
    permissions: { __typename?: 'OrderPermissions'; id: string; relocatable: boolean };
  };
};

export type MovingPackingSuppliesQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingPackingSuppliesQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    type: OrderTypeEnum;
    accountPackages: Array<{
      __typename?: 'AccountPackage';
      id: string;
      amount: number;
      name: string;
      kind: PackageKindEnum;
    }>;
    movingOperation?: { __typename?: 'MovingOperation'; packing?: Moving__PackingEnum | null } | null;
  };
};

export type MovingPackingHelpUpdateMutationVariables = Exact<{
  orderID: Scalars['ID'];
  packingHelp: Moving__PackingEnum;
}>;

export type MovingPackingHelpUpdateMutation = {
  __typename?: 'Mutation';
  movingPackingHelpUpdate?: {
    __typename?: 'Moving__PackingHelpUpdatePayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type UpdatePickupInventoryMutationVariables = Exact<{
  orderID: Scalars['ID'];
  input: PickupInventoryInput;
}>;

export type UpdatePickupInventoryMutation = {
  __typename?: 'Mutation';
  updatePickupInventory?: {
    __typename?: 'UpdatePickupInventoryPayload';
    error?: string | null;
    status: Status;
    message?: string | null;
  } | null;
};

export type PickupInventoryOrderFragment = {
  __typename?: 'Order';
  id: string;
  number: string;
  scheduled: string;
  serviceType?: OrderServiceTypeEnum | null;
  subtype: OrderSubtypeEnum;
  services: Array<{
    __typename?: 'OrderService';
    id: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    isFullMoveOut?: boolean | null;
    needsPackingHelp?: boolean | null;
    requestedMovers?: number | null;
    estimatedItems: Array<{
      __typename?: 'EstimatedItem';
      id: string;
      categoryId: string;
      quantity: number;
      categoryDisplayName?: string | null;
      notes?: string | null;
      length?: number | null;
      width?: number | null;
      height?: number | null;
      cuft?: number | null;
      estimatedMovers?: number | null;
    }>;
    rooms: Array<{ __typename?: 'RoomSelection'; id: string; selected: boolean; quantity: number; categoryID: string }>;
    estimations: Array<{
      __typename?: 'EstimationSelection';
      id: string;
      selected: boolean;
      quantity: number;
      categoryID: string;
    }>;
  }>;
  dropOffAppointment?: { __typename?: 'DropOff__Appointment'; id: string; expectedAt: string } | null;
  permissions: { __typename?: 'OrderPermissions'; id: string; locked: boolean };
};

export type PickupInventoryOrderServiceFragment = {
  __typename?: 'OrderService';
  id: string;
  type: OrderTypeEnum;
  subtype: OrderSubtypeEnum;
  isFullMoveOut?: boolean | null;
  needsPackingHelp?: boolean | null;
  requestedMovers?: number | null;
  estimatedItems: Array<{
    __typename?: 'EstimatedItem';
    id: string;
    categoryId: string;
    quantity: number;
    categoryDisplayName?: string | null;
    notes?: string | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    cuft?: number | null;
    estimatedMovers?: number | null;
  }>;
  rooms: Array<{ __typename?: 'RoomSelection'; id: string; selected: boolean; quantity: number; categoryID: string }>;
  estimations: Array<{
    __typename?: 'EstimationSelection';
    id: string;
    selected: boolean;
    quantity: number;
    categoryID: string;
  }>;
};

export type OrderFormPickupInventoryQueryQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderFormPickupInventoryQueryQuery = {
  __typename?: 'Query';
  estimationCategories: Array<{
    __typename?: 'EstimationCategory';
    id: string;
    name: string;
    packable: boolean;
    cuft: number;
    numMovers?: number | null;
  }>;
  roomCategories: Array<{ __typename?: 'RoomCategory'; id: string; name: string; countable: boolean; cuft: number }>;
  order: {
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    serviceType?: OrderServiceTypeEnum | null;
    subtype: OrderSubtypeEnum;
    services: Array<{
      __typename?: 'OrderService';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      isFullMoveOut?: boolean | null;
      needsPackingHelp?: boolean | null;
      requestedMovers?: number | null;
      estimatedItems: Array<{
        __typename?: 'EstimatedItem';
        id: string;
        categoryId: string;
        quantity: number;
        categoryDisplayName?: string | null;
        notes?: string | null;
        length?: number | null;
        width?: number | null;
        height?: number | null;
        cuft?: number | null;
        estimatedMovers?: number | null;
      }>;
      rooms: Array<{
        __typename?: 'RoomSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
      estimations: Array<{
        __typename?: 'EstimationSelection';
        id: string;
        selected: boolean;
        quantity: number;
        categoryID: string;
      }>;
    }>;
    dropOffAppointment?: { __typename?: 'DropOff__Appointment'; id: string; expectedAt: string } | null;
    permissions: { __typename?: 'OrderPermissions'; id: string; locked: boolean };
  };
};

export type RescheduleOrderMutationVariables = Exact<{
  input: RescheduleOrderInput;
}>;

export type RescheduleOrderMutation = {
  __typename?: 'Mutation';
  rescheduleOrder?: {
    __typename?: 'RescheduleOrderPayload';
    error?: string | null;
    status: Status;
    order?: {
      __typename?: 'Order';
      id: string;
      scheduled: string;
      window: string;
      status: OrderStatusEnum;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      serviceType?: OrderServiceTypeEnum | null;
      services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
      laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
      address: { __typename?: 'Address'; id: string };
      rescheduleOfferCoupon?: {
        __typename?: 'Coupon';
        id: string;
        amount: number;
        percent: number;
        discountDescription: string;
      } | null;
      facilityWarehouse?: {
        __typename?: 'Warehouse';
        id: string;
        accessHours: Array<string>;
        address: {
          __typename?: 'Address';
          id: string;
          street: string;
          aptsuite?: string | null;
          city: string;
          state: string;
          zip: string;
          formatted: string;
          formattedWithoutApartmentNumber: string;
          latitude?: number | null;
          longitude?: number | null;
          serviceable: boolean;
          businessName?: string | null;
          outsideServiceArea: boolean;
          stateAbbreviation: string;
          details?: {
            __typename?: 'AddressDetail';
            buildingType?: string | null;
            buildingSubtype?: string | null;
            floor?: number | null;
            stories?: number | null;
            frontDoorSteps?: number | null;
            stairs: boolean;
            squareFeet?: number | null;
            elevator: boolean;
            paperwork: boolean;
            gated: boolean;
            steep: boolean;
            serviceElevator: boolean;
            serviceEntrance: boolean;
            loadingDock: boolean;
            code: boolean;
            codeValue?: string | null;
            parkingStreet: boolean;
            parkingDriveway: boolean;
            parkingLot: boolean;
            parkingAlley: boolean;
            parkingPermit: boolean;
            parkingInFront: boolean;
            parkingOnSpecificStreet?: string | null;
            parkingInstructions?: string | null;
            maxTruckHeight?: number | null;
            maxTruckHeightUnknown: boolean;
            accessUnknown: boolean;
            paperworkUnknown: boolean;
            parkingUnknown: boolean;
            environment?: AddressDetailEnvironmentEnum | null;
            additionalNotes?: string | null;
            buildingOpeningHour?: number | null;
            buildingClosingHour?: number | null;
            buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
            parkingLatitude?: number | null;
            parkingLongitude?: number | null;
            secureLocationDetails?: string | null;
            longCarry: boolean;
            unitSize?: AddressDetailUnitSizeEnum | null;
          } | null;
          region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
        };
      } | null;
      dropOffAppointment?: {
        __typename?: 'DropOff__Appointment';
        id: string;
        expectedAt: string;
        facility: { __typename?: 'DropOff__Facility'; id: string; address: { __typename?: 'Address'; id: string } };
      } | null;
      permissions: { __typename?: 'OrderPermissions'; id: string; chargeLateFee: boolean; reschedulable: boolean };
      accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
      baseLaborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
      waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
      account: { __typename?: 'Account'; id: string; state: Account__State };
    } | null;
  } | null;
};

export type RescheduleOrderFragment = {
  __typename?: 'Order';
  id: string;
  scheduled: string;
  window: string;
  status: OrderStatusEnum;
  type: OrderTypeEnum;
  subtype: OrderSubtypeEnum;
  serviceType?: OrderServiceTypeEnum | null;
  services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
  laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
  address: { __typename?: 'Address'; id: string };
  rescheduleOfferCoupon?: {
    __typename?: 'Coupon';
    id: string;
    amount: number;
    percent: number;
    discountDescription: string;
  } | null;
  facilityWarehouse?: {
    __typename?: 'Warehouse';
    id: string;
    accessHours: Array<string>;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
      region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
    };
  } | null;
  dropOffAppointment?: {
    __typename?: 'DropOff__Appointment';
    id: string;
    expectedAt: string;
    facility: { __typename?: 'DropOff__Facility'; id: string; address: { __typename?: 'Address'; id: string } };
  } | null;
  permissions: { __typename?: 'OrderPermissions'; id: string; chargeLateFee: boolean; reschedulable: boolean };
  accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
  baseLaborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
  waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
  account: { __typename?: 'Account'; id: string; state: Account__State };
};

export type OrderFormRescheduleQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderFormRescheduleQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    window: string;
    status: OrderStatusEnum;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    serviceType?: OrderServiceTypeEnum | null;
    services: Array<{ __typename?: 'OrderService'; type: OrderTypeEnum; subtype: OrderSubtypeEnum }>;
    laborRate: { __typename?: 'LaborRate'; id: string; amount: number };
    address: { __typename?: 'Address'; id: string };
    rescheduleOfferCoupon?: {
      __typename?: 'Coupon';
      id: string;
      amount: number;
      percent: number;
      discountDescription: string;
    } | null;
    facilityWarehouse?: {
      __typename?: 'Warehouse';
      id: string;
      accessHours: Array<string>;
      address: {
        __typename?: 'Address';
        id: string;
        street: string;
        aptsuite?: string | null;
        city: string;
        state: string;
        zip: string;
        formatted: string;
        formattedWithoutApartmentNumber: string;
        latitude?: number | null;
        longitude?: number | null;
        serviceable: boolean;
        businessName?: string | null;
        outsideServiceArea: boolean;
        stateAbbreviation: string;
        details?: {
          __typename?: 'AddressDetail';
          buildingType?: string | null;
          buildingSubtype?: string | null;
          floor?: number | null;
          stories?: number | null;
          frontDoorSteps?: number | null;
          stairs: boolean;
          squareFeet?: number | null;
          elevator: boolean;
          paperwork: boolean;
          gated: boolean;
          steep: boolean;
          serviceElevator: boolean;
          serviceEntrance: boolean;
          loadingDock: boolean;
          code: boolean;
          codeValue?: string | null;
          parkingStreet: boolean;
          parkingDriveway: boolean;
          parkingLot: boolean;
          parkingAlley: boolean;
          parkingPermit: boolean;
          parkingInFront: boolean;
          parkingOnSpecificStreet?: string | null;
          parkingInstructions?: string | null;
          maxTruckHeight?: number | null;
          maxTruckHeightUnknown: boolean;
          accessUnknown: boolean;
          paperworkUnknown: boolean;
          parkingUnknown: boolean;
          environment?: AddressDetailEnvironmentEnum | null;
          additionalNotes?: string | null;
          buildingOpeningHour?: number | null;
          buildingClosingHour?: number | null;
          buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
          parkingLatitude?: number | null;
          parkingLongitude?: number | null;
          secureLocationDetails?: string | null;
          longCarry: boolean;
          unitSize?: AddressDetailUnitSizeEnum | null;
        } | null;
        region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
      };
    } | null;
    dropOffAppointment?: {
      __typename?: 'DropOff__Appointment';
      id: string;
      expectedAt: string;
      facility: { __typename?: 'DropOff__Facility'; id: string; address: { __typename?: 'Address'; id: string } };
    } | null;
    permissions: { __typename?: 'OrderPermissions'; id: string; chargeLateFee: boolean; reschedulable: boolean };
    accountCancelIntent?: { __typename?: 'Account__CancelIntent'; reason: Account__CancelIntent__Reason } | null;
    baseLaborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
    waitlistRequests: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string; date: string }>;
    account: { __typename?: 'Account'; id: string; state: Account__State };
  };
  account: {
    __typename?: 'Account';
    termCommitment?: {
      __typename?: 'Account__TermCommitment';
      term: number;
      contractEndDate?: string | null;
      type: Account__TermCommitment__Type;
      fulfilled: boolean;
    } | null;
  };
};

export type AvailabilityWaitlistRequestCreateMutationVariables = Exact<{
  input: Availability__WaitlistRequestCreateInput;
}>;

export type AvailabilityWaitlistRequestCreateMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Availability__WaitlistRequestCreatePayload';
    error?: string | null;
    status: Status;
    waitlistRequest?: { __typename?: 'Availability__WaitlistRequest'; id: string; date: string } | null;
  } | null;
};

export type AvailabilityWaitlistRequestDestroyMutationVariables = Exact<{
  input: Availability__WaitlistRequestDestroyInput;
}>;

export type AvailabilityWaitlistRequestDestroyMutation = {
  __typename?: 'Mutation';
  result?: {
    __typename?: 'Availability__WaitlistRequestDestroyPayload';
    error?: string | null;
    status: Status;
    deleted: Array<{ __typename?: 'Availability__WaitlistRequest'; id: string }>;
  } | null;
};

export type UpdateReturnInventoryMutationVariables = Exact<{
  orderID: Scalars['ID'];
  input: ReturnInventoryInput;
}>;

export type UpdateReturnInventoryMutation = {
  __typename?: 'Mutation';
  updateReturnInventory?: {
    __typename?: 'UpdateReturnInventoryPayload';
    status: Status;
    error?: string | null;
    order?: {
      __typename?: 'Order';
      id: string;
      number: string;
      scheduled: string;
      serviceType?: OrderServiceTypeEnum | null;
      services: Array<{
        __typename?: 'OrderService';
        id: string;
        type: OrderTypeEnum;
        subtype: OrderSubtypeEnum;
        items: Array<{
          __typename: 'Item';
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          contents?: string | null;
          selectableForReturn: boolean;
          shippingEligible: boolean;
          shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        }>;
      }>;
      dropOffAppointment?: { __typename?: 'DropOff__Appointment'; id: string; expectedAt: string } | null;
    } | null;
  } | null;
};

export type ReturnInventoryOrderFragment = {
  __typename?: 'Order';
  id: string;
  number: string;
  scheduled: string;
  serviceType?: OrderServiceTypeEnum | null;
  services: Array<{
    __typename?: 'OrderService';
    id: string;
    type: OrderTypeEnum;
    subtype: OrderSubtypeEnum;
    items: Array<{
      __typename: 'Item';
      id: string;
      uuid: string;
      hero: number;
      name?: string | null;
      contents?: string | null;
      selectableForReturn: boolean;
      shippingEligible: boolean;
      shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
      barcode: { __typename?: 'Barcode'; id: string; value: string };
      images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
    }>;
  }>;
  dropOffAppointment?: { __typename?: 'DropOff__Appointment'; id: string; expectedAt: string } | null;
};

export type ReturnInventoryOrderServiceFragment = {
  __typename?: 'OrderService';
  id: string;
  type: OrderTypeEnum;
  subtype: OrderSubtypeEnum;
  items: Array<{
    __typename: 'Item';
    id: string;
    uuid: string;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    shippingEligible: boolean;
    shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type OrderFormReturnInventoryQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderFormReturnInventoryQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    number: string;
    scheduled: string;
    serviceType?: OrderServiceTypeEnum | null;
    permissions: { __typename?: 'OrderPermissions'; id: string; changeableReturnInventory: boolean };
    services: Array<{
      __typename?: 'OrderService';
      id: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      items: Array<{
        __typename: 'Item';
        id: string;
        uuid: string;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }>;
    }>;
    dropOffAppointment?: { __typename?: 'DropOff__Appointment'; id: string; expectedAt: string } | null;
  };
  account: {
    __typename?: 'Account';
    termCommitment?: {
      __typename?: 'Account__TermCommitment';
      term: number;
      contractEndDate?: string | null;
      type: Account__TermCommitment__Type;
      fulfilled: boolean;
    } | null;
  };
};

export type MovingServicePackageUpdateOrderQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingServicePackageUpdateOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    tags: Array<string>;
    movingOperation?: {
      __typename?: 'MovingOperation';
      id: string;
      movingQuote: {
        __typename?: 'Moving__Quote';
        id: string;
        movingConfiguration?: { __typename?: 'MovingConfiguration'; id: string; requiredLaborDuration: number } | null;
        whiteGloveMoverCountOptions: Array<{
          __typename?: 'Moving__MoverCountOptionType';
          moverCount: number;
          whiteGloveBundle?: Moving__WhiteGloveBundleEnum | null;
          laborRate?: { __typename?: 'LaborRate'; id: string; amount: number } | null;
        }>;
      };
    } | null;
    movingMaterialPackageSetEntries: Array<{
      __typename?: 'Moving__PackageSetEntry';
      id: string;
      fixedCost: number;
      package: { __typename?: 'Package'; id: string; name: string };
    }>;
  };
};

export type MovingServicePackageUpdateMutationVariables = Exact<{
  input: Moving__ServicePackageUpdate__Input;
}>;

export type MovingServicePackageUpdateMutation = {
  __typename?: 'Mutation';
  movingServicePackageUpdate?: {
    __typename?: 'Moving__ServicePackageUpdatePayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type PickupServicePackageUpdateOrderQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type PickupServicePackageUpdateOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    tags: Array<string>;
    pickupServicePackageOptions: Array<{
      __typename?: 'Pricing__Bundle';
      id: string;
      kind: DoorToDoor__BundleEnum;
      name: string;
      packageSetEntry?: { __typename?: 'PackageSetEntry'; id: string; fixedCost: number } | null;
    }>;
  };
};

export type PickupServicePackageUpdateMutationVariables = Exact<{
  input: PickupServicePackageUpdate__Input;
}>;

export type PickupServicePackageUpdateMutation = {
  __typename?: 'Mutation';
  pickupServicePackageUpdate?: {
    __typename?: 'PickupServicePackageUpdatePayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type EligibleFacilityQueryVariables = Exact<{
  itemIDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type EligibleFacilityQuery = {
  __typename?: 'Query';
  facility?: {
    __typename?: 'Warehouse';
    id: string;
    accessHours: Array<string>;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
      region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
    };
  } | null;
};

export type OrderEligibleServiceTypesQueryVariables = Exact<{
  orderInput: OrderInput;
  skipPrimaryOrderCheck?: InputMaybe<Scalars['Boolean']>;
}>;

export type OrderEligibleServiceTypesQuery = {
  __typename?: 'Query';
  orderEligibleServiceTypes: Array<{
    __typename?: 'OrderEligibleServiceType';
    serviceType: OrderServiceTypeEnum;
    eligible: boolean;
    price?: string | null;
  }>;
};

export type OrderSignatureRequestsQueryVariables = Exact<{
  input: SignatureRequestInput;
}>;

export type OrderSignatureRequestsQuery = {
  __typename?: 'Query';
  correctCustomerLoggedIn: boolean;
  offerInTruckProtection: boolean;
  orderSignatures?: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    type: OrderTypeEnum;
    physicallySigned: boolean;
    status: OrderStatusEnum;
    account: {
      __typename?: 'Account';
      id: string;
      customer: { __typename?: 'Customer'; id: string; firstName?: string | null };
    };
    signatureRequests: Array<{
      __typename?: 'SignatureRequest';
      id: string;
      kind: SignatureRequestKindEnum;
      url?: string | null;
      signedAt?: string | null;
    }>;
    childDisposal?: {
      __typename?: 'Order';
      id: string;
      disposalConfirmationSignedOrderId: string;
      state: OrderStateEnum;
      items: Array<{ __typename?: 'Item'; id: string; uuid: string }>;
    } | null;
  } | null;
};

export type BuildAccountCancelIntentMutationVariables = Exact<{
  reason: Account__CancelIntent__Reason;
  orderID?: InputMaybe<Scalars['ID']>;
}>;

export type BuildAccountCancelIntentMutation = {
  __typename?: 'Mutation';
  build?: {
    __typename?: 'Account__BuildCancelIntentPayload';
    status: Status;
    error?: string | null;
    accountCancelIntent?: {
      __typename?: 'Account__CancelIntent';
      id: string;
      reason: Account__CancelIntent__Reason;
    } | null;
    retentionOffer?: {
      __typename?: 'Retention__Offer';
      id: string;
      detail: {
        __typename?: 'Retention__OfferDetail';
        id: string;
        type: Retention__OfferDetail__Type;
        waiveRateAdjustment: boolean;
        action: {
          __typename?: 'Retention__OfferAction';
          id: string;
          discountAmount?: number | null;
          discountPercent?: number | null;
          discountDuration?: string | null;
        };
      };
    } | null;
  } | null;
};

export type ChargeEarlyAccountClosureFeeMutationVariables = Exact<{
  sourceID: Scalars['ID'];
  scheduled?: InputMaybe<Scalars['DateTime']>;
}>;

export type ChargeEarlyAccountClosureFeeMutation = {
  __typename?: 'Mutation';
  charge?: { __typename?: 'Account__ChargeEarlyClosureFeePayload'; status: Status; error?: string | null } | null;
};

export type ShipmentLabelsQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type ShipmentLabelsQuery = {
  __typename?: 'Query';
  shipmentLabels: Array<{
    __typename?: 'Shipping__EasyPostShipmentLabel';
    easyPostShipmentId: string;
    shipmentLabel?: string | null;
    shipmentContents: string;
    carrier: string;
    status: string;
  }>;
};

export type DisclaimersForBookingOrderQueryVariables = Exact<{
  input: OrderInput;
}>;

export type DisclaimersForBookingOrderQuery = {
  __typename?: 'Query';
  disclaimersForBookingOrder: {
    __typename?: 'BookingDisclaimer';
    orderCancellationDisclaimer?: string | null;
    accountTerminationDisclaimer?: string | null;
    orderLaborDisclaimer?: string | null;
    authHoldDisclaimer?: string | null;
  };
};

export type ExtendedServiceAreaOrderQueryVariables = Exact<{
  itemIDs: Array<Scalars['ID']> | Scalars['ID'];
  addressID: Scalars['ID'];
  orderSubtype: OrderSubtypeEnum;
}>;

export type ExtendedServiceAreaOrderQuery = { __typename?: 'Query'; extendedServiceAreaOrder: boolean };

export type FacilityInstructionsQueryVariables = Exact<{
  articleName: Zendesk__ArticleName;
}>;

export type FacilityInstructionsQuery = { __typename?: 'Query'; zendeskCopy?: string | null };

export type OrderBaseLaborRateQueryVariables = Exact<{
  input: OrderInput;
}>;

export type OrderBaseLaborRateQuery = {
  __typename?: 'Query';
  orderBaseLaborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
};

export type PerItemPricingQueryVariables = Exact<{ [key: string]: never }>;

export type PerItemPricingQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; perItemPricing: boolean; perItemFee: string; baseAppointmentFee: string };
};

export type PricingPerItemFeeQueryVariables = Exact<{
  pickupInput: Pricing__PerItemFeeInput;
  returnInput: Pricing__PerItemFeeInput;
}>;

export type PricingPerItemFeeQuery = {
  __typename?: 'Query';
  subPickupPerItemPricingFee?: {
    __typename?: 'Pricing__PerItemFee';
    id: string;
    amount: number;
    orderType: OrderTypeEnum;
    orderSubtype: OrderSubtypeEnum;
  } | null;
  subReturnPerItemPricingFee?: {
    __typename?: 'Pricing__PerItemFee';
    id: string;
    amount: number;
    orderType: OrderTypeEnum;
    orderSubtype: OrderSubtypeEnum;
  } | null;
};

export type PricingPolicyNoticeInfoQueryVariables = Exact<{ [key: string]: never }>;

export type PricingPolicyNoticeInfoQuery = {
  __typename?: 'Query';
  pricingPolicyNoticeInfo: {
    __typename?: 'Pricing__PolicyNoticeInfo';
    id: string;
    subsequentPickupRate: string;
    subsequentReturnRate: string;
    facilityAppointmentCostPerCuft: string;
    disposalDonationCostPerCuft: string;
  };
};

export type CustomerPriceQueryVariables = Exact<{
  orderInput?: InputMaybe<OrderInput>;
  shippingOptions: Array<Shipping__ShippingOptionInput> | Shipping__ShippingOptionInput;
}>;

export type CustomerPriceQuery = {
  __typename?: 'Query';
  customerPrice: Array<{
    __typename?: 'Shipping__ShippingOption';
    carrier: string;
    deliveryDays: number;
    price: number;
    service: string;
    maxPrice: number;
  }>;
};

export type EasypostInboundShippingOptionsQueryVariables = Exact<{
  input: Shipping__EasyPostShipmentInput;
}>;

export type EasypostInboundShippingOptionsQuery = {
  __typename?: 'Query';
  easypostInboundShippingOptions: Array<{
    __typename?: 'Shipping__EasyPostInboundShipment';
    id: string;
    itemName: string;
    weight: number;
    rates: Array<{
      __typename?: 'Shipping__EasyPostRate';
      id: string;
      mode: string;
      rate: number;
      currency: string;
      service: string;
      carrier: string;
      deliveryDays?: number | null;
      deliveryDate?: string | null;
    }>;
  }>;
};

export type EasypostOutboundShippingOptionsQueryVariables = Exact<{
  input: Shipping__EasyPostShipmentInput;
}>;

export type EasypostOutboundShippingOptionsQuery = {
  __typename?: 'Query';
  easypostOutboundShippingOptions: Array<{
    __typename?: 'Shipping__EasyPostOutboundShipment';
    id: string;
    itemID: string;
    rates: Array<{
      __typename?: 'Shipping__EasyPostRate';
      id: string;
      mode: string;
      rate: number;
      currency: string;
      service: string;
      carrier: string;
      deliveryDays?: number | null;
      deliveryDate?: string | null;
    }>;
  }>;
};

export type OutboundShipmentMinDaysQueryVariables = Exact<{
  itemIDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type OutboundShipmentMinDaysQuery = { __typename?: 'Query'; outboundShipmentMinDays: number };

export type ShipmentItemQuotesUpsertMutationVariables = Exact<{
  inputs: Array<Shipping__ShipmentItemQuoteInput> | Shipping__ShipmentItemQuoteInput;
}>;

export type ShipmentItemQuotesUpsertMutation = {
  __typename?: 'Mutation';
  shipmentItemQuotesUpsert?: {
    __typename?: 'Shipping__ShipmentItemQuotesUpsertPayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type BuildOrderMutationVariables = Exact<{
  input: OrderInput;
  sourceID?: InputMaybe<Scalars['ID']>;
  skipBookableCheck?: InputMaybe<Scalars['Boolean']>;
}>;

export type BuildOrderMutation = {
  __typename?: 'Mutation';
  buildOrder?: {
    __typename?: 'BuildOrderPayload';
    status: Status;
    error?: string | null;
    order?: { __typename?: 'Order'; id: string } | null;
  } | null;
};

export type TermCommitmentBalanceQueryVariables = Exact<{
  scheduled?: InputMaybe<Scalars['DateTime']>;
}>;

export type TermCommitmentBalanceQuery = {
  __typename?: 'Query';
  termCommitmentBalance?: {
    __typename?: 'Account__TermCommitmentBalance';
    term: number;
    amountDue: number;
    paidInvoices: Array<{
      __typename?: 'Billing__Invoice';
      id: string;
      date?: string | null;
      lines: Array<string>;
      amountDue: number;
      total: number;
      payable: boolean;
      status: Billing__Invoice__Status;
      summary?: string | null;
    }>;
    owedInvoices: Array<{
      __typename?: 'Billing__EarlyClosureFeeInvoice';
      date: string;
      amountDue: number;
      summary: string;
    }>;
  } | null;
};

export type TermsForBookingOrderQueryVariables = Exact<{
  input: OrderInput;
}>;

export type TermsForBookingOrderQuery = { __typename?: 'Query'; terms: string };

export type OrderSupplyKitsQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type OrderSupplyKitsQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    scheduled: string;
    serviceType?: OrderServiceTypeEnum | null;
    retailDeliveryItems: Array<{
      __typename?: 'Retail__DeliveryItem';
      id: string;
      quantity: number;
      summedComponentSummaries: Array<string>;
    }>;
  };
};

export type BuildTermCommitmentCustomerTicketMutationVariables = Exact<{ [key: string]: never }>;

export type BuildTermCommitmentCustomerTicketMutation = {
  __typename?: 'Mutation';
  buildTicket?: {
    __typename?: 'TermCommitment__BuildCustomerTicketPayload';
    error?: string | null;
    status: Status;
  } | null;
};

export type ShipmentValuationDeclarationQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type ShipmentValuationDeclarationQuery = {
  __typename?: 'Query';
  declaredCoverage?: {
    __typename?: 'ShipmentValuationDeclaration';
    id: string;
    orderId: string;
    value: number;
    chargeAmount: number;
    coverage: {
      __typename?: 'ShipmentValuationCoverage';
      id: string;
      name: string;
      deductible: number;
      price: number;
      key: string;
    };
  } | null;
  shipmentValuationCoverages: Array<{
    __typename?: 'ShipmentValuationCoverage';
    id: string;
    name: string;
    deductible: number;
    price: number;
    key: string;
    summary?: string | null;
    minimumDeclaredValue: number;
    maximumDeclaredValue?: number | null;
  }>;
};

export type ShipmentValuationUpdateMutationVariables = Exact<{
  input: Compliance__ShipmentValuationUpdateInput;
}>;

export type ShipmentValuationUpdateMutation = {
  __typename?: 'Mutation';
  shipmentValuationUpdate?: {
    __typename?: 'Compliance__ShipmentValuationUpdatePayload';
    error?: string | null;
    status: Status;
  } | null;
};

export type ActivateAccountMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ActivateAccountMutation = {
  __typename?: 'Mutation';
  activateAccount?: { __typename?: 'ActivateAccountPayload'; error?: string | null; status: Status } | null;
};

export type FailedPrepaymentQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type FailedPrepaymentQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    failedPrepayment?: { __typename?: 'Billing__Prepayment'; id: string; amount: number; dueAt: string } | null;
  };
};

export type MovingPrepaymentPayMutationVariables = Exact<{
  orderID: Scalars['ID'];
  sourceID: Scalars['ID'];
}>;

export type MovingPrepaymentPayMutation = {
  __typename?: 'Mutation';
  movingPrepaymentPay?: {
    __typename?: 'Moving__PrepaymentPayPayload';
    status: Status;
    error?: string | null;
    order?: {
      __typename?: 'Order';
      id: string;
      failedPrepayment?: { __typename?: 'Billing__Prepayment'; id: string } | null;
    } | null;
  } | null;
};

export type BillingInvoicesQueryVariables = Exact<{
  status?: InputMaybe<Billing__Invoice__Status>;
}>;

export type BillingInvoicesQuery = {
  __typename?: 'Query';
  invoices: Array<{
    __typename?: 'Billing__Invoice';
    id: string;
    date?: string | null;
    amountDue: number;
    total: number;
    displayStatus: Billing__Invoice__Status;
    forgiven: boolean;
    forgivenessReasonKind?: string | null;
    refundedAt?: string | null;
    paidAt?: string | null;
    failedAt?: string | null;
    displayName: string;
    order?: {
      __typename?: 'Order';
      id: string;
      number: string;
      scheduled: string;
      bookingPartner?: { __typename?: 'Partnerships__Partner'; id: string; name: string } | null;
    } | null;
    lumpSumInvoice?: {
      __typename?: 'Billing__Invoice';
      id: string;
      total: number;
      summary?: string | null;
      paidAt?: string | null;
      amountDue: number;
      charges: Array<{
        __typename?: 'Billing__Charge';
        id: string;
        amount: number;
        date: string;
        status: Billing__Charge__Status;
        source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
      }>;
      refunds: Array<{
        __typename?: 'Billing__Refund';
        id: string;
        date: string;
        amount: number;
        source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
      }>;
    } | null;
  }>;
};

export type ResolveDelinquencyMutationVariables = Exact<{
  sourceID: Scalars['ID'];
  invoiceIDs: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type ResolveDelinquencyMutation = {
  __typename?: 'Mutation';
  resolveDelinquency?: { __typename?: 'ResolveDelinquencyPayload'; status: Status; error?: string | null } | null;
};

export type AcceptPaymentPlanOfferMutationVariables = Exact<{
  offerID: Scalars['ID'];
  sourceID: Scalars['ID'];
}>;

export type AcceptPaymentPlanOfferMutation = {
  __typename?: 'Mutation';
  accept?: {
    __typename?: 'PaymentPlans__Offer__AcceptPayload';
    status: Status;
    error?: string | null;
    offer?: {
      __typename?: 'PaymentPlan__Offer';
      id: string;
      state: PaymentPlan__Offer__State;
      acceptedAt?: string | null;
      paymentMethod?: { __typename?: 'Billing__Source'; id: string; number: string; brand?: string | null } | null;
    } | null;
  } | null;
};

export type PaymentPlanDetail__OfferFragment = {
  __typename?: 'PaymentPlan__Offer';
  id: string;
  type: PaymentPlan__Offer__Type;
  state: PaymentPlan__Offer__State;
  oneTimeFee?: number | null;
  expiry: string;
  expired: boolean;
  createdAt: string;
  acceptedAt?: string | null;
  storageTermEndDate?: string | null;
  monthlyFee?: number | null;
  monthlyFeeTerm?: number | null;
  account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; id: string; name: string } };
  settledInvoices: Array<{
    __typename?: 'Billing__Invoice';
    id: string;
    date?: string | null;
    lines: Array<string>;
    amountDue: number;
    total: number;
    payable: boolean;
    status: Billing__Invoice__Status;
    summary?: string | null;
  }>;
  paymentMethod?: { __typename?: 'Billing__Source'; id: string; number: string; brand?: string | null } | null;
};

export type PaymentPlan__PaymentMethodFragment = {
  __typename?: 'Billing__Source';
  id: string;
  number: string;
  brand?: string | null;
};

export type PaymentPlanOfferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type PaymentPlanOfferQuery = {
  __typename?: 'Query';
  offer: {
    __typename?: 'PaymentPlan__Offer';
    id: string;
    type: PaymentPlan__Offer__Type;
    state: PaymentPlan__Offer__State;
    oneTimeFee?: number | null;
    expiry: string;
    expired: boolean;
    createdAt: string;
    acceptedAt?: string | null;
    storageTermEndDate?: string | null;
    monthlyFee?: number | null;
    monthlyFeeTerm?: number | null;
    account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; id: string; name: string } };
    settledInvoices: Array<{
      __typename?: 'Billing__Invoice';
      id: string;
      date?: string | null;
      lines: Array<string>;
      amountDue: number;
      total: number;
      payable: boolean;
      status: Billing__Invoice__Status;
      summary?: string | null;
    }>;
    paymentMethod?: { __typename?: 'Billing__Source'; id: string; number: string; brand?: string | null } | null;
  };
};

export type MovingStorageAddMutationVariables = Exact<{
  parentID: Scalars['ID'];
  input: Moving__StorageAddInputType;
}>;

export type MovingStorageAddMutation = {
  __typename?: 'Mutation';
  movingStorageAdd?: { __typename?: 'Moving__StorageAddPayload'; status: Status; error?: string | null } | null;
};

export type MovingStorageAddOnEligibilityQueryVariables = Exact<{
  orderID: Scalars['ID'];
}>;

export type MovingStorageAddOnEligibilityQuery = {
  __typename?: 'Query';
  order: { __typename?: 'Order'; id: string; storageAddOnEligible: boolean };
};

export type UpdatePlanMutationVariables = Exact<{
  input: Pricing__UpdatePlanInput;
}>;

export type UpdatePlanMutation = {
  __typename?: 'Mutation';
  updatePlan?: {
    __typename?: 'Pricing__UpdatePlanPayload';
    status: Status;
    error?: string | null;
    allowsServicePackageSelection?: boolean | null;
    account?: {
      __typename?: 'Account';
      id: string;
      onboardingCostDetails: {
        __typename?: 'LaborCostDetails';
        id: string;
        formattedCost: string;
        discountTierDescription?: string | null;
        appointmentFeeCost: number;
      };
      finalOrderCostDetails: {
        __typename?: 'LaborCostDetails';
        id: string;
        formattedCost: string;
        discountTierDescription?: string | null;
        appointmentFeeCost: number;
      };
      upcomingSubscriptionSet: {
        __typename?: 'UpcomingSubscriptionSet';
        nextPeriodEnd?: string | null;
        storageSubscriptions: Array<{
          __typename?: 'UpcomingSubscription';
          id: string;
          name: string;
          cubicFootage?: number | null;
          quantity: number;
          discount: number;
          tax: number;
          total: number;
        }>;
        protectionSubscription?: {
          __typename?: 'UpcomingSubscription';
          id: string;
          name: string;
          cubicFootage?: number | null;
          quantity: number;
          discount: number;
          tax: number;
          total: number;
        } | null;
      };
      termCommitment?: { __typename?: 'Account__TermCommitment'; contractEndDate?: string | null; term: number } | null;
    } | null;
  } | null;
};

export type UpcomingStorageOnboardingQueryVariables = Exact<{ [key: string]: never }>;

export type UpcomingStorageOnboardingQuery = {
  __typename?: 'Query';
  upcomingStorageOnboarding?: {
    __typename?: 'Order';
    id: string;
    serviceType?: OrderServiceTypeEnum | null;
    started: boolean;
    tags: Array<string>;
    parent?: {
      __typename?: 'Order';
      id: string;
      type: OrderTypeEnum;
      laborCostDetails?: {
        __typename?: 'LaborCostDetails';
        id: string;
        formattedCost: string;
        discountTierDescription?: string | null;
        appointmentFeeCost: number;
      } | null;
    } | null;
  } | null;
};

export type PlanUpdateQueryVariables = Exact<{
  parentOrderID?: InputMaybe<Scalars['ID']>;
}>;

export type PlanUpdateQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    flexerAndMoverOnly: boolean;
    subscriptionSet: {
      __typename?: 'SubscriptionSet';
      storageSubscriptions: Array<{
        __typename?: 'Subscription';
        id: string;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          amount: number;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      }>;
      protectionSubscription?: {
        __typename?: 'Subscription';
        id: string;
        pricing: { __typename?: 'Pricing__Price'; id: string };
      } | null;
    };
    rateGroup?: {
      __typename?: 'Pricing__RateGroup';
      id: string;
      commitmentType?: Pricing__RateGroup__Commitment | null;
      serviceType?: Pricing__RateGroup__OrderService | null;
    } | null;
  };
  pricingSet?: {
    __typename?: 'Pricing__Set';
    id: string;
    quoteID?: string | null;
    storagePriceEntries: Array<{
      __typename?: 'Pricing__Set__PriceEntry';
      id: string;
      rateGroup?: {
        __typename?: 'Pricing__RateGroup';
        id: string;
        commitmentType?: Pricing__RateGroup__Commitment | null;
        serviceType?: Pricing__RateGroup__OrderService | null;
      } | null;
      price: {
        __typename?: 'Pricing__Price';
        id: string;
        amount: number;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
      storageTerm?: { __typename?: 'Pricing__StorageTerm'; id: string; name: string; minimumMonths: number } | null;
    }>;
    protectionPriceEntries: Array<{
      __typename?: 'Pricing__Set__PriceEntry';
      id: string;
      rateGroup?: {
        __typename?: 'Pricing__RateGroup';
        id: string;
        commitmentType?: Pricing__RateGroup__Commitment | null;
        serviceType?: Pricing__RateGroup__OrderService | null;
      } | null;
      price: {
        __typename?: 'Pricing__Price';
        id: string;
        amount: number;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
      storageTerm?: { __typename?: 'Pricing__StorageTerm'; id: string; name: string; minimumMonths: number } | null;
    }>;
  } | null;
};

export type AppointmentFeeOptionsQueryVariables = Exact<{
  cuft: Scalars['Float'];
  rateGroupID: Scalars['ID'];
  pricingSetID?: InputMaybe<Scalars['ID']>;
}>;

export type AppointmentFeeOptionsQuery = {
  __typename?: 'Query';
  appointmentFeeOptions: Array<{
    __typename?: 'Pricing__Set__AppointmentFees';
    serviceType: Pricing__RateGroup__OrderService;
    laborEntry: {
      __typename?: 'Pricing__Set__LaborEntry';
      id: string;
      laborPolicy: {
        __typename?: 'Pricing__LaborPolicy';
        id: string;
        laborBillingFormat: Pricing__LaborBillingFormat;
        perItemPricing: boolean;
        perMoverHour: boolean;
        laborRate: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string };
      };
    };
    packageSetEntries: Array<{
      __typename?: 'Pricing__Set__PackageSetEntry';
      id: string;
      orderSubtype: string;
      amount: number;
    }>;
  }>;
};

export type OrderRescheduleOfferItemAcceptMutationVariables = Exact<{
  input: Order__RescheduleOfferItemAcceptInput;
}>;

export type OrderRescheduleOfferItemAcceptMutation = {
  __typename?: 'Mutation';
  offerAccept?: {
    __typename?: 'Order__RescheduleOfferItemAcceptPayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type RescheduleOfferDetailsQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type RescheduleOfferDetailsQuery = {
  __typename?: 'Query';
  details?: {
    __typename?: 'RescheduleOffer__DetailsType';
    orderRescheduleOfferItemID: string;
    order: {
      __typename?: 'Order';
      id: string;
      documentHubURL?: string | null;
      number: string;
      scheduled: string;
      window: string;
      type: OrderTypeEnum;
      subtype: OrderSubtypeEnum;
      status: OrderStatusEnum;
      serviceType?: OrderServiceTypeEnum | null;
      baseLaborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
      laborCostDetails?: {
        __typename?: 'LaborCostDetails';
        id: string;
        formattedCost: string;
        discountTierDescription?: string | null;
        appointmentFeeCost: number;
      } | null;
      account: { __typename?: 'Account'; id: string; state: Account__State };
    };
    coupon?: { __typename?: 'Coupon'; id: string; amount: number; percent: number; discountDescription: string } | null;
    availabilities?: Array<{
      __typename?: 'Availability';
      datetime: string;
      duration?: string | null;
      available: boolean;
      appointmentFee?: string | null;
      perMoverHourAdjustmentAmount?: number | null;
      waitlistable: boolean;
      laborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
      feeAmount?: { __typename?: 'MonetaryAmount'; formatted: string; value: number } | null;
    }> | null;
  } | null;
};

export type InventoryItemFragment = {
  __typename: 'Item';
  cuft?: number | null;
  id: string;
  uuid: string;
  hero: number;
  name?: string | null;
  contents?: string | null;
  selectableForReturn: boolean;
  shippingEligible: boolean;
  category?: { __typename?: 'ItemCategory'; name: string } | null;
  barcode: { __typename?: 'Barcode'; id: string; value: string };
  images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
};

export type InventoryItemGroupFragment = {
  __typename: 'ItemGroup';
  id: string;
  cuft?: number | null;
  uuid: string;
  name?: string | null;
  category?: { __typename?: 'ItemCategory'; name: string } | null;
  heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
  items: Array<{
    __typename: 'Item';
    cuft?: number | null;
    id: string;
    uuid: string;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    shippingEligible: boolean;
    category?: { __typename?: 'ItemCategory'; name: string } | null;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type ReturnInventoryQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
}>;

export type ReturnInventoryQuery = {
  __typename?: 'Query';
  inventory: Array<
    | {
        __typename: 'Item';
        cuft?: number | null;
        id: string;
        uuid: string;
        hero: number;
        name?: string | null;
        contents?: string | null;
        selectableForReturn: boolean;
        shippingEligible: boolean;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        barcode: { __typename?: 'Barcode'; id: string; value: string };
        images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
      }
    | {
        __typename: 'ItemGroup';
        id: string;
        cuft?: number | null;
        uuid: string;
        name?: string | null;
        category?: { __typename?: 'ItemCategory'; name: string } | null;
        heroImage?: { __typename?: 'Image'; id: string; source: string; state: ImageState } | null;
        items: Array<{
          __typename: 'Item';
          cuft?: number | null;
          id: string;
          uuid: string;
          hero: number;
          name?: string | null;
          contents?: string | null;
          selectableForReturn: boolean;
          shippingEligible: boolean;
          category?: { __typename?: 'ItemCategory'; name: string } | null;
          barcode: { __typename?: 'Barcode'; id: string; value: string };
          images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
        }>;
      }
  >;
};

export type BuildReviewMutationVariables = Exact<{
  input: BuildReviewInput;
}>;

export type BuildReviewMutation = {
  __typename?: 'Mutation';
  buildReview?: { __typename?: 'BuildReviewPayload'; status: Status; error?: string | null } | null;
};

export type ReviewReasonsQueryVariables = Exact<{
  reviewableType: Review__ReviewableEnum;
  schedulingID?: InputMaybe<Scalars['ID']>;
}>;

export type ReviewReasonsQuery = {
  __typename?: 'Query';
  reviewReasons: { __typename?: 'Review__Reasons'; good: Array<string>; poor: Array<string> };
};

export type ReferralQueryVariables = Exact<{
  reviewableID: Scalars['ID'];
}>;

export type ReferralQuery = {
  __typename?: 'Query';
  referralCode?: string | null;
  marketingShare?: { __typename?: 'Marketing__Share'; url: string; text: string } | null;
};

export type ReviewableQueryVariables = Exact<{
  reviewableID: Scalars['ID'];
}>;

export type ReviewableQuery = {
  __typename?: 'Query';
  reviewable?:
    | { __typename?: 'Review__Reviewable__Base'; id: string; type: Review__ReviewableEnum }
    | { __typename?: 'Review__Reviewable__Order'; id: string; type: Review__ReviewableEnum; accountID: string }
    | {
        __typename?: 'Review__Reviewable__SelfStorageRental';
        id: string;
        type: Review__ReviewableEnum;
        facilityName: string;
      }
    | null;
};

export type ShareMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
  source: Scalars['String'];
}>;

export type ShareMutation = {
  __typename?: 'Mutation';
  share?: { __typename?: 'SharePayload'; status: Status; error?: string | null } | null;
};

export type ReferralHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type ReferralHistoryQuery = {
  __typename?: 'Query';
  referralHistory: {
    __typename?: 'Account__ReferralHistory';
    referralCode: string;
    creditsEarned: number;
    referralsCount: number;
  };
};

export type ContactInformationQueryVariables = Exact<{ [key: string]: never }>;

export type ContactInformationQuery = {
  __typename?: 'Query';
  customer?: { __typename?: 'Customer'; id: string; name: string; email: string; phone: string } | null;
};

export type CustomerNameUpdateMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CustomerNameUpdateMutation = {
  __typename?: 'Mutation';
  customerNameUpdate?: {
    __typename?: 'Customer__NameUpdatePayload';
    customer: { __typename?: 'Customer'; id: string; name: string };
  } | null;
};

export type CustomerVerifiedEmailUpdateMutationVariables = Exact<{
  verifiedEmail: Scalars['String'];
}>;

export type CustomerVerifiedEmailUpdateMutation = {
  __typename?: 'Mutation';
  customerVerifiedEmailUpdate?: {
    __typename?: 'Customer__VerifiedEmailUpdatePayload';
    customer: { __typename?: 'Customer'; id: string; email: string };
  } | null;
};

export type CustomerVerifiedPhoneUpdateMutationVariables = Exact<{
  verifiedPhoneNumber: Scalars['String'];
}>;

export type CustomerVerifiedPhoneUpdateMutation = {
  __typename?: 'Mutation';
  customerVerifiedPhoneUpdate?: {
    __typename?: 'Customer__VerifiedPhoneUpdatePayload';
    customer: { __typename?: 'Customer'; id: string; phone: string };
  } | null;
};

export type DocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type DocumentsQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    firstOnboarding?: {
      __typename?: 'Order';
      scheduled: string;
      consumerBrochure?: { __typename?: 'Document'; url?: string | null; name: string } | null;
    } | null;
    ingestedDocuments: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
  };
  orders: Array<{
    __typename?: 'Order';
    id: string;
    state: OrderStateEnum;
    scheduled: string;
    type: OrderTypeEnum;
    signatures: Array<{
      __typename?: 'Signature';
      id: string;
      contractUrl?: string | null;
      documentKind: string;
      agreement?: { __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string } | null;
    }>;
    coiRequests: Array<{
      __typename?: 'OrderCOIRequest';
      id: string;
      certificateOfInsurance?: {
        __typename?: 'ActiveStorageAttachment';
        id: string;
        filename: string;
        url: string;
      } | null;
    }>;
    ingestedDocuments: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; filename: string; url: string }>;
  }>;
};

export type MfaCodeCheckMutationVariables = Exact<{
  requestId: Scalars['ID'];
  code: Scalars['String'];
}>;

export type MfaCodeCheckMutation = {
  __typename?: 'Mutation';
  mfaCodeCheck?: { __typename?: 'MFA__CodeCheckPayload'; status: Status; error?: string | null } | null;
};

export type MfaRequestSendMutationVariables = Exact<{
  verificationKind: Mfa__VerificationKind;
  verificationValue: Scalars['String'];
}>;

export type MfaRequestSendMutation = {
  __typename?: 'Mutation';
  mfaRequestSend?: {
    __typename?: 'MFA__RequestSendPayload';
    status: Status;
    error?: string | null;
    mfaRequest?: {
      __typename?: 'MFA__Request';
      id: string;
      verificationKind?: Mfa__VerificationKind | null;
      verificationValue?: string | null;
    } | null;
  } | null;
};

export type PreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type PreferencesQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; preferences: { __typename?: 'Account__PreferencesType'; emailReceipts: boolean } };
};

export type UpdateNotificationPreferencesMutationVariables = Exact<{
  input: Settings__UpdateNotificationPreferencesInput;
}>;

export type UpdateNotificationPreferencesMutation = {
  __typename?: 'Mutation';
  updateNotificationPreferences?: {
    __typename?: 'Settings__UpdateNotificationPreferencesPayload';
    status: Status;
    error?: string | null;
  } | null;
};

export type UpdatePasswordMutationVariables = Exact<{
  input: Settings__UpdatePasswordInput;
}>;

export type UpdatePasswordMutation = {
  __typename?: 'Mutation';
  updatePassword?: { __typename?: 'Settings__UpdatePasswordPayload'; status: Status; error?: string | null } | null;
};

export type BuildSourceMutationVariables = Exact<{
  input: Billing__BuildSourceInput;
}>;

export type BuildSourceMutation = {
  __typename?: 'Mutation';
  buildSource?: {
    __typename?: 'Billing__BuildSourcePayload';
    error?: string | null;
    status: Status;
    source?: { __typename?: 'Billing__Source'; id: string } | null;
  } | null;
};

export type DefaultSourceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DefaultSourceMutation = {
  __typename?: 'Mutation';
  defaultSource?: { __typename?: 'Billing__DefaultSourcePayload'; status: Status; error?: string | null } | null;
};

export type DelinquentBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type DelinquentBalanceQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; delinquentBalance?: number | null };
};

export type DestroySourceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DestroySourceMutation = {
  __typename?: 'Mutation';
  destroySource?: { __typename?: 'Billing__DestroySourcePayload'; status: Status; error?: string | null } | null;
};

export type SourcesQueryVariables = Exact<{ [key: string]: never }>;

export type SourcesQuery = {
  __typename?: 'Query';
  sources: Array<{
    __typename?: 'Billing__Source';
    id: string;
    number: string;
    brand?: string | null;
    name?: string | null;
    expiration?: string | null;
    expired: boolean;
    default: boolean;
    fundingType: Billing__Source__FundingType;
  }>;
};

export type BuildCustomerTicketMutationVariables = Exact<{
  input: BuildCustomerTicketInput;
}>;

export type BuildCustomerTicketMutation = {
  __typename?: 'Mutation';
  buildCustomerTicket?: {
    __typename?: 'BuildCustomerTicketPayload';
    error?: string | null;
    status: Status;
    customerTicketID?: string | null;
    zendeskTicketID?: string | null;
  } | null;
};

export type CustomerTicketCreateQueryVariables = Exact<{ [key: string]: never }>;

export type CustomerTicketCreateQuery = {
  __typename?: 'Query';
  account: { __typename?: 'Account'; customer: { __typename?: 'Customer'; name: string; email: string } };
};

export type UsageWithDownsizeQueryVariables = Exact<{ [key: string]: never }>;

export type UsageWithDownsizeQuery = {
  __typename?: 'Query';
  account: {
    __typename?: 'Account';
    id: string;
    usage: { __typename?: 'Usage'; used: number; total: number; kind: Usage__Kind };
    upcomingSubscriptionSet: {
      __typename?: 'UpcomingSubscriptionSet';
      storageSubscriptions: Array<{
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      }>;
      protectionSubscription?: {
        __typename?: 'UpcomingSubscription';
        id: string;
        name: string;
        cubicFootage?: number | null;
        quantity: number;
        discount: number;
        tax: number;
        total: number;
        pricing: {
          __typename?: 'Pricing__Price';
          id: string;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      } | null;
    };
    summaryForSuggestedUpgradeDowngrade?: {
      __typename?: 'UpgradeDowngradeSummary';
      savings: number;
      current: Array<{
        __typename?: 'UpgradeDowngradeEntry';
        total: number;
        quantity: number;
        price: {
          __typename?: 'Pricing__Price';
          id: string;
          amount: number;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      }>;
      proposed: Array<{
        __typename?: 'UpgradeDowngradeEntry';
        total: number;
        quantity: number;
        price: {
          __typename?: 'Pricing__Price';
          id: string;
          amount: number;
          plan:
            | {
                __typename?: 'Pricing__ProtectionPlan';
                id: string;
                name: string;
                classification?: Pricing__Plan__ProtectionClassification | null;
                maxCovered: number;
              }
            | {
                __typename?: 'Pricing__StoragePlan';
                id: string;
                cuft: number;
                name: string;
                type: Pricing__Plan__Type;
                dimensions?: {
                  __typename?: 'Pricing__Plan__Dimensions';
                  length: number;
                  width: number;
                  height?: number | null;
                } | null;
              };
        };
      }>;
    } | null;
  };
};

export type VirtualWalkthroughSubmitMutationVariables = Exact<{
  input: VirtualWalkthroughSubmitInput;
}>;

export type VirtualWalkthroughSubmitMutation = {
  __typename?: 'Mutation';
  virtualWalkthroughSubmit?: {
    __typename?: 'VirtualWalkthroughSubmitPayload';
    error?: string | null;
    status: Status;
  } | null;
};

export type ExperimentsQueryVariables = Exact<{ [key: string]: never }>;

export type ExperimentsQuery = {
  __typename?: 'Query';
  laborBillingFormat?: Pricing__LaborBillingFormat | null;
  account: { __typename?: 'Account'; id: string; region: { __typename?: 'Region'; id: string; name: string } };
  pricingSet?: { __typename?: 'Pricing__Set'; id: string } | null;
};

export type AccountResolution__OfferFragment = {
  __typename?: 'AccountResolution__Offer';
  id: string;
  kind: AccountResolution__OfferKind;
  metadata: any;
};

export type AccountResolution__ReasonFragment = {
  __typename?: 'AccountResolution__Reason';
  id: string;
  parentID?: string | null;
  copy: string;
  offerKind?: AccountResolution__OfferKind | null;
  zendeskCopy?: string | null;
  canonicalName: string;
  children: Array<{
    __typename?: 'AccountResolution__ReasonChild';
    id: string;
    parentID: string;
    position: number;
    copy: string;
    canonicalName: string;
  }>;
};

export type AccountResolution__ReasonChildFragment = {
  __typename?: 'AccountResolution__ReasonChild';
  id: string;
  parentID: string;
  position: number;
  copy: string;
  canonicalName: string;
};

export type AccountWithUsageAndUpgradeDowngradeSummaryFragment = {
  __typename?: 'Account';
  id: string;
  usage: { __typename?: 'Usage'; used: number; total: number; kind: Usage__Kind };
  upcomingSubscriptionSet: {
    __typename?: 'UpcomingSubscriptionSet';
    storageSubscriptions: Array<{
      __typename?: 'UpcomingSubscription';
      id: string;
      name: string;
      cubicFootage?: number | null;
      quantity: number;
      discount: number;
      tax: number;
      total: number;
      pricing: {
        __typename?: 'Pricing__Price';
        id: string;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
    }>;
    protectionSubscription?: {
      __typename?: 'UpcomingSubscription';
      id: string;
      name: string;
      cubicFootage?: number | null;
      quantity: number;
      discount: number;
      tax: number;
      total: number;
      pricing: {
        __typename?: 'Pricing__Price';
        id: string;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
    } | null;
  };
  summaryForSuggestedUpgradeDowngrade?: {
    __typename?: 'UpgradeDowngradeSummary';
    savings: number;
    current: Array<{
      __typename?: 'UpgradeDowngradeEntry';
      total: number;
      quantity: number;
      price: {
        __typename?: 'Pricing__Price';
        id: string;
        amount: number;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
    }>;
    proposed: Array<{
      __typename?: 'UpgradeDowngradeEntry';
      total: number;
      quantity: number;
      price: {
        __typename?: 'Pricing__Price';
        id: string;
        amount: number;
        plan:
          | {
              __typename?: 'Pricing__ProtectionPlan';
              id: string;
              name: string;
              classification?: Pricing__Plan__ProtectionClassification | null;
              maxCovered: number;
            }
          | {
              __typename?: 'Pricing__StoragePlan';
              id: string;
              cuft: number;
              name: string;
              type: Pricing__Plan__Type;
              dimensions?: {
                __typename?: 'Pricing__Plan__Dimensions';
                length: number;
                width: number;
                height?: number | null;
              } | null;
            };
      };
    }>;
  } | null;
};

export type AddressFragment = {
  __typename?: 'Address';
  id: string;
  street: string;
  aptsuite?: string | null;
  city: string;
  state: string;
  zip: string;
  formatted: string;
  formattedWithoutApartmentNumber: string;
  latitude?: number | null;
  longitude?: number | null;
  serviceable: boolean;
  businessName?: string | null;
  outsideServiceArea: boolean;
  stateAbbreviation: string;
};

export type DetailsFragment = {
  __typename?: 'AddressDetail';
  buildingType?: string | null;
  buildingSubtype?: string | null;
  floor?: number | null;
  stories?: number | null;
  frontDoorSteps?: number | null;
  stairs: boolean;
  squareFeet?: number | null;
  elevator: boolean;
  paperwork: boolean;
  gated: boolean;
  steep: boolean;
  serviceElevator: boolean;
  serviceEntrance: boolean;
  loadingDock: boolean;
  code: boolean;
  codeValue?: string | null;
  parkingStreet: boolean;
  parkingDriveway: boolean;
  parkingLot: boolean;
  parkingAlley: boolean;
  parkingPermit: boolean;
  parkingInFront: boolean;
  parkingOnSpecificStreet?: string | null;
  parkingInstructions?: string | null;
  maxTruckHeight?: number | null;
  maxTruckHeightUnknown: boolean;
  accessUnknown: boolean;
  paperworkUnknown: boolean;
  parkingUnknown: boolean;
  environment?: AddressDetailEnvironmentEnum | null;
  additionalNotes?: string | null;
  buildingOpeningHour?: number | null;
  buildingClosingHour?: number | null;
  buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
  parkingLatitude?: number | null;
  parkingLongitude?: number | null;
  secureLocationDetails?: string | null;
  longCarry: boolean;
  unitSize?: AddressDetailUnitSizeEnum | null;
};

export type AddressWithDetailsFragment = {
  __typename?: 'Address';
  id: string;
  street: string;
  aptsuite?: string | null;
  city: string;
  state: string;
  zip: string;
  formatted: string;
  formattedWithoutApartmentNumber: string;
  latitude?: number | null;
  longitude?: number | null;
  serviceable: boolean;
  businessName?: string | null;
  outsideServiceArea: boolean;
  stateAbbreviation: string;
  details?: {
    __typename?: 'AddressDetail';
    buildingType?: string | null;
    buildingSubtype?: string | null;
    floor?: number | null;
    stories?: number | null;
    frontDoorSteps?: number | null;
    stairs: boolean;
    squareFeet?: number | null;
    elevator: boolean;
    paperwork: boolean;
    gated: boolean;
    steep: boolean;
    serviceElevator: boolean;
    serviceEntrance: boolean;
    loadingDock: boolean;
    code: boolean;
    codeValue?: string | null;
    parkingStreet: boolean;
    parkingDriveway: boolean;
    parkingLot: boolean;
    parkingAlley: boolean;
    parkingPermit: boolean;
    parkingInFront: boolean;
    parkingOnSpecificStreet?: string | null;
    parkingInstructions?: string | null;
    maxTruckHeight?: number | null;
    maxTruckHeightUnknown: boolean;
    accessUnknown: boolean;
    paperworkUnknown: boolean;
    parkingUnknown: boolean;
    environment?: AddressDetailEnvironmentEnum | null;
    additionalNotes?: string | null;
    buildingOpeningHour?: number | null;
    buildingClosingHour?: number | null;
    buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
    parkingLatitude?: number | null;
    parkingLongitude?: number | null;
    secureLocationDetails?: string | null;
    longCarry: boolean;
    unitSize?: AddressDetailUnitSizeEnum | null;
  } | null;
  region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
};

export type AttachmentFragment = {
  __typename?: 'ActiveStorageAttachment';
  id: string;
  imgixURL: string;
  filename: string;
};

export type AvailabilityFragment = {
  __typename?: 'Availability';
  datetime: string;
  duration?: string | null;
  available: boolean;
  appointmentFee?: string | null;
  perMoverHourAdjustmentAmount?: number | null;
  waitlistable: boolean;
  laborRate?: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string } | null;
  feeAmount?: { __typename?: 'MonetaryAmount'; formatted: string; value: number } | null;
};

export type AvailabilityWaitlistRequestFragment = {
  __typename?: 'Availability__WaitlistRequest';
  id: string;
  date: string;
};

export type AvailabilityTierFragment = {
  __typename?: 'AvailabilityTier';
  type: AvailabilityTierTypeEnum;
  label: string;
  description: string;
  price: string;
};

export type BarcodeFragment = { __typename?: 'Barcode'; id: string; value: string };

export type Billing__ChargeFragment = {
  __typename?: 'Billing__Charge';
  id: string;
  amount: number;
  date: string;
  status: Billing__Charge__Status;
  source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
};

export type Billing__InvoiceItemFragment = {
  __typename?: 'Billing__InvoiceItem';
  id: string;
  type: string;
  amount: number;
  description?: string | null;
  displayDescription: string;
  displayName: string;
  quantity?: number | null;
  unitPrice?: number | null;
};

export type Billing__NestedInvoiceFragment = {
  __typename?: 'Billing__Invoice';
  id: string;
  total: number;
  summary?: string | null;
  paidAt?: string | null;
  amountDue: number;
  charges: Array<{
    __typename?: 'Billing__Charge';
    id: string;
    amount: number;
    date: string;
    status: Billing__Charge__Status;
    source: { __typename?: 'Billing__Source'; id: string; brand?: string | null; number: string };
  }>;
  refunds: Array<{
    __typename?: 'Billing__Refund';
    id: string;
    date: string;
    amount: number;
    source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
  }>;
};

export type Billing__OfflinePaymentFragment = {
  __typename?: 'Billing__OfflinePayment';
  id: string;
  date: string;
  amount: number;
  kind: Billing__OfflinePayment__Kind;
  summary?: string | null;
};

export type Billing__RefundFragment = {
  __typename?: 'Billing__Refund';
  id: string;
  date: string;
  amount: number;
  source: { __typename?: 'Billing__Source'; brand?: string | null; number: string };
};

export type ClaimFragment = {
  __typename?: 'Claim';
  id: string;
  uuid: string;
  status: ClaimStatus;
  step?: string | null;
  hasIssue: boolean;
  hasLostItem: boolean;
  hasDamagedItem: boolean;
  hasDamagedProperty: boolean;
  openedAt?: string | null;
  closedAt?: string | null;
};

export type ClaimIssueSelectionFragment = {
  __typename?: 'ClaimIssueSelection';
  id: string;
  compensationRequested?: boolean | null;
  declaration?: number | null;
  description?: string | null;
  kind?: ClaimIssueSelectionKind | null;
  photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
};

export type ClaimItemSelectionFragment = {
  __typename?: 'ClaimItemSelection';
  id: string;
  kind: ClaimItemSelectionKind;
  tracked: boolean;
  name?: string | null;
  declaration?: number | null;
  productURL?: string | null;
  photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
  receipts: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
  item?: {
    __typename: 'Item';
    id: string;
    uuid: string;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    shippingEligible: boolean;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  } | null;
};

export type ClaimOfferFragment = {
  __typename?: 'ClaimOffer';
  id: string;
  details: string;
  createdAt: string;
  expiry: string;
  totalAmount: number;
  user: { __typename?: 'User'; id: string; name: string };
};

export type ClaimPaymentOptionFragment = {
  __typename?: 'ClaimPaymentOption';
  kind: ClaimPaymentOptionKind;
  name: string;
  email?: string | null;
  address?: {
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    formatted: string;
    formattedWithoutApartmentNumber: string;
    latitude?: number | null;
    longitude?: number | null;
    serviceable: boolean;
    businessName?: string | null;
    outsideServiceArea: boolean;
    stateAbbreviation: string;
  } | null;
};

export type ClaimPropertySelectionFragment = {
  __typename?: 'ClaimPropertySelection';
  id: string;
  name?: string | null;
  declaration?: number | null;
  ownership?: ClaimPropertySelectionOwnership | null;
  owner?: { __typename?: 'ClaimPropertyOwner'; name: string; email: string; phone: string } | null;
  photos: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
  quotes: Array<{ __typename?: 'ActiveStorageAttachment'; id: string; imgixURL: string; filename: string }>;
};

export type ContactFragment = {
  __typename?: 'Order__Contact';
  id: string;
  name?: string | null;
  phone: string;
  kind?: Order__ContactKind | null;
};

export type CouponFragment = {
  __typename?: 'Coupon';
  id: string;
  discountDescription: string;
  discountDisclaimer?: string | null;
};

export type CustomerFragment = { __typename?: 'Customer'; id: string; name: string; phone: string };

export type EstimatedItemFragment = {
  __typename?: 'EstimatedItem';
  id: string;
  categoryId: string;
  quantity: number;
  categoryDisplayName?: string | null;
  notes?: string | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  cuft?: number | null;
  estimatedMovers?: number | null;
};

export type EstimationEstimatedItemFragment = {
  __typename?: 'Estimation__EstimatedItemType';
  id: string;
  quantity: number;
  category: {
    __typename?: 'Estimation__CategoryType';
    id: string;
    name: string;
    position: number;
    parent?: { __typename?: 'Estimation__CategoryType'; id: string; name: string } | null;
  };
};

export type EstimationUploadFragment = {
  __typename?: 'Estimation__UploadType';
  id: string;
  media: {
    __typename?: 'Estimation__UploadMediaType';
    id: string;
    imgixURL: string;
    isImage: boolean;
    isVideo: boolean;
  };
};

export type EstimationCategoryFragment = {
  __typename?: 'EstimationCategory';
  id: string;
  name: string;
  packable: boolean;
  cuft: number;
  numMovers?: number | null;
};

export type FacilityFragment = {
  __typename?: 'Warehouse';
  id: string;
  accessHours: Array<string>;
  address: {
    __typename?: 'Address';
    id: string;
    street: string;
    aptsuite?: string | null;
    city: string;
    state: string;
    zip: string;
    formatted: string;
    formattedWithoutApartmentNumber: string;
    latitude?: number | null;
    longitude?: number | null;
    serviceable: boolean;
    businessName?: string | null;
    outsideServiceArea: boolean;
    stateAbbreviation: string;
    details?: {
      __typename?: 'AddressDetail';
      buildingType?: string | null;
      buildingSubtype?: string | null;
      floor?: number | null;
      stories?: number | null;
      frontDoorSteps?: number | null;
      stairs: boolean;
      squareFeet?: number | null;
      elevator: boolean;
      paperwork: boolean;
      gated: boolean;
      steep: boolean;
      serviceElevator: boolean;
      serviceEntrance: boolean;
      loadingDock: boolean;
      code: boolean;
      codeValue?: string | null;
      parkingStreet: boolean;
      parkingDriveway: boolean;
      parkingLot: boolean;
      parkingAlley: boolean;
      parkingPermit: boolean;
      parkingInFront: boolean;
      parkingOnSpecificStreet?: string | null;
      parkingInstructions?: string | null;
      maxTruckHeight?: number | null;
      maxTruckHeightUnknown: boolean;
      accessUnknown: boolean;
      paperworkUnknown: boolean;
      parkingUnknown: boolean;
      environment?: AddressDetailEnvironmentEnum | null;
      additionalNotes?: string | null;
      buildingOpeningHour?: number | null;
      buildingClosingHour?: number | null;
      buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
      parkingLatitude?: number | null;
      parkingLongitude?: number | null;
      secureLocationDetails?: string | null;
      longCarry: boolean;
      unitSize?: AddressDetailUnitSizeEnum | null;
    } | null;
    region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
  };
};

export type FlowDropOffAppointmentFragment = {
  __typename?: 'DropOff__Appointment';
  id: string;
  state: DropOff__AppointmentStateEnum;
  expectedAt: string;
  status: OrderStatusEnum;
  direction: DropOff__AppointmentDirectionEnum;
  facility: {
    __typename?: 'DropOff__Facility';
    id: string;
    hasEmployees: boolean;
    instructions?: string | null;
    fulfiller:
      | {
          __typename?: 'DropOff__ThirdPartySelfStorageFacility';
          id: string;
          accessCode?: string | null;
          operator: string;
        }
      | { __typename?: 'SelfStorage__Facility'; id: string; accessCode?: string | null };
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
    };
  };
  access?: { __typename?: 'DropOff__Access'; id: string; customerCode: string; revealedAt?: string | null } | null;
  storageArrangement?:
    | { __typename?: 'DropOff__ThirdPartySelfStorageUnit'; id: string; name: string; directions?: string | null }
    | {
        __typename?: 'SelfStorage__Hold';
        unit: { __typename?: 'SelfStorage__Unit'; id: string; name: string; directions?: string | null };
      }
    | null;
  order: { __typename?: 'Order'; type: OrderTypeEnum; subtype: OrderSubtypeEnum };
};

export type ImageFragment = { __typename?: 'Image'; id: string; source: string; state: ImageState };

export type InvoiceFragment = {
  __typename?: 'Billing__Invoice';
  id: string;
  date?: string | null;
  lines: Array<string>;
  amountDue: number;
  total: number;
  payable: boolean;
  status: Billing__Invoice__Status;
  summary?: string | null;
};

export type ItemFragment = {
  __typename: 'Item';
  id: string;
  uuid: string;
  hero: number;
  name?: string | null;
  contents?: string | null;
  selectableForReturn: boolean;
  shippingEligible: boolean;
  barcode: { __typename?: 'Barcode'; id: string; value: string };
  images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
};

export type ItemPricingFragment = {
  __typename?: 'ItemPricing';
  pickupPerItemFee?: string | null;
  returnPerItemFee?: string | null;
  pickupLargeItemFee?: string | null;
  returnLargeItemFee?: string | null;
  appointmentFee?: string | null;
  returnedItemsCount: number;
  returnedLargeItemsCount: number;
  price: string;
  pickupEstimate?: {
    __typename?: 'PickupEstimate';
    lowestEstimatedItems?: number | null;
    highestEstimatedItems?: number | null;
  } | null;
  pickupLargeEstimate?: {
    __typename?: 'PickupEstimate';
    lowestEstimatedItems?: number | null;
    highestEstimatedItems?: number | null;
  } | null;
  accountPackages: Array<{
    __typename?: 'AccountPackage';
    id: string;
    name: string;
    amount: number;
    kind: PackageKindEnum;
  }>;
};

export type LaborCostDetailsFragment = {
  __typename?: 'LaborCostDetails';
  id: string;
  formattedCost: string;
  discountTierDescription?: string | null;
  appointmentFeeCost: number;
};

export type Account__Marketing__CouponFragment = {
  __typename?: 'Coupon';
  id: string;
  amount: number;
  percent: number;
  discountDescription: string;
};

export type EstimationMovingQuoteFragment = {
  __typename?: 'Moving__Quote';
  id: string;
  eligibility: boolean;
  reasons: Array<string>;
  truckCount?: number | null;
  moverSelection?: number | null;
  suggestedMoverCount?: number | null;
  deadtimeDuration: number;
  drivetimeDuration?: number | null;
  estimatedPackDuration?: number | null;
  estimatedUnpackDuration?: number | null;
  estimatedOrderDuration?: number | null;
  minimumOrderCost?: number | null;
  estimatedOrderCost?: number | null;
  cuftSource: Moving__QuoteCuftSourceEnum;
  estimatedCuft: number;
  laborRate?: { __typename?: 'LaborRate'; id: string; amount: number; name: string } | null;
  movingConfiguration?: {
    __typename?: 'MovingConfiguration';
    id: string;
    requiredLaborDuration: number;
    travelRateMultiplier: number;
  } | null;
};

export type OrderFragment = {
  __typename?: 'Order';
  id: string;
  documentHubURL?: string | null;
  number: string;
  scheduled: string;
  window: string;
  type: OrderTypeEnum;
  subtype: OrderSubtypeEnum;
  status: OrderStatusEnum;
  serviceType?: OrderServiceTypeEnum | null;
};

export type PaginationFragment = {
  __typename?: 'Pagination';
  currentPage: number;
  nextPage?: number | null;
  prevPage?: number | null;
  totalPages: number;
  totalCount: number;
};

export type Partnerships__PartnerFragment = { __typename?: 'Partnerships__Partner'; id: string; name: string };

export type PickupEstimateFragment = {
  __typename?: 'PickupEstimate';
  lowestEstimatedItems?: number | null;
  highestEstimatedItems?: number | null;
};

export type PickupInventoryFragment = {
  __typename?: 'OrderService';
  isFullMoveOut?: boolean | null;
  needsPackingHelp?: boolean | null;
  requestedMovers?: number | null;
  estimatedItems: Array<{
    __typename?: 'EstimatedItem';
    id: string;
    categoryId: string;
    quantity: number;
    categoryDisplayName?: string | null;
    notes?: string | null;
    length?: number | null;
    width?: number | null;
    height?: number | null;
    cuft?: number | null;
    estimatedMovers?: number | null;
  }>;
  rooms: Array<{ __typename?: 'RoomSelection'; id: string; selected: boolean; quantity: number; categoryID: string }>;
  estimations: Array<{
    __typename?: 'EstimationSelection';
    id: string;
    selected: boolean;
    quantity: number;
    categoryID: string;
  }>;
};

export type AccountPackageFragment = {
  __typename?: 'AccountPackage';
  id: string;
  name: string;
  amount: number;
  kind: PackageKindEnum;
};

export type LaborPolicyFragment = {
  __typename?: 'Pricing__LaborPolicy';
  id: string;
  laborBillingFormat: Pricing__LaborBillingFormat;
  perItemPricing: boolean;
  perMoverHour: boolean;
  laborRate: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string };
};

export type LaborRateFragment = { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string };

export type PerItemFeeFragment = {
  __typename?: 'Pricing__PerItemFee';
  id: string;
  amount: number;
  orderType: OrderTypeEnum;
  orderSubtype: OrderSubtypeEnum;
};

type Plan_Pricing__ProtectionPlan_Fragment = {
  __typename?: 'Pricing__ProtectionPlan';
  id: string;
  name: string;
  classification?: Pricing__Plan__ProtectionClassification | null;
  maxCovered: number;
};

type Plan_Pricing__StoragePlan_Fragment = {
  __typename?: 'Pricing__StoragePlan';
  id: string;
  cuft: number;
  name: string;
  type: Pricing__Plan__Type;
  dimensions?: {
    __typename?: 'Pricing__Plan__Dimensions';
    length: number;
    width: number;
    height?: number | null;
  } | null;
};

export type PlanFragment = Plan_Pricing__ProtectionPlan_Fragment | Plan_Pricing__StoragePlan_Fragment;

export type DimensionsFragment = {
  __typename?: 'Pricing__Plan__Dimensions';
  length: number;
  width: number;
  height?: number | null;
};

export type Pricing__PolicyNoticeInfoFragment = {
  __typename?: 'Pricing__PolicyNoticeInfo';
  id: string;
  subsequentPickupRate: string;
  subsequentReturnRate: string;
  facilityAppointmentCostPerCuft: string;
  disposalDonationCostPerCuft: string;
};

export type PriceFragment = {
  __typename?: 'Pricing__Price';
  id: string;
  amount: number;
  plan:
    | {
        __typename?: 'Pricing__ProtectionPlan';
        id: string;
        name: string;
        classification?: Pricing__Plan__ProtectionClassification | null;
        maxCovered: number;
      }
    | {
        __typename?: 'Pricing__StoragePlan';
        id: string;
        cuft: number;
        name: string;
        type: Pricing__Plan__Type;
        dimensions?: {
          __typename?: 'Pricing__Plan__Dimensions';
          length: number;
          width: number;
          height?: number | null;
        } | null;
      };
};

export type ProtectionPlanFragment = {
  __typename?: 'Pricing__ProtectionPlan';
  id: string;
  name: string;
  classification?: Pricing__Plan__ProtectionClassification | null;
  maxCovered: number;
};

export type RateGroupFragment = {
  __typename?: 'Pricing__RateGroup';
  id: string;
  commitmentType?: Pricing__RateGroup__Commitment | null;
  serviceType?: Pricing__RateGroup__OrderService | null;
};

export type PricingSetLaborEntryFragment = {
  __typename?: 'Pricing__Set__LaborEntry';
  id: string;
  laborPolicy: {
    __typename?: 'Pricing__LaborPolicy';
    id: string;
    laborBillingFormat: Pricing__LaborBillingFormat;
    perItemPricing: boolean;
    perMoverHour: boolean;
    laborRate: { __typename?: 'Pricing__LaborRate'; id: string; amount: number; name: string };
  };
};

export type PricingSetPackageSetEntryFragment = {
  __typename?: 'Pricing__Set__PackageSetEntry';
  id: string;
  orderSubtype: string;
  amount: number;
};

export type PricingSetPriceEntryFragment = {
  __typename?: 'Pricing__Set__PriceEntry';
  id: string;
  rateGroup?: {
    __typename?: 'Pricing__RateGroup';
    id: string;
    commitmentType?: Pricing__RateGroup__Commitment | null;
    serviceType?: Pricing__RateGroup__OrderService | null;
  } | null;
  price: {
    __typename?: 'Pricing__Price';
    id: string;
    amount: number;
    plan:
      | {
          __typename?: 'Pricing__ProtectionPlan';
          id: string;
          name: string;
          classification?: Pricing__Plan__ProtectionClassification | null;
          maxCovered: number;
        }
      | {
          __typename?: 'Pricing__StoragePlan';
          id: string;
          cuft: number;
          name: string;
          type: Pricing__Plan__Type;
          dimensions?: {
            __typename?: 'Pricing__Plan__Dimensions';
            length: number;
            width: number;
            height?: number | null;
          } | null;
        };
  };
  storageTerm?: { __typename?: 'Pricing__StorageTerm'; id: string; name: string; minimumMonths: number } | null;
};

export type StoragePlanFragment = {
  __typename?: 'Pricing__StoragePlan';
  id: string;
  cuft: number;
  name: string;
  type: Pricing__Plan__Type;
  dimensions?: {
    __typename?: 'Pricing__Plan__Dimensions';
    length: number;
    width: number;
    height?: number | null;
  } | null;
};

export type StorageTermFragment = {
  __typename?: 'Pricing__StorageTerm';
  id: string;
  name: string;
  minimumMonths: number;
};

export type RegionFragment = { __typename?: 'Region'; id: string; name: string; tz: string };

export type RetailOrderFragment = { __typename?: 'Order'; id: string; state: OrderStateEnum; scheduled: string };

export type RetentionOfferFragment = {
  __typename?: 'Retention__Offer';
  id: string;
  detail: {
    __typename?: 'Retention__OfferDetail';
    id: string;
    type: Retention__OfferDetail__Type;
    waiveRateAdjustment: boolean;
    action: {
      __typename?: 'Retention__OfferAction';
      id: string;
      discountAmount?: number | null;
      discountPercent?: number | null;
      discountDuration?: string | null;
    };
  };
};

export type ReturnInventoryFragment = {
  __typename?: 'OrderService';
  items: Array<{
    __typename: 'Item';
    id: string;
    uuid: string;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    shippingEligible: boolean;
    shipment?: { __typename?: 'Shipment'; id: string; trackingUrl: string } | null;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
};

export type RoomCategoryFragment = {
  __typename?: 'RoomCategory';
  id: string;
  name: string;
  countable: boolean;
  cuft: number;
};

export type SelfStorage__AdjustedRateRentalFragment = {
  __typename?: 'SelfStorage__AdjustedRateRental';
  id: string;
  discount: number;
  subscriptionItems: Array<{
    __typename?: 'SelfStorage__UpcomingSubscriptionItem';
    id: string;
    summary: string;
    rate: number;
    kind: SelfStorage__SubscriptionItem__Kind;
  }>;
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    name: string;
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      width: number;
      length: number;
      kind: string;
    };
  };
};

export type SelfStorage__FacilityFragment = {
  __typename?: 'SelfStorage__Facility';
  id: string;
  name: string;
  officeHours: Array<string>;
  address: { __typename?: 'Address'; street: string; city: string; state: string; zip: string };
};

export type SelfStorage__RentalFragment = {
  __typename?: 'SelfStorage__Rental';
  id: string;
  subscriptionItems: Array<{
    __typename?: 'SelfStorage__SubscriptionItem';
    id: string;
    summary: string;
    rate: number;
    kind: SelfStorage__SubscriptionItem__Kind;
  }>;
  unit: {
    __typename?: 'SelfStorage__Unit';
    id: string;
    name: string;
    classification: {
      __typename?: 'SelfStorage__Classification';
      id: string;
      width: number;
      length: number;
      kind: string;
    };
  };
};

export type SelfStorage__SubscriptionFragment = {
  __typename?: 'SelfStorage__Subscription';
  id: string;
  billing: SelfStorage__Subscription__Billing;
  billsOn?: string | null;
  facility: {
    __typename?: 'SelfStorage__Facility';
    id: string;
    name: string;
    officeHours: Array<string>;
    address: { __typename?: 'Address'; street: string; city: string; state: string; zip: string };
  };
  rentals: Array<{
    __typename?: 'SelfStorage__Rental';
    id: string;
    subscriptionItems: Array<{
      __typename?: 'SelfStorage__SubscriptionItem';
      id: string;
      summary: string;
      rate: number;
      kind: SelfStorage__SubscriptionItem__Kind;
    }>;
    unit: {
      __typename?: 'SelfStorage__Unit';
      id: string;
      name: string;
      classification: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        width: number;
        length: number;
        kind: string;
      };
    };
  }>;
  source?: {
    __typename?: 'Billing__Source';
    id: string;
    number: string;
    brand?: string | null;
    name?: string | null;
    expiration?: string | null;
    expired: boolean;
    default: boolean;
    fundingType: Billing__Source__FundingType;
  } | null;
};

export type SelfStorage__SubscriptionItemFragment = {
  __typename?: 'SelfStorage__SubscriptionItem';
  id: string;
  summary: string;
  rate: number;
  kind: SelfStorage__SubscriptionItem__Kind;
};

export type SelfStorage__UnitFragment = {
  __typename?: 'SelfStorage__Unit';
  id: string;
  name: string;
  classification: {
    __typename?: 'SelfStorage__Classification';
    id: string;
    width: number;
    length: number;
    kind: string;
  };
};

export type SelfStorage__UpcomingSubscriptionFragment = {
  __typename?: 'SelfStorage__UpcomingSubscription';
  id: string;
  billing: SelfStorage__Subscription__Billing;
  billsOn?: string | null;
  facility: {
    __typename?: 'SelfStorage__Facility';
    id: string;
    name: string;
    officeHours: Array<string>;
    address: { __typename?: 'Address'; street: string; city: string; state: string; zip: string };
  };
  rentals: Array<{
    __typename?: 'SelfStorage__AdjustedRateRental';
    id: string;
    discount: number;
    subscriptionItems: Array<{
      __typename?: 'SelfStorage__UpcomingSubscriptionItem';
      id: string;
      summary: string;
      rate: number;
      kind: SelfStorage__SubscriptionItem__Kind;
    }>;
    unit: {
      __typename?: 'SelfStorage__Unit';
      id: string;
      name: string;
      classification: {
        __typename?: 'SelfStorage__Classification';
        id: string;
        width: number;
        length: number;
        kind: string;
      };
    };
  }>;
  source?: {
    __typename?: 'Billing__Source';
    id: string;
    number: string;
    brand?: string | null;
    name?: string | null;
    expiration?: string | null;
    expired: boolean;
    default: boolean;
    fundingType: Billing__Source__FundingType;
  } | null;
};

export type SelfStorage__UpcomingSubscriptionItemFragment = {
  __typename?: 'SelfStorage__UpcomingSubscriptionItem';
  id: string;
  summary: string;
  rate: number;
  kind: SelfStorage__SubscriptionItem__Kind;
};

export type Shipping__EasyPostRateFragment = {
  __typename?: 'Shipping__EasyPostRate';
  id: string;
  mode: string;
  rate: number;
  currency: string;
  service: string;
  carrier: string;
  deliveryDays?: number | null;
  deliveryDate?: string | null;
};

export type SignatureRequestFragment = {
  __typename?: 'SignatureRequest';
  id: string;
  kind: SignatureRequestKindEnum;
  url?: string | null;
  signedAt?: string | null;
};

export type SourceFragment = {
  __typename?: 'Billing__Source';
  id: string;
  number: string;
  brand?: string | null;
  name?: string | null;
  expiration?: string | null;
  expired: boolean;
  default: boolean;
  fundingType: Billing__Source__FundingType;
};

export type StorageTreasuresAuctionFragment = {
  __typename?: 'StorageTreasures__AuctionType';
  id: string;
  endTime: string;
  storageTreasuresLink: string;
  items: Array<{
    __typename: 'Item';
    id: string;
    uuid: string;
    cuft?: number | null;
    hero: number;
    name?: string | null;
    contents?: string | null;
    selectableForReturn: boolean;
    shippingEligible: boolean;
    category?: { __typename?: 'ItemCategory'; name: string } | null;
    barcode: { __typename?: 'Barcode'; id: string; value: string };
    images: Array<{ __typename?: 'Image'; id: string; source: string; state: ImageState }>;
  }>;
  warehouse: {
    __typename?: 'Warehouse';
    id: string;
    accessHours: Array<string>;
    address: {
      __typename?: 'Address';
      id: string;
      street: string;
      aptsuite?: string | null;
      city: string;
      state: string;
      zip: string;
      formatted: string;
      formattedWithoutApartmentNumber: string;
      latitude?: number | null;
      longitude?: number | null;
      serviceable: boolean;
      businessName?: string | null;
      outsideServiceArea: boolean;
      stateAbbreviation: string;
      details?: {
        __typename?: 'AddressDetail';
        buildingType?: string | null;
        buildingSubtype?: string | null;
        floor?: number | null;
        stories?: number | null;
        frontDoorSteps?: number | null;
        stairs: boolean;
        squareFeet?: number | null;
        elevator: boolean;
        paperwork: boolean;
        gated: boolean;
        steep: boolean;
        serviceElevator: boolean;
        serviceEntrance: boolean;
        loadingDock: boolean;
        code: boolean;
        codeValue?: string | null;
        parkingStreet: boolean;
        parkingDriveway: boolean;
        parkingLot: boolean;
        parkingAlley: boolean;
        parkingPermit: boolean;
        parkingInFront: boolean;
        parkingOnSpecificStreet?: string | null;
        parkingInstructions?: string | null;
        maxTruckHeight?: number | null;
        maxTruckHeightUnknown: boolean;
        accessUnknown: boolean;
        paperworkUnknown: boolean;
        parkingUnknown: boolean;
        environment?: AddressDetailEnvironmentEnum | null;
        additionalNotes?: string | null;
        buildingOpeningHour?: number | null;
        buildingClosingHour?: number | null;
        buildingRestrictionsAnswer?: AddressDetailBuildingRestrictionsAnswer | null;
        parkingLatitude?: number | null;
        parkingLongitude?: number | null;
        secureLocationDetails?: string | null;
        longCarry: boolean;
        unitSize?: AddressDetailUnitSizeEnum | null;
      } | null;
      region?: { __typename?: 'Region'; id: string; name: string; tz: string } | null;
    };
  };
  winnerOrder?: { __typename?: 'Order'; id: string; state: OrderStateEnum } | null;
};

export type SubscriptionFragment = {
  __typename?: 'Subscription';
  id: string;
  name: string;
  cubicFootage?: number | null;
  quantity: number;
  discount: number;
  tax: number;
  total: number;
};

export type TermCommitmentFragment = {
  __typename?: 'Account__TermCommitment';
  term: number;
  contractEndDate?: string | null;
  type: Account__TermCommitment__Type;
  fulfilled: boolean;
};

export type UpcomingSubscriptionFragment = {
  __typename?: 'UpcomingSubscription';
  id: string;
  name: string;
  cubicFootage?: number | null;
  quantity: number;
  discount: number;
  tax: number;
  total: number;
};

export type UpcomingSubscriptionSetFragment = {
  __typename?: 'UpcomingSubscriptionSet';
  nextPeriodEnd?: string | null;
  storageSubscriptions: Array<{
    __typename?: 'UpcomingSubscription';
    id: string;
    name: string;
    cubicFootage?: number | null;
    quantity: number;
    discount: number;
    tax: number;
    total: number;
    pricing: {
      __typename?: 'Pricing__Price';
      id: string;
      plan:
        | {
            __typename?: 'Pricing__ProtectionPlan';
            id: string;
            name: string;
            classification?: Pricing__Plan__ProtectionClassification | null;
            maxCovered: number;
          }
        | {
            __typename?: 'Pricing__StoragePlan';
            id: string;
            cuft: number;
            name: string;
            type: Pricing__Plan__Type;
            dimensions?: {
              __typename?: 'Pricing__Plan__Dimensions';
              length: number;
              width: number;
              height?: number | null;
            } | null;
          };
    };
  }>;
  protectionSubscription?: {
    __typename?: 'UpcomingSubscription';
    id: string;
    name: string;
    cubicFootage?: number | null;
    quantity: number;
    discount: number;
    tax: number;
    total: number;
    pricing: {
      __typename?: 'Pricing__Price';
      id: string;
      plan:
        | {
            __typename?: 'Pricing__ProtectionPlan';
            id: string;
            name: string;
            classification?: Pricing__Plan__ProtectionClassification | null;
            maxCovered: number;
          }
        | {
            __typename?: 'Pricing__StoragePlan';
            id: string;
            cuft: number;
            name: string;
            type: Pricing__Plan__Type;
            dimensions?: {
              __typename?: 'Pricing__Plan__Dimensions';
              length: number;
              width: number;
              height?: number | null;
            } | null;
          };
    };
  } | null;
};

export type UpgradeDowngrade__EntryFragment = {
  __typename?: 'UpgradeDowngradeEntry';
  total: number;
  quantity: number;
  price: {
    __typename?: 'Pricing__Price';
    id: string;
    amount: number;
    plan:
      | {
          __typename?: 'Pricing__ProtectionPlan';
          id: string;
          name: string;
          classification?: Pricing__Plan__ProtectionClassification | null;
          maxCovered: number;
        }
      | {
          __typename?: 'Pricing__StoragePlan';
          id: string;
          cuft: number;
          name: string;
          type: Pricing__Plan__Type;
          dimensions?: {
            __typename?: 'Pricing__Plan__Dimensions';
            length: number;
            width: number;
            height?: number | null;
          } | null;
        };
  };
};

export type UpgradeDowngrade__SummaryFragment = {
  __typename?: 'UpgradeDowngradeSummary';
  savings: number;
  current: Array<{
    __typename?: 'UpgradeDowngradeEntry';
    total: number;
    quantity: number;
    price: {
      __typename?: 'Pricing__Price';
      id: string;
      amount: number;
      plan:
        | {
            __typename?: 'Pricing__ProtectionPlan';
            id: string;
            name: string;
            classification?: Pricing__Plan__ProtectionClassification | null;
            maxCovered: number;
          }
        | {
            __typename?: 'Pricing__StoragePlan';
            id: string;
            cuft: number;
            name: string;
            type: Pricing__Plan__Type;
            dimensions?: {
              __typename?: 'Pricing__Plan__Dimensions';
              length: number;
              width: number;
              height?: number | null;
            } | null;
          };
    };
  }>;
  proposed: Array<{
    __typename?: 'UpgradeDowngradeEntry';
    total: number;
    quantity: number;
    price: {
      __typename?: 'Pricing__Price';
      id: string;
      amount: number;
      plan:
        | {
            __typename?: 'Pricing__ProtectionPlan';
            id: string;
            name: string;
            classification?: Pricing__Plan__ProtectionClassification | null;
            maxCovered: number;
          }
        | {
            __typename?: 'Pricing__StoragePlan';
            id: string;
            cuft: number;
            name: string;
            type: Pricing__Plan__Type;
            dimensions?: {
              __typename?: 'Pricing__Plan__Dimensions';
              length: number;
              width: number;
              height?: number | null;
            } | null;
          };
    };
  }>;
};

export type UsageFragment = { __typename?: 'Usage'; used: number; total: number; kind: Usage__Kind };

export type UserFragment = { __typename?: 'User'; id: string; name: string };

export type OrderClassifyQueryVariables = Exact<{
  orderID?: InputMaybe<Scalars['ID']>;
  cancelable: Scalars['Boolean'];
  type?: InputMaybe<OrderTypeEnum>;
}>;

export type OrderClassifyQuery = {
  __typename?: 'Query';
  classify?: { __typename?: 'Order'; type: OrderTypeEnum; subtype: OrderSubtypeEnum } | null;
};

export type ZendeskCopyQueryVariables = Exact<{
  articleName: Zendesk__ArticleName;
}>;

export type ZendeskCopyQuery = { __typename?: 'Query'; zendeskCopy?: string | null };

export const LaborRateFragmentDoc = gql`
  fragment laborRate on Pricing__LaborRate {
    id
    amount
    name
  }
`;
export const RegionFragmentDoc = gql`
  fragment region on Region {
    id
    name
    tz
  }
`;
export const RateGroupFragmentDoc = gql`
  fragment rateGroup on Pricing__RateGroup {
    id
    commitmentType
    serviceType
  }
`;
export const LaborCostDetailsFragmentDoc = gql`
  fragment laborCostDetails on LaborCostDetails {
    id
    formattedCost
    discountTierDescription
    appointmentFeeCost
  }
`;
export const UpcomingSubscriptionFragmentDoc = gql`
  fragment upcomingSubscription on UpcomingSubscription {
    id
    name
    cubicFootage
    quantity
    discount
    tax
    total
  }
`;
export const ProtectionPlanFragmentDoc = gql`
  fragment protectionPlan on Pricing__ProtectionPlan {
    id
    name
    classification
    maxCovered
  }
`;
export const DimensionsFragmentDoc = gql`
  fragment dimensions on Pricing__Plan__Dimensions {
    length
    width
    height
  }
`;
export const StoragePlanFragmentDoc = gql`
  fragment storagePlan on Pricing__StoragePlan {
    id
    cuft
    name
    type
    dimensions {
      ...dimensions
    }
  }
  ${DimensionsFragmentDoc}
`;
export const PlanFragmentDoc = gql`
  fragment plan on Pricing__Plan {
    ... on Pricing__ProtectionPlan {
      ...protectionPlan
    }
    ... on Pricing__StoragePlan {
      ...storagePlan
    }
  }
  ${ProtectionPlanFragmentDoc}
  ${StoragePlanFragmentDoc}
`;
export const UpcomingSubscriptionDetailsFragmentDoc = gql`
  fragment upcomingSubscriptionDetails on UpcomingSubscription {
    ...upcomingSubscription
    pricing {
      id
      plan {
        ...plan
      }
    }
  }
  ${UpcomingSubscriptionFragmentDoc}
  ${PlanFragmentDoc}
`;
export const UpcomingSubscriptionSetFragmentDoc = gql`
  fragment upcomingSubscriptionSet on UpcomingSubscriptionSet {
    storageSubscriptions {
      ...upcomingSubscriptionDetails
    }
    protectionSubscription {
      ...upcomingSubscriptionDetails
    }
    nextPeriodEnd
  }
  ${UpcomingSubscriptionDetailsFragmentDoc}
`;
export const SelfStorage__FacilityFragmentDoc = gql`
  fragment SelfStorage__Facility on SelfStorage__Facility {
    id
    name
    officeHours
    address {
      street
      city
      state
      zip
    }
  }
`;
export const SelfStorage__UpcomingSubscriptionItemFragmentDoc = gql`
  fragment SelfStorage__UpcomingSubscriptionItem on SelfStorage__UpcomingSubscriptionItem {
    id
    summary
    rate
    kind
  }
`;
export const SelfStorage__UnitFragmentDoc = gql`
  fragment SelfStorage__Unit on SelfStorage__Unit {
    id
    name
    classification {
      id
      width
      length
      kind
    }
  }
`;
export const SelfStorage__AdjustedRateRentalFragmentDoc = gql`
  fragment SelfStorage__AdjustedRateRental on SelfStorage__AdjustedRateRental {
    id
    discount
    subscriptionItems {
      ...SelfStorage__UpcomingSubscriptionItem
    }
    unit {
      ...SelfStorage__Unit
    }
  }
  ${SelfStorage__UpcomingSubscriptionItemFragmentDoc}
  ${SelfStorage__UnitFragmentDoc}
`;
export const SourceFragmentDoc = gql`
  fragment source on Billing__Source {
    id
    number
    brand
    name
    expiration
    expired
    default
    fundingType
  }
`;
export const SelfStorage__UpcomingSubscriptionFragmentDoc = gql`
  fragment SelfStorage__UpcomingSubscription on SelfStorage__UpcomingSubscription {
    id
    billing
    billsOn
    facility {
      ...SelfStorage__Facility
    }
    rentals {
      ...SelfStorage__AdjustedRateRental
    }
    source: defaultSource {
      ...source
    }
  }
  ${SelfStorage__FacilityFragmentDoc}
  ${SelfStorage__AdjustedRateRentalFragmentDoc}
  ${SourceFragmentDoc}
`;
export const BarcodeFragmentDoc = gql`
  fragment barcode on Barcode {
    id
    value
  }
`;
export const ImageFragmentDoc = gql`
  fragment image on Image {
    id
    source
    state
  }
`;
export const ItemFragmentDoc = gql`
  fragment item on Item {
    __typename
    id
    uuid
    hero
    name
    contents
    selectableForReturn
    shippingEligible
    barcode {
      ...barcode
    }
    images {
      ...image
    }
  }
  ${BarcodeFragmentDoc}
  ${ImageFragmentDoc}
`;
export const InventoryItemFragmentDoc = gql`
  fragment inventoryItem on Item {
    __typename
    ...item
    cuft
    category {
      name
    }
  }
  ${ItemFragmentDoc}
`;
export const AddressFragmentDoc = gql`
  fragment address on Address {
    id
    street
    aptsuite
    city
    state
    zip
    formatted
    formattedWithoutApartmentNumber
    latitude
    longitude
    serviceable
    businessName
    outsideServiceArea
    stateAbbreviation
  }
`;
export const DetailsFragmentDoc = gql`
  fragment details on AddressDetail {
    buildingType
    buildingSubtype
    floor
    stories
    frontDoorSteps
    stairs
    squareFeet
    elevator
    paperwork
    gated
    steep
    serviceElevator
    serviceEntrance
    loadingDock
    code
    codeValue
    parkingStreet
    parkingDriveway
    parkingLot
    parkingAlley
    parkingPermit
    parkingInFront
    parkingOnSpecificStreet
    parkingInstructions
    maxTruckHeight
    maxTruckHeightUnknown
    accessUnknown
    paperworkUnknown
    parkingUnknown
    environment
    additionalNotes
    buildingOpeningHour
    buildingClosingHour
    buildingRestrictionsAnswer
    parkingLatitude
    parkingLongitude
    secureLocationDetails
    longCarry
    unitSize
  }
`;
export const AddressWithDetailsFragmentDoc = gql`
  fragment addressWithDetails on Address {
    ...address
    details {
      ...details
    }
    region {
      ...region
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
  ${RegionFragmentDoc}
`;
export const FacilityFragmentDoc = gql`
  fragment facility on Facility {
    ... on Warehouse {
      id
      address {
        ...addressWithDetails
      }
      accessHours
    }
  }
  ${AddressWithDetailsFragmentDoc}
`;
export const StorageTreasuresAuctionFragmentDoc = gql`
  fragment storageTreasuresAuction on StorageTreasures__AuctionType {
    id
    endTime
    items {
      id
      uuid
      ...inventoryItem
    }
    storageTreasuresLink
    warehouse {
      id
      ...facility
    }
    winnerOrder {
      id
      state
    }
  }
  ${InventoryItemFragmentDoc}
  ${FacilityFragmentDoc}
`;
export const AccountDetailFragmentDoc = gql`
  fragment accountDetail on Account {
    id
    state
    onboardable
    contractStartDate
    perItemPricing
    requestableItemsExist
    laborRate {
      ...laborRate
    }
    region {
      ...region
    }
    rateGroup {
      ...rateGroup
    }
    onboardingCostDetails {
      ...laborCostDetails
    }
    subsequentPickupCostDetails {
      ...laborCostDetails
    }
    subsequentReturnCostDetails {
      ...laborCostDetails
    }
    finalOrderCostDetails {
      ...laborCostDetails
    }
    upcomingSubscriptionSet {
      ...upcomingSubscriptionSet
    }
    termCommitment {
      term
    }
    upcomingSelfStorageSubscriptions {
      ...SelfStorage__UpcomingSubscription
    }
    unscheduledStorageTreasuresAuctionsWon {
      ...storageTreasuresAuction
    }
  }
  ${LaborRateFragmentDoc}
  ${RegionFragmentDoc}
  ${RateGroupFragmentDoc}
  ${LaborCostDetailsFragmentDoc}
  ${UpcomingSubscriptionSetFragmentDoc}
  ${SelfStorage__UpcomingSubscriptionFragmentDoc}
  ${StorageTreasuresAuctionFragmentDoc}
`;
export const UpcomingStorageOnboardingFragmentDoc = gql`
  fragment upcomingStorageOnboarding on Order {
    id
    parent {
      id
      laborCostDetails {
        ...laborCostDetails
      }
      type
    }
    serviceType
    started
    tags
  }
  ${LaborCostDetailsFragmentDoc}
`;
export const DisplaySourceFragmentDoc = gql`
  fragment displaySource on Billing__Source {
    id
    brand
    number
  }
`;
export const Partnerships__PartnerFragmentDoc = gql`
  fragment Partnerships__Partner on Partnerships__Partner {
    id
    name
  }
`;
export const InvoiceableOrderFragmentDoc = gql`
  fragment invoiceableOrder on Order {
    id
    parentID
    serviceType
    type
    bookingPartner {
      ...Partnerships__Partner
    }
  }
  ${Partnerships__PartnerFragmentDoc}
`;
export const Billing__InvoiceableFragmentDoc = gql`
  fragment Billing__Invoiceable on Billing__Invoiceable {
    ... on Order {
      ...invoiceableOrder
    }
    ... on UpcomingSubscription {
      id
      dimensions {
        ...dimensions
      }
      pricing {
        id
        plan {
          ... on Pricing__ProtectionPlan {
            id
            name
          }
          ... on Pricing__StoragePlan {
            id
            name
          }
        }
      }
    }
    ... on SelfStorage__AdjustedRateRental {
      id
      unit {
        ...SelfStorage__Unit
        facility {
          id
          name
        }
      }
    }
  }
  ${InvoiceableOrderFragmentDoc}
  ${DimensionsFragmentDoc}
  ${SelfStorage__UnitFragmentDoc}
`;
export const Billing__UpcomingInvoiceFragmentDoc = gql`
  fragment Billing__UpcomingInvoice on Billing__UpcomingInvoice {
    estimatedAmount
    billDate
    invoiceable {
      ...Billing__Invoiceable
    }
  }
  ${Billing__InvoiceableFragmentDoc}
`;
export const ClaimFragmentDoc = gql`
  fragment claim on Claim {
    id
    uuid
    status
    step
    hasIssue
    hasLostItem
    hasDamagedItem
    hasDamagedProperty
    openedAt
    closedAt
  }
`;
export const AttachmentFragmentDoc = gql`
  fragment attachment on ActiveStorageAttachment {
    id
    imgixURL
    filename
  }
`;
export const ClaimIssueSelectionFragmentDoc = gql`
  fragment claimIssueSelection on ClaimIssueSelection {
    id
    compensationRequested
    declaration
    description
    kind
    photos {
      ...attachment
    }
  }
  ${AttachmentFragmentDoc}
`;
export const ClaimItemSelectionFragmentDoc = gql`
  fragment claimItemSelection on ClaimItemSelection {
    id
    kind
    tracked
    name
    declaration
    productURL
    photos {
      ...attachment
    }
    receipts {
      ...attachment
    }
    item {
      ...item
    }
  }
  ${AttachmentFragmentDoc}
  ${ItemFragmentDoc}
`;
export const ClaimPropertySelectionFragmentDoc = gql`
  fragment claimPropertySelection on ClaimPropertySelection {
    id
    name
    declaration
    ownership
    owner {
      name
      email
      phone
    }
    photos {
      ...attachment
    }
    quotes {
      ...attachment
    }
  }
  ${AttachmentFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    id
    name
  }
`;
export const ClaimOfferFragmentDoc = gql`
  fragment claimOffer on ClaimOffer {
    id
    details
    createdAt
    expiry
    totalAmount
    user {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const ClaimPaymentOptionFragmentDoc = gql`
  fragment claimPaymentOption on ClaimPaymentOption {
    kind
    name
    email
    address {
      ...address
    }
  }
  ${AddressFragmentDoc}
`;
export const DetailedClaimFragmentDoc = gql`
  fragment detailedClaim on Claim {
    ...claim
    issueSelection {
      ...claimIssueSelection
    }
    itemSelections {
      ...claimItemSelection
    }
    propertySelection {
      ...claimPropertySelection
    }
    offer {
      ...claimOffer
    }
    paymentOption {
      ...claimPaymentOption
    }
    settlementAgreement {
      id
      url
    }
  }
  ${ClaimFragmentDoc}
  ${ClaimIssueSelectionFragmentDoc}
  ${ClaimItemSelectionFragmentDoc}
  ${ClaimPropertySelectionFragmentDoc}
  ${ClaimOfferFragmentDoc}
  ${ClaimPaymentOptionFragmentDoc}
`;
export const Billing__OrderFragmentDoc = gql`
  fragment Billing__Order on Order {
    id
    number
    scheduled
    bookingPartner {
      ...Partnerships__Partner
    }
  }
  ${Partnerships__PartnerFragmentDoc}
`;
export const Billing__ChargeFragmentDoc = gql`
  fragment Billing__Charge on Billing__Charge {
    id
    amount
    date
    status
    source {
      id
      brand
      number
    }
  }
`;
export const Billing__RefundFragmentDoc = gql`
  fragment Billing__Refund on Billing__Refund {
    id
    date
    amount
    source {
      brand
      number
    }
  }
`;
export const Billing__NestedInvoiceFragmentDoc = gql`
  fragment Billing__NestedInvoice on Billing__Invoice {
    id
    total
    summary
    paidAt
    amountDue
    charges {
      ...Billing__Charge
    }
    refunds {
      ...Billing__Refund
    }
  }
  ${Billing__ChargeFragmentDoc}
  ${Billing__RefundFragmentDoc}
`;
export const Billing__InvoiceFragmentDoc = gql`
  fragment Billing__Invoice on Billing__Invoice {
    id
    date
    amountDue
    total
    displayStatus
    forgiven
    forgivenessReasonKind
    refundedAt
    paidAt
    failedAt
    displayName
    order {
      ...Billing__Order
    }
    lumpSumInvoice {
      ...Billing__NestedInvoice
    }
  }
  ${Billing__OrderFragmentDoc}
  ${Billing__NestedInvoiceFragmentDoc}
`;
export const Billing__InvoiceItemFragmentDoc = gql`
  fragment Billing__InvoiceItem on Billing__InvoiceItem {
    id
    type
    amount
    description
    displayDescription
    displayName
    quantity
    unitPrice
  }
`;
export const Billing__OfflinePaymentFragmentDoc = gql`
  fragment Billing__OfflinePayment on Billing__OfflinePayment {
    id
    date
    amount
    kind
    summary
  }
`;
export const InvoiceDetailFragmentDoc = gql`
  fragment InvoiceDetail on Billing__Invoice {
    ...Billing__Invoice
    displayID
    stripeCreatedAt
    amountOwed
    appliedCredits
    discountAmount
    subtotal
    tax
    lines
    invoiceItems {
      ...Billing__InvoiceItem
    }
    subscription {
      id
    }
    selfStorageSubscription {
      id
    }
    charges {
      ...Billing__Charge
    }
    offlinePayments {
      ...Billing__OfflinePayment
    }
    refunds {
      ...Billing__Refund
    }
    settledInvoices {
      ...Billing__NestedInvoice
    }
    rebillable
    swapPaymentInvoices {
      ...Billing__NestedInvoice
    }
  }
  ${Billing__InvoiceFragmentDoc}
  ${Billing__InvoiceItemFragmentDoc}
  ${Billing__ChargeFragmentDoc}
  ${Billing__OfflinePaymentFragmentDoc}
  ${Billing__RefundFragmentDoc}
  ${Billing__NestedInvoiceFragmentDoc}
`;
export const InventoryListItemFragmentDoc = gql`
  fragment inventoryListItem on Item {
    __typename
    id
    uuid
    hero
    name
    cuft
    contents
    category {
      name
    }
    pickedUpAt
    returnedAt
    images {
      ...image
    }
    barcode {
      ...barcode
    }
  }
  ${ImageFragmentDoc}
  ${BarcodeFragmentDoc}
`;
export const InventoryListItemGroupFragmentDoc = gql`
  fragment inventoryListItemGroup on ItemGroup {
    __typename
    id
    uuid
    heroImage {
      ...image
    }
    name
    category {
      name
    }
    contents
    cuft
    pickedUpAt
    returnedAt
    items {
      id
      uuid
      images {
        ...image
      }
    }
  }
  ${ImageFragmentDoc}
`;
export const CustomerItemFragmentDoc = gql`
  fragment customerItem on CustomerItem {
    ... on Item {
      ...inventoryListItem
    }
    ... on ItemGroup {
      ...inventoryListItemGroup
    }
  }
  ${InventoryListItemFragmentDoc}
  ${InventoryListItemGroupFragmentDoc}
`;
export const EstimatedItemFragmentDoc = gql`
  fragment estimatedItem on EstimatedItem {
    id
    categoryId
    quantity
    categoryDisplayName
    notes
    length
    width
    height
    cuft
    estimatedMovers
  }
`;
export const PickupInventoryFragmentDoc = gql`
  fragment pickupInventory on OrderService {
    isFullMoveOut
    needsPackingHelp
    requestedMovers
    estimatedItems {
      ...estimatedItem
    }
    rooms {
      id
      selected
      quantity
      categoryID
    }
    estimations {
      id
      selected
      quantity
      categoryID
    }
  }
  ${EstimatedItemFragmentDoc}
`;
export const PickupInventoryOrderServiceFragmentDoc = gql`
  fragment PickupInventoryOrderService on OrderService {
    id
    type
    subtype
    ...pickupInventory
  }
  ${PickupInventoryFragmentDoc}
`;
export const PickupInventoryOrderFragmentDoc = gql`
  fragment PickupInventoryOrder on Order {
    id
    number
    scheduled
    serviceType
    subtype
    services {
      ...PickupInventoryOrderService
    }
    dropOffAppointment {
      id
      expectedAt
    }
    permissions {
      id
      locked
    }
  }
  ${PickupInventoryOrderServiceFragmentDoc}
`;
export const Account__Marketing__CouponFragmentDoc = gql`
  fragment Account__Marketing__Coupon on Coupon {
    id
    amount
    percent
    discountDescription
  }
`;
export const AvailabilityWaitlistRequestFragmentDoc = gql`
  fragment availabilityWaitlistRequest on Availability__WaitlistRequest {
    id
    date
  }
`;
export const RescheduleOrderFragmentDoc = gql`
  fragment RescheduleOrder on Order {
    id
    scheduled
    window
    status
    type
    subtype
    serviceType
    services {
      type
      subtype
    }
    laborRate {
      id
      amount
    }
    address {
      id
    }
    rescheduleOfferCoupon {
      ...Account__Marketing__Coupon
    }
    facilityWarehouse {
      ...facility
    }
    dropOffAppointment {
      id
      expectedAt
      facility {
        id
        address {
          id
        }
      }
    }
    permissions {
      id
      chargeLateFee
      reschedulable
    }
    accountCancelIntent {
      reason
    }
    baseLaborRate {
      ...laborRate
    }
    waitlistRequests {
      ...availabilityWaitlistRequest
    }
    account {
      id
      state
    }
  }
  ${Account__Marketing__CouponFragmentDoc}
  ${FacilityFragmentDoc}
  ${LaborRateFragmentDoc}
  ${AvailabilityWaitlistRequestFragmentDoc}
`;
export const ReturnInventoryFragmentDoc = gql`
  fragment returnInventory on OrderService {
    items {
      ...item
      shipment {
        id
        trackingUrl
      }
    }
  }
  ${ItemFragmentDoc}
`;
export const ReturnInventoryOrderServiceFragmentDoc = gql`
  fragment ReturnInventoryOrderService on OrderService {
    id
    type
    subtype
    ...returnInventory
  }
  ${ReturnInventoryFragmentDoc}
`;
export const ReturnInventoryOrderFragmentDoc = gql`
  fragment ReturnInventoryOrder on Order {
    id
    number
    scheduled
    serviceType
    services {
      ...ReturnInventoryOrderService
    }
    dropOffAppointment {
      id
      expectedAt
    }
  }
  ${ReturnInventoryOrderServiceFragmentDoc}
`;
export const InvoiceFragmentDoc = gql`
  fragment invoice on Billing__Invoice {
    id
    date
    lines
    amountDue
    total
    payable
    status
    summary
  }
`;
export const PaymentPlan__PaymentMethodFragmentDoc = gql`
  fragment PaymentPlan__PaymentMethod on Billing__Source {
    id
    number
    brand
  }
`;
export const PaymentPlanDetail__OfferFragmentDoc = gql`
  fragment PaymentPlanDetail__Offer on PaymentPlan__Offer {
    id
    type
    state
    oneTimeFee
    expiry
    expired
    createdAt
    account {
      customer {
        id
        name
      }
    }
    settledInvoices {
      ...invoice
    }
    paymentMethod {
      ...PaymentPlan__PaymentMethod
    }
    acceptedAt
    storageTermEndDate
    monthlyFee
    monthlyFeeTerm
  }
  ${InvoiceFragmentDoc}
  ${PaymentPlan__PaymentMethodFragmentDoc}
`;
export const InventoryItemGroupFragmentDoc = gql`
  fragment inventoryItemGroup on ItemGroup {
    __typename
    id
    cuft
    uuid
    name
    category {
      name
    }
    heroImage {
      ...image
    }
    items: requestableItems(orderID: $orderID) {
      ...inventoryItem
    }
  }
  ${ImageFragmentDoc}
  ${InventoryItemFragmentDoc}
`;
export const AccountResolution__OfferFragmentDoc = gql`
  fragment AccountResolution__offer on AccountResolution__Offer {
    id
    kind
    metadata
  }
`;
export const AccountResolution__ReasonChildFragmentDoc = gql`
  fragment AccountResolution__reasonChild on AccountResolution__ReasonChild {
    id
    parentID
    position
    copy
    canonicalName
  }
`;
export const AccountResolution__ReasonFragmentDoc = gql`
  fragment AccountResolution__reason on AccountResolution__Reason {
    id
    parentID
    copy
    offerKind
    zendeskCopy
    canonicalName
    children {
      ...AccountResolution__reasonChild
    }
  }
  ${AccountResolution__ReasonChildFragmentDoc}
`;
export const UsageFragmentDoc = gql`
  fragment usage on Usage {
    used
    total
    kind
  }
`;
export const PriceFragmentDoc = gql`
  fragment price on Pricing__Price {
    id
    amount
    plan {
      ...plan
    }
  }
  ${PlanFragmentDoc}
`;
export const UpgradeDowngrade__EntryFragmentDoc = gql`
  fragment UpgradeDowngrade__entry on UpgradeDowngradeEntry {
    total
    quantity
    price {
      ...price
    }
  }
  ${PriceFragmentDoc}
`;
export const UpgradeDowngrade__SummaryFragmentDoc = gql`
  fragment UpgradeDowngrade__summary on UpgradeDowngradeSummary {
    current {
      ...UpgradeDowngrade__entry
    }
    proposed {
      ...UpgradeDowngrade__entry
    }
    savings
  }
  ${UpgradeDowngrade__EntryFragmentDoc}
`;
export const AccountWithUsageAndUpgradeDowngradeSummaryFragmentDoc = gql`
  fragment accountWithUsageAndUpgradeDowngradeSummary on Account {
    id
    usage {
      ...usage
    }
    upcomingSubscriptionSet {
      storageSubscriptions {
        ...upcomingSubscriptionDetails
      }
      protectionSubscription {
        ...upcomingSubscriptionDetails
      }
    }
    summaryForSuggestedUpgradeDowngrade {
      ...UpgradeDowngrade__summary
    }
  }
  ${UsageFragmentDoc}
  ${UpcomingSubscriptionDetailsFragmentDoc}
  ${UpgradeDowngrade__SummaryFragmentDoc}
`;
export const AvailabilityFragmentDoc = gql`
  fragment availability on Availability {
    datetime
    duration
    available
    appointmentFee
    laborRate {
      ...laborRate
    }
    perMoverHourAdjustmentAmount
    waitlistable
    feeAmount {
      formatted
      value
    }
  }
  ${LaborRateFragmentDoc}
`;
export const AvailabilityTierFragmentDoc = gql`
  fragment availabilityTier on AvailabilityTier {
    type
    label
    description
    price
  }
`;
export const ContactFragmentDoc = gql`
  fragment contact on Order__Contact {
    id
    name
    phone
    kind
  }
`;
export const CouponFragmentDoc = gql`
  fragment coupon on Coupon {
    id
    discountDescription
    discountDisclaimer
  }
`;
export const CustomerFragmentDoc = gql`
  fragment customer on Customer {
    id
    name
    phone
  }
`;
export const EstimationEstimatedItemFragmentDoc = gql`
  fragment estimationEstimatedItem on Estimation__EstimatedItemType {
    id
    quantity
    category {
      id
      name
      position
      parent {
        id
        name
      }
    }
  }
`;
export const EstimationUploadFragmentDoc = gql`
  fragment estimationUpload on Estimation__UploadType {
    id
    media {
      id
      imgixURL
      isImage
      isVideo
    }
  }
`;
export const EstimationCategoryFragmentDoc = gql`
  fragment estimationCategory on EstimationCategory {
    id
    name
    packable
    cuft
    numMovers
  }
`;
export const FlowDropOffAppointmentFragmentDoc = gql`
  fragment FlowDropOffAppointment on DropOff__Appointment {
    id
    state
    expectedAt
    status
    direction
    facility {
      id
      hasEmployees
      instructions
      fulfiller {
        ... on SelfStorage__Facility {
          id
          accessCode
        }
        ... on DropOff__ThirdPartySelfStorageFacility {
          id
          accessCode
          operator
        }
      }
      address {
        ...address
      }
    }
    access {
      id
      customerCode
      revealedAt
    }
    storageArrangement {
      ... on SelfStorage__Hold {
        unit {
          id
          name
          directions
        }
      }
      ... on DropOff__ThirdPartySelfStorageUnit {
        id
        name
        directions
      }
    }
    order {
      type
      subtype
    }
  }
  ${AddressFragmentDoc}
`;
export const PickupEstimateFragmentDoc = gql`
  fragment pickupEstimate on PickupEstimate {
    lowestEstimatedItems
    highestEstimatedItems
  }
`;
export const AccountPackageFragmentDoc = gql`
  fragment accountPackage on AccountPackage {
    id
    name
    amount
    kind
  }
`;
export const ItemPricingFragmentDoc = gql`
  fragment itemPricing on ItemPricing {
    pickupPerItemFee
    returnPerItemFee
    pickupLargeItemFee
    returnLargeItemFee
    appointmentFee
    pickupEstimate {
      ...pickupEstimate
    }
    pickupLargeEstimate {
      ...pickupEstimate
    }
    returnedItemsCount
    returnedLargeItemsCount
    price
    accountPackages {
      ...accountPackage
    }
  }
  ${PickupEstimateFragmentDoc}
  ${AccountPackageFragmentDoc}
`;
export const EstimationMovingQuoteFragmentDoc = gql`
  fragment estimationMovingQuote on Moving__Quote {
    id
    eligibility
    reasons
    laborRate {
      id
      amount
      name
    }
    truckCount
    moverSelection
    movingConfiguration {
      id
      requiredLaborDuration
      travelRateMultiplier
    }
    suggestedMoverCount
    deadtimeDuration
    drivetimeDuration
    estimatedPackDuration
    estimatedUnpackDuration
    estimatedOrderDuration
    minimumOrderCost
    estimatedOrderCost
    cuftSource
    estimatedCuft
  }
`;
export const OrderFragmentDoc = gql`
  fragment order on Order {
    id
    documentHubURL
    number
    scheduled
    window
    type
    subtype
    status
    serviceType
  }
`;
export const PaginationFragmentDoc = gql`
  fragment pagination on Pagination {
    currentPage
    nextPage
    prevPage
    totalPages
    totalCount
  }
`;
export const PerItemFeeFragmentDoc = gql`
  fragment perItemFee on Pricing__PerItemFee {
    id
    amount
    orderType
    orderSubtype
  }
`;
export const Pricing__PolicyNoticeInfoFragmentDoc = gql`
  fragment Pricing__policyNoticeInfo on Pricing__PolicyNoticeInfo {
    id
    subsequentPickupRate
    subsequentReturnRate
    facilityAppointmentCostPerCuft
    disposalDonationCostPerCuft
  }
`;
export const LaborPolicyFragmentDoc = gql`
  fragment laborPolicy on Pricing__LaborPolicy {
    id
    laborRate(cuft: $cuft) {
      ...laborRate
    }
    laborBillingFormat
    perItemPricing
    perMoverHour
  }
  ${LaborRateFragmentDoc}
`;
export const PricingSetLaborEntryFragmentDoc = gql`
  fragment pricingSetLaborEntry on Pricing__Set__LaborEntry {
    id
    laborPolicy {
      ...laborPolicy
    }
  }
  ${LaborPolicyFragmentDoc}
`;
export const PricingSetPackageSetEntryFragmentDoc = gql`
  fragment pricingSetPackageSetEntry on Pricing__Set__PackageSetEntry {
    id
    orderSubtype
    amount(cuft: $cuft)
  }
`;
export const StorageTermFragmentDoc = gql`
  fragment storageTerm on Pricing__StorageTerm {
    id
    name
    minimumMonths
  }
`;
export const PricingSetPriceEntryFragmentDoc = gql`
  fragment pricingSetPriceEntry on Pricing__Set__PriceEntry {
    id
    rateGroup {
      ...rateGroup
    }
    price {
      ...price
    }
    storageTerm {
      ...storageTerm
    }
  }
  ${RateGroupFragmentDoc}
  ${PriceFragmentDoc}
  ${StorageTermFragmentDoc}
`;
export const RetailOrderFragmentDoc = gql`
  fragment retailOrder on Order {
    id
    state
    scheduled
  }
`;
export const RetentionOfferFragmentDoc = gql`
  fragment retentionOffer on Retention__Offer {
    id
    detail {
      id
      type
      action {
        id
        discountAmount
        discountPercent
        discountDuration
      }
      waiveRateAdjustment
    }
  }
`;
export const RoomCategoryFragmentDoc = gql`
  fragment roomCategory on RoomCategory {
    id
    name
    countable
    cuft
  }
`;
export const SelfStorage__SubscriptionItemFragmentDoc = gql`
  fragment SelfStorage__SubscriptionItem on SelfStorage__SubscriptionItem {
    id
    summary
    rate
    kind
  }
`;
export const SelfStorage__RentalFragmentDoc = gql`
  fragment SelfStorage__Rental on SelfStorage__Rental {
    id
    subscriptionItems {
      ...SelfStorage__SubscriptionItem
    }
    unit {
      ...SelfStorage__Unit
    }
  }
  ${SelfStorage__SubscriptionItemFragmentDoc}
  ${SelfStorage__UnitFragmentDoc}
`;
export const SelfStorage__SubscriptionFragmentDoc = gql`
  fragment SelfStorage__Subscription on SelfStorage__Subscription {
    id
    billing
    billsOn
    facility {
      ...SelfStorage__Facility
    }
    rentals {
      ...SelfStorage__Rental
    }
    source: defaultSource {
      ...source
    }
  }
  ${SelfStorage__FacilityFragmentDoc}
  ${SelfStorage__RentalFragmentDoc}
  ${SourceFragmentDoc}
`;
export const Shipping__EasyPostRateFragmentDoc = gql`
  fragment Shipping__EasyPostRate on Shipping__EasyPostRate {
    id
    mode
    rate
    currency
    service
    carrier
    deliveryDays
    deliveryDate
  }
`;
export const SignatureRequestFragmentDoc = gql`
  fragment signatureRequest on SignatureRequest {
    id
    kind
    url
    signedAt
  }
`;
export const SubscriptionFragmentDoc = gql`
  fragment subscription on Subscription {
    id
    name
    cubicFootage
    quantity
    discount
    tax
    total
  }
`;
export const TermCommitmentFragmentDoc = gql`
  fragment termCommitment on Account__TermCommitment {
    term
    contractEndDate
    type
    fulfilled
  }
`;
export const AddressDetailsUpdateDocument = gql`
  mutation AddressDetailsUpdate($input: AddressDetailsUpdateInput!) {
    addressDetailsUpdate(input: $input) {
      error
      status
    }
  }
`;
export type AddressDetailsUpdateMutationFn = Apollo.MutationFunction<
  AddressDetailsUpdateMutation,
  AddressDetailsUpdateMutationVariables
>;

/**
 * __useAddressDetailsUpdateMutation__
 *
 * To run a mutation, you first call `useAddressDetailsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressDetailsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressDetailsUpdateMutation, { data, loading, error }] = useAddressDetailsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressDetailsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<AddressDetailsUpdateMutation, AddressDetailsUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddressDetailsUpdateMutation, AddressDetailsUpdateMutationVariables>(
    AddressDetailsUpdateDocument,
    options,
  );
}
export type AddressDetailsUpdateMutationHookResult = ReturnType<typeof useAddressDetailsUpdateMutation>;
export type AddressDetailsUpdateMutationResult = Apollo.MutationResult<AddressDetailsUpdateMutation>;
export type AddressDetailsUpdateMutationOptions = Apollo.BaseMutationOptions<
  AddressDetailsUpdateMutation,
  AddressDetailsUpdateMutationVariables
>;
export const CustomerDocument = gql`
  query Customer {
    account {
      customer {
        ...customer
      }
    }
  }
  ${CustomerFragmentDoc}
`;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerQuery(baseOptions?: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
}
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const BalanceDocument = gql`
  query Balance {
    account {
      creditBalance
    }
  }
`;

/**
 * __useBalanceQuery__
 *
 * To run a query within a React component, call `useBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useBalanceQuery(baseOptions?: Apollo.QueryHookOptions<BalanceQuery, BalanceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BalanceQuery, BalanceQueryVariables>(BalanceDocument, options);
}
export function useBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BalanceQuery, BalanceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BalanceQuery, BalanceQueryVariables>(BalanceDocument, options);
}
export type BalanceQueryHookResult = ReturnType<typeof useBalanceQuery>;
export type BalanceLazyQueryHookResult = ReturnType<typeof useBalanceLazyQuery>;
export type BalanceQueryResult = Apollo.QueryResult<BalanceQuery, BalanceQueryVariables>;
export const AccountDetailDocument = gql`
  query AccountDetail {
    account {
      ...accountDetail
    }
    upcomingStorageOnboarding {
      ...upcomingStorageOnboarding
    }
  }
  ${AccountDetailFragmentDoc}
  ${UpcomingStorageOnboardingFragmentDoc}
`;

/**
 * __useAccountDetailQuery__
 *
 * To run a query within a React component, call `useAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountDetailQuery, AccountDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountDetailQuery, AccountDetailQueryVariables>(AccountDetailDocument, options);
}
export function useAccountDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailQuery, AccountDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountDetailQuery, AccountDetailQueryVariables>(AccountDetailDocument, options);
}
export type AccountDetailQueryHookResult = ReturnType<typeof useAccountDetailQuery>;
export type AccountDetailLazyQueryHookResult = ReturnType<typeof useAccountDetailLazyQuery>;
export type AccountDetailQueryResult = Apollo.QueryResult<AccountDetailQuery, AccountDetailQueryVariables>;
export const AccountResolutionOfferCreateDocument = gql`
  mutation AccountResolutionOfferCreate($reasonID: ID!) {
    offerCreate: accountResolutionOfferCreate(reasonID: $reasonID) {
      status
      error
      note
      offer {
        ...AccountResolution__offer
      }
    }
  }
  ${AccountResolution__OfferFragmentDoc}
`;
export type AccountResolutionOfferCreateMutationFn = Apollo.MutationFunction<
  AccountResolutionOfferCreateMutation,
  AccountResolutionOfferCreateMutationVariables
>;

/**
 * __useAccountResolutionOfferCreateMutation__
 *
 * To run a mutation, you first call `useAccountResolutionOfferCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountResolutionOfferCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountResolutionOfferCreateMutation, { data, loading, error }] = useAccountResolutionOfferCreateMutation({
 *   variables: {
 *      reasonID: // value for 'reasonID'
 *   },
 * });
 */
export function useAccountResolutionOfferCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountResolutionOfferCreateMutation,
    AccountResolutionOfferCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountResolutionOfferCreateMutation, AccountResolutionOfferCreateMutationVariables>(
    AccountResolutionOfferCreateDocument,
    options,
  );
}
export type AccountResolutionOfferCreateMutationHookResult = ReturnType<typeof useAccountResolutionOfferCreateMutation>;
export type AccountResolutionOfferCreateMutationResult = Apollo.MutationResult<AccountResolutionOfferCreateMutation>;
export type AccountResolutionOfferCreateMutationOptions = Apollo.BaseMutationOptions<
  AccountResolutionOfferCreateMutation,
  AccountResolutionOfferCreateMutationVariables
>;
export const AccountResolutionOfferAcceptDocument = gql`
  mutation AccountResolutionOfferAccept($offerID: ID!, $reasonID: ID!, $category: String!) {
    offerAccept: accountResolutionOfferAccept(offerID: $offerID, reasonID: $reasonID, category: $category) {
      status
      error
      customerTicket {
        id
      }
    }
  }
`;
export type AccountResolutionOfferAcceptMutationFn = Apollo.MutationFunction<
  AccountResolutionOfferAcceptMutation,
  AccountResolutionOfferAcceptMutationVariables
>;

/**
 * __useAccountResolutionOfferAcceptMutation__
 *
 * To run a mutation, you first call `useAccountResolutionOfferAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountResolutionOfferAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountResolutionOfferAcceptMutation, { data, loading, error }] = useAccountResolutionOfferAcceptMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *      reasonID: // value for 'reasonID'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useAccountResolutionOfferAcceptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountResolutionOfferAcceptMutation,
    AccountResolutionOfferAcceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountResolutionOfferAcceptMutation, AccountResolutionOfferAcceptMutationVariables>(
    AccountResolutionOfferAcceptDocument,
    options,
  );
}
export type AccountResolutionOfferAcceptMutationHookResult = ReturnType<typeof useAccountResolutionOfferAcceptMutation>;
export type AccountResolutionOfferAcceptMutationResult = Apollo.MutationResult<AccountResolutionOfferAcceptMutation>;
export type AccountResolutionOfferAcceptMutationOptions = Apollo.BaseMutationOptions<
  AccountResolutionOfferAcceptMutation,
  AccountResolutionOfferAcceptMutationVariables
>;
export const AccountResolutionOfferRejectDocument = gql`
  mutation AccountResolutionOfferReject($offerID: ID!) {
    offerReject: accountResolutionOfferReject(offerID: $offerID) {
      status
      error
      note
    }
  }
`;
export type AccountResolutionOfferRejectMutationFn = Apollo.MutationFunction<
  AccountResolutionOfferRejectMutation,
  AccountResolutionOfferRejectMutationVariables
>;

/**
 * __useAccountResolutionOfferRejectMutation__
 *
 * To run a mutation, you first call `useAccountResolutionOfferRejectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountResolutionOfferRejectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountResolutionOfferRejectMutation, { data, loading, error }] = useAccountResolutionOfferRejectMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useAccountResolutionOfferRejectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AccountResolutionOfferRejectMutation,
    AccountResolutionOfferRejectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AccountResolutionOfferRejectMutation, AccountResolutionOfferRejectMutationVariables>(
    AccountResolutionOfferRejectDocument,
    options,
  );
}
export type AccountResolutionOfferRejectMutationHookResult = ReturnType<typeof useAccountResolutionOfferRejectMutation>;
export type AccountResolutionOfferRejectMutationResult = Apollo.MutationResult<AccountResolutionOfferRejectMutation>;
export type AccountResolutionOfferRejectMutationOptions = Apollo.BaseMutationOptions<
  AccountResolutionOfferRejectMutation,
  AccountResolutionOfferRejectMutationVariables
>;
export const AccountResolutionReasonDocument = gql`
  query AccountResolutionReason($id: ID, $rootName: String) {
    reason: accountResolutionReason(id: $id, rootName: $rootName) {
      ...AccountResolution__reason
    }
  }
  ${AccountResolution__ReasonFragmentDoc}
`;

/**
 * __useAccountResolutionReasonQuery__
 *
 * To run a query within a React component, call `useAccountResolutionReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountResolutionReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountResolutionReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rootName: // value for 'rootName'
 *   },
 * });
 */
export function useAccountResolutionReasonQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountResolutionReasonQuery, AccountResolutionReasonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountResolutionReasonQuery, AccountResolutionReasonQueryVariables>(
    AccountResolutionReasonDocument,
    options,
  );
}
export function useAccountResolutionReasonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountResolutionReasonQuery, AccountResolutionReasonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountResolutionReasonQuery, AccountResolutionReasonQueryVariables>(
    AccountResolutionReasonDocument,
    options,
  );
}
export type AccountResolutionReasonQueryHookResult = ReturnType<typeof useAccountResolutionReasonQuery>;
export type AccountResolutionReasonLazyQueryHookResult = ReturnType<typeof useAccountResolutionReasonLazyQuery>;
export type AccountResolutionReasonQueryResult = Apollo.QueryResult<
  AccountResolutionReasonQuery,
  AccountResolutionReasonQueryVariables
>;
export const BuildAddressDocument = gql`
  mutation BuildAddress($input: AddressInput!) {
    buildAddress(input: $input) {
      address {
        ...address
        details {
          ...details
        }
        region {
          ...region
        }
      }
      error
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
  ${RegionFragmentDoc}
`;
export type BuildAddressMutationFn = Apollo.MutationFunction<BuildAddressMutation, BuildAddressMutationVariables>;

/**
 * __useBuildAddressMutation__
 *
 * To run a mutation, you first call `useBuildAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildAddressMutation, { data, loading, error }] = useBuildAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildAddressMutation, BuildAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildAddressMutation, BuildAddressMutationVariables>(BuildAddressDocument, options);
}
export type BuildAddressMutationHookResult = ReturnType<typeof useBuildAddressMutation>;
export type BuildAddressMutationResult = Apollo.MutationResult<BuildAddressMutation>;
export type BuildAddressMutationOptions = Apollo.BaseMutationOptions<
  BuildAddressMutation,
  BuildAddressMutationVariables
>;
export const DestroyAddressDocument = gql`
  mutation DestroyAddress($id: String!) {
    destroyAddress(id: $id) {
      status
    }
  }
`;
export type DestroyAddressMutationFn = Apollo.MutationFunction<DestroyAddressMutation, DestroyAddressMutationVariables>;

/**
 * __useDestroyAddressMutation__
 *
 * To run a mutation, you first call `useDestroyAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyAddressMutation, { data, loading, error }] = useDestroyAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<DestroyAddressMutation, DestroyAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DestroyAddressMutation, DestroyAddressMutationVariables>(DestroyAddressDocument, options);
}
export type DestroyAddressMutationHookResult = ReturnType<typeof useDestroyAddressMutation>;
export type DestroyAddressMutationResult = Apollo.MutationResult<DestroyAddressMutation>;
export type DestroyAddressMutationOptions = Apollo.BaseMutationOptions<
  DestroyAddressMutation,
  DestroyAddressMutationVariables
>;
export const AddressListDocument = gql`
  query AddressList {
    addresses {
      ...addressWithDetails
    }
  }
  ${AddressWithDetailsFragmentDoc}
`;

/**
 * __useAddressListQuery__
 *
 * To run a query within a React component, call `useAddressListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressListQuery(
  baseOptions?: Apollo.QueryHookOptions<AddressListQuery, AddressListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressListQuery, AddressListQueryVariables>(AddressListDocument, options);
}
export function useAddressListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AddressListQuery, AddressListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressListQuery, AddressListQueryVariables>(AddressListDocument, options);
}
export type AddressListQueryHookResult = ReturnType<typeof useAddressListQuery>;
export type AddressListLazyQueryHookResult = ReturnType<typeof useAddressListLazyQuery>;
export type AddressListQueryResult = Apollo.QueryResult<AddressListQuery, AddressListQueryVariables>;
export const AddressDocument = gql`
  query Address($addressID: ID!) {
    address(addressID: $addressID) {
      ...address
      details {
        ...details
      }
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
`;

/**
 * __useAddressQuery__
 *
 * To run a query within a React component, call `useAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressQuery({
 *   variables: {
 *      addressID: // value for 'addressID'
 *   },
 * });
 */
export function useAddressQuery(baseOptions: Apollo.QueryHookOptions<AddressQuery, AddressQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
}
export function useAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressQuery, AddressQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressQuery, AddressQueryVariables>(AddressDocument, options);
}
export type AddressQueryHookResult = ReturnType<typeof useAddressQuery>;
export type AddressLazyQueryHookResult = ReturnType<typeof useAddressLazyQuery>;
export type AddressQueryResult = Apollo.QueryResult<AddressQuery, AddressQueryVariables>;
export const ZipClassificationDocument = gql`
  query ZipClassification($zip: String!) {
    zipClassification(zip: $zip) {
      fullServiceEligible
    }
  }
`;

/**
 * __useZipClassificationQuery__
 *
 * To run a query within a React component, call `useZipClassificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useZipClassificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZipClassificationQuery({
 *   variables: {
 *      zip: // value for 'zip'
 *   },
 * });
 */
export function useZipClassificationQuery(
  baseOptions: Apollo.QueryHookOptions<ZipClassificationQuery, ZipClassificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZipClassificationQuery, ZipClassificationQueryVariables>(ZipClassificationDocument, options);
}
export function useZipClassificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZipClassificationQuery, ZipClassificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZipClassificationQuery, ZipClassificationQueryVariables>(
    ZipClassificationDocument,
    options,
  );
}
export type ZipClassificationQueryHookResult = ReturnType<typeof useZipClassificationQuery>;
export type ZipClassificationLazyQueryHookResult = ReturnType<typeof useZipClassificationLazyQuery>;
export type ZipClassificationQueryResult = Apollo.QueryResult<ZipClassificationQuery, ZipClassificationQueryVariables>;
export const CustomerNameDocument = gql`
  query CustomerName {
    customer {
      id
      name
    }
  }
`;

/**
 * __useCustomerNameQuery__
 *
 * To run a query within a React component, call `useCustomerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerNameQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerNameQuery, CustomerNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerNameQuery, CustomerNameQueryVariables>(CustomerNameDocument, options);
}
export function useCustomerNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerNameQuery, CustomerNameQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerNameQuery, CustomerNameQueryVariables>(CustomerNameDocument, options);
}
export type CustomerNameQueryHookResult = ReturnType<typeof useCustomerNameQuery>;
export type CustomerNameLazyQueryHookResult = ReturnType<typeof useCustomerNameLazyQuery>;
export type CustomerNameQueryResult = Apollo.QueryResult<CustomerNameQuery, CustomerNameQueryVariables>;
export const AccountNoticesDocument = gql`
  query AccountNotices {
    account {
      delinquentBalance
      approvedPaymentPlanOffer {
        id
      }
    }
  }
`;

/**
 * __useAccountNoticesQuery__
 *
 * To run a query within a React component, call `useAccountNoticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountNoticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountNoticesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountNoticesQuery(
  baseOptions?: Apollo.QueryHookOptions<AccountNoticesQuery, AccountNoticesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountNoticesQuery, AccountNoticesQueryVariables>(AccountNoticesDocument, options);
}
export function useAccountNoticesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountNoticesQuery, AccountNoticesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountNoticesQuery, AccountNoticesQueryVariables>(AccountNoticesDocument, options);
}
export type AccountNoticesQueryHookResult = ReturnType<typeof useAccountNoticesQuery>;
export type AccountNoticesLazyQueryHookResult = ReturnType<typeof useAccountNoticesLazyQuery>;
export type AccountNoticesQueryResult = Apollo.QueryResult<AccountNoticesQuery, AccountNoticesQueryVariables>;
export const BillingBannerDocument = gql`
  query BillingBanner {
    account {
      creditBalance
      delinquentBalance
      approvedPaymentPlanOffer {
        id
      }
    }
  }
`;

/**
 * __useBillingBannerQuery__
 *
 * To run a query within a React component, call `useBillingBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingBannerQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingBannerQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingBannerQuery, BillingBannerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingBannerQuery, BillingBannerQueryVariables>(BillingBannerDocument, options);
}
export function useBillingBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingBannerQuery, BillingBannerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingBannerQuery, BillingBannerQueryVariables>(BillingBannerDocument, options);
}
export type BillingBannerQueryHookResult = ReturnType<typeof useBillingBannerQuery>;
export type BillingBannerLazyQueryHookResult = ReturnType<typeof useBillingBannerLazyQuery>;
export type BillingBannerQueryResult = Apollo.QueryResult<BillingBannerQuery, BillingBannerQueryVariables>;
export const BillingHistoryDocument = gql`
  query BillingHistory($page: Int, $numPer: Int) {
    paginatedInvoiceList(page: $page, numPer: $numPer) {
      results {
        ...Billing__Invoice
      }
      pagination {
        ...pagination
      }
    }
  }
  ${Billing__InvoiceFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useBillingHistoryQuery__
 *
 * To run a query within a React component, call `useBillingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingHistoryQuery({
 *   variables: {
 *      page: // value for 'page'
 *      numPer: // value for 'numPer'
 *   },
 * });
 */
export function useBillingHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingHistoryQuery, BillingHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingHistoryQuery, BillingHistoryQueryVariables>(BillingHistoryDocument, options);
}
export function useBillingHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingHistoryQuery, BillingHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingHistoryQuery, BillingHistoryQueryVariables>(BillingHistoryDocument, options);
}
export type BillingHistoryQueryHookResult = ReturnType<typeof useBillingHistoryQuery>;
export type BillingHistoryLazyQueryHookResult = ReturnType<typeof useBillingHistoryLazyQuery>;
export type BillingHistoryQueryResult = Apollo.QueryResult<BillingHistoryQuery, BillingHistoryQueryVariables>;
export const PaymentSourcesDocument = gql`
  query PaymentSources {
    sources {
      ...source
    }
    account {
      delinquentBalance
    }
  }
  ${SourceFragmentDoc}
`;

/**
 * __usePaymentSourcesQuery__
 *
 * To run a query within a React component, call `usePaymentSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentSourcesQuery(
  baseOptions?: Apollo.QueryHookOptions<PaymentSourcesQuery, PaymentSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentSourcesQuery, PaymentSourcesQueryVariables>(PaymentSourcesDocument, options);
}
export function usePaymentSourcesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentSourcesQuery, PaymentSourcesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentSourcesQuery, PaymentSourcesQueryVariables>(PaymentSourcesDocument, options);
}
export type PaymentSourcesQueryHookResult = ReturnType<typeof usePaymentSourcesQuery>;
export type PaymentSourcesLazyQueryHookResult = ReturnType<typeof usePaymentSourcesLazyQuery>;
export type PaymentSourcesQueryResult = Apollo.QueryResult<PaymentSourcesQuery, PaymentSourcesQueryVariables>;
export const BuildPrepaymentDocument = gql`
  mutation BuildPrepayment($amount: Float!) {
    buildPrepayment(amount: $amount) {
      status
      error
      account {
        id
        creditBalance
      }
    }
  }
`;
export type BuildPrepaymentMutationFn = Apollo.MutationFunction<
  BuildPrepaymentMutation,
  BuildPrepaymentMutationVariables
>;

/**
 * __useBuildPrepaymentMutation__
 *
 * To run a mutation, you first call `useBuildPrepaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildPrepaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildPrepaymentMutation, { data, loading, error }] = useBuildPrepaymentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useBuildPrepaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildPrepaymentMutation, BuildPrepaymentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildPrepaymentMutation, BuildPrepaymentMutationVariables>(
    BuildPrepaymentDocument,
    options,
  );
}
export type BuildPrepaymentMutationHookResult = ReturnType<typeof useBuildPrepaymentMutation>;
export type BuildPrepaymentMutationResult = Apollo.MutationResult<BuildPrepaymentMutation>;
export type BuildPrepaymentMutationOptions = Apollo.BaseMutationOptions<
  BuildPrepaymentMutation,
  BuildPrepaymentMutationVariables
>;
export const PrepayFormDocument = gql`
  query PrepayForm {
    defaultSource {
      ...displaySource
    }
  }
  ${DisplaySourceFragmentDoc}
`;

/**
 * __usePrepayFormQuery__
 *
 * To run a query within a React component, call `usePrepayFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrepayFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrepayFormQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrepayFormQuery(baseOptions?: Apollo.QueryHookOptions<PrepayFormQuery, PrepayFormQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PrepayFormQuery, PrepayFormQueryVariables>(PrepayFormDocument, options);
}
export function usePrepayFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PrepayFormQuery, PrepayFormQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PrepayFormQuery, PrepayFormQueryVariables>(PrepayFormDocument, options);
}
export type PrepayFormQueryHookResult = ReturnType<typeof usePrepayFormQuery>;
export type PrepayFormLazyQueryHookResult = ReturnType<typeof usePrepayFormLazyQuery>;
export type PrepayFormQueryResult = Apollo.QueryResult<PrepayFormQuery, PrepayFormQueryVariables>;
export const BillingUpcomingDocument = gql`
  query BillingUpcoming {
    storageCheckoutLink: reonboardingCheckoutLink(type: PICKUP)
    upcomingInvoices {
      ...Billing__UpcomingInvoice
    }
    account {
      id
      delinquentBalance
    }
  }
  ${Billing__UpcomingInvoiceFragmentDoc}
`;

/**
 * __useBillingUpcomingQuery__
 *
 * To run a query within a React component, call `useBillingUpcomingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingUpcomingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingUpcomingQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingUpcomingQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingUpcomingQuery, BillingUpcomingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingUpcomingQuery, BillingUpcomingQueryVariables>(BillingUpcomingDocument, options);
}
export function useBillingUpcomingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingUpcomingQuery, BillingUpcomingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingUpcomingQuery, BillingUpcomingQueryVariables>(BillingUpcomingDocument, options);
}
export type BillingUpcomingQueryHookResult = ReturnType<typeof useBillingUpcomingQuery>;
export type BillingUpcomingLazyQueryHookResult = ReturnType<typeof useBillingUpcomingLazyQuery>;
export type BillingUpcomingQueryResult = Apollo.QueryResult<BillingUpcomingQuery, BillingUpcomingQueryVariables>;
export const BuildClaimDocument = gql`
  mutation BuildClaim($input: ClaimKindsInput!) {
    buildClaim(input: $input) {
      error
      status
      claim {
        ...detailedClaim
      }
    }
  }
  ${DetailedClaimFragmentDoc}
`;
export type BuildClaimMutationFn = Apollo.MutationFunction<BuildClaimMutation, BuildClaimMutationVariables>;

/**
 * __useBuildClaimMutation__
 *
 * To run a mutation, you first call `useBuildClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildClaimMutation, { data, loading, error }] = useBuildClaimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildClaimMutation, BuildClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildClaimMutation, BuildClaimMutationVariables>(BuildClaimDocument, options);
}
export type BuildClaimMutationHookResult = ReturnType<typeof useBuildClaimMutation>;
export type BuildClaimMutationResult = Apollo.MutationResult<BuildClaimMutation>;
export type BuildClaimMutationOptions = Apollo.BaseMutationOptions<BuildClaimMutation, BuildClaimMutationVariables>;
export const BuildClaimItemSelectionBatchDocument = gql`
  mutation BuildClaimItemSelectionBatch(
    $uuid: String!
    $kind: ClaimItemSelectionKind!
    $input: [BuildClaimItemSelectionInput!]!
  ) {
    buildClaimItemSelectionBatch(uuid: $uuid, kind: $kind, input: $input) {
      status
      error
      claim {
        ...claim
        itemSelections {
          ...claimItemSelection
        }
        propertySelection {
          ...claimPropertySelection
        }
      }
    }
  }
  ${ClaimFragmentDoc}
  ${ClaimItemSelectionFragmentDoc}
  ${ClaimPropertySelectionFragmentDoc}
`;
export type BuildClaimItemSelectionBatchMutationFn = Apollo.MutationFunction<
  BuildClaimItemSelectionBatchMutation,
  BuildClaimItemSelectionBatchMutationVariables
>;

/**
 * __useBuildClaimItemSelectionBatchMutation__
 *
 * To run a mutation, you first call `useBuildClaimItemSelectionBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildClaimItemSelectionBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildClaimItemSelectionBatchMutation, { data, loading, error }] = useBuildClaimItemSelectionBatchMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      kind: // value for 'kind'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildClaimItemSelectionBatchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildClaimItemSelectionBatchMutation,
    BuildClaimItemSelectionBatchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildClaimItemSelectionBatchMutation, BuildClaimItemSelectionBatchMutationVariables>(
    BuildClaimItemSelectionBatchDocument,
    options,
  );
}
export type BuildClaimItemSelectionBatchMutationHookResult = ReturnType<typeof useBuildClaimItemSelectionBatchMutation>;
export type BuildClaimItemSelectionBatchMutationResult = Apollo.MutationResult<BuildClaimItemSelectionBatchMutation>;
export type BuildClaimItemSelectionBatchMutationOptions = Apollo.BaseMutationOptions<
  BuildClaimItemSelectionBatchMutation,
  BuildClaimItemSelectionBatchMutationVariables
>;
export const ClaimDocument = gql`
  query Claim($uuid: String!) {
    claim(uuid: $uuid) {
      ...detailedClaim
    }
  }
  ${DetailedClaimFragmentDoc}
`;

/**
 * __useClaimQuery__
 *
 * To run a query within a React component, call `useClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useClaimQuery(baseOptions: Apollo.QueryHookOptions<ClaimQuery, ClaimQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimQuery, ClaimQueryVariables>(ClaimDocument, options);
}
export function useClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimQuery, ClaimQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimQuery, ClaimQueryVariables>(ClaimDocument, options);
}
export type ClaimQueryHookResult = ReturnType<typeof useClaimQuery>;
export type ClaimLazyQueryHookResult = ReturnType<typeof useClaimLazyQuery>;
export type ClaimQueryResult = Apollo.QueryResult<ClaimQuery, ClaimQueryVariables>;
export const DestroyClaimItemSelectionDocument = gql`
  mutation DestroyClaimItemSelection($uuid: ID!, $selectionID: ID!) {
    destroyClaimItemSelection(uuid: $uuid, selectionID: $selectionID) {
      status
      error
    }
  }
`;
export type DestroyClaimItemSelectionMutationFn = Apollo.MutationFunction<
  DestroyClaimItemSelectionMutation,
  DestroyClaimItemSelectionMutationVariables
>;

/**
 * __useDestroyClaimItemSelectionMutation__
 *
 * To run a mutation, you first call `useDestroyClaimItemSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyClaimItemSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyClaimItemSelectionMutation, { data, loading, error }] = useDestroyClaimItemSelectionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      selectionID: // value for 'selectionID'
 *   },
 * });
 */
export function useDestroyClaimItemSelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DestroyClaimItemSelectionMutation,
    DestroyClaimItemSelectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DestroyClaimItemSelectionMutation, DestroyClaimItemSelectionMutationVariables>(
    DestroyClaimItemSelectionDocument,
    options,
  );
}
export type DestroyClaimItemSelectionMutationHookResult = ReturnType<typeof useDestroyClaimItemSelectionMutation>;
export type DestroyClaimItemSelectionMutationResult = Apollo.MutationResult<DestroyClaimItemSelectionMutation>;
export type DestroyClaimItemSelectionMutationOptions = Apollo.BaseMutationOptions<
  DestroyClaimItemSelectionMutation,
  DestroyClaimItemSelectionMutationVariables
>;
export const DestroyClaimPropertySelectionDocument = gql`
  mutation DestroyClaimPropertySelection($uuid: ID!) {
    destroyClaimPropertySelection(uuid: $uuid) {
      status
      error
    }
  }
`;
export type DestroyClaimPropertySelectionMutationFn = Apollo.MutationFunction<
  DestroyClaimPropertySelectionMutation,
  DestroyClaimPropertySelectionMutationVariables
>;

/**
 * __useDestroyClaimPropertySelectionMutation__
 *
 * To run a mutation, you first call `useDestroyClaimPropertySelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyClaimPropertySelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyClaimPropertySelectionMutation, { data, loading, error }] = useDestroyClaimPropertySelectionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDestroyClaimPropertySelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DestroyClaimPropertySelectionMutation,
    DestroyClaimPropertySelectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DestroyClaimPropertySelectionMutation, DestroyClaimPropertySelectionMutationVariables>(
    DestroyClaimPropertySelectionDocument,
    options,
  );
}
export type DestroyClaimPropertySelectionMutationHookResult = ReturnType<
  typeof useDestroyClaimPropertySelectionMutation
>;
export type DestroyClaimPropertySelectionMutationResult = Apollo.MutationResult<DestroyClaimPropertySelectionMutation>;
export type DestroyClaimPropertySelectionMutationOptions = Apollo.BaseMutationOptions<
  DestroyClaimPropertySelectionMutation,
  DestroyClaimPropertySelectionMutationVariables
>;
export const ClaimIssueSelectionDestroyDocument = gql`
  mutation ClaimIssueSelectionDestroy($uuid: ID!) {
    claimIssueSelectionDestroy(uuid: $uuid) {
      status
      error
      claimIssueSelection {
        ...claimIssueSelection
      }
    }
  }
  ${ClaimIssueSelectionFragmentDoc}
`;
export type ClaimIssueSelectionDestroyMutationFn = Apollo.MutationFunction<
  ClaimIssueSelectionDestroyMutation,
  ClaimIssueSelectionDestroyMutationVariables
>;

/**
 * __useClaimIssueSelectionDestroyMutation__
 *
 * To run a mutation, you first call `useClaimIssueSelectionDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimIssueSelectionDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimIssueSelectionDestroyMutation, { data, loading, error }] = useClaimIssueSelectionDestroyMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useClaimIssueSelectionDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimIssueSelectionDestroyMutation,
    ClaimIssueSelectionDestroyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimIssueSelectionDestroyMutation, ClaimIssueSelectionDestroyMutationVariables>(
    ClaimIssueSelectionDestroyDocument,
    options,
  );
}
export type ClaimIssueSelectionDestroyMutationHookResult = ReturnType<typeof useClaimIssueSelectionDestroyMutation>;
export type ClaimIssueSelectionDestroyMutationResult = Apollo.MutationResult<ClaimIssueSelectionDestroyMutation>;
export type ClaimIssueSelectionDestroyMutationOptions = Apollo.BaseMutationOptions<
  ClaimIssueSelectionDestroyMutation,
  ClaimIssueSelectionDestroyMutationVariables
>;
export const LatestProtectionSubscriptionDocument = gql`
  query LatestProtectionSubscription {
    account {
      latestProtectionSubscription {
        ...subscription
      }
    }
  }
  ${SubscriptionFragmentDoc}
`;

/**
 * __useLatestProtectionSubscriptionQuery__
 *
 * To run a query within a React component, call `useLatestProtectionSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestProtectionSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestProtectionSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestProtectionSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<LatestProtectionSubscriptionQuery, LatestProtectionSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LatestProtectionSubscriptionQuery, LatestProtectionSubscriptionQueryVariables>(
    LatestProtectionSubscriptionDocument,
    options,
  );
}
export function useLatestProtectionSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LatestProtectionSubscriptionQuery,
    LatestProtectionSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LatestProtectionSubscriptionQuery, LatestProtectionSubscriptionQueryVariables>(
    LatestProtectionSubscriptionDocument,
    options,
  );
}
export type LatestProtectionSubscriptionQueryHookResult = ReturnType<typeof useLatestProtectionSubscriptionQuery>;
export type LatestProtectionSubscriptionLazyQueryHookResult = ReturnType<
  typeof useLatestProtectionSubscriptionLazyQuery
>;
export type LatestProtectionSubscriptionQueryResult = Apollo.QueryResult<
  LatestProtectionSubscriptionQuery,
  LatestProtectionSubscriptionQueryVariables
>;
export const AttachClaimIssueSelectionAttachmentDocument = gql`
  mutation AttachClaimIssueSelectionAttachment($uuid: ID!, $signedID: ID!) {
    attachClaimIssueSelectionAttachment(uuid: $uuid, signedID: $signedID) {
      status
      error
      attachment {
        ...attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type AttachClaimIssueSelectionAttachmentMutationFn = Apollo.MutationFunction<
  AttachClaimIssueSelectionAttachmentMutation,
  AttachClaimIssueSelectionAttachmentMutationVariables
>;

/**
 * __useAttachClaimIssueSelectionAttachmentMutation__
 *
 * To run a mutation, you first call `useAttachClaimIssueSelectionAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachClaimIssueSelectionAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachClaimIssueSelectionAttachmentMutation, { data, loading, error }] = useAttachClaimIssueSelectionAttachmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      signedID: // value for 'signedID'
 *   },
 * });
 */
export function useAttachClaimIssueSelectionAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachClaimIssueSelectionAttachmentMutation,
    AttachClaimIssueSelectionAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachClaimIssueSelectionAttachmentMutation,
    AttachClaimIssueSelectionAttachmentMutationVariables
  >(AttachClaimIssueSelectionAttachmentDocument, options);
}
export type AttachClaimIssueSelectionAttachmentMutationHookResult = ReturnType<
  typeof useAttachClaimIssueSelectionAttachmentMutation
>;
export type AttachClaimIssueSelectionAttachmentMutationResult =
  Apollo.MutationResult<AttachClaimIssueSelectionAttachmentMutation>;
export type AttachClaimIssueSelectionAttachmentMutationOptions = Apollo.BaseMutationOptions<
  AttachClaimIssueSelectionAttachmentMutation,
  AttachClaimIssueSelectionAttachmentMutationVariables
>;
export const DetachClaimIssueSelectionAttachmentDocument = gql`
  mutation DetachClaimIssueSelectionAttachment($uuid: ID!, $attachmentID: ID!) {
    detachClaimIssueSelectionAttachment(uuid: $uuid, attachmentID: $attachmentID) {
      status
      error
      attachment {
        ...attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type DetachClaimIssueSelectionAttachmentMutationFn = Apollo.MutationFunction<
  DetachClaimIssueSelectionAttachmentMutation,
  DetachClaimIssueSelectionAttachmentMutationVariables
>;

/**
 * __useDetachClaimIssueSelectionAttachmentMutation__
 *
 * To run a mutation, you first call `useDetachClaimIssueSelectionAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachClaimIssueSelectionAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachClaimIssueSelectionAttachmentMutation, { data, loading, error }] = useDetachClaimIssueSelectionAttachmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      attachmentID: // value for 'attachmentID'
 *   },
 * });
 */
export function useDetachClaimIssueSelectionAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachClaimIssueSelectionAttachmentMutation,
    DetachClaimIssueSelectionAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachClaimIssueSelectionAttachmentMutation,
    DetachClaimIssueSelectionAttachmentMutationVariables
  >(DetachClaimIssueSelectionAttachmentDocument, options);
}
export type DetachClaimIssueSelectionAttachmentMutationHookResult = ReturnType<
  typeof useDetachClaimIssueSelectionAttachmentMutation
>;
export type DetachClaimIssueSelectionAttachmentMutationResult =
  Apollo.MutationResult<DetachClaimIssueSelectionAttachmentMutation>;
export type DetachClaimIssueSelectionAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DetachClaimIssueSelectionAttachmentMutation,
  DetachClaimIssueSelectionAttachmentMutationVariables
>;
export const ClaimIssueSelectionUpdateDocument = gql`
  mutation ClaimIssueSelectionUpdate($uuid: String!, $input: Claim__IssueSelectionUpdateInput!) {
    claimIssueSelectionUpdate(uuid: $uuid, input: $input) {
      error
      status
      claimIssueSelection {
        ...claimIssueSelection
      }
    }
  }
  ${ClaimIssueSelectionFragmentDoc}
`;
export type ClaimIssueSelectionUpdateMutationFn = Apollo.MutationFunction<
  ClaimIssueSelectionUpdateMutation,
  ClaimIssueSelectionUpdateMutationVariables
>;

/**
 * __useClaimIssueSelectionUpdateMutation__
 *
 * To run a mutation, you first call `useClaimIssueSelectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimIssueSelectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimIssueSelectionUpdateMutation, { data, loading, error }] = useClaimIssueSelectionUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClaimIssueSelectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimIssueSelectionUpdateMutation,
    ClaimIssueSelectionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimIssueSelectionUpdateMutation, ClaimIssueSelectionUpdateMutationVariables>(
    ClaimIssueSelectionUpdateDocument,
    options,
  );
}
export type ClaimIssueSelectionUpdateMutationHookResult = ReturnType<typeof useClaimIssueSelectionUpdateMutation>;
export type ClaimIssueSelectionUpdateMutationResult = Apollo.MutationResult<ClaimIssueSelectionUpdateMutation>;
export type ClaimIssueSelectionUpdateMutationOptions = Apollo.BaseMutationOptions<
  ClaimIssueSelectionUpdateMutation,
  ClaimIssueSelectionUpdateMutationVariables
>;
export const AttachItemSelectionAttachmentDocument = gql`
  mutation AttachItemSelectionAttachment(
    $uuid: ID!
    $selectionID: ID!
    $signedID: ID!
    $name: ClaimItemSelectionAttachmentName!
  ) {
    result: attachItemSelectionAttachment(uuid: $uuid, selectionID: $selectionID, signedID: $signedID, name: $name) {
      status
      error
      attachment {
        ...attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type AttachItemSelectionAttachmentMutationFn = Apollo.MutationFunction<
  AttachItemSelectionAttachmentMutation,
  AttachItemSelectionAttachmentMutationVariables
>;

/**
 * __useAttachItemSelectionAttachmentMutation__
 *
 * To run a mutation, you first call `useAttachItemSelectionAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachItemSelectionAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachItemSelectionAttachmentMutation, { data, loading, error }] = useAttachItemSelectionAttachmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      selectionID: // value for 'selectionID'
 *      signedID: // value for 'signedID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAttachItemSelectionAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachItemSelectionAttachmentMutation,
    AttachItemSelectionAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachItemSelectionAttachmentMutation, AttachItemSelectionAttachmentMutationVariables>(
    AttachItemSelectionAttachmentDocument,
    options,
  );
}
export type AttachItemSelectionAttachmentMutationHookResult = ReturnType<
  typeof useAttachItemSelectionAttachmentMutation
>;
export type AttachItemSelectionAttachmentMutationResult = Apollo.MutationResult<AttachItemSelectionAttachmentMutation>;
export type AttachItemSelectionAttachmentMutationOptions = Apollo.BaseMutationOptions<
  AttachItemSelectionAttachmentMutation,
  AttachItemSelectionAttachmentMutationVariables
>;
export const DetachItemSelectionAttachmentDocument = gql`
  mutation DetachItemSelectionAttachment(
    $uuid: ID!
    $selectionID: ID!
    $attachmentID: ID!
    $name: ClaimItemSelectionAttachmentName!
  ) {
    result: detachItemSelectionAttachment(
      uuid: $uuid
      selectionID: $selectionID
      attachmentID: $attachmentID
      name: $name
    ) {
      status
      error
      attachment {
        ...attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type DetachItemSelectionAttachmentMutationFn = Apollo.MutationFunction<
  DetachItemSelectionAttachmentMutation,
  DetachItemSelectionAttachmentMutationVariables
>;

/**
 * __useDetachItemSelectionAttachmentMutation__
 *
 * To run a mutation, you first call `useDetachItemSelectionAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachItemSelectionAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachItemSelectionAttachmentMutation, { data, loading, error }] = useDetachItemSelectionAttachmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      selectionID: // value for 'selectionID'
 *      attachmentID: // value for 'attachmentID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDetachItemSelectionAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachItemSelectionAttachmentMutation,
    DetachItemSelectionAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DetachItemSelectionAttachmentMutation, DetachItemSelectionAttachmentMutationVariables>(
    DetachItemSelectionAttachmentDocument,
    options,
  );
}
export type DetachItemSelectionAttachmentMutationHookResult = ReturnType<
  typeof useDetachItemSelectionAttachmentMutation
>;
export type DetachItemSelectionAttachmentMutationResult = Apollo.MutationResult<DetachItemSelectionAttachmentMutation>;
export type DetachItemSelectionAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DetachItemSelectionAttachmentMutation,
  DetachItemSelectionAttachmentMutationVariables
>;
export const UpdateClaimItemSelectionDocument = gql`
  mutation UpdateClaimItemSelection($input: ClaimItemSelectionInput!) {
    updateClaimItemSelection(input: $input) {
      error
      status
    }
  }
`;
export type UpdateClaimItemSelectionMutationFn = Apollo.MutationFunction<
  UpdateClaimItemSelectionMutation,
  UpdateClaimItemSelectionMutationVariables
>;

/**
 * __useUpdateClaimItemSelectionMutation__
 *
 * To run a mutation, you first call `useUpdateClaimItemSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClaimItemSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClaimItemSelectionMutation, { data, loading, error }] = useUpdateClaimItemSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClaimItemSelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateClaimItemSelectionMutation, UpdateClaimItemSelectionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateClaimItemSelectionMutation, UpdateClaimItemSelectionMutationVariables>(
    UpdateClaimItemSelectionDocument,
    options,
  );
}
export type UpdateClaimItemSelectionMutationHookResult = ReturnType<typeof useUpdateClaimItemSelectionMutation>;
export type UpdateClaimItemSelectionMutationResult = Apollo.MutationResult<UpdateClaimItemSelectionMutation>;
export type UpdateClaimItemSelectionMutationOptions = Apollo.BaseMutationOptions<
  UpdateClaimItemSelectionMutation,
  UpdateClaimItemSelectionMutationVariables
>;
export const ClaimsDocument = gql`
  query Claims {
    claims {
      ...claim
    }
  }
  ${ClaimFragmentDoc}
`;

/**
 * __useClaimsQuery__
 *
 * To run a query within a React component, call `useClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClaimsQuery(baseOptions?: Apollo.QueryHookOptions<ClaimsQuery, ClaimsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimsQuery, ClaimsQueryVariables>(ClaimsDocument, options);
}
export function useClaimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimsQuery, ClaimsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimsQuery, ClaimsQueryVariables>(ClaimsDocument, options);
}
export type ClaimsQueryHookResult = ReturnType<typeof useClaimsQuery>;
export type ClaimsLazyQueryHookResult = ReturnType<typeof useClaimsLazyQuery>;
export type ClaimsQueryResult = Apollo.QueryResult<ClaimsQuery, ClaimsQueryVariables>;
export const DestroyClaimDocument = gql`
  mutation DestroyClaim($uuid: ID!) {
    destroyClaim(uuid: $uuid) {
      status
    }
  }
`;
export type DestroyClaimMutationFn = Apollo.MutationFunction<DestroyClaimMutation, DestroyClaimMutationVariables>;

/**
 * __useDestroyClaimMutation__
 *
 * To run a mutation, you first call `useDestroyClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyClaimMutation, { data, loading, error }] = useDestroyClaimMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDestroyClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<DestroyClaimMutation, DestroyClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DestroyClaimMutation, DestroyClaimMutationVariables>(DestroyClaimDocument, options);
}
export type DestroyClaimMutationHookResult = ReturnType<typeof useDestroyClaimMutation>;
export type DestroyClaimMutationResult = Apollo.MutationResult<DestroyClaimMutation>;
export type DestroyClaimMutationOptions = Apollo.BaseMutationOptions<
  DestroyClaimMutation,
  DestroyClaimMutationVariables
>;
export const AttachPropertySelectionAttachmentDocument = gql`
  mutation AttachPropertySelectionAttachment($uuid: ID!, $signedID: ID!, $name: ClaimPropertySelectionAttachmentName!) {
    result: attachPropertySelectionAttachment(uuid: $uuid, signedID: $signedID, name: $name) {
      status
      error
      attachment {
        ...attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type AttachPropertySelectionAttachmentMutationFn = Apollo.MutationFunction<
  AttachPropertySelectionAttachmentMutation,
  AttachPropertySelectionAttachmentMutationVariables
>;

/**
 * __useAttachPropertySelectionAttachmentMutation__
 *
 * To run a mutation, you first call `useAttachPropertySelectionAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachPropertySelectionAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachPropertySelectionAttachmentMutation, { data, loading, error }] = useAttachPropertySelectionAttachmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      signedID: // value for 'signedID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAttachPropertySelectionAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachPropertySelectionAttachmentMutation,
    AttachPropertySelectionAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachPropertySelectionAttachmentMutation,
    AttachPropertySelectionAttachmentMutationVariables
  >(AttachPropertySelectionAttachmentDocument, options);
}
export type AttachPropertySelectionAttachmentMutationHookResult = ReturnType<
  typeof useAttachPropertySelectionAttachmentMutation
>;
export type AttachPropertySelectionAttachmentMutationResult =
  Apollo.MutationResult<AttachPropertySelectionAttachmentMutation>;
export type AttachPropertySelectionAttachmentMutationOptions = Apollo.BaseMutationOptions<
  AttachPropertySelectionAttachmentMutation,
  AttachPropertySelectionAttachmentMutationVariables
>;
export const DetachPropertySelectionAttachmentDocument = gql`
  mutation DetachPropertySelectionAttachment(
    $uuid: ID!
    $attachmentID: ID!
    $name: ClaimPropertySelectionAttachmentName!
  ) {
    result: detachPropertySelectionAttachment(uuid: $uuid, attachmentID: $attachmentID, name: $name) {
      status
      error
      attachment {
        ...attachment
      }
    }
  }
  ${AttachmentFragmentDoc}
`;
export type DetachPropertySelectionAttachmentMutationFn = Apollo.MutationFunction<
  DetachPropertySelectionAttachmentMutation,
  DetachPropertySelectionAttachmentMutationVariables
>;

/**
 * __useDetachPropertySelectionAttachmentMutation__
 *
 * To run a mutation, you first call `useDetachPropertySelectionAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachPropertySelectionAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachPropertySelectionAttachmentMutation, { data, loading, error }] = useDetachPropertySelectionAttachmentMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      attachmentID: // value for 'attachmentID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDetachPropertySelectionAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DetachPropertySelectionAttachmentMutation,
    DetachPropertySelectionAttachmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DetachPropertySelectionAttachmentMutation,
    DetachPropertySelectionAttachmentMutationVariables
  >(DetachPropertySelectionAttachmentDocument, options);
}
export type DetachPropertySelectionAttachmentMutationHookResult = ReturnType<
  typeof useDetachPropertySelectionAttachmentMutation
>;
export type DetachPropertySelectionAttachmentMutationResult =
  Apollo.MutationResult<DetachPropertySelectionAttachmentMutation>;
export type DetachPropertySelectionAttachmentMutationOptions = Apollo.BaseMutationOptions<
  DetachPropertySelectionAttachmentMutation,
  DetachPropertySelectionAttachmentMutationVariables
>;
export const BuildClaimPropertySelectionDocument = gql`
  mutation BuildClaimPropertySelection($uuid: String!, $input: BuildClaimPropertySelectionInput!) {
    buildClaimPropertySelection(uuid: $uuid, input: $input) {
      error
      status
    }
  }
`;
export type BuildClaimPropertySelectionMutationFn = Apollo.MutationFunction<
  BuildClaimPropertySelectionMutation,
  BuildClaimPropertySelectionMutationVariables
>;

/**
 * __useBuildClaimPropertySelectionMutation__
 *
 * To run a mutation, you first call `useBuildClaimPropertySelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildClaimPropertySelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildClaimPropertySelectionMutation, { data, loading, error }] = useBuildClaimPropertySelectionMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildClaimPropertySelectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildClaimPropertySelectionMutation,
    BuildClaimPropertySelectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildClaimPropertySelectionMutation, BuildClaimPropertySelectionMutationVariables>(
    BuildClaimPropertySelectionDocument,
    options,
  );
}
export type BuildClaimPropertySelectionMutationHookResult = ReturnType<typeof useBuildClaimPropertySelectionMutation>;
export type BuildClaimPropertySelectionMutationResult = Apollo.MutationResult<BuildClaimPropertySelectionMutation>;
export type BuildClaimPropertySelectionMutationOptions = Apollo.BaseMutationOptions<
  BuildClaimPropertySelectionMutation,
  BuildClaimPropertySelectionMutationVariables
>;
export const SubmitClaimDocument = gql`
  mutation SubmitClaim($uuid: String!) {
    submitClaim(uuid: $uuid) {
      error
      status
    }
  }
`;
export type SubmitClaimMutationFn = Apollo.MutationFunction<SubmitClaimMutation, SubmitClaimMutationVariables>;

/**
 * __useSubmitClaimMutation__
 *
 * To run a mutation, you first call `useSubmitClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitClaimMutation, { data, loading, error }] = useSubmitClaimMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSubmitClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitClaimMutation, SubmitClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitClaimMutation, SubmitClaimMutationVariables>(SubmitClaimDocument, options);
}
export type SubmitClaimMutationHookResult = ReturnType<typeof useSubmitClaimMutation>;
export type SubmitClaimMutationResult = Apollo.MutationResult<SubmitClaimMutation>;
export type SubmitClaimMutationOptions = Apollo.BaseMutationOptions<SubmitClaimMutation, SubmitClaimMutationVariables>;
export const TrackClaimDocument = gql`
  mutation TrackClaim($uuid: ID!, $step: String!) {
    trackClaim(uuid: $uuid, step: $step) {
      status
    }
  }
`;
export type TrackClaimMutationFn = Apollo.MutationFunction<TrackClaimMutation, TrackClaimMutationVariables>;

/**
 * __useTrackClaimMutation__
 *
 * To run a mutation, you first call `useTrackClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackClaimMutation, { data, loading, error }] = useTrackClaimMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      step: // value for 'step'
 *   },
 * });
 */
export function useTrackClaimMutation(
  baseOptions?: Apollo.MutationHookOptions<TrackClaimMutation, TrackClaimMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackClaimMutation, TrackClaimMutationVariables>(TrackClaimDocument, options);
}
export type TrackClaimMutationHookResult = ReturnType<typeof useTrackClaimMutation>;
export type TrackClaimMutationResult = Apollo.MutationResult<TrackClaimMutation>;
export type TrackClaimMutationOptions = Apollo.BaseMutationOptions<TrackClaimMutation, TrackClaimMutationVariables>;
export const AcceptClaimOfferDocument = gql`
  mutation AcceptClaimOffer($input: AcceptClaimOfferInput!) {
    acceptClaimOffer(input: $input) {
      status
      error
      claim {
        ...detailedClaim
      }
    }
  }
  ${DetailedClaimFragmentDoc}
`;
export type AcceptClaimOfferMutationFn = Apollo.MutationFunction<
  AcceptClaimOfferMutation,
  AcceptClaimOfferMutationVariables
>;

/**
 * __useAcceptClaimOfferMutation__
 *
 * To run a mutation, you first call `useAcceptClaimOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptClaimOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptClaimOfferMutation, { data, loading, error }] = useAcceptClaimOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptClaimOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptClaimOfferMutation, AcceptClaimOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptClaimOfferMutation, AcceptClaimOfferMutationVariables>(
    AcceptClaimOfferDocument,
    options,
  );
}
export type AcceptClaimOfferMutationHookResult = ReturnType<typeof useAcceptClaimOfferMutation>;
export type AcceptClaimOfferMutationResult = Apollo.MutationResult<AcceptClaimOfferMutation>;
export type AcceptClaimOfferMutationOptions = Apollo.BaseMutationOptions<
  AcceptClaimOfferMutation,
  AcceptClaimOfferMutationVariables
>;
export const ClaimPreviewDocument = gql`
  query ClaimPreview($uuid: String!, $name: String, $address: NoDetailAddressInput) {
    claimPreview(uuid: $uuid, name: $name, address: $address)
  }
`;

/**
 * __useClaimPreviewQuery__
 *
 * To run a query within a React component, call `useClaimPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimPreviewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useClaimPreviewQuery(
  baseOptions: Apollo.QueryHookOptions<ClaimPreviewQuery, ClaimPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClaimPreviewQuery, ClaimPreviewQueryVariables>(ClaimPreviewDocument, options);
}
export function useClaimPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ClaimPreviewQuery, ClaimPreviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClaimPreviewQuery, ClaimPreviewQueryVariables>(ClaimPreviewDocument, options);
}
export type ClaimPreviewQueryHookResult = ReturnType<typeof useClaimPreviewQuery>;
export type ClaimPreviewLazyQueryHookResult = ReturnType<typeof useClaimPreviewLazyQuery>;
export type ClaimPreviewQueryResult = Apollo.QueryResult<ClaimPreviewQuery, ClaimPreviewQueryVariables>;
export const AcknowledgeDocumentsDocument = gql`
  mutation AcknowledgeDocuments($input: [AcknowledgeDocumentsInput!]!) {
    acknowledgeDocuments(input: $input) {
      status
    }
  }
`;
export type AcknowledgeDocumentsMutationFn = Apollo.MutationFunction<
  AcknowledgeDocumentsMutation,
  AcknowledgeDocumentsMutationVariables
>;

/**
 * __useAcknowledgeDocumentsMutation__
 *
 * To run a mutation, you first call `useAcknowledgeDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcknowledgeDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acknowledgeDocumentsMutation, { data, loading, error }] = useAcknowledgeDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcknowledgeDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<AcknowledgeDocumentsMutation, AcknowledgeDocumentsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcknowledgeDocumentsMutation, AcknowledgeDocumentsMutationVariables>(
    AcknowledgeDocumentsDocument,
    options,
  );
}
export type AcknowledgeDocumentsMutationHookResult = ReturnType<typeof useAcknowledgeDocumentsMutation>;
export type AcknowledgeDocumentsMutationResult = Apollo.MutationResult<AcknowledgeDocumentsMutation>;
export type AcknowledgeDocumentsMutationOptions = Apollo.BaseMutationOptions<
  AcknowledgeDocumentsMutation,
  AcknowledgeDocumentsMutationVariables
>;
export const UnacknowledgedDocumentsDocument = gql`
  query UnacknowledgedDocuments {
    account {
      unacknowledgedDocuments {
        id
        name
        url
      }
    }
  }
`;

/**
 * __useUnacknowledgedDocumentsQuery__
 *
 * To run a query within a React component, call `useUnacknowledgedDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnacknowledgedDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnacknowledgedDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnacknowledgedDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<UnacknowledgedDocumentsQuery, UnacknowledgedDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UnacknowledgedDocumentsQuery, UnacknowledgedDocumentsQueryVariables>(
    UnacknowledgedDocumentsDocument,
    options,
  );
}
export function useUnacknowledgedDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UnacknowledgedDocumentsQuery, UnacknowledgedDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UnacknowledgedDocumentsQuery, UnacknowledgedDocumentsQueryVariables>(
    UnacknowledgedDocumentsDocument,
    options,
  );
}
export type UnacknowledgedDocumentsQueryHookResult = ReturnType<typeof useUnacknowledgedDocumentsQuery>;
export type UnacknowledgedDocumentsLazyQueryHookResult = ReturnType<typeof useUnacknowledgedDocumentsLazyQuery>;
export type UnacknowledgedDocumentsQueryResult = Apollo.QueryResult<
  UnacknowledgedDocumentsQuery,
  UnacknowledgedDocumentsQueryVariables
>;
export const ConfirmUpgradeDowngradeForSuggestedPricingDocument = gql`
  mutation ConfirmUpgradeDowngradeForSuggestedPricing {
    result: confirmUpgradeDowngradeForSuggestedPricing {
      account {
        ...accountWithUsageAndUpgradeDowngradeSummary
      }
    }
  }
  ${AccountWithUsageAndUpgradeDowngradeSummaryFragmentDoc}
`;
export type ConfirmUpgradeDowngradeForSuggestedPricingMutationFn = Apollo.MutationFunction<
  ConfirmUpgradeDowngradeForSuggestedPricingMutation,
  ConfirmUpgradeDowngradeForSuggestedPricingMutationVariables
>;

/**
 * __useConfirmUpgradeDowngradeForSuggestedPricingMutation__
 *
 * To run a mutation, you first call `useConfirmUpgradeDowngradeForSuggestedPricingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUpgradeDowngradeForSuggestedPricingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUpgradeDowngradeForSuggestedPricingMutation, { data, loading, error }] = useConfirmUpgradeDowngradeForSuggestedPricingMutation({
 *   variables: {
 *   },
 * });
 */
export function useConfirmUpgradeDowngradeForSuggestedPricingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmUpgradeDowngradeForSuggestedPricingMutation,
    ConfirmUpgradeDowngradeForSuggestedPricingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmUpgradeDowngradeForSuggestedPricingMutation,
    ConfirmUpgradeDowngradeForSuggestedPricingMutationVariables
  >(ConfirmUpgradeDowngradeForSuggestedPricingDocument, options);
}
export type ConfirmUpgradeDowngradeForSuggestedPricingMutationHookResult = ReturnType<
  typeof useConfirmUpgradeDowngradeForSuggestedPricingMutation
>;
export type ConfirmUpgradeDowngradeForSuggestedPricingMutationResult =
  Apollo.MutationResult<ConfirmUpgradeDowngradeForSuggestedPricingMutation>;
export type ConfirmUpgradeDowngradeForSuggestedPricingMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUpgradeDowngradeForSuggestedPricingMutation,
  ConfirmUpgradeDowngradeForSuggestedPricingMutationVariables
>;
export const OnboardingIncentiveGiftCardQueryDocument = gql`
  query OnboardingIncentiveGiftCardQuery {
    onboardingIncentiveGiftCard {
      status
      claimCode
    }
  }
`;

/**
 * __useOnboardingIncentiveGiftCardQueryQuery__
 *
 * To run a query within a React component, call `useOnboardingIncentiveGiftCardQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingIncentiveGiftCardQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingIncentiveGiftCardQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingIncentiveGiftCardQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnboardingIncentiveGiftCardQueryQuery,
    OnboardingIncentiveGiftCardQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingIncentiveGiftCardQueryQuery, OnboardingIncentiveGiftCardQueryQueryVariables>(
    OnboardingIncentiveGiftCardQueryDocument,
    options,
  );
}
export function useOnboardingIncentiveGiftCardQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingIncentiveGiftCardQueryQuery,
    OnboardingIncentiveGiftCardQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingIncentiveGiftCardQueryQuery, OnboardingIncentiveGiftCardQueryQueryVariables>(
    OnboardingIncentiveGiftCardQueryDocument,
    options,
  );
}
export type OnboardingIncentiveGiftCardQueryQueryHookResult = ReturnType<
  typeof useOnboardingIncentiveGiftCardQueryQuery
>;
export type OnboardingIncentiveGiftCardQueryLazyQueryHookResult = ReturnType<
  typeof useOnboardingIncentiveGiftCardQueryLazyQuery
>;
export type OnboardingIncentiveGiftCardQueryQueryResult = Apollo.QueryResult<
  OnboardingIncentiveGiftCardQueryQuery,
  OnboardingIncentiveGiftCardQueryQueryVariables
>;
export const AvailabilitiesDocument = gql`
  query Availabilities($input: AvailabilitiesInput!) {
    availabilities(input: $input) {
      ...availability
    }
  }
  ${AvailabilityFragmentDoc}
`;

/**
 * __useAvailabilitiesQuery__
 *
 * To run a query within a React component, call `useAvailabilitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilitiesQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilitiesQuery, AvailabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilitiesQuery, AvailabilitiesQueryVariables>(AvailabilitiesDocument, options);
}
export function useAvailabilitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailabilitiesQuery, AvailabilitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilitiesQuery, AvailabilitiesQueryVariables>(AvailabilitiesDocument, options);
}
export type AvailabilitiesQueryHookResult = ReturnType<typeof useAvailabilitiesQuery>;
export type AvailabilitiesLazyQueryHookResult = ReturnType<typeof useAvailabilitiesLazyQuery>;
export type AvailabilitiesQueryResult = Apollo.QueryResult<AvailabilitiesQuery, AvailabilitiesQueryVariables>;
export const ItemsDocument = gql`
  query Items($query: String, $status: ItemStatus) {
    items(query: $query, status: $status) {
      ...item
      cuft
      category {
        name
      }
    }
  }
  ${ItemFragmentDoc}
`;

/**
 * __useItemsQuery__
 *
 * To run a query within a React component, call `useItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useItemsQuery(baseOptions?: Apollo.QueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
}
export function useItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemsQuery, ItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemsQuery, ItemsQueryVariables>(ItemsDocument, options);
}
export type ItemsQueryHookResult = ReturnType<typeof useItemsQuery>;
export type ItemsLazyQueryHookResult = ReturnType<typeof useItemsLazyQuery>;
export type ItemsQueryResult = Apollo.QueryResult<ItemsQuery, ItemsQueryVariables>;
export const PageReviewDocument = gql`
  mutation PageReview($input: ReviewInput!) {
    reviewSave(input: $input) {
      status
    }
  }
`;
export type PageReviewMutationFn = Apollo.MutationFunction<PageReviewMutation, PageReviewMutationVariables>;

/**
 * __usePageReviewMutation__
 *
 * To run a mutation, you first call `usePageReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePageReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pageReviewMutation, { data, loading, error }] = usePageReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePageReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<PageReviewMutation, PageReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PageReviewMutation, PageReviewMutationVariables>(PageReviewDocument, options);
}
export type PageReviewMutationHookResult = ReturnType<typeof usePageReviewMutation>;
export type PageReviewMutationResult = Apollo.MutationResult<PageReviewMutation>;
export type PageReviewMutationOptions = Apollo.BaseMutationOptions<PageReviewMutation, PageReviewMutationVariables>;
export const TimeZoneDocument = gql`
  query TimeZone($input: TimeZoneInput!) {
    timeZone(input: $input)
  }
`;

/**
 * __useTimeZoneQuery__
 *
 * To run a query within a React component, call `useTimeZoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeZoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeZoneQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTimeZoneQuery(baseOptions: Apollo.QueryHookOptions<TimeZoneQuery, TimeZoneQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimeZoneQuery, TimeZoneQueryVariables>(TimeZoneDocument, options);
}
export function useTimeZoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeZoneQuery, TimeZoneQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimeZoneQuery, TimeZoneQueryVariables>(TimeZoneDocument, options);
}
export type TimeZoneQueryHookResult = ReturnType<typeof useTimeZoneQuery>;
export type TimeZoneLazyQueryHookResult = ReturnType<typeof useTimeZoneLazyQuery>;
export type TimeZoneQueryResult = Apollo.QueryResult<TimeZoneQuery, TimeZoneQueryVariables>;
export const ValidPhoneNumberDocument = gql`
  query ValidPhoneNumber($phoneNumber: PhoneNumber!) {
    validPhoneNumber(phoneNumber: $phoneNumber)
  }
`;

/**
 * __useValidPhoneNumberQuery__
 *
 * To run a query within a React component, call `useValidPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidPhoneNumberQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useValidPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<ValidPhoneNumberQuery, ValidPhoneNumberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidPhoneNumberQuery, ValidPhoneNumberQueryVariables>(ValidPhoneNumberDocument, options);
}
export function useValidPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidPhoneNumberQuery, ValidPhoneNumberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidPhoneNumberQuery, ValidPhoneNumberQueryVariables>(ValidPhoneNumberDocument, options);
}
export type ValidPhoneNumberQueryHookResult = ReturnType<typeof useValidPhoneNumberQuery>;
export type ValidPhoneNumberLazyQueryHookResult = ReturnType<typeof useValidPhoneNumberLazyQuery>;
export type ValidPhoneNumberQueryResult = Apollo.QueryResult<ValidPhoneNumberQuery, ValidPhoneNumberQueryVariables>;
export const WaitlistEligibleDocument = gql`
  query WaitlistEligible {
    eligible: waitlistEligible
  }
`;

/**
 * __useWaitlistEligibleQuery__
 *
 * To run a query within a React component, call `useWaitlistEligibleQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaitlistEligibleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaitlistEligibleQuery({
 *   variables: {
 *   },
 * });
 */
export function useWaitlistEligibleQuery(
  baseOptions?: Apollo.QueryHookOptions<WaitlistEligibleQuery, WaitlistEligibleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WaitlistEligibleQuery, WaitlistEligibleQueryVariables>(WaitlistEligibleDocument, options);
}
export function useWaitlistEligibleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WaitlistEligibleQuery, WaitlistEligibleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WaitlistEligibleQuery, WaitlistEligibleQueryVariables>(WaitlistEligibleDocument, options);
}
export type WaitlistEligibleQueryHookResult = ReturnType<typeof useWaitlistEligibleQuery>;
export type WaitlistEligibleLazyQueryHookResult = ReturnType<typeof useWaitlistEligibleLazyQuery>;
export type WaitlistEligibleQueryResult = Apollo.QueryResult<WaitlistEligibleQuery, WaitlistEligibleQueryVariables>;
export const CustomerEmailDocument = gql`
  query CustomerEmail {
    customer {
      id
      email
    }
  }
`;

/**
 * __useCustomerEmailQuery__
 *
 * To run a query within a React component, call `useCustomerEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerEmailQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerEmailQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerEmailQuery, CustomerEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerEmailQuery, CustomerEmailQueryVariables>(CustomerEmailDocument, options);
}
export function useCustomerEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerEmailQuery, CustomerEmailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerEmailQuery, CustomerEmailQueryVariables>(CustomerEmailDocument, options);
}
export type CustomerEmailQueryHookResult = ReturnType<typeof useCustomerEmailQuery>;
export type CustomerEmailLazyQueryHookResult = ReturnType<typeof useCustomerEmailLazyQuery>;
export type CustomerEmailQueryResult = Apollo.QueryResult<CustomerEmailQuery, CustomerEmailQueryVariables>;
export const SendReceiptDocument = gql`
  mutation SendReceipt($invoiceID: ID!) {
    sendReceipt(invoiceID: $invoiceID) {
      status
      error
    }
  }
`;
export type SendReceiptMutationFn = Apollo.MutationFunction<SendReceiptMutation, SendReceiptMutationVariables>;

/**
 * __useSendReceiptMutation__
 *
 * To run a mutation, you first call `useSendReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReceiptMutation, { data, loading, error }] = useSendReceiptMutation({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *   },
 * });
 */
export function useSendReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<SendReceiptMutation, SendReceiptMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendReceiptMutation, SendReceiptMutationVariables>(SendReceiptDocument, options);
}
export type SendReceiptMutationHookResult = ReturnType<typeof useSendReceiptMutation>;
export type SendReceiptMutationResult = Apollo.MutationResult<SendReceiptMutation>;
export type SendReceiptMutationOptions = Apollo.BaseMutationOptions<SendReceiptMutation, SendReceiptMutationVariables>;
export const InvoiceDetailDocument = gql`
  query InvoiceDetail($invoiceID: ID!) {
    invoice(invoiceID: $invoiceID) {
      ...InvoiceDetail
    }
  }
  ${InvoiceDetailFragmentDoc}
`;

/**
 * __useInvoiceDetailQuery__
 *
 * To run a query within a React component, call `useInvoiceDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceDetailQuery({
 *   variables: {
 *      invoiceID: // value for 'invoiceID'
 *   },
 * });
 */
export function useInvoiceDetailQuery(
  baseOptions: Apollo.QueryHookOptions<InvoiceDetailQuery, InvoiceDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>(InvoiceDetailDocument, options);
}
export function useInvoiceDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvoiceDetailQuery, InvoiceDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceDetailQuery, InvoiceDetailQueryVariables>(InvoiceDetailDocument, options);
}
export type InvoiceDetailQueryHookResult = ReturnType<typeof useInvoiceDetailQuery>;
export type InvoiceDetailLazyQueryHookResult = ReturnType<typeof useInvoiceDetailLazyQuery>;
export type InvoiceDetailQueryResult = Apollo.QueryResult<InvoiceDetailQuery, InvoiceDetailQueryVariables>;
export const SwapPaymentMethodDocument = gql`
  mutation SwapPaymentMethod($input: Billing__SwapPaymentMethodInput!) {
    swapPaymentMethod(input: $input) {
      status
      error
      swapped
    }
  }
`;
export type SwapPaymentMethodMutationFn = Apollo.MutationFunction<
  SwapPaymentMethodMutation,
  SwapPaymentMethodMutationVariables
>;

/**
 * __useSwapPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSwapPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwapPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [swapPaymentMethodMutation, { data, loading, error }] = useSwapPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwapPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<SwapPaymentMethodMutation, SwapPaymentMethodMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SwapPaymentMethodMutation, SwapPaymentMethodMutationVariables>(
    SwapPaymentMethodDocument,
    options,
  );
}
export type SwapPaymentMethodMutationHookResult = ReturnType<typeof useSwapPaymentMethodMutation>;
export type SwapPaymentMethodMutationResult = Apollo.MutationResult<SwapPaymentMethodMutation>;
export type SwapPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  SwapPaymentMethodMutation,
  SwapPaymentMethodMutationVariables
>;
export const CustomerItemDocument = gql`
  query CustomerItem($uuid: ID!, $type: CustomerItemTypeEnum) {
    customerItem(uuid: $uuid, type: $type) {
      ...customerItem
    }
  }
  ${CustomerItemFragmentDoc}
`;

/**
 * __useCustomerItemQuery__
 *
 * To run a query within a React component, call `useCustomerItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerItemQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCustomerItemQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerItemQuery, CustomerItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerItemQuery, CustomerItemQueryVariables>(CustomerItemDocument, options);
}
export function useCustomerItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerItemQuery, CustomerItemQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerItemQuery, CustomerItemQueryVariables>(CustomerItemDocument, options);
}
export type CustomerItemQueryHookResult = ReturnType<typeof useCustomerItemQuery>;
export type CustomerItemLazyQueryHookResult = ReturnType<typeof useCustomerItemLazyQuery>;
export type CustomerItemQueryResult = Apollo.QueryResult<CustomerItemQuery, CustomerItemQueryVariables>;
export const UpdateCustomerItemDocument = gql`
  mutation UpdateCustomerItem($uuid: ID!, $type: CustomerItemTypeEnum!, $input: CustomerItemInput!) {
    updateCustomerItem(uuid: $uuid, type: $type, input: $input) {
      item {
        ... on Item {
          uuid
          name
          contents
          hero
        }
        ... on ItemGroup {
          uuid
          name
          contents
          heroImage {
            ...image
          }
        }
      }
    }
  }
  ${ImageFragmentDoc}
`;
export type UpdateCustomerItemMutationFn = Apollo.MutationFunction<
  UpdateCustomerItemMutation,
  UpdateCustomerItemMutationVariables
>;

/**
 * __useUpdateCustomerItemMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerItemMutation, { data, loading, error }] = useUpdateCustomerItemMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      type: // value for 'type'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCustomerItemMutation, UpdateCustomerItemMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomerItemMutation, UpdateCustomerItemMutationVariables>(
    UpdateCustomerItemDocument,
    options,
  );
}
export type UpdateCustomerItemMutationHookResult = ReturnType<typeof useUpdateCustomerItemMutation>;
export type UpdateCustomerItemMutationResult = Apollo.MutationResult<UpdateCustomerItemMutation>;
export type UpdateCustomerItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerItemMutation,
  UpdateCustomerItemMutationVariables
>;
export const ItemDocument = gql`
  query Item($uuid: ID!) {
    item(uuid: $uuid) {
      id
      uuid
      name
      contents
      hero
      barcode {
        ...barcode
      }
      images {
        ...image
      }
      orders {
        id
        type
        number
        scheduled
        address {
          id
          aptsuite
          street
          city
          state
          zip
        }
      }
    }
  }
  ${BarcodeFragmentDoc}
  ${ImageFragmentDoc}
`;

/**
 * __useItemQuery__
 *
 * To run a query within a React component, call `useItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useItemQuery(baseOptions: Apollo.QueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
}
export function useItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemQuery, ItemQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemQuery, ItemQueryVariables>(ItemDocument, options);
}
export type ItemQueryHookResult = ReturnType<typeof useItemQuery>;
export type ItemLazyQueryHookResult = ReturnType<typeof useItemLazyQuery>;
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>;
export const InventoryDocument = gql`
  query Inventory {
    account {
      state
      onboardable
      requestableItemsExist
      rescheduleOfferDetails {
        token
        coupon {
          ...Account__Marketing__Coupon
        }
      }
      unscheduledStorageTreasuresAuctionsWon {
        ...storageTreasuresAuction
      }
    }
    orders(onlyActive: false) {
      ...order
    }
    needSignature
  }
  ${Account__Marketing__CouponFragmentDoc}
  ${StorageTreasuresAuctionFragmentDoc}
  ${OrderFragmentDoc}
`;

/**
 * __useInventoryQuery__
 *
 * To run a query within a React component, call `useInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInventoryQuery(baseOptions?: Apollo.QueryHookOptions<InventoryQuery, InventoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
}
export function useInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InventoryQuery, InventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryQuery, InventoryQueryVariables>(InventoryDocument, options);
}
export type InventoryQueryHookResult = ReturnType<typeof useInventoryQuery>;
export type InventoryLazyQueryHookResult = ReturnType<typeof useInventoryLazyQuery>;
export type InventoryQueryResult = Apollo.QueryResult<InventoryQuery, InventoryQueryVariables>;
export const InventoryContentDocument = gql`
  query InventoryContent($filters: ItemFiltersInput!, $query: String, $page: Int!, $per: Int!) {
    paginatedCustomerItems(filters: $filters, page: $page, per: $per) {
      results {
        ...customerItem
      }
      pagination {
        ...pagination
      }
    }
    available: itemsCount(filters: { status: AVAILABLE, query: $query })
    requested: itemsCount(filters: { status: REQUESTED, query: $query })
    returned: itemsCount(filters: { status: RETURNED, query: $query })
    delayed: itemsCount(filters: { status: DELAYED, query: $query })
  }
  ${CustomerItemFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useInventoryContentQuery__
 *
 * To run a query within a React component, call `useInventoryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryContentQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      per: // value for 'per'
 *   },
 * });
 */
export function useInventoryContentQuery(
  baseOptions: Apollo.QueryHookOptions<InventoryContentQuery, InventoryContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryContentQuery, InventoryContentQueryVariables>(InventoryContentDocument, options);
}
export function useInventoryContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InventoryContentQuery, InventoryContentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryContentQuery, InventoryContentQueryVariables>(InventoryContentDocument, options);
}
export type InventoryContentQueryHookResult = ReturnType<typeof useInventoryContentQuery>;
export type InventoryContentLazyQueryHookResult = ReturnType<typeof useInventoryContentLazyQuery>;
export type InventoryContentQueryResult = Apollo.QueryResult<InventoryContentQuery, InventoryContentQueryVariables>;
export const CustomerInitialSetupDocument = gql`
  mutation CustomerInitialSetup($input: CustomerInitialSetupInput!) {
    customerInitialSetup(input: $input) {
      error
      status
    }
  }
`;
export type CustomerInitialSetupMutationFn = Apollo.MutationFunction<
  CustomerInitialSetupMutation,
  CustomerInitialSetupMutationVariables
>;

/**
 * __useCustomerInitialSetupMutation__
 *
 * To run a mutation, you first call `useCustomerInitialSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerInitialSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerInitialSetupMutation, { data, loading, error }] = useCustomerInitialSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerInitialSetupMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerInitialSetupMutation, CustomerInitialSetupMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerInitialSetupMutation, CustomerInitialSetupMutationVariables>(
    CustomerInitialSetupDocument,
    options,
  );
}
export type CustomerInitialSetupMutationHookResult = ReturnType<typeof useCustomerInitialSetupMutation>;
export type CustomerInitialSetupMutationResult = Apollo.MutationResult<CustomerInitialSetupMutation>;
export type CustomerInitialSetupMutationOptions = Apollo.BaseMutationOptions<
  CustomerInitialSetupMutation,
  CustomerInitialSetupMutationVariables
>;
export const FirstTimeCustomerDocument = gql`
  query FirstTimeCustomer($initialSetupToken: String!) {
    firstTimeCustomer(initialSetupToken: $initialSetupToken) {
      id
    }
  }
`;

/**
 * __useFirstTimeCustomerQuery__
 *
 * To run a query within a React component, call `useFirstTimeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFirstTimeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFirstTimeCustomerQuery({
 *   variables: {
 *      initialSetupToken: // value for 'initialSetupToken'
 *   },
 * });
 */
export function useFirstTimeCustomerQuery(
  baseOptions: Apollo.QueryHookOptions<FirstTimeCustomerQuery, FirstTimeCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FirstTimeCustomerQuery, FirstTimeCustomerQueryVariables>(FirstTimeCustomerDocument, options);
}
export function useFirstTimeCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FirstTimeCustomerQuery, FirstTimeCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FirstTimeCustomerQuery, FirstTimeCustomerQueryVariables>(
    FirstTimeCustomerDocument,
    options,
  );
}
export type FirstTimeCustomerQueryHookResult = ReturnType<typeof useFirstTimeCustomerQuery>;
export type FirstTimeCustomerLazyQueryHookResult = ReturnType<typeof useFirstTimeCustomerLazyQuery>;
export type FirstTimeCustomerQueryResult = Apollo.QueryResult<FirstTimeCustomerQuery, FirstTimeCustomerQueryVariables>;
export const MovingAddressUpdateDocument = gql`
  mutation MovingAddressUpdate($input: Moving__AddressUpdateInput!) {
    movingAddressUpdate(input: $input) {
      error
      status
    }
  }
`;
export type MovingAddressUpdateMutationFn = Apollo.MutationFunction<
  MovingAddressUpdateMutation,
  MovingAddressUpdateMutationVariables
>;

/**
 * __useMovingAddressUpdateMutation__
 *
 * To run a mutation, you first call `useMovingAddressUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingAddressUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingAddressUpdateMutation, { data, loading, error }] = useMovingAddressUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovingAddressUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingAddressUpdateMutation, MovingAddressUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingAddressUpdateMutation, MovingAddressUpdateMutationVariables>(
    MovingAddressUpdateDocument,
    options,
  );
}
export type MovingAddressUpdateMutationHookResult = ReturnType<typeof useMovingAddressUpdateMutation>;
export type MovingAddressUpdateMutationResult = Apollo.MutationResult<MovingAddressUpdateMutation>;
export type MovingAddressUpdateMutationOptions = Apollo.BaseMutationOptions<
  MovingAddressUpdateMutation,
  MovingAddressUpdateMutationVariables
>;
export const ReonboardingCheckoutLinksDocument = gql`
  query ReonboardingCheckoutLinks {
    movingLink: reonboardingCheckoutLink(type: MOVE)
    storageLink: reonboardingCheckoutLink(type: PICKUP)
  }
`;

/**
 * __useReonboardingCheckoutLinksQuery__
 *
 * To run a query within a React component, call `useReonboardingCheckoutLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useReonboardingCheckoutLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReonboardingCheckoutLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useReonboardingCheckoutLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<ReonboardingCheckoutLinksQuery, ReonboardingCheckoutLinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReonboardingCheckoutLinksQuery, ReonboardingCheckoutLinksQueryVariables>(
    ReonboardingCheckoutLinksDocument,
    options,
  );
}
export function useReonboardingCheckoutLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReonboardingCheckoutLinksQuery, ReonboardingCheckoutLinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReonboardingCheckoutLinksQuery, ReonboardingCheckoutLinksQueryVariables>(
    ReonboardingCheckoutLinksDocument,
    options,
  );
}
export type ReonboardingCheckoutLinksQueryHookResult = ReturnType<typeof useReonboardingCheckoutLinksQuery>;
export type ReonboardingCheckoutLinksLazyQueryHookResult = ReturnType<typeof useReonboardingCheckoutLinksLazyQuery>;
export type ReonboardingCheckoutLinksQueryResult = Apollo.QueryResult<
  ReonboardingCheckoutLinksQuery,
  ReonboardingCheckoutLinksQueryVariables
>;
export const OrdersAccountDocument = gql`
  query OrdersAccount {
    account {
      bannedFromBooking
      suspended
      termCommitment {
        ...termCommitment
      }
      state
      unscheduledStorageTreasuresAuctionsWon {
        ...storageTreasuresAuction
      }
    }
    needSignature
    inboundShippingEligible
  }
  ${TermCommitmentFragmentDoc}
  ${StorageTreasuresAuctionFragmentDoc}
`;

/**
 * __useOrdersAccountQuery__
 *
 * To run a query within a React component, call `useOrdersAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersAccountQuery(
  baseOptions?: Apollo.QueryHookOptions<OrdersAccountQuery, OrdersAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersAccountQuery, OrdersAccountQueryVariables>(OrdersAccountDocument, options);
}
export function useOrdersAccountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersAccountQuery, OrdersAccountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersAccountQuery, OrdersAccountQueryVariables>(OrdersAccountDocument, options);
}
export type OrdersAccountQueryHookResult = ReturnType<typeof useOrdersAccountQuery>;
export type OrdersAccountLazyQueryHookResult = ReturnType<typeof useOrdersAccountLazyQuery>;
export type OrdersAccountQueryResult = Apollo.QueryResult<OrdersAccountQuery, OrdersAccountQueryVariables>;
export const AcceptRetentionOfferDocument = gql`
  mutation AcceptRetentionOffer($offerID: ID!) {
    accept: acceptRetentionOffer(offerID: $offerID) {
      status
      error
      storageRate
    }
  }
`;
export type AcceptRetentionOfferMutationFn = Apollo.MutationFunction<
  AcceptRetentionOfferMutation,
  AcceptRetentionOfferMutationVariables
>;

/**
 * __useAcceptRetentionOfferMutation__
 *
 * To run a mutation, you first call `useAcceptRetentionOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRetentionOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRetentionOfferMutation, { data, loading, error }] = useAcceptRetentionOfferMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *   },
 * });
 */
export function useAcceptRetentionOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptRetentionOfferMutation, AcceptRetentionOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptRetentionOfferMutation, AcceptRetentionOfferMutationVariables>(
    AcceptRetentionOfferDocument,
    options,
  );
}
export type AcceptRetentionOfferMutationHookResult = ReturnType<typeof useAcceptRetentionOfferMutation>;
export type AcceptRetentionOfferMutationResult = Apollo.MutationResult<AcceptRetentionOfferMutation>;
export type AcceptRetentionOfferMutationOptions = Apollo.BaseMutationOptions<
  AcceptRetentionOfferMutation,
  AcceptRetentionOfferMutationVariables
>;
export const CurrentStorageSubscriptionDocument = gql`
  query CurrentStorageSubscription {
    account {
      id
      subscriptionSet {
        storageSubscriptions {
          id
          total
        }
      }
    }
  }
`;

/**
 * __useCurrentStorageSubscriptionQuery__
 *
 * To run a query within a React component, call `useCurrentStorageSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentStorageSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentStorageSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentStorageSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentStorageSubscriptionQuery, CurrentStorageSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentStorageSubscriptionQuery, CurrentStorageSubscriptionQueryVariables>(
    CurrentStorageSubscriptionDocument,
    options,
  );
}
export function useCurrentStorageSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentStorageSubscriptionQuery, CurrentStorageSubscriptionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentStorageSubscriptionQuery, CurrentStorageSubscriptionQueryVariables>(
    CurrentStorageSubscriptionDocument,
    options,
  );
}
export type CurrentStorageSubscriptionQueryHookResult = ReturnType<typeof useCurrentStorageSubscriptionQuery>;
export type CurrentStorageSubscriptionLazyQueryHookResult = ReturnType<typeof useCurrentStorageSubscriptionLazyQuery>;
export type CurrentStorageSubscriptionQueryResult = Apollo.QueryResult<
  CurrentStorageSubscriptionQuery,
  CurrentStorageSubscriptionQueryVariables
>;
export const UncancellableShipmentOrderIDsDocument = gql`
  query UncancellableShipmentOrderIDs($orderIDs: [ID!]!) {
    uncancellableShipmentOrderIds(orderIds: $orderIDs)
  }
`;

/**
 * __useUncancellableShipmentOrderIDsQuery__
 *
 * To run a query within a React component, call `useUncancellableShipmentOrderIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUncancellableShipmentOrderIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUncancellableShipmentOrderIDsQuery({
 *   variables: {
 *      orderIDs: // value for 'orderIDs'
 *   },
 * });
 */
export function useUncancellableShipmentOrderIDsQuery(
  baseOptions: Apollo.QueryHookOptions<UncancellableShipmentOrderIDsQuery, UncancellableShipmentOrderIDsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UncancellableShipmentOrderIDsQuery, UncancellableShipmentOrderIDsQueryVariables>(
    UncancellableShipmentOrderIDsDocument,
    options,
  );
}
export function useUncancellableShipmentOrderIDsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UncancellableShipmentOrderIDsQuery,
    UncancellableShipmentOrderIDsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UncancellableShipmentOrderIDsQuery, UncancellableShipmentOrderIDsQueryVariables>(
    UncancellableShipmentOrderIDsDocument,
    options,
  );
}
export type UncancellableShipmentOrderIDsQueryHookResult = ReturnType<typeof useUncancellableShipmentOrderIDsQuery>;
export type UncancellableShipmentOrderIDsLazyQueryHookResult = ReturnType<
  typeof useUncancellableShipmentOrderIDsLazyQuery
>;
export type UncancellableShipmentOrderIDsQueryResult = Apollo.QueryResult<
  UncancellableShipmentOrderIDsQuery,
  UncancellableShipmentOrderIDsQueryVariables
>;
export const UpdateOrderAddressDocument = gql`
  mutation UpdateOrderAddress($orderID: ID!, $addressInput: AddressInput!) {
    result: updateOrderAddress(orderID: $orderID, addressInput: $addressInput) {
      error
      status
    }
  }
`;
export type UpdateOrderAddressMutationFn = Apollo.MutationFunction<
  UpdateOrderAddressMutation,
  UpdateOrderAddressMutationVariables
>;

/**
 * __useUpdateOrderAddressMutation__
 *
 * To run a mutation, you first call `useUpdateOrderAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderAddressMutation, { data, loading, error }] = useUpdateOrderAddressMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      addressInput: // value for 'addressInput'
 *   },
 * });
 */
export function useUpdateOrderAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderAddressMutation, UpdateOrderAddressMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderAddressMutation, UpdateOrderAddressMutationVariables>(
    UpdateOrderAddressDocument,
    options,
  );
}
export type UpdateOrderAddressMutationHookResult = ReturnType<typeof useUpdateOrderAddressMutation>;
export type UpdateOrderAddressMutationResult = Apollo.MutationResult<UpdateOrderAddressMutation>;
export type UpdateOrderAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderAddressMutation,
  UpdateOrderAddressMutationVariables
>;
export const OrderFormAddressSelectorDocument = gql`
  query OrderFormAddressSelector($orderID: ID!) {
    order(orderID: $orderID) {
      id
      number
      scheduled
      services {
        type
        subtype
      }
      address {
        ...address
        details {
          ...details
        }
      }
      permissions {
        id
        relocatable
      }
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
`;

/**
 * __useOrderFormAddressSelectorQuery__
 *
 * To run a query within a React component, call `useOrderFormAddressSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFormAddressSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFormAddressSelectorQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFormAddressSelectorQuery(
  baseOptions: Apollo.QueryHookOptions<OrderFormAddressSelectorQuery, OrderFormAddressSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderFormAddressSelectorQuery, OrderFormAddressSelectorQueryVariables>(
    OrderFormAddressSelectorDocument,
    options,
  );
}
export function useOrderFormAddressSelectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderFormAddressSelectorQuery, OrderFormAddressSelectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderFormAddressSelectorQuery, OrderFormAddressSelectorQueryVariables>(
    OrderFormAddressSelectorDocument,
    options,
  );
}
export type OrderFormAddressSelectorQueryHookResult = ReturnType<typeof useOrderFormAddressSelectorQuery>;
export type OrderFormAddressSelectorLazyQueryHookResult = ReturnType<typeof useOrderFormAddressSelectorLazyQuery>;
export type OrderFormAddressSelectorQueryResult = Apollo.QueryResult<
  OrderFormAddressSelectorQuery,
  OrderFormAddressSelectorQueryVariables
>;
export const AvailabilityTiersDocument = gql`
  query AvailabilityTiers($input: AvailabilityTiersInputType!) {
    availabilityTiers(input: $input) {
      ...availabilityTier
    }
  }
  ${AvailabilityTierFragmentDoc}
`;

/**
 * __useAvailabilityTiersQuery__
 *
 * To run a query within a React component, call `useAvailabilityTiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityTiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailabilityTiersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityTiersQuery(
  baseOptions: Apollo.QueryHookOptions<AvailabilityTiersQuery, AvailabilityTiersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailabilityTiersQuery, AvailabilityTiersQueryVariables>(AvailabilityTiersDocument, options);
}
export function useAvailabilityTiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailabilityTiersQuery, AvailabilityTiersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailabilityTiersQuery, AvailabilityTiersQueryVariables>(
    AvailabilityTiersDocument,
    options,
  );
}
export type AvailabilityTiersQueryHookResult = ReturnType<typeof useAvailabilityTiersQuery>;
export type AvailabilityTiersLazyQueryHookResult = ReturnType<typeof useAvailabilityTiersLazyQuery>;
export type AvailabilityTiersQueryResult = Apollo.QueryResult<AvailabilityTiersQuery, AvailabilityTiersQueryVariables>;
export const BuildOnboardingCancelIntentDocument = gql`
  mutation BuildOnboardingCancelIntent(
    $reason: Onboarding__CancelIntent__Reason!
    $orderID: ID!
    $otherReason: String
  ) {
    build: buildOnboardingCancelIntent(reason: $reason, orderID: $orderID, otherReason: $otherReason) {
      status
      error
      cancelIntent {
        id
        reason
      }
      retentionOffer {
        ...retentionOffer
      }
    }
  }
  ${RetentionOfferFragmentDoc}
`;
export type BuildOnboardingCancelIntentMutationFn = Apollo.MutationFunction<
  BuildOnboardingCancelIntentMutation,
  BuildOnboardingCancelIntentMutationVariables
>;

/**
 * __useBuildOnboardingCancelIntentMutation__
 *
 * To run a mutation, you first call `useBuildOnboardingCancelIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildOnboardingCancelIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildOnboardingCancelIntentMutation, { data, loading, error }] = useBuildOnboardingCancelIntentMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      orderID: // value for 'orderID'
 *      otherReason: // value for 'otherReason'
 *   },
 * });
 */
export function useBuildOnboardingCancelIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildOnboardingCancelIntentMutation,
    BuildOnboardingCancelIntentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildOnboardingCancelIntentMutation, BuildOnboardingCancelIntentMutationVariables>(
    BuildOnboardingCancelIntentDocument,
    options,
  );
}
export type BuildOnboardingCancelIntentMutationHookResult = ReturnType<typeof useBuildOnboardingCancelIntentMutation>;
export type BuildOnboardingCancelIntentMutationResult = Apollo.MutationResult<BuildOnboardingCancelIntentMutation>;
export type BuildOnboardingCancelIntentMutationOptions = Apollo.BaseMutationOptions<
  BuildOnboardingCancelIntentMutation,
  BuildOnboardingCancelIntentMutationVariables
>;
export const MovingCancelIntentCreateDocument = gql`
  mutation MovingCancelIntentCreate($reason: Moving__CancelIntent__Reason!, $orderID: ID!, $otherReason: String) {
    create: movingCancelIntentCreate(reason: $reason, orderID: $orderID, otherReason: $otherReason) {
      status
      error
      retentionOffer {
        ...retentionOffer
      }
    }
  }
  ${RetentionOfferFragmentDoc}
`;
export type MovingCancelIntentCreateMutationFn = Apollo.MutationFunction<
  MovingCancelIntentCreateMutation,
  MovingCancelIntentCreateMutationVariables
>;

/**
 * __useMovingCancelIntentCreateMutation__
 *
 * To run a mutation, you first call `useMovingCancelIntentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingCancelIntentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingCancelIntentCreateMutation, { data, loading, error }] = useMovingCancelIntentCreateMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      orderID: // value for 'orderID'
 *      otherReason: // value for 'otherReason'
 *   },
 * });
 */
export function useMovingCancelIntentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingCancelIntentCreateMutation, MovingCancelIntentCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingCancelIntentCreateMutation, MovingCancelIntentCreateMutationVariables>(
    MovingCancelIntentCreateDocument,
    options,
  );
}
export type MovingCancelIntentCreateMutationHookResult = ReturnType<typeof useMovingCancelIntentCreateMutation>;
export type MovingCancelIntentCreateMutationResult = Apollo.MutationResult<MovingCancelIntentCreateMutation>;
export type MovingCancelIntentCreateMutationOptions = Apollo.BaseMutationOptions<
  MovingCancelIntentCreateMutation,
  MovingCancelIntentCreateMutationVariables
>;
export const StorageSubscriptionsDocument = gql`
  query StorageSubscriptions {
    account {
      upcomingSubscriptionSet {
        storageSubscriptions {
          ...upcomingSubscription
        }
      }
    }
  }
  ${UpcomingSubscriptionFragmentDoc}
`;

/**
 * __useStorageSubscriptionsQuery__
 *
 * To run a query within a React component, call `useStorageSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorageSubscriptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>(
    StorageSubscriptionsDocument,
    options,
  );
}
export function useStorageSubscriptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StorageSubscriptionsQuery, StorageSubscriptionsQueryVariables>(
    StorageSubscriptionsDocument,
    options,
  );
}
export type StorageSubscriptionsQueryHookResult = ReturnType<typeof useStorageSubscriptionsQuery>;
export type StorageSubscriptionsLazyQueryHookResult = ReturnType<typeof useStorageSubscriptionsLazyQuery>;
export type StorageSubscriptionsQueryResult = Apollo.QueryResult<
  StorageSubscriptionsQuery,
  StorageSubscriptionsQueryVariables
>;
export const CancelOrderDocument = gql`
  mutation CancelOrder($orderID: ID!, $skipCancellationFee: Boolean) {
    cancelOrder(orderID: $orderID, skipCancellationFee: $skipCancellationFee) {
      error
      status
    }
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<CancelOrderMutation, CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      skipCancellationFee: // value for 'skipCancellationFee'
 *   },
 * });
 */
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelOrderMutation, CancelOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(CancelOrderDocument, options);
}
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<CancelOrderMutation, CancelOrderMutationVariables>;
export const BuildOrderCoiRequestDocument = gql`
  mutation BuildOrderCoiRequest($input: OrderCOIRequestInput!) {
    buildOrderCoiRequest(input: $input) {
      error
      status
    }
  }
`;
export type BuildOrderCoiRequestMutationFn = Apollo.MutationFunction<
  BuildOrderCoiRequestMutation,
  BuildOrderCoiRequestMutationVariables
>;

/**
 * __useBuildOrderCoiRequestMutation__
 *
 * To run a mutation, you first call `useBuildOrderCoiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildOrderCoiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildOrderCoiRequestMutation, { data, loading, error }] = useBuildOrderCoiRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildOrderCoiRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildOrderCoiRequestMutation, BuildOrderCoiRequestMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildOrderCoiRequestMutation, BuildOrderCoiRequestMutationVariables>(
    BuildOrderCoiRequestDocument,
    options,
  );
}
export type BuildOrderCoiRequestMutationHookResult = ReturnType<typeof useBuildOrderCoiRequestMutation>;
export type BuildOrderCoiRequestMutationResult = Apollo.MutationResult<BuildOrderCoiRequestMutation>;
export type BuildOrderCoiRequestMutationOptions = Apollo.BaseMutationOptions<
  BuildOrderCoiRequestMutation,
  BuildOrderCoiRequestMutationVariables
>;
export const OrderCertificateOfInsuranceDocument = gql`
  query OrderCertificateOfInsurance($orderID: ID!) {
    order(orderID: $orderID) {
      id
      scheduled
      serviceType
      account {
        customer {
          id
          email
        }
      }
    }
    addresses {
      id
      formatted
    }
  }
`;

/**
 * __useOrderCertificateOfInsuranceQuery__
 *
 * To run a query within a React component, call `useOrderCertificateOfInsuranceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderCertificateOfInsuranceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderCertificateOfInsuranceQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderCertificateOfInsuranceQuery(
  baseOptions: Apollo.QueryHookOptions<OrderCertificateOfInsuranceQuery, OrderCertificateOfInsuranceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderCertificateOfInsuranceQuery, OrderCertificateOfInsuranceQueryVariables>(
    OrderCertificateOfInsuranceDocument,
    options,
  );
}
export function useOrderCertificateOfInsuranceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderCertificateOfInsuranceQuery,
    OrderCertificateOfInsuranceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderCertificateOfInsuranceQuery, OrderCertificateOfInsuranceQueryVariables>(
    OrderCertificateOfInsuranceDocument,
    options,
  );
}
export type OrderCertificateOfInsuranceQueryHookResult = ReturnType<typeof useOrderCertificateOfInsuranceQuery>;
export type OrderCertificateOfInsuranceLazyQueryHookResult = ReturnType<typeof useOrderCertificateOfInsuranceLazyQuery>;
export type OrderCertificateOfInsuranceQueryResult = Apollo.QueryResult<
  OrderCertificateOfInsuranceQuery,
  OrderCertificateOfInsuranceQueryVariables
>;
export const ConfirmOrderDocument = gql`
  mutation ConfirmOrder($token: ID!) {
    confirm: confirmOrder(token: $token) {
      status
      error
      order {
        id
        scheduled
        accountReferralCode
      }
    }
  }
`;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<ConfirmOrderMutation, ConfirmOrderMutationVariables>;

/**
 * __useConfirmOrderMutation__
 *
 * To run a mutation, you first call `useConfirmOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmOrderMutation, { data, loading, error }] = useConfirmOrderMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmOrderMutation, ConfirmOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmOrderMutation, ConfirmOrderMutationVariables>(ConfirmOrderDocument, options);
}
export type ConfirmOrderMutationHookResult = ReturnType<typeof useConfirmOrderMutation>;
export type ConfirmOrderMutationResult = Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderMutation,
  ConfirmOrderMutationVariables
>;
export const UpdateOrderContactDocument = gql`
  mutation UpdateOrderContact($input: OrderContactInput!) {
    updateOrderContact(input: $input) {
      error
      status
      order {
        id
        contact {
          ...contact
        }
      }
    }
  }
  ${ContactFragmentDoc}
`;
export type UpdateOrderContactMutationFn = Apollo.MutationFunction<
  UpdateOrderContactMutation,
  UpdateOrderContactMutationVariables
>;

/**
 * __useUpdateOrderContactMutation__
 *
 * To run a mutation, you first call `useUpdateOrderContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderContactMutation, { data, loading, error }] = useUpdateOrderContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrderContactMutation, UpdateOrderContactMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderContactMutation, UpdateOrderContactMutationVariables>(
    UpdateOrderContactDocument,
    options,
  );
}
export type UpdateOrderContactMutationHookResult = ReturnType<typeof useUpdateOrderContactMutation>;
export type UpdateOrderContactMutationResult = Apollo.MutationResult<UpdateOrderContactMutation>;
export type UpdateOrderContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderContactMutation,
  UpdateOrderContactMutationVariables
>;
export const OrderFormContactDocument = gql`
  query OrderFormContact($orderID: ID!) {
    order(orderID: $orderID) {
      id
      number
      scheduled
      serviceType
      pickupVehicleKind
      contact {
        ...contact
      }
      permissions {
        id
        locked
      }
    }
    account {
      customer {
        ...customer
      }
    }
  }
  ${ContactFragmentDoc}
  ${CustomerFragmentDoc}
`;

/**
 * __useOrderFormContactQuery__
 *
 * To run a query within a React component, call `useOrderFormContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFormContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFormContactQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFormContactQuery(
  baseOptions: Apollo.QueryHookOptions<OrderFormContactQuery, OrderFormContactQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderFormContactQuery, OrderFormContactQueryVariables>(OrderFormContactDocument, options);
}
export function useOrderFormContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderFormContactQuery, OrderFormContactQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderFormContactQuery, OrderFormContactQueryVariables>(OrderFormContactDocument, options);
}
export type OrderFormContactQueryHookResult = ReturnType<typeof useOrderFormContactQuery>;
export type OrderFormContactLazyQueryHookResult = ReturnType<typeof useOrderFormContactLazyQuery>;
export type OrderFormContactQueryResult = Apollo.QueryResult<OrderFormContactQuery, OrderFormContactQueryVariables>;
export const OrderDetailsDocument = gql`
  query OrderDetails($orderID: ID!) {
    estimationCategories {
      ...estimationCategory
    }
    roomCategories {
      ...roomCategory
    }
    account {
      customer {
        id
        name
        phone
      }
    }
    order(orderID: $orderID) {
      id
      dropOffAppointment {
        id
        expectedAt
        status
        facility {
          fulfiller {
            ... on SelfStorage__Facility {
              id
            }
            ... on DropOff__ThirdPartySelfStorageFacility {
              id
              operator
            }
          }
          address {
            ...address
          }
        }
      }
      ...order
      movers
      account {
        customer {
          id
          firstName
          name
          phone
          email
        }
      }
      address {
        ...address
        details {
          ...details
        }
      }
      contact {
        ...contact
      }
      services {
        id
        type
        subtype
        ...pickupInventory
        ...returnInventory
      }
      permissions {
        id
        active
        locked
        cancelable
        relocatable
        reschedulable
        addDisposal
        changeablePickupInventory
        changeableReturnInventory
        chargeForCancellation
        cancellationFeeDisclaimer
        chargeLateFee
      }
      laborRate {
        id
        amount
      }
      laborCostDetails {
        ...laborCostDetails
      }
      coupon {
        ...Account__Marketing__Coupon
      }
      perItemPricingInfo {
        ...itemPricing
      }
      accountCancelIntent {
        reason
      }
      accountPackages {
        amount
        name
      }
      coiRequests {
        id
        state
        createdAt
        certificateOfInsurance {
          id
          url
        }
      }
      movingOperation {
        id
        originAddress {
          ...address
        }
        destinationAddress {
          ...address
        }
        movingConfiguration {
          id
          travelRateMultiplier
        }
      }
      retailDelivery {
        ...retailOrder
      }
      retailDeliveryItems {
        id
        quantity
      }
      shipped
      bookingPartner {
        ...Partnerships__Partner
      }
      facilityWarehouse {
        id
        accessHours
        accessInstructions
      }
      waitlistRequests {
        ...availabilityWaitlistRequest
      }
      pickupVehicleKind
      formattedArrivalWindow
      customerPinCode
      multiDay
      nonTravelSourceAndSuccessors {
        id
        scheduled
        type
        subtype
        window
      }
    }
  }
  ${EstimationCategoryFragmentDoc}
  ${RoomCategoryFragmentDoc}
  ${AddressFragmentDoc}
  ${OrderFragmentDoc}
  ${DetailsFragmentDoc}
  ${ContactFragmentDoc}
  ${PickupInventoryFragmentDoc}
  ${ReturnInventoryFragmentDoc}
  ${LaborCostDetailsFragmentDoc}
  ${Account__Marketing__CouponFragmentDoc}
  ${ItemPricingFragmentDoc}
  ${RetailOrderFragmentDoc}
  ${Partnerships__PartnerFragmentDoc}
  ${AvailabilityWaitlistRequestFragmentDoc}
`;

/**
 * __useOrderDetailsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export function useOrderDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsQuery, OrderDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(OrderDetailsDocument, options);
}
export type OrderDetailsQueryHookResult = ReturnType<typeof useOrderDetailsQuery>;
export type OrderDetailsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLazyQuery>;
export type OrderDetailsQueryResult = Apollo.QueryResult<OrderDetailsQuery, OrderDetailsQueryVariables>;
export const CancellationPolicyDocument = gql`
  query CancellationPolicy($orderID: ID!) {
    cancellationPolicy(orderID: $orderID)
  }
`;

/**
 * __useCancellationPolicyQuery__
 *
 * To run a query within a React component, call `useCancellationPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancellationPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancellationPolicyQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useCancellationPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<CancellationPolicyQuery, CancellationPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CancellationPolicyQuery, CancellationPolicyQueryVariables>(
    CancellationPolicyDocument,
    options,
  );
}
export function useCancellationPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CancellationPolicyQuery, CancellationPolicyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CancellationPolicyQuery, CancellationPolicyQueryVariables>(
    CancellationPolicyDocument,
    options,
  );
}
export type CancellationPolicyQueryHookResult = ReturnType<typeof useCancellationPolicyQuery>;
export type CancellationPolicyLazyQueryHookResult = ReturnType<typeof useCancellationPolicyLazyQuery>;
export type CancellationPolicyQueryResult = Apollo.QueryResult<
  CancellationPolicyQuery,
  CancellationPolicyQueryVariables
>;
export const ItemPricingDocument = gql`
  query ItemPricing($input: ItemPricingInput!) {
    itemPricing(input: $input) {
      ...itemPricing
    }
  }
  ${ItemPricingFragmentDoc}
`;

/**
 * __useItemPricingQuery__
 *
 * To run a query within a React component, call `useItemPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemPricingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useItemPricingQuery(baseOptions: Apollo.QueryHookOptions<ItemPricingQuery, ItemPricingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemPricingQuery, ItemPricingQueryVariables>(ItemPricingDocument, options);
}
export function useItemPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemPricingQuery, ItemPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemPricingQuery, ItemPricingQueryVariables>(ItemPricingDocument, options);
}
export type ItemPricingQueryHookResult = ReturnType<typeof useItemPricingQuery>;
export type ItemPricingLazyQueryHookResult = ReturnType<typeof useItemPricingLazyQuery>;
export type ItemPricingQueryResult = Apollo.QueryResult<ItemPricingQuery, ItemPricingQueryVariables>;
export const PlanDetailsDocument = gql`
  query PlanDetails {
    account {
      subscriptionSet {
        storageSubscriptions {
          id
          cubicFootage
          dimensions {
            ...dimensions
          }
          name
        }
      }
    }
  }
  ${DimensionsFragmentDoc}
`;

/**
 * __usePlanDetailsQuery__
 *
 * To run a query within a React component, call `usePlanDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<PlanDetailsQuery, PlanDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanDetailsQuery, PlanDetailsQueryVariables>(PlanDetailsDocument, options);
}
export function usePlanDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanDetailsQuery, PlanDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanDetailsQuery, PlanDetailsQueryVariables>(PlanDetailsDocument, options);
}
export type PlanDetailsQueryHookResult = ReturnType<typeof usePlanDetailsQuery>;
export type PlanDetailsLazyQueryHookResult = ReturnType<typeof usePlanDetailsLazyQuery>;
export type PlanDetailsQueryResult = Apollo.QueryResult<PlanDetailsQuery, PlanDetailsQueryVariables>;
export const DisposalCategoryPricingDocument = gql`
  query DisposalCategoryPricing {
    disposalCategoryPricing {
      category
      minimum
    }
  }
`;

/**
 * __useDisposalCategoryPricingQuery__
 *
 * To run a query within a React component, call `useDisposalCategoryPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalCategoryPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalCategoryPricingQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisposalCategoryPricingQuery(
  baseOptions?: Apollo.QueryHookOptions<DisposalCategoryPricingQuery, DisposalCategoryPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisposalCategoryPricingQuery, DisposalCategoryPricingQueryVariables>(
    DisposalCategoryPricingDocument,
    options,
  );
}
export function useDisposalCategoryPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DisposalCategoryPricingQuery, DisposalCategoryPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisposalCategoryPricingQuery, DisposalCategoryPricingQueryVariables>(
    DisposalCategoryPricingDocument,
    options,
  );
}
export type DisposalCategoryPricingQueryHookResult = ReturnType<typeof useDisposalCategoryPricingQuery>;
export type DisposalCategoryPricingLazyQueryHookResult = ReturnType<typeof useDisposalCategoryPricingLazyQuery>;
export type DisposalCategoryPricingQueryResult = Apollo.QueryResult<
  DisposalCategoryPricingQuery,
  DisposalCategoryPricingQueryVariables
>;
export const AttachedDisposalConfirmationDocument = gql`
  mutation AttachedDisposalConfirmation(
    $signedDisposalID: String!
    $disposalItemIDs: [ID!]!
    $storageItemIDs: [ID!]!
    $storagePlanConfig: Disposal__Confirm__StoragePlanInput
  ) {
    disposalConfirm(
      signedDisposalID: $signedDisposalID
      disposalItemIDs: $disposalItemIDs
      storageItemIDs: $storageItemIDs
      storagePlanConfig: $storagePlanConfig
    ) {
      status
      error
      disposal {
        id
      }
      signingURL
    }
  }
`;
export type AttachedDisposalConfirmationMutationFn = Apollo.MutationFunction<
  AttachedDisposalConfirmationMutation,
  AttachedDisposalConfirmationMutationVariables
>;

/**
 * __useAttachedDisposalConfirmationMutation__
 *
 * To run a mutation, you first call `useAttachedDisposalConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachedDisposalConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachedDisposalConfirmationMutation, { data, loading, error }] = useAttachedDisposalConfirmationMutation({
 *   variables: {
 *      signedDisposalID: // value for 'signedDisposalID'
 *      disposalItemIDs: // value for 'disposalItemIDs'
 *      storageItemIDs: // value for 'storageItemIDs'
 *      storagePlanConfig: // value for 'storagePlanConfig'
 *   },
 * });
 */
export function useAttachedDisposalConfirmationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachedDisposalConfirmationMutation,
    AttachedDisposalConfirmationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachedDisposalConfirmationMutation, AttachedDisposalConfirmationMutationVariables>(
    AttachedDisposalConfirmationDocument,
    options,
  );
}
export type AttachedDisposalConfirmationMutationHookResult = ReturnType<typeof useAttachedDisposalConfirmationMutation>;
export type AttachedDisposalConfirmationMutationResult = Apollo.MutationResult<AttachedDisposalConfirmationMutation>;
export type AttachedDisposalConfirmationMutationOptions = Apollo.BaseMutationOptions<
  AttachedDisposalConfirmationMutation,
  AttachedDisposalConfirmationMutationVariables
>;
export const DisposalConfirmationDocument = gql`
  query DisposalConfirmation($signedOrderID: String!, $signedOrderPurpose: String!) {
    order(signedOrderID: $signedOrderID, signedOrderPurpose: $signedOrderPurpose) {
      id
      account {
        id
        currentStorageSubscription {
          id
          formattedName
          cubicFootage
          pricing {
            amount
          }
        }
        rateGroup {
          id
          kind
        }
      }
      associatedPickup {
        id
        customerItems {
          ...customerItem
        }
      }
      customerItems {
        ...customerItem
      }
      ended
      parent {
        id
        disposalConfirmationSignedOrderId
        type
      }
    }
  }
  ${CustomerItemFragmentDoc}
`;

/**
 * __useDisposalConfirmationQuery__
 *
 * To run a query within a React component, call `useDisposalConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalConfirmationQuery({
 *   variables: {
 *      signedOrderID: // value for 'signedOrderID'
 *      signedOrderPurpose: // value for 'signedOrderPurpose'
 *   },
 * });
 */
export function useDisposalConfirmationQuery(
  baseOptions: Apollo.QueryHookOptions<DisposalConfirmationQuery, DisposalConfirmationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisposalConfirmationQuery, DisposalConfirmationQueryVariables>(
    DisposalConfirmationDocument,
    options,
  );
}
export function useDisposalConfirmationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DisposalConfirmationQuery, DisposalConfirmationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisposalConfirmationQuery, DisposalConfirmationQueryVariables>(
    DisposalConfirmationDocument,
    options,
  );
}
export type DisposalConfirmationQueryHookResult = ReturnType<typeof useDisposalConfirmationQuery>;
export type DisposalConfirmationLazyQueryHookResult = ReturnType<typeof useDisposalConfirmationLazyQuery>;
export type DisposalConfirmationQueryResult = Apollo.QueryResult<
  DisposalConfirmationQuery,
  DisposalConfirmationQueryVariables
>;
export const DisposalItemPricingDocument = gql`
  query DisposalItemPricing($itemIds: [ID!]!) {
    disposalItemPricing(itemIds: $itemIds) {
      id
      category
      amount
    }
  }
`;

/**
 * __useDisposalItemPricingQuery__
 *
 * To run a query within a React component, call `useDisposalItemPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalItemPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalItemPricingQuery({
 *   variables: {
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useDisposalItemPricingQuery(
  baseOptions: Apollo.QueryHookOptions<DisposalItemPricingQuery, DisposalItemPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisposalItemPricingQuery, DisposalItemPricingQueryVariables>(
    DisposalItemPricingDocument,
    options,
  );
}
export function useDisposalItemPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DisposalItemPricingQuery, DisposalItemPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisposalItemPricingQuery, DisposalItemPricingQueryVariables>(
    DisposalItemPricingDocument,
    options,
  );
}
export type DisposalItemPricingQueryHookResult = ReturnType<typeof useDisposalItemPricingQuery>;
export type DisposalItemPricingLazyQueryHookResult = ReturnType<typeof useDisposalItemPricingLazyQuery>;
export type DisposalItemPricingQueryResult = Apollo.QueryResult<
  DisposalItemPricingQuery,
  DisposalItemPricingQueryVariables
>;
export const DisposalUpdatedStoragePricingDocument = gql`
  query DisposalUpdatedStoragePricing(
    $signedParentOrderID: String!
    $signedParentOrderPurpose: String!
    $rateGroupKind: RateGroupKindEnum!
  ) {
    pricingSet(signedParentOrderID: $signedParentOrderID, signedParentOrderPurpose: $signedParentOrderPurpose) {
      id
      quoteID
      storagePriceEntries(rateGroupKind: $rateGroupKind) {
        price {
          amount
          plan {
            ... on Pricing__StoragePlan {
              id
              formattedName
              cuft
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDisposalUpdatedStoragePricingQuery__
 *
 * To run a query within a React component, call `useDisposalUpdatedStoragePricingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisposalUpdatedStoragePricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisposalUpdatedStoragePricingQuery({
 *   variables: {
 *      signedParentOrderID: // value for 'signedParentOrderID'
 *      signedParentOrderPurpose: // value for 'signedParentOrderPurpose'
 *      rateGroupKind: // value for 'rateGroupKind'
 *   },
 * });
 */
export function useDisposalUpdatedStoragePricingQuery(
  baseOptions: Apollo.QueryHookOptions<DisposalUpdatedStoragePricingQuery, DisposalUpdatedStoragePricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisposalUpdatedStoragePricingQuery, DisposalUpdatedStoragePricingQueryVariables>(
    DisposalUpdatedStoragePricingDocument,
    options,
  );
}
export function useDisposalUpdatedStoragePricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DisposalUpdatedStoragePricingQuery,
    DisposalUpdatedStoragePricingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisposalUpdatedStoragePricingQuery, DisposalUpdatedStoragePricingQueryVariables>(
    DisposalUpdatedStoragePricingDocument,
    options,
  );
}
export type DisposalUpdatedStoragePricingQueryHookResult = ReturnType<typeof useDisposalUpdatedStoragePricingQuery>;
export type DisposalUpdatedStoragePricingLazyQueryHookResult = ReturnType<
  typeof useDisposalUpdatedStoragePricingLazyQuery
>;
export type DisposalUpdatedStoragePricingQueryResult = Apollo.QueryResult<
  DisposalUpdatedStoragePricingQuery,
  DisposalUpdatedStoragePricingQueryVariables
>;
export const OrderDocumentsDocument = gql`
  query OrderDocuments($orderID: ID!) {
    order(orderID: $orderID) {
      id
      state
      nonTravelSourceAndSuccessors {
        id
        coiRequests {
          id
          certificateOfInsurance {
            id
            filename
            url
          }
        }
        signatures {
          id
          contractUrl
          documentKind
          agreement {
            id
            imgixURL
          }
        }
        ingestedDocuments {
          id
          filename
          url
        }
      }
    }
  }
`;

/**
 * __useOrderDocumentsQuery__
 *
 * To run a query within a React component, call `useOrderDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDocumentsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderDocumentsQuery, OrderDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderDocumentsQuery, OrderDocumentsQueryVariables>(OrderDocumentsDocument, options);
}
export function useOrderDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderDocumentsQuery, OrderDocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderDocumentsQuery, OrderDocumentsQueryVariables>(OrderDocumentsDocument, options);
}
export type OrderDocumentsQueryHookResult = ReturnType<typeof useOrderDocumentsQuery>;
export type OrderDocumentsLazyQueryHookResult = ReturnType<typeof useOrderDocumentsLazyQuery>;
export type OrderDocumentsQueryResult = Apollo.QueryResult<OrderDocumentsQuery, OrderDocumentsQueryVariables>;
export const EstimationCreateRoomsDocument = gql`
  mutation EstimationCreateRooms(
    $orderID: ID!
    $roomInputs: [Estimation__SelectedRoomInputType!]!
    $algorithmName: Estimation__AlgorithmName
    $deactivateExistingRooms: Boolean
    $allItemsInListedRooms: Boolean
  ) {
    estimationCreateRooms(
      orderID: $orderID
      roomInputs: $roomInputs
      algorithmName: $algorithmName
      deactivateExistingRooms: $deactivateExistingRooms
      allItemsInListedRooms: $allItemsInListedRooms
    ) {
      error
      status
      selectedRooms {
        id
        name
        roomCategory {
          id
          name
        }
        estimatedItems {
          id
          quantity
          category {
            id
            name
          }
        }
      }
    }
  }
`;
export type EstimationCreateRoomsMutationFn = Apollo.MutationFunction<
  EstimationCreateRoomsMutation,
  EstimationCreateRoomsMutationVariables
>;

/**
 * __useEstimationCreateRoomsMutation__
 *
 * To run a mutation, you first call `useEstimationCreateRoomsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationCreateRoomsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationCreateRoomsMutation, { data, loading, error }] = useEstimationCreateRoomsMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      roomInputs: // value for 'roomInputs'
 *      algorithmName: // value for 'algorithmName'
 *      deactivateExistingRooms: // value for 'deactivateExistingRooms'
 *      allItemsInListedRooms: // value for 'allItemsInListedRooms'
 *   },
 * });
 */
export function useEstimationCreateRoomsMutation(
  baseOptions?: Apollo.MutationHookOptions<EstimationCreateRoomsMutation, EstimationCreateRoomsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationCreateRoomsMutation, EstimationCreateRoomsMutationVariables>(
    EstimationCreateRoomsDocument,
    options,
  );
}
export type EstimationCreateRoomsMutationHookResult = ReturnType<typeof useEstimationCreateRoomsMutation>;
export type EstimationCreateRoomsMutationResult = Apollo.MutationResult<EstimationCreateRoomsMutation>;
export type EstimationCreateRoomsMutationOptions = Apollo.BaseMutationOptions<
  EstimationCreateRoomsMutation,
  EstimationCreateRoomsMutationVariables
>;
export const EstimationStatusCreateDocument = gql`
  mutation EstimationStatusCreate($input: Estimation__StatusCreateInputType!) {
    estimationStatusCreate(input: $input) {
      status
      error
    }
  }
`;
export type EstimationStatusCreateMutationFn = Apollo.MutationFunction<
  EstimationStatusCreateMutation,
  EstimationStatusCreateMutationVariables
>;

/**
 * __useEstimationStatusCreateMutation__
 *
 * To run a mutation, you first call `useEstimationStatusCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationStatusCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationStatusCreateMutation, { data, loading, error }] = useEstimationStatusCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimationStatusCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<EstimationStatusCreateMutation, EstimationStatusCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationStatusCreateMutation, EstimationStatusCreateMutationVariables>(
    EstimationStatusCreateDocument,
    options,
  );
}
export type EstimationStatusCreateMutationHookResult = ReturnType<typeof useEstimationStatusCreateMutation>;
export type EstimationStatusCreateMutationResult = Apollo.MutationResult<EstimationStatusCreateMutation>;
export type EstimationStatusCreateMutationOptions = Apollo.BaseMutationOptions<
  EstimationStatusCreateMutation,
  EstimationStatusCreateMutationVariables
>;
export const CreateStepTransitionDocument = gql`
  mutation CreateStepTransition($input: Flow__StepTransitionInputType!) {
    createStepTransition(input: $input) {
      error
      status
    }
  }
`;
export type CreateStepTransitionMutationFn = Apollo.MutationFunction<
  CreateStepTransitionMutation,
  CreateStepTransitionMutationVariables
>;

/**
 * __useCreateStepTransitionMutation__
 *
 * To run a mutation, you first call `useCreateStepTransitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStepTransitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStepTransitionMutation, { data, loading, error }] = useCreateStepTransitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStepTransitionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateStepTransitionMutation, CreateStepTransitionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateStepTransitionMutation, CreateStepTransitionMutationVariables>(
    CreateStepTransitionDocument,
    options,
  );
}
export type CreateStepTransitionMutationHookResult = ReturnType<typeof useCreateStepTransitionMutation>;
export type CreateStepTransitionMutationResult = Apollo.MutationResult<CreateStepTransitionMutation>;
export type CreateStepTransitionMutationOptions = Apollo.BaseMutationOptions<
  CreateStepTransitionMutation,
  CreateStepTransitionMutationVariables
>;
export const EstimationCreateBoxEstimatedItemsDocument = gql`
  mutation EstimationCreateBoxEstimatedItems($orderID: ID!, $boxCategoryName: String!) {
    estimationCreateBoxEstimatedItems(orderID: $orderID, boxCategoryName: $boxCategoryName) {
      error
      status
      estimatedItems {
        id
        quantity
        category {
          id
          name
          position
        }
      }
    }
  }
`;
export type EstimationCreateBoxEstimatedItemsMutationFn = Apollo.MutationFunction<
  EstimationCreateBoxEstimatedItemsMutation,
  EstimationCreateBoxEstimatedItemsMutationVariables
>;

/**
 * __useEstimationCreateBoxEstimatedItemsMutation__
 *
 * To run a mutation, you first call `useEstimationCreateBoxEstimatedItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationCreateBoxEstimatedItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationCreateBoxEstimatedItemsMutation, { data, loading, error }] = useEstimationCreateBoxEstimatedItemsMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      boxCategoryName: // value for 'boxCategoryName'
 *   },
 * });
 */
export function useEstimationCreateBoxEstimatedItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimationCreateBoxEstimatedItemsMutation,
    EstimationCreateBoxEstimatedItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EstimationCreateBoxEstimatedItemsMutation,
    EstimationCreateBoxEstimatedItemsMutationVariables
  >(EstimationCreateBoxEstimatedItemsDocument, options);
}
export type EstimationCreateBoxEstimatedItemsMutationHookResult = ReturnType<
  typeof useEstimationCreateBoxEstimatedItemsMutation
>;
export type EstimationCreateBoxEstimatedItemsMutationResult =
  Apollo.MutationResult<EstimationCreateBoxEstimatedItemsMutation>;
export type EstimationCreateBoxEstimatedItemsMutationOptions = Apollo.BaseMutationOptions<
  EstimationCreateBoxEstimatedItemsMutation,
  EstimationCreateBoxEstimatedItemsMutationVariables
>;
export const EstimationCreateItemsDocument = gql`
  mutation EstimationCreateItems($input: Estimation__CreateItemsInputType!) {
    estimationCreateItems(input: $input) {
      error
      status
      estimatedItems {
        id
        quantity
        category {
          id
          name
        }
      }
    }
  }
`;
export type EstimationCreateItemsMutationFn = Apollo.MutationFunction<
  EstimationCreateItemsMutation,
  EstimationCreateItemsMutationVariables
>;

/**
 * __useEstimationCreateItemsMutation__
 *
 * To run a mutation, you first call `useEstimationCreateItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationCreateItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationCreateItemsMutation, { data, loading, error }] = useEstimationCreateItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimationCreateItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<EstimationCreateItemsMutation, EstimationCreateItemsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationCreateItemsMutation, EstimationCreateItemsMutationVariables>(
    EstimationCreateItemsDocument,
    options,
  );
}
export type EstimationCreateItemsMutationHookResult = ReturnType<typeof useEstimationCreateItemsMutation>;
export type EstimationCreateItemsMutationResult = Apollo.MutationResult<EstimationCreateItemsMutation>;
export type EstimationCreateItemsMutationOptions = Apollo.BaseMutationOptions<
  EstimationCreateItemsMutation,
  EstimationCreateItemsMutationVariables
>;
export const MovingMaterialPackageSetEntriesFetchDocument = gql`
  mutation MovingMaterialPackageSetEntriesFetch($orderID: ID!) {
    materialPackageSetEntriesFetch(orderID: $orderID) {
      error
      status
      materialPackageSetEntries {
        id
        fixedCost
        package {
          name
        }
      }
    }
  }
`;
export type MovingMaterialPackageSetEntriesFetchMutationFn = Apollo.MutationFunction<
  MovingMaterialPackageSetEntriesFetchMutation,
  MovingMaterialPackageSetEntriesFetchMutationVariables
>;

/**
 * __useMovingMaterialPackageSetEntriesFetchMutation__
 *
 * To run a mutation, you first call `useMovingMaterialPackageSetEntriesFetchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingMaterialPackageSetEntriesFetchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingMaterialPackageSetEntriesFetchMutation, { data, loading, error }] = useMovingMaterialPackageSetEntriesFetchMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingMaterialPackageSetEntriesFetchMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MovingMaterialPackageSetEntriesFetchMutation,
    MovingMaterialPackageSetEntriesFetchMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MovingMaterialPackageSetEntriesFetchMutation,
    MovingMaterialPackageSetEntriesFetchMutationVariables
  >(MovingMaterialPackageSetEntriesFetchDocument, options);
}
export type MovingMaterialPackageSetEntriesFetchMutationHookResult = ReturnType<
  typeof useMovingMaterialPackageSetEntriesFetchMutation
>;
export type MovingMaterialPackageSetEntriesFetchMutationResult =
  Apollo.MutationResult<MovingMaterialPackageSetEntriesFetchMutation>;
export type MovingMaterialPackageSetEntriesFetchMutationOptions = Apollo.BaseMutationOptions<
  MovingMaterialPackageSetEntriesFetchMutation,
  MovingMaterialPackageSetEntriesFetchMutationVariables
>;
export const MovingPackingMaterialsUpdateDocument = gql`
  mutation MovingPackingMaterialsUpdate(
    $orderID: ID!
    $packageSetEntryID: ID
    $action: Moving__PackingMaterialsUpdateAction
  ) {
    movingPackingMaterialsUpdate(orderID: $orderID, packageSetEntryID: $packageSetEntryID, action: $action) {
      status
      error
    }
  }
`;
export type MovingPackingMaterialsUpdateMutationFn = Apollo.MutationFunction<
  MovingPackingMaterialsUpdateMutation,
  MovingPackingMaterialsUpdateMutationVariables
>;

/**
 * __useMovingPackingMaterialsUpdateMutation__
 *
 * To run a mutation, you first call `useMovingPackingMaterialsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingPackingMaterialsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingPackingMaterialsUpdateMutation, { data, loading, error }] = useMovingPackingMaterialsUpdateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      packageSetEntryID: // value for 'packageSetEntryID'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useMovingPackingMaterialsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MovingPackingMaterialsUpdateMutation,
    MovingPackingMaterialsUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingPackingMaterialsUpdateMutation, MovingPackingMaterialsUpdateMutationVariables>(
    MovingPackingMaterialsUpdateDocument,
    options,
  );
}
export type MovingPackingMaterialsUpdateMutationHookResult = ReturnType<typeof useMovingPackingMaterialsUpdateMutation>;
export type MovingPackingMaterialsUpdateMutationResult = Apollo.MutationResult<MovingPackingMaterialsUpdateMutation>;
export type MovingPackingMaterialsUpdateMutationOptions = Apollo.BaseMutationOptions<
  MovingPackingMaterialsUpdateMutation,
  MovingPackingMaterialsUpdateMutationVariables
>;
export const HydrateEstimationFlowDocument = gql`
  query HydrateEstimationFlow($orderID: ID!) {
    estimationBoxItemCategories {
      id
      position
      name
    }
    estimationItemCategoryThemes {
      id
      name
      position
      categories {
        id
        name
        position
      }
    }
    estimationRoomCategories {
      id
      name
      itemCategories {
        id
        name
        position
      }
    }
    estimationSelectedBoxEstimatedItem(orderID: $orderID) {
      id
      quantity
      category {
        id
        name
        position
      }
    }
    estimationSelectedRooms(orderID: $orderID) {
      id
      name
      roomCategory {
        id
        name
      }
      estimatedItems {
        id
        quantity
        category {
          id
          name
          position
        }
      }
    }
    order(orderID: $orderID) {
      address {
        ...address
        details {
          ...details
        }
      }
      type
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
`;

/**
 * __useHydrateEstimationFlowQuery__
 *
 * To run a query within a React component, call `useHydrateEstimationFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useHydrateEstimationFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHydrateEstimationFlowQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useHydrateEstimationFlowQuery(
  baseOptions: Apollo.QueryHookOptions<HydrateEstimationFlowQuery, HydrateEstimationFlowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HydrateEstimationFlowQuery, HydrateEstimationFlowQueryVariables>(
    HydrateEstimationFlowDocument,
    options,
  );
}
export function useHydrateEstimationFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HydrateEstimationFlowQuery, HydrateEstimationFlowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HydrateEstimationFlowQuery, HydrateEstimationFlowQueryVariables>(
    HydrateEstimationFlowDocument,
    options,
  );
}
export type HydrateEstimationFlowQueryHookResult = ReturnType<typeof useHydrateEstimationFlowQuery>;
export type HydrateEstimationFlowLazyQueryHookResult = ReturnType<typeof useHydrateEstimationFlowLazyQuery>;
export type HydrateEstimationFlowQueryResult = Apollo.QueryResult<
  HydrateEstimationFlowQuery,
  HydrateEstimationFlowQueryVariables
>;
export const EstimationAiClassificationCreateDocument = gql`
  mutation EstimationAIClassificationCreate($orderID: ID!) {
    estimationAiClassificationCreate(orderID: $orderID) {
      error
      status
      aiClassification {
        id
      }
    }
  }
`;
export type EstimationAiClassificationCreateMutationFn = Apollo.MutationFunction<
  EstimationAiClassificationCreateMutation,
  EstimationAiClassificationCreateMutationVariables
>;

/**
 * __useEstimationAiClassificationCreateMutation__
 *
 * To run a mutation, you first call `useEstimationAiClassificationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationAiClassificationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationAiClassificationCreateMutation, { data, loading, error }] = useEstimationAiClassificationCreateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useEstimationAiClassificationCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimationAiClassificationCreateMutation,
    EstimationAiClassificationCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EstimationAiClassificationCreateMutation,
    EstimationAiClassificationCreateMutationVariables
  >(EstimationAiClassificationCreateDocument, options);
}
export type EstimationAiClassificationCreateMutationHookResult = ReturnType<
  typeof useEstimationAiClassificationCreateMutation
>;
export type EstimationAiClassificationCreateMutationResult =
  Apollo.MutationResult<EstimationAiClassificationCreateMutation>;
export type EstimationAiClassificationCreateMutationOptions = Apollo.BaseMutationOptions<
  EstimationAiClassificationCreateMutation,
  EstimationAiClassificationCreateMutationVariables
>;
export const EstimationAiClassificationDocument = gql`
  query EstimationAIClassification($id: ID!) {
    estimationAiClassification(id: $id) {
      id
      estimatedItems {
        ...estimationEstimatedItem
      }
      status
    }
  }
  ${EstimationEstimatedItemFragmentDoc}
`;

/**
 * __useEstimationAiClassificationQuery__
 *
 * To run a query within a React component, call `useEstimationAiClassificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationAiClassificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationAiClassificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEstimationAiClassificationQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationAiClassificationQuery, EstimationAiClassificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationAiClassificationQuery, EstimationAiClassificationQueryVariables>(
    EstimationAiClassificationDocument,
    options,
  );
}
export function useEstimationAiClassificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationAiClassificationQuery, EstimationAiClassificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationAiClassificationQuery, EstimationAiClassificationQueryVariables>(
    EstimationAiClassificationDocument,
    options,
  );
}
export type EstimationAiClassificationQueryHookResult = ReturnType<typeof useEstimationAiClassificationQuery>;
export type EstimationAiClassificationLazyQueryHookResult = ReturnType<typeof useEstimationAiClassificationLazyQuery>;
export type EstimationAiClassificationQueryResult = Apollo.QueryResult<
  EstimationAiClassificationQuery,
  EstimationAiClassificationQueryVariables
>;
export const EstimationAiClassificationSkipDocument = gql`
  mutation EstimationAIClassificationSkip($aiClassificationID: ID!) {
    estimationAIClassificationSkip(aiClassificationID: $aiClassificationID) {
      status
    }
  }
`;
export type EstimationAiClassificationSkipMutationFn = Apollo.MutationFunction<
  EstimationAiClassificationSkipMutation,
  EstimationAiClassificationSkipMutationVariables
>;

/**
 * __useEstimationAiClassificationSkipMutation__
 *
 * To run a mutation, you first call `useEstimationAiClassificationSkipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationAiClassificationSkipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationAiClassificationSkipMutation, { data, loading, error }] = useEstimationAiClassificationSkipMutation({
 *   variables: {
 *      aiClassificationID: // value for 'aiClassificationID'
 *   },
 * });
 */
export function useEstimationAiClassificationSkipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimationAiClassificationSkipMutation,
    EstimationAiClassificationSkipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationAiClassificationSkipMutation, EstimationAiClassificationSkipMutationVariables>(
    EstimationAiClassificationSkipDocument,
    options,
  );
}
export type EstimationAiClassificationSkipMutationHookResult = ReturnType<
  typeof useEstimationAiClassificationSkipMutation
>;
export type EstimationAiClassificationSkipMutationResult =
  Apollo.MutationResult<EstimationAiClassificationSkipMutation>;
export type EstimationAiClassificationSkipMutationOptions = Apollo.BaseMutationOptions<
  EstimationAiClassificationSkipMutation,
  EstimationAiClassificationSkipMutationVariables
>;
export const ItemCategorySearchDocument = gql`
  query ItemCategorySearch($query: String!) {
    itemCategorySearch: virtualWalkthroughItemCategorySearch(query: $query) {
      id
      name
      position
    }
  }
`;

/**
 * __useItemCategorySearchQuery__
 *
 * To run a query within a React component, call `useItemCategorySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemCategorySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemCategorySearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useItemCategorySearchQuery(
  baseOptions: Apollo.QueryHookOptions<ItemCategorySearchQuery, ItemCategorySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemCategorySearchQuery, ItemCategorySearchQueryVariables>(
    ItemCategorySearchDocument,
    options,
  );
}
export function useItemCategorySearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ItemCategorySearchQuery, ItemCategorySearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemCategorySearchQuery, ItemCategorySearchQueryVariables>(
    ItemCategorySearchDocument,
    options,
  );
}
export type ItemCategorySearchQueryHookResult = ReturnType<typeof useItemCategorySearchQuery>;
export type ItemCategorySearchLazyQueryHookResult = ReturnType<typeof useItemCategorySearchLazyQuery>;
export type ItemCategorySearchQueryResult = Apollo.QueryResult<
  ItemCategorySearchQuery,
  ItemCategorySearchQueryVariables
>;
export const MovingNextLaborRateDocument = gql`
  query MovingNextLaborRate($orderID: ID!, $movers: Int!) {
    movingConfiguration(orderID: $orderID) {
      id
      laborRate(movers: $movers) {
        id
        amount
      }
    }
  }
`;

/**
 * __useMovingNextLaborRateQuery__
 *
 * To run a query within a React component, call `useMovingNextLaborRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingNextLaborRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingNextLaborRateQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      movers: // value for 'movers'
 *   },
 * });
 */
export function useMovingNextLaborRateQuery(
  baseOptions: Apollo.QueryHookOptions<MovingNextLaborRateQuery, MovingNextLaborRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingNextLaborRateQuery, MovingNextLaborRateQueryVariables>(
    MovingNextLaborRateDocument,
    options,
  );
}
export function useMovingNextLaborRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MovingNextLaborRateQuery, MovingNextLaborRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingNextLaborRateQuery, MovingNextLaborRateQueryVariables>(
    MovingNextLaborRateDocument,
    options,
  );
}
export type MovingNextLaborRateQueryHookResult = ReturnType<typeof useMovingNextLaborRateQuery>;
export type MovingNextLaborRateLazyQueryHookResult = ReturnType<typeof useMovingNextLaborRateLazyQuery>;
export type MovingNextLaborRateQueryResult = Apollo.QueryResult<
  MovingNextLaborRateQuery,
  MovingNextLaborRateQueryVariables
>;
export const ItemInventoryFeaturedCategoriesDocument = gql`
  query ItemInventoryFeaturedCategories {
    virtualWalkthroughItemCategories {
      id
      name
      position
    }
    virtualWalkthroughOtherItemCategories {
      id
      name
      position
    }
  }
`;

/**
 * __useItemInventoryFeaturedCategoriesQuery__
 *
 * To run a query within a React component, call `useItemInventoryFeaturedCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemInventoryFeaturedCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemInventoryFeaturedCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useItemInventoryFeaturedCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ItemInventoryFeaturedCategoriesQuery,
    ItemInventoryFeaturedCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ItemInventoryFeaturedCategoriesQuery, ItemInventoryFeaturedCategoriesQueryVariables>(
    ItemInventoryFeaturedCategoriesDocument,
    options,
  );
}
export function useItemInventoryFeaturedCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ItemInventoryFeaturedCategoriesQuery,
    ItemInventoryFeaturedCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ItemInventoryFeaturedCategoriesQuery, ItemInventoryFeaturedCategoriesQueryVariables>(
    ItemInventoryFeaturedCategoriesDocument,
    options,
  );
}
export type ItemInventoryFeaturedCategoriesQueryHookResult = ReturnType<typeof useItemInventoryFeaturedCategoriesQuery>;
export type ItemInventoryFeaturedCategoriesLazyQueryHookResult = ReturnType<
  typeof useItemInventoryFeaturedCategoriesLazyQuery
>;
export type ItemInventoryFeaturedCategoriesQueryResult = Apollo.QueryResult<
  ItemInventoryFeaturedCategoriesQuery,
  ItemInventoryFeaturedCategoriesQueryVariables
>;
export const HydrateVirtualWalkthroughFlowDocument = gql`
  query HydrateVirtualWalkthroughFlow($orderID: ID!) {
    order(orderID: $orderID) {
      ...RescheduleOrder
      address {
        ...address
        details {
          ...details
        }
      }
      estimatedCuft
      type
      metadata {
        itemsRequireMoreThanOneMover
      }
      movers
      movingOperation {
        destinationAddress {
          ...address
          details {
            ...details
          }
        }
        movingQuote {
          ...estimationMovingQuote
        }
      }
      permissions {
        id
        chargeLateFee
        reschedulable
        priceMatchGuaranteeEligible
        whiteGloveTestEligible
      }
      nonTravelSourceAndSuccessors {
        id
        type
        subtype
        scheduled
        window
      }
      multiDay
      movingPricingSummary {
        flatRateAmount
      }
    }
    virtualWalkthroughEstimationItems(resourceID: $orderID) {
      ...estimationEstimatedItem
    }
    virtualWalkthroughOtherItemCategories {
      id
      name
      position
    }
  }
  ${RescheduleOrderFragmentDoc}
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
  ${EstimationMovingQuoteFragmentDoc}
  ${EstimationEstimatedItemFragmentDoc}
`;

/**
 * __useHydrateVirtualWalkthroughFlowQuery__
 *
 * To run a query within a React component, call `useHydrateVirtualWalkthroughFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useHydrateVirtualWalkthroughFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHydrateVirtualWalkthroughFlowQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useHydrateVirtualWalkthroughFlowQuery(
  baseOptions: Apollo.QueryHookOptions<HydrateVirtualWalkthroughFlowQuery, HydrateVirtualWalkthroughFlowQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HydrateVirtualWalkthroughFlowQuery, HydrateVirtualWalkthroughFlowQueryVariables>(
    HydrateVirtualWalkthroughFlowDocument,
    options,
  );
}
export function useHydrateVirtualWalkthroughFlowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HydrateVirtualWalkthroughFlowQuery,
    HydrateVirtualWalkthroughFlowQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HydrateVirtualWalkthroughFlowQuery, HydrateVirtualWalkthroughFlowQueryVariables>(
    HydrateVirtualWalkthroughFlowDocument,
    options,
  );
}
export type HydrateVirtualWalkthroughFlowQueryHookResult = ReturnType<typeof useHydrateVirtualWalkthroughFlowQuery>;
export type HydrateVirtualWalkthroughFlowLazyQueryHookResult = ReturnType<
  typeof useHydrateVirtualWalkthroughFlowLazyQuery
>;
export type HydrateVirtualWalkthroughFlowQueryResult = Apollo.QueryResult<
  HydrateVirtualWalkthroughFlowQuery,
  HydrateVirtualWalkthroughFlowQueryVariables
>;
export const MovingQuoteRegenerateWithItemsDocument = gql`
  mutation MovingQuoteRegenerateWithItems($estimationInput: Estimation__CreateItemsInputType!) {
    movingQuoteRegenerateWithItems(estimationInput: $estimationInput) {
      error
      status
      estimatedItems {
        id
        quantity
        category {
          id
          name
        }
      }
      movingQuote {
        ...estimationMovingQuote
      }
    }
  }
  ${EstimationMovingQuoteFragmentDoc}
`;
export type MovingQuoteRegenerateWithItemsMutationFn = Apollo.MutationFunction<
  MovingQuoteRegenerateWithItemsMutation,
  MovingQuoteRegenerateWithItemsMutationVariables
>;

/**
 * __useMovingQuoteRegenerateWithItemsMutation__
 *
 * To run a mutation, you first call `useMovingQuoteRegenerateWithItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingQuoteRegenerateWithItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingQuoteRegenerateWithItemsMutation, { data, loading, error }] = useMovingQuoteRegenerateWithItemsMutation({
 *   variables: {
 *      estimationInput: // value for 'estimationInput'
 *   },
 * });
 */
export function useMovingQuoteRegenerateWithItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MovingQuoteRegenerateWithItemsMutation,
    MovingQuoteRegenerateWithItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingQuoteRegenerateWithItemsMutation, MovingQuoteRegenerateWithItemsMutationVariables>(
    MovingQuoteRegenerateWithItemsDocument,
    options,
  );
}
export type MovingQuoteRegenerateWithItemsMutationHookResult = ReturnType<
  typeof useMovingQuoteRegenerateWithItemsMutation
>;
export type MovingQuoteRegenerateWithItemsMutationResult =
  Apollo.MutationResult<MovingQuoteRegenerateWithItemsMutation>;
export type MovingQuoteRegenerateWithItemsMutationOptions = Apollo.BaseMutationOptions<
  MovingQuoteRegenerateWithItemsMutation,
  MovingQuoteRegenerateWithItemsMutationVariables
>;
export const EstimationFlowStatusDocument = gql`
  query EstimationFlowStatus($orderID: ID!) {
    estimationStatus(orderID: $orderID) {
      id
      complete
      estimationCalculatedCuft
      planSizeName
      planSizeCuft
      planSizeComparison
      fitsPlanSize
    }
  }
`;

/**
 * __useEstimationFlowStatusQuery__
 *
 * To run a query within a React component, call `useEstimationFlowStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationFlowStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationFlowStatusQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useEstimationFlowStatusQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationFlowStatusQuery, EstimationFlowStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationFlowStatusQuery, EstimationFlowStatusQueryVariables>(
    EstimationFlowStatusDocument,
    options,
  );
}
export function useEstimationFlowStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationFlowStatusQuery, EstimationFlowStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationFlowStatusQuery, EstimationFlowStatusQueryVariables>(
    EstimationFlowStatusDocument,
    options,
  );
}
export type EstimationFlowStatusQueryHookResult = ReturnType<typeof useEstimationFlowStatusQuery>;
export type EstimationFlowStatusLazyQueryHookResult = ReturnType<typeof useEstimationFlowStatusLazyQuery>;
export type EstimationFlowStatusQueryResult = Apollo.QueryResult<
  EstimationFlowStatusQuery,
  EstimationFlowStatusQueryVariables
>;
export const MoveReviewDocument = gql`
  query MoveReview($orderID: ID!) {
    order(orderID: $orderID) {
      id
      account {
        id
        customer {
          id
          email
          name
          phone
        }
      }
      address {
        ...address
        details {
          ...details
        }
        stateAbbreviation
      }
      contact {
        id
        name
        phone
      }
      laborRate {
        id
        amount
      }
      movers
      movingOperation {
        destinationAddress {
          ...address
          details {
            ...details
          }
          stateAbbreviation
        }
      }
      movingPricingSummary {
        materialPackageSetEntry {
          id
          fixedCost
          package {
            id
            name
          }
        }
        flatRateAmount
      }
      scheduled
      window
      nonTravelSourceAndSuccessors {
        id
        type
        subtype
        scheduled
        window
      }
      multiDay
      permissions {
        id
        whiteGloveTestEligible
      }
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
`;

/**
 * __useMoveReviewQuery__
 *
 * To run a query within a React component, call `useMoveReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveReviewQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMoveReviewQuery(baseOptions: Apollo.QueryHookOptions<MoveReviewQuery, MoveReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoveReviewQuery, MoveReviewQueryVariables>(MoveReviewDocument, options);
}
export function useMoveReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MoveReviewQuery, MoveReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MoveReviewQuery, MoveReviewQueryVariables>(MoveReviewDocument, options);
}
export type MoveReviewQueryHookResult = ReturnType<typeof useMoveReviewQuery>;
export type MoveReviewLazyQueryHookResult = ReturnType<typeof useMoveReviewLazyQuery>;
export type MoveReviewQueryResult = Apollo.QueryResult<MoveReviewQuery, MoveReviewQueryVariables>;
export const WalkthroughCsatReviewDocument = gql`
  query WalkthroughCsatReview($orderID: ID!) {
    submittedWalkthroughCsatReview(orderID: $orderID)
  }
`;

/**
 * __useWalkthroughCsatReviewQuery__
 *
 * To run a query within a React component, call `useWalkthroughCsatReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalkthroughCsatReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalkthroughCsatReviewQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useWalkthroughCsatReviewQuery(
  baseOptions: Apollo.QueryHookOptions<WalkthroughCsatReviewQuery, WalkthroughCsatReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalkthroughCsatReviewQuery, WalkthroughCsatReviewQueryVariables>(
    WalkthroughCsatReviewDocument,
    options,
  );
}
export function useWalkthroughCsatReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalkthroughCsatReviewQuery, WalkthroughCsatReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WalkthroughCsatReviewQuery, WalkthroughCsatReviewQueryVariables>(
    WalkthroughCsatReviewDocument,
    options,
  );
}
export type WalkthroughCsatReviewQueryHookResult = ReturnType<typeof useWalkthroughCsatReviewQuery>;
export type WalkthroughCsatReviewLazyQueryHookResult = ReturnType<typeof useWalkthroughCsatReviewLazyQuery>;
export type WalkthroughCsatReviewQueryResult = Apollo.QueryResult<
  WalkthroughCsatReviewQuery,
  WalkthroughCsatReviewQueryVariables
>;
export const EstimationUploadCreateDocument = gql`
  mutation EstimationUploadCreate($orderID: ID!, $signedID: ID!) {
    estimationUploadCreate(orderID: $orderID, signedID: $signedID) {
      error
      status
      upload {
        ...estimationUpload
      }
    }
  }
  ${EstimationUploadFragmentDoc}
`;
export type EstimationUploadCreateMutationFn = Apollo.MutationFunction<
  EstimationUploadCreateMutation,
  EstimationUploadCreateMutationVariables
>;

/**
 * __useEstimationUploadCreateMutation__
 *
 * To run a mutation, you first call `useEstimationUploadCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationUploadCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationUploadCreateMutation, { data, loading, error }] = useEstimationUploadCreateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      signedID: // value for 'signedID'
 *   },
 * });
 */
export function useEstimationUploadCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<EstimationUploadCreateMutation, EstimationUploadCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationUploadCreateMutation, EstimationUploadCreateMutationVariables>(
    EstimationUploadCreateDocument,
    options,
  );
}
export type EstimationUploadCreateMutationHookResult = ReturnType<typeof useEstimationUploadCreateMutation>;
export type EstimationUploadCreateMutationResult = Apollo.MutationResult<EstimationUploadCreateMutation>;
export type EstimationUploadCreateMutationOptions = Apollo.BaseMutationOptions<
  EstimationUploadCreateMutation,
  EstimationUploadCreateMutationVariables
>;
export const EstimationUploadDestroyDocument = gql`
  mutation EstimationUploadDestroy($orderID: ID!, $uploadID: ID!) {
    estimationUploadDestroy(orderID: $orderID, uploadID: $uploadID) {
      error
      status
      upload {
        id
      }
    }
  }
`;
export type EstimationUploadDestroyMutationFn = Apollo.MutationFunction<
  EstimationUploadDestroyMutation,
  EstimationUploadDestroyMutationVariables
>;

/**
 * __useEstimationUploadDestroyMutation__
 *
 * To run a mutation, you first call `useEstimationUploadDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimationUploadDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimationUploadDestroyMutation, { data, loading, error }] = useEstimationUploadDestroyMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      uploadID: // value for 'uploadID'
 *   },
 * });
 */
export function useEstimationUploadDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<EstimationUploadDestroyMutation, EstimationUploadDestroyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EstimationUploadDestroyMutation, EstimationUploadDestroyMutationVariables>(
    EstimationUploadDestroyDocument,
    options,
  );
}
export type EstimationUploadDestroyMutationHookResult = ReturnType<typeof useEstimationUploadDestroyMutation>;
export type EstimationUploadDestroyMutationResult = Apollo.MutationResult<EstimationUploadDestroyMutation>;
export type EstimationUploadDestroyMutationOptions = Apollo.BaseMutationOptions<
  EstimationUploadDestroyMutation,
  EstimationUploadDestroyMutationVariables
>;
export const EstimationUploadsDocument = gql`
  query EstimationUploads($orderID: ID!) {
    estimationUploads(orderID: $orderID) {
      ...estimationUpload
    }
  }
  ${EstimationUploadFragmentDoc}
`;

/**
 * __useEstimationUploadsQuery__
 *
 * To run a query within a React component, call `useEstimationUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationUploadsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useEstimationUploadsQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationUploadsQuery, EstimationUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationUploadsQuery, EstimationUploadsQueryVariables>(EstimationUploadsDocument, options);
}
export function useEstimationUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationUploadsQuery, EstimationUploadsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationUploadsQuery, EstimationUploadsQueryVariables>(
    EstimationUploadsDocument,
    options,
  );
}
export type EstimationUploadsQueryHookResult = ReturnType<typeof useEstimationUploadsQuery>;
export type EstimationUploadsLazyQueryHookResult = ReturnType<typeof useEstimationUploadsLazyQuery>;
export type EstimationUploadsQueryResult = Apollo.QueryResult<EstimationUploadsQuery, EstimationUploadsQueryVariables>;
export const FinancingCheckoutDocument = gql`
  query FinancingCheckout($orderID: ID!) {
    order(orderID: $orderID) {
      id
      financingContext {
        total
        taxAmount
        items {
          sku
          displayName
          unitPrice
          quantity
          imageURL
          url
        }
        discounts {
          code
          amount
          displayName
        }
      }
    }
    customer {
      id
      name
      phone
      email
    }
    addresses {
      id
      aptsuite
      street
      city
      state
      country
      zip
    }
  }
`;

/**
 * __useFinancingCheckoutQuery__
 *
 * To run a query within a React component, call `useFinancingCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinancingCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinancingCheckoutQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useFinancingCheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<FinancingCheckoutQuery, FinancingCheckoutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FinancingCheckoutQuery, FinancingCheckoutQueryVariables>(FinancingCheckoutDocument, options);
}
export function useFinancingCheckoutLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FinancingCheckoutQuery, FinancingCheckoutQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FinancingCheckoutQuery, FinancingCheckoutQueryVariables>(
    FinancingCheckoutDocument,
    options,
  );
}
export type FinancingCheckoutQueryHookResult = ReturnType<typeof useFinancingCheckoutQuery>;
export type FinancingCheckoutLazyQueryHookResult = ReturnType<typeof useFinancingCheckoutLazyQuery>;
export type FinancingCheckoutQueryResult = Apollo.QueryResult<FinancingCheckoutQuery, FinancingCheckoutQueryVariables>;
export const SubmitFinancingCheckoutDocument = gql`
  mutation SubmitFinancingCheckout($input: Financing__CheckoutInput!) {
    submitFinancingCheckout(input: $input) {
      status
      failureKind
    }
  }
`;
export type SubmitFinancingCheckoutMutationFn = Apollo.MutationFunction<
  SubmitFinancingCheckoutMutation,
  SubmitFinancingCheckoutMutationVariables
>;

/**
 * __useSubmitFinancingCheckoutMutation__
 *
 * To run a mutation, you first call `useSubmitFinancingCheckoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFinancingCheckoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFinancingCheckoutMutation, { data, loading, error }] = useSubmitFinancingCheckoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFinancingCheckoutMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitFinancingCheckoutMutation, SubmitFinancingCheckoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitFinancingCheckoutMutation, SubmitFinancingCheckoutMutationVariables>(
    SubmitFinancingCheckoutDocument,
    options,
  );
}
export type SubmitFinancingCheckoutMutationHookResult = ReturnType<typeof useSubmitFinancingCheckoutMutation>;
export type SubmitFinancingCheckoutMutationResult = Apollo.MutationResult<SubmitFinancingCheckoutMutation>;
export type SubmitFinancingCheckoutMutationOptions = Apollo.BaseMutationOptions<
  SubmitFinancingCheckoutMutation,
  SubmitFinancingCheckoutMutationVariables
>;
export const OrderFinancingIntentDocument = gql`
  query OrderFinancingIntent($orderID: ID!) {
    order(orderID: $orderID) {
      id
      scheduled
      state
      financingIntent {
        id
        intended
        estimatedAmount
      }
    }
  }
`;

/**
 * __useOrderFinancingIntentQuery__
 *
 * To run a query within a React component, call `useOrderFinancingIntentQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFinancingIntentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFinancingIntentQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFinancingIntentQuery(
  baseOptions: Apollo.QueryHookOptions<OrderFinancingIntentQuery, OrderFinancingIntentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderFinancingIntentQuery, OrderFinancingIntentQueryVariables>(
    OrderFinancingIntentDocument,
    options,
  );
}
export function useOrderFinancingIntentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderFinancingIntentQuery, OrderFinancingIntentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderFinancingIntentQuery, OrderFinancingIntentQueryVariables>(
    OrderFinancingIntentDocument,
    options,
  );
}
export type OrderFinancingIntentQueryHookResult = ReturnType<typeof useOrderFinancingIntentQuery>;
export type OrderFinancingIntentLazyQueryHookResult = ReturnType<typeof useOrderFinancingIntentLazyQuery>;
export type OrderFinancingIntentQueryResult = Apollo.QueryResult<
  OrderFinancingIntentQuery,
  OrderFinancingIntentQueryVariables
>;
export const SwitchFinancingIntentDocument = gql`
  mutation SwitchFinancingIntent($orderID: ID!, $intended: Boolean!) {
    switchFinancingIntent(orderID: $orderID, intended: $intended) {
      intent {
        id
        intended
      }
    }
  }
`;
export type SwitchFinancingIntentMutationFn = Apollo.MutationFunction<
  SwitchFinancingIntentMutation,
  SwitchFinancingIntentMutationVariables
>;

/**
 * __useSwitchFinancingIntentMutation__
 *
 * To run a mutation, you first call `useSwitchFinancingIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchFinancingIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchFinancingIntentMutation, { data, loading, error }] = useSwitchFinancingIntentMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      intended: // value for 'intended'
 *   },
 * });
 */
export function useSwitchFinancingIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<SwitchFinancingIntentMutation, SwitchFinancingIntentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SwitchFinancingIntentMutation, SwitchFinancingIntentMutationVariables>(
    SwitchFinancingIntentDocument,
    options,
  );
}
export type SwitchFinancingIntentMutationHookResult = ReturnType<typeof useSwitchFinancingIntentMutation>;
export type SwitchFinancingIntentMutationResult = Apollo.MutationResult<SwitchFinancingIntentMutation>;
export type SwitchFinancingIntentMutationOptions = Apollo.BaseMutationOptions<
  SwitchFinancingIntentMutation,
  SwitchFinancingIntentMutationVariables
>;
export const OnboardingAccountDetailDocument = gql`
  query OnboardingAccountDetail {
    account {
      upcomingSubscriptionSet {
        ...upcomingSubscriptionSet
      }
      termCommitment {
        term
      }
      rescheduleOfferDetails {
        token
        coupon {
          ...Account__Marketing__Coupon
        }
      }
    }
  }
  ${UpcomingSubscriptionSetFragmentDoc}
  ${Account__Marketing__CouponFragmentDoc}
`;

/**
 * __useOnboardingAccountDetailQuery__
 *
 * To run a query within a React component, call `useOnboardingAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingAccountDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingAccountDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<OnboardingAccountDetailQuery, OnboardingAccountDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OnboardingAccountDetailQuery, OnboardingAccountDetailQueryVariables>(
    OnboardingAccountDetailDocument,
    options,
  );
}
export function useOnboardingAccountDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OnboardingAccountDetailQuery, OnboardingAccountDetailQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OnboardingAccountDetailQuery, OnboardingAccountDetailQueryVariables>(
    OnboardingAccountDetailDocument,
    options,
  );
}
export type OnboardingAccountDetailQueryHookResult = ReturnType<typeof useOnboardingAccountDetailQuery>;
export type OnboardingAccountDetailLazyQueryHookResult = ReturnType<typeof useOnboardingAccountDetailLazyQuery>;
export type OnboardingAccountDetailQueryResult = Apollo.QueryResult<
  OnboardingAccountDetailQuery,
  OnboardingAccountDetailQueryVariables
>;
export const AvailableForMoversDocument = gql`
  query AvailableForMovers($input: AvailabilitiesInput!) {
    availableForMovers(input: $input)
  }
`;

/**
 * __useAvailableForMoversQuery__
 *
 * To run a query within a React component, call `useAvailableForMoversQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableForMoversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableForMoversQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailableForMoversQuery(
  baseOptions: Apollo.QueryHookOptions<AvailableForMoversQuery, AvailableForMoversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableForMoversQuery, AvailableForMoversQueryVariables>(
    AvailableForMoversDocument,
    options,
  );
}
export function useAvailableForMoversLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AvailableForMoversQuery, AvailableForMoversQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailableForMoversQuery, AvailableForMoversQueryVariables>(
    AvailableForMoversDocument,
    options,
  );
}
export type AvailableForMoversQueryHookResult = ReturnType<typeof useAvailableForMoversQuery>;
export type AvailableForMoversLazyQueryHookResult = ReturnType<typeof useAvailableForMoversLazyQuery>;
export type AvailableForMoversQueryResult = Apollo.QueryResult<
  AvailableForMoversQuery,
  AvailableForMoversQueryVariables
>;
export const MovingEstimationsUpdateDocument = gql`
  mutation MovingEstimationsUpdate(
    $input: Moving__EstimationsUpdateInputType!
    $rescheduleInput: Moving__RescheduleInputType
  ) {
    movingEstimationsUpdate(input: $input, rescheduleInput: $rescheduleInput) {
      error
      status
      order {
        id
        movers
        scheduled
      }
    }
  }
`;
export type MovingEstimationsUpdateMutationFn = Apollo.MutationFunction<
  MovingEstimationsUpdateMutation,
  MovingEstimationsUpdateMutationVariables
>;

/**
 * __useMovingEstimationsUpdateMutation__
 *
 * To run a mutation, you first call `useMovingEstimationsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingEstimationsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingEstimationsUpdateMutation, { data, loading, error }] = useMovingEstimationsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *      rescheduleInput: // value for 'rescheduleInput'
 *   },
 * });
 */
export function useMovingEstimationsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingEstimationsUpdateMutation, MovingEstimationsUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingEstimationsUpdateMutation, MovingEstimationsUpdateMutationVariables>(
    MovingEstimationsUpdateDocument,
    options,
  );
}
export type MovingEstimationsUpdateMutationHookResult = ReturnType<typeof useMovingEstimationsUpdateMutation>;
export type MovingEstimationsUpdateMutationResult = Apollo.MutationResult<MovingEstimationsUpdateMutation>;
export type MovingEstimationsUpdateMutationOptions = Apollo.BaseMutationOptions<
  MovingEstimationsUpdateMutation,
  MovingEstimationsUpdateMutationVariables
>;
export const CouponApplyDocument = gql`
  mutation CouponApply($promoCode: String!, $orderID: ID!) {
    couponApply(promoCode: $promoCode, orderID: $orderID) {
      status
      error
      order {
        id
        account {
          id
          coupon {
            ...coupon
          }
        }
        coupon {
          ...coupon
        }
      }
    }
  }
  ${CouponFragmentDoc}
`;
export type CouponApplyMutationFn = Apollo.MutationFunction<CouponApplyMutation, CouponApplyMutationVariables>;

/**
 * __useCouponApplyMutation__
 *
 * To run a mutation, you first call `useCouponApplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCouponApplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [couponApplyMutation, { data, loading, error }] = useCouponApplyMutation({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useCouponApplyMutation(
  baseOptions?: Apollo.MutationHookOptions<CouponApplyMutation, CouponApplyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CouponApplyMutation, CouponApplyMutationVariables>(CouponApplyDocument, options);
}
export type CouponApplyMutationHookResult = ReturnType<typeof useCouponApplyMutation>;
export type CouponApplyMutationResult = Apollo.MutationResult<CouponApplyMutation>;
export type CouponApplyMutationOptions = Apollo.BaseMutationOptions<CouponApplyMutation, CouponApplyMutationVariables>;
export const MovingQuoteRegenerateDocument = gql`
  mutation MovingQuoteRegenerate(
    $orderID: ID!
    $source: Moving__QuoteSourceEnum!
    $quoteInput: Moving__QuoteRegenerateInput
  ) {
    movingQuoteRegenerate(orderID: $orderID, source: $source, quoteInput: $quoteInput) {
      error
      status
      movingQuote {
        ...estimationMovingQuote
      }
    }
  }
  ${EstimationMovingQuoteFragmentDoc}
`;
export type MovingQuoteRegenerateMutationFn = Apollo.MutationFunction<
  MovingQuoteRegenerateMutation,
  MovingQuoteRegenerateMutationVariables
>;

/**
 * __useMovingQuoteRegenerateMutation__
 *
 * To run a mutation, you first call `useMovingQuoteRegenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingQuoteRegenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingQuoteRegenerateMutation, { data, loading, error }] = useMovingQuoteRegenerateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      source: // value for 'source'
 *      quoteInput: // value for 'quoteInput'
 *   },
 * });
 */
export function useMovingQuoteRegenerateMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingQuoteRegenerateMutation, MovingQuoteRegenerateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingQuoteRegenerateMutation, MovingQuoteRegenerateMutationVariables>(
    MovingQuoteRegenerateDocument,
    options,
  );
}
export type MovingQuoteRegenerateMutationHookResult = ReturnType<typeof useMovingQuoteRegenerateMutation>;
export type MovingQuoteRegenerateMutationResult = Apollo.MutationResult<MovingQuoteRegenerateMutation>;
export type MovingQuoteRegenerateMutationOptions = Apollo.BaseMutationOptions<
  MovingQuoteRegenerateMutation,
  MovingQuoteRegenerateMutationVariables
>;
export const AttachDisposalDocument = gql`
  mutation AttachDisposal($parentId: ID!) {
    disposalAttach(parentID: $parentId) {
      status
    }
  }
`;
export type AttachDisposalMutationFn = Apollo.MutationFunction<AttachDisposalMutation, AttachDisposalMutationVariables>;

/**
 * __useAttachDisposalMutation__
 *
 * To run a mutation, you first call `useAttachDisposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachDisposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachDisposalMutation, { data, loading, error }] = useAttachDisposalMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useAttachDisposalMutation(
  baseOptions?: Apollo.MutationHookOptions<AttachDisposalMutation, AttachDisposalMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachDisposalMutation, AttachDisposalMutationVariables>(AttachDisposalDocument, options);
}
export type AttachDisposalMutationHookResult = ReturnType<typeof useAttachDisposalMutation>;
export type AttachDisposalMutationResult = Apollo.MutationResult<AttachDisposalMutation>;
export type AttachDisposalMutationOptions = Apollo.BaseMutationOptions<
  AttachDisposalMutation,
  AttachDisposalMutationVariables
>;
export const EstimationFlowCompletionDocument = gql`
  query EstimationFlowCompletion(
    $resourceID: ID!
    $resourceType: Estimation__ResourceType!
    $algorithmName: Estimation__AlgorithmName!
  ) {
    estimationSelectedRooms(orderID: $resourceID) {
      id
      name
      roomCategory {
        id
        name
      }
      estimatedItems {
        id
        quantity
        category {
          id
          name
          position
        }
      }
    }
    estimationItems(resourceID: $resourceID, resourceType: $resourceType, algorithmName: $algorithmName) {
      id
      quantity
      category {
        id
        name
      }
    }
    estimationStatus(orderID: $resourceID) {
      id
      complete
      fitsPlanSize
    }
  }
`;

/**
 * __useEstimationFlowCompletionQuery__
 *
 * To run a query within a React component, call `useEstimationFlowCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimationFlowCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimationFlowCompletionQuery({
 *   variables: {
 *      resourceID: // value for 'resourceID'
 *      resourceType: // value for 'resourceType'
 *      algorithmName: // value for 'algorithmName'
 *   },
 * });
 */
export function useEstimationFlowCompletionQuery(
  baseOptions: Apollo.QueryHookOptions<EstimationFlowCompletionQuery, EstimationFlowCompletionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimationFlowCompletionQuery, EstimationFlowCompletionQueryVariables>(
    EstimationFlowCompletionDocument,
    options,
  );
}
export function useEstimationFlowCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EstimationFlowCompletionQuery, EstimationFlowCompletionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimationFlowCompletionQuery, EstimationFlowCompletionQueryVariables>(
    EstimationFlowCompletionDocument,
    options,
  );
}
export type EstimationFlowCompletionQueryHookResult = ReturnType<typeof useEstimationFlowCompletionQuery>;
export type EstimationFlowCompletionLazyQueryHookResult = ReturnType<typeof useEstimationFlowCompletionLazyQuery>;
export type EstimationFlowCompletionQueryResult = Apollo.QueryResult<
  EstimationFlowCompletionQuery,
  EstimationFlowCompletionQueryVariables
>;
export const AppointmentHubDocument = gql`
  query AppointmentHub($orderID: ID!) {
    estimationCategories {
      ...estimationCategory
    }
    roomCategories {
      ...roomCategory
    }
    order(orderID: $orderID) {
      ...order
      started
      bookingPartner {
        ...Partnerships__Partner
      }
      estimationAlgorithm {
        name
      }
      dropOffAppointment {
        ...FlowDropOffAppointment
      }
      movers
      consumerBrochure {
        id
        url
      }
      depositPercent
      formattedDepositInterval
      account {
        id
        coupon {
          ...coupon
        }
        customer {
          id
          firstName
          name
          phone
        }
        state
      }
      movingPricingSummary {
        totalFixedAmount
        fixedDuration
        materialsAmount
        materialPackageSetEntry {
          id
          fixedCost
          package {
            name
          }
        }
        laborRate {
          id
          amount
        }
        flatRateAmount
      }
      address {
        ...address
        details {
          ...details
        }
      }
      contact {
        name
        phone
      }
      services {
        id
        type
        subtype
        ...pickupInventory
        ...returnInventory
      }
      permissions {
        id
        active
        addDisposal
        locked
        cancelable
        relocatable
        reschedulable
        changeablePickupInventory
        changeableReturnInventory
        chargeForCancellation
        chargeLateFee
        customerChangeableMoverCount
        priceMatchGuaranteeEligible
        landingCheckoutPageTestEligible
        whiteGloveTestEligible
      }
      laborRate {
        id
        amount
      }
      perItemPricingInfo {
        ...itemPricing
      }
      accountCancelIntent {
        reason
      }
      accountPackages {
        id
        amount
        name
        kind
      }
      coiRequests {
        id
        state
        createdAt
        certificateOfInsurance {
          id
          url
        }
        addressID
      }
      coupon {
        ...coupon
      }
      movingOperation {
        id
        originAddress {
          ...address
          details {
            ...details
          }
        }
        destinationAddress {
          ...address
          details {
            ...details
          }
        }
        moveSize
        movingConfiguration {
          id
          travelRateMultiplier
        }
        packing
        walkthroughEstimatedDuration
        longDistanceTransportationRate {
          id
          description
        }
        longDistanceMinimumCargoWeightInPounds
        longDistanceMinimumTransportFee
      }
      movingSelections {
        id
        quantity
        movingCategory {
          id
          name
        }
      }
      retailDelivery {
        ...retailOrder
      }
      retailDeliveryItems {
        id
        quantity
      }
      requiredLaborDuration
      financingIntent {
        id
        intended
      }
      financingContext {
        total
      }
      failedPrepayment {
        id
        amount
        dueAt
      }
      laborCostDetails {
        id
        appointmentFeeCost
        laborCost
      }
      simultaneousPickup {
        id
      }
      nonTravelSourceAndSuccessors {
        id
        type
        subtype
        scheduled
        window
      }
      multiDay
      storageAddOnEligible
      waitlistRequests {
        ...availabilityWaitlistRequest
      }
      tags
      formattedArrivalWindow
      pickupServicePackageOptions {
        id
        kind
        name
        packageSetEntry {
          id
          fixedCost
        }
      }
    }
    currentStorageSubscription {
      id
      formattedName
      cubicFootage
    }
  }
  ${EstimationCategoryFragmentDoc}
  ${RoomCategoryFragmentDoc}
  ${OrderFragmentDoc}
  ${Partnerships__PartnerFragmentDoc}
  ${FlowDropOffAppointmentFragmentDoc}
  ${CouponFragmentDoc}
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
  ${PickupInventoryFragmentDoc}
  ${ReturnInventoryFragmentDoc}
  ${ItemPricingFragmentDoc}
  ${RetailOrderFragmentDoc}
  ${AvailabilityWaitlistRequestFragmentDoc}
`;

/**
 * __useAppointmentHubQuery__
 *
 * To run a query within a React component, call `useAppointmentHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentHubQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useAppointmentHubQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentHubQuery, AppointmentHubQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentHubQuery, AppointmentHubQueryVariables>(AppointmentHubDocument, options);
}
export function useAppointmentHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentHubQuery, AppointmentHubQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentHubQuery, AppointmentHubQueryVariables>(AppointmentHubDocument, options);
}
export type AppointmentHubQueryHookResult = ReturnType<typeof useAppointmentHubQuery>;
export type AppointmentHubLazyQueryHookResult = ReturnType<typeof useAppointmentHubLazyQuery>;
export type AppointmentHubQueryResult = Apollo.QueryResult<AppointmentHubQuery, AppointmentHubQueryVariables>;
export const OrdersListDocument = gql`
  query OrdersList($onlyActive: Boolean = false, $onlyCustomerSource: Boolean = false) {
    orders(onlyActive: $onlyActive, onlyCustomerSource: $onlyCustomerSource) {
      ...order
      isActive
      address {
        ...address
      }
      facilityWarehouse {
        id
        accessHours
      }
      bookingPartner {
        ...Partnerships__Partner
      }
      services {
        type
        subtype
      }
      accountCancelIntent {
        reason
      }
      shipped
      multiDay
      nonTravelSourceAndSuccessors {
        id
        isActive
        scheduled
        type
        subtype
        window
      }
      waitlistRequests {
        ...availabilityWaitlistRequest
      }
      formattedArrivalWindow
      permissions {
        id
        cancelable
        reschedulable
      }
    }
    account {
      id
      onboardable
      requestableItemsExist
      unscheduledStorageTreasuresAuctionsWon {
        ...storageTreasuresAuction
      }
    }
  }
  ${OrderFragmentDoc}
  ${AddressFragmentDoc}
  ${Partnerships__PartnerFragmentDoc}
  ${AvailabilityWaitlistRequestFragmentDoc}
  ${StorageTreasuresAuctionFragmentDoc}
`;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *      onlyCustomerSource: // value for 'onlyCustomerSource'
 *   },
 * });
 */
export function useOrdersListQuery(baseOptions?: Apollo.QueryHookOptions<OrdersListQuery, OrdersListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
}
export function useOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrdersListQuery, OrdersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(OrdersListDocument, options);
}
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<typeof useOrdersListLazyQuery>;
export type OrdersListQueryResult = Apollo.QueryResult<OrdersListQuery, OrdersListQueryVariables>;
export const OrdersDocument = gql`
  query Orders($onlyActive: Boolean = false) {
    orders(onlyActive: $onlyActive) {
      ...order
      address {
        ...address
      }
      bookingPartner {
        ...Partnerships__Partner
      }
      services {
        type
        subtype
      }
      dropOffAppointment {
        ...FlowDropOffAppointment
      }
      accountCancelIntent {
        reason
      }
      shipped
      primary
      formattedArrivalWindow
    }
    account {
      onboardable
      requestableItemsExist
      unscheduledStorageTreasuresAuctionsWon {
        ...storageTreasuresAuction
      }
    }
    storageCheckoutLink: reonboardingCheckoutLink(type: PICKUP)
  }
  ${OrderFragmentDoc}
  ${AddressFragmentDoc}
  ${Partnerships__PartnerFragmentDoc}
  ${FlowDropOffAppointmentFragmentDoc}
  ${StorageTreasuresAuctionFragmentDoc}
`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
}
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const AppointmentDetailsDocument = gql`
  query AppointmentDetails($orderID: ID!) {
    order(orderID: $orderID) {
      id
      scheduled
      account {
        id
      }
      movingOperation {
        id
        originAddress {
          ...address
          details {
            ...details
          }
        }
        destinationAddress {
          ...address
          details {
            ...details
          }
        }
        packing
        moveSize
        movingQuote {
          id
        }
      }
      movingSelections {
        id
        quantity
        movingCategory {
          id
          name
        }
      }
      movingPricingSummary {
        materialPackageSetEntry {
          id
        }
      }
      movers
      permissions {
        id
        relocatable
      }
    }
  }
  ${AddressFragmentDoc}
  ${DetailsFragmentDoc}
`;

/**
 * __useAppointmentDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentDetailsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useAppointmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>(
    AppointmentDetailsDocument,
    options,
  );
}
export function useAppointmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>(
    AppointmentDetailsDocument,
    options,
  );
}
export type AppointmentDetailsQueryHookResult = ReturnType<typeof useAppointmentDetailsQuery>;
export type AppointmentDetailsLazyQueryHookResult = ReturnType<typeof useAppointmentDetailsLazyQuery>;
export type AppointmentDetailsQueryResult = Apollo.QueryResult<
  AppointmentDetailsQuery,
  AppointmentDetailsQueryVariables
>;
export const MovingPackingSuppliesDocument = gql`
  query MovingPackingSupplies($orderID: ID!) {
    order(orderID: $orderID) {
      id
      scheduled
      type
      accountPackages {
        id
        amount
        name
        kind
      }
      movingOperation {
        packing
      }
    }
  }
`;

/**
 * __useMovingPackingSuppliesQuery__
 *
 * To run a query within a React component, call `useMovingPackingSuppliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingPackingSuppliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingPackingSuppliesQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingPackingSuppliesQuery(
  baseOptions: Apollo.QueryHookOptions<MovingPackingSuppliesQuery, MovingPackingSuppliesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingPackingSuppliesQuery, MovingPackingSuppliesQueryVariables>(
    MovingPackingSuppliesDocument,
    options,
  );
}
export function useMovingPackingSuppliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MovingPackingSuppliesQuery, MovingPackingSuppliesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingPackingSuppliesQuery, MovingPackingSuppliesQueryVariables>(
    MovingPackingSuppliesDocument,
    options,
  );
}
export type MovingPackingSuppliesQueryHookResult = ReturnType<typeof useMovingPackingSuppliesQuery>;
export type MovingPackingSuppliesLazyQueryHookResult = ReturnType<typeof useMovingPackingSuppliesLazyQuery>;
export type MovingPackingSuppliesQueryResult = Apollo.QueryResult<
  MovingPackingSuppliesQuery,
  MovingPackingSuppliesQueryVariables
>;
export const MovingPackingHelpUpdateDocument = gql`
  mutation MovingPackingHelpUpdate($orderID: ID!, $packingHelp: Moving__PackingEnum!) {
    movingPackingHelpUpdate(orderID: $orderID, packingHelp: $packingHelp) {
      status
      error
    }
  }
`;
export type MovingPackingHelpUpdateMutationFn = Apollo.MutationFunction<
  MovingPackingHelpUpdateMutation,
  MovingPackingHelpUpdateMutationVariables
>;

/**
 * __useMovingPackingHelpUpdateMutation__
 *
 * To run a mutation, you first call `useMovingPackingHelpUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingPackingHelpUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingPackingHelpUpdateMutation, { data, loading, error }] = useMovingPackingHelpUpdateMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      packingHelp: // value for 'packingHelp'
 *   },
 * });
 */
export function useMovingPackingHelpUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingPackingHelpUpdateMutation, MovingPackingHelpUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingPackingHelpUpdateMutation, MovingPackingHelpUpdateMutationVariables>(
    MovingPackingHelpUpdateDocument,
    options,
  );
}
export type MovingPackingHelpUpdateMutationHookResult = ReturnType<typeof useMovingPackingHelpUpdateMutation>;
export type MovingPackingHelpUpdateMutationResult = Apollo.MutationResult<MovingPackingHelpUpdateMutation>;
export type MovingPackingHelpUpdateMutationOptions = Apollo.BaseMutationOptions<
  MovingPackingHelpUpdateMutation,
  MovingPackingHelpUpdateMutationVariables
>;
export const UpdatePickupInventoryDocument = gql`
  mutation UpdatePickupInventory($orderID: ID!, $input: PickupInventoryInput!) {
    updatePickupInventory(orderID: $orderID, input: $input) {
      error
      status
      message
    }
  }
`;
export type UpdatePickupInventoryMutationFn = Apollo.MutationFunction<
  UpdatePickupInventoryMutation,
  UpdatePickupInventoryMutationVariables
>;

/**
 * __useUpdatePickupInventoryMutation__
 *
 * To run a mutation, you first call `useUpdatePickupInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupInventoryMutation, { data, loading, error }] = useUpdatePickupInventoryMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePickupInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePickupInventoryMutation, UpdatePickupInventoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePickupInventoryMutation, UpdatePickupInventoryMutationVariables>(
    UpdatePickupInventoryDocument,
    options,
  );
}
export type UpdatePickupInventoryMutationHookResult = ReturnType<typeof useUpdatePickupInventoryMutation>;
export type UpdatePickupInventoryMutationResult = Apollo.MutationResult<UpdatePickupInventoryMutation>;
export type UpdatePickupInventoryMutationOptions = Apollo.BaseMutationOptions<
  UpdatePickupInventoryMutation,
  UpdatePickupInventoryMutationVariables
>;
export const OrderFormPickupInventoryQueryDocument = gql`
  query OrderFormPickupInventoryQuery($orderID: ID!) {
    estimationCategories {
      ...estimationCategory
    }
    roomCategories {
      ...roomCategory
    }
    order(orderID: $orderID) {
      ...PickupInventoryOrder
    }
  }
  ${EstimationCategoryFragmentDoc}
  ${RoomCategoryFragmentDoc}
  ${PickupInventoryOrderFragmentDoc}
`;

/**
 * __useOrderFormPickupInventoryQueryQuery__
 *
 * To run a query within a React component, call `useOrderFormPickupInventoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFormPickupInventoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFormPickupInventoryQueryQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFormPickupInventoryQueryQuery(
  baseOptions: Apollo.QueryHookOptions<OrderFormPickupInventoryQueryQuery, OrderFormPickupInventoryQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderFormPickupInventoryQueryQuery, OrderFormPickupInventoryQueryQueryVariables>(
    OrderFormPickupInventoryQueryDocument,
    options,
  );
}
export function useOrderFormPickupInventoryQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderFormPickupInventoryQueryQuery,
    OrderFormPickupInventoryQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderFormPickupInventoryQueryQuery, OrderFormPickupInventoryQueryQueryVariables>(
    OrderFormPickupInventoryQueryDocument,
    options,
  );
}
export type OrderFormPickupInventoryQueryQueryHookResult = ReturnType<typeof useOrderFormPickupInventoryQueryQuery>;
export type OrderFormPickupInventoryQueryLazyQueryHookResult = ReturnType<
  typeof useOrderFormPickupInventoryQueryLazyQuery
>;
export type OrderFormPickupInventoryQueryQueryResult = Apollo.QueryResult<
  OrderFormPickupInventoryQueryQuery,
  OrderFormPickupInventoryQueryQueryVariables
>;
export const RescheduleOrderDocument = gql`
  mutation RescheduleOrder($input: RescheduleOrderInput!) {
    rescheduleOrder(input: $input) {
      error
      status
      order {
        ...RescheduleOrder
      }
    }
  }
  ${RescheduleOrderFragmentDoc}
`;
export type RescheduleOrderMutationFn = Apollo.MutationFunction<
  RescheduleOrderMutation,
  RescheduleOrderMutationVariables
>;

/**
 * __useRescheduleOrderMutation__
 *
 * To run a mutation, you first call `useRescheduleOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleOrderMutation, { data, loading, error }] = useRescheduleOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRescheduleOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<RescheduleOrderMutation, RescheduleOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RescheduleOrderMutation, RescheduleOrderMutationVariables>(
    RescheduleOrderDocument,
    options,
  );
}
export type RescheduleOrderMutationHookResult = ReturnType<typeof useRescheduleOrderMutation>;
export type RescheduleOrderMutationResult = Apollo.MutationResult<RescheduleOrderMutation>;
export type RescheduleOrderMutationOptions = Apollo.BaseMutationOptions<
  RescheduleOrderMutation,
  RescheduleOrderMutationVariables
>;
export const OrderFormRescheduleDocument = gql`
  query OrderFormReschedule($orderID: ID!) {
    order(orderID: $orderID) {
      ...RescheduleOrder
    }
    account {
      termCommitment {
        ...termCommitment
      }
    }
  }
  ${RescheduleOrderFragmentDoc}
  ${TermCommitmentFragmentDoc}
`;

/**
 * __useOrderFormRescheduleQuery__
 *
 * To run a query within a React component, call `useOrderFormRescheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFormRescheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFormRescheduleQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFormRescheduleQuery(
  baseOptions: Apollo.QueryHookOptions<OrderFormRescheduleQuery, OrderFormRescheduleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderFormRescheduleQuery, OrderFormRescheduleQueryVariables>(
    OrderFormRescheduleDocument,
    options,
  );
}
export function useOrderFormRescheduleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderFormRescheduleQuery, OrderFormRescheduleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderFormRescheduleQuery, OrderFormRescheduleQueryVariables>(
    OrderFormRescheduleDocument,
    options,
  );
}
export type OrderFormRescheduleQueryHookResult = ReturnType<typeof useOrderFormRescheduleQuery>;
export type OrderFormRescheduleLazyQueryHookResult = ReturnType<typeof useOrderFormRescheduleLazyQuery>;
export type OrderFormRescheduleQueryResult = Apollo.QueryResult<
  OrderFormRescheduleQuery,
  OrderFormRescheduleQueryVariables
>;
export const AvailabilityWaitlistRequestCreateDocument = gql`
  mutation AvailabilityWaitlistRequestCreate($input: Availability__WaitlistRequestCreateInput!) {
    result: availabilityWaitlistRequestCreate(input: $input) {
      error
      status
      waitlistRequest {
        ...availabilityWaitlistRequest
      }
    }
  }
  ${AvailabilityWaitlistRequestFragmentDoc}
`;
export type AvailabilityWaitlistRequestCreateMutationFn = Apollo.MutationFunction<
  AvailabilityWaitlistRequestCreateMutation,
  AvailabilityWaitlistRequestCreateMutationVariables
>;

/**
 * __useAvailabilityWaitlistRequestCreateMutation__
 *
 * To run a mutation, you first call `useAvailabilityWaitlistRequestCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityWaitlistRequestCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityWaitlistRequestCreateMutation, { data, loading, error }] = useAvailabilityWaitlistRequestCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityWaitlistRequestCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityWaitlistRequestCreateMutation,
    AvailabilityWaitlistRequestCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityWaitlistRequestCreateMutation,
    AvailabilityWaitlistRequestCreateMutationVariables
  >(AvailabilityWaitlistRequestCreateDocument, options);
}
export type AvailabilityWaitlistRequestCreateMutationHookResult = ReturnType<
  typeof useAvailabilityWaitlistRequestCreateMutation
>;
export type AvailabilityWaitlistRequestCreateMutationResult =
  Apollo.MutationResult<AvailabilityWaitlistRequestCreateMutation>;
export type AvailabilityWaitlistRequestCreateMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityWaitlistRequestCreateMutation,
  AvailabilityWaitlistRequestCreateMutationVariables
>;
export const AvailabilityWaitlistRequestDestroyDocument = gql`
  mutation AvailabilityWaitlistRequestDestroy($input: Availability__WaitlistRequestDestroyInput!) {
    result: availabilityWaitlistRequestDestroy(input: $input) {
      error
      status
      deleted {
        id
      }
    }
  }
`;
export type AvailabilityWaitlistRequestDestroyMutationFn = Apollo.MutationFunction<
  AvailabilityWaitlistRequestDestroyMutation,
  AvailabilityWaitlistRequestDestroyMutationVariables
>;

/**
 * __useAvailabilityWaitlistRequestDestroyMutation__
 *
 * To run a mutation, you first call `useAvailabilityWaitlistRequestDestroyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAvailabilityWaitlistRequestDestroyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [availabilityWaitlistRequestDestroyMutation, { data, loading, error }] = useAvailabilityWaitlistRequestDestroyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAvailabilityWaitlistRequestDestroyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AvailabilityWaitlistRequestDestroyMutation,
    AvailabilityWaitlistRequestDestroyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AvailabilityWaitlistRequestDestroyMutation,
    AvailabilityWaitlistRequestDestroyMutationVariables
  >(AvailabilityWaitlistRequestDestroyDocument, options);
}
export type AvailabilityWaitlistRequestDestroyMutationHookResult = ReturnType<
  typeof useAvailabilityWaitlistRequestDestroyMutation
>;
export type AvailabilityWaitlistRequestDestroyMutationResult =
  Apollo.MutationResult<AvailabilityWaitlistRequestDestroyMutation>;
export type AvailabilityWaitlistRequestDestroyMutationOptions = Apollo.BaseMutationOptions<
  AvailabilityWaitlistRequestDestroyMutation,
  AvailabilityWaitlistRequestDestroyMutationVariables
>;
export const UpdateReturnInventoryDocument = gql`
  mutation UpdateReturnInventory($orderID: ID!, $input: ReturnInventoryInput!) {
    updateReturnInventory(orderID: $orderID, input: $input) {
      status
      error
      order {
        ...ReturnInventoryOrder
      }
    }
  }
  ${ReturnInventoryOrderFragmentDoc}
`;
export type UpdateReturnInventoryMutationFn = Apollo.MutationFunction<
  UpdateReturnInventoryMutation,
  UpdateReturnInventoryMutationVariables
>;

/**
 * __useUpdateReturnInventoryMutation__
 *
 * To run a mutation, you first call `useUpdateReturnInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReturnInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReturnInventoryMutation, { data, loading, error }] = useUpdateReturnInventoryMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReturnInventoryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateReturnInventoryMutation, UpdateReturnInventoryMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReturnInventoryMutation, UpdateReturnInventoryMutationVariables>(
    UpdateReturnInventoryDocument,
    options,
  );
}
export type UpdateReturnInventoryMutationHookResult = ReturnType<typeof useUpdateReturnInventoryMutation>;
export type UpdateReturnInventoryMutationResult = Apollo.MutationResult<UpdateReturnInventoryMutation>;
export type UpdateReturnInventoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateReturnInventoryMutation,
  UpdateReturnInventoryMutationVariables
>;
export const OrderFormReturnInventoryDocument = gql`
  query OrderFormReturnInventory($orderID: ID!) {
    order(orderID: $orderID) {
      ...ReturnInventoryOrder
      permissions {
        id
        changeableReturnInventory
      }
    }
    account {
      termCommitment {
        ...termCommitment
      }
    }
  }
  ${ReturnInventoryOrderFragmentDoc}
  ${TermCommitmentFragmentDoc}
`;

/**
 * __useOrderFormReturnInventoryQuery__
 *
 * To run a query within a React component, call `useOrderFormReturnInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderFormReturnInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderFormReturnInventoryQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderFormReturnInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<OrderFormReturnInventoryQuery, OrderFormReturnInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderFormReturnInventoryQuery, OrderFormReturnInventoryQueryVariables>(
    OrderFormReturnInventoryDocument,
    options,
  );
}
export function useOrderFormReturnInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderFormReturnInventoryQuery, OrderFormReturnInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderFormReturnInventoryQuery, OrderFormReturnInventoryQueryVariables>(
    OrderFormReturnInventoryDocument,
    options,
  );
}
export type OrderFormReturnInventoryQueryHookResult = ReturnType<typeof useOrderFormReturnInventoryQuery>;
export type OrderFormReturnInventoryLazyQueryHookResult = ReturnType<typeof useOrderFormReturnInventoryLazyQuery>;
export type OrderFormReturnInventoryQueryResult = Apollo.QueryResult<
  OrderFormReturnInventoryQuery,
  OrderFormReturnInventoryQueryVariables
>;
export const MovingServicePackageUpdateOrderDocument = gql`
  query MovingServicePackageUpdateOrder($orderID: ID!) {
    order(orderID: $orderID) {
      id
      movingOperation {
        id
        movingQuote {
          id
          movingConfiguration {
            id
            requiredLaborDuration
          }
          whiteGloveMoverCountOptions {
            laborRate {
              id
              amount
            }
            moverCount
            whiteGloveBundle
          }
        }
      }
      tags
      movingMaterialPackageSetEntries {
        id
        fixedCost
        package {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useMovingServicePackageUpdateOrderQuery__
 *
 * To run a query within a React component, call `useMovingServicePackageUpdateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingServicePackageUpdateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingServicePackageUpdateOrderQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingServicePackageUpdateOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    MovingServicePackageUpdateOrderQuery,
    MovingServicePackageUpdateOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingServicePackageUpdateOrderQuery, MovingServicePackageUpdateOrderQueryVariables>(
    MovingServicePackageUpdateOrderDocument,
    options,
  );
}
export function useMovingServicePackageUpdateOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MovingServicePackageUpdateOrderQuery,
    MovingServicePackageUpdateOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingServicePackageUpdateOrderQuery, MovingServicePackageUpdateOrderQueryVariables>(
    MovingServicePackageUpdateOrderDocument,
    options,
  );
}
export type MovingServicePackageUpdateOrderQueryHookResult = ReturnType<typeof useMovingServicePackageUpdateOrderQuery>;
export type MovingServicePackageUpdateOrderLazyQueryHookResult = ReturnType<
  typeof useMovingServicePackageUpdateOrderLazyQuery
>;
export type MovingServicePackageUpdateOrderQueryResult = Apollo.QueryResult<
  MovingServicePackageUpdateOrderQuery,
  MovingServicePackageUpdateOrderQueryVariables
>;
export const MovingServicePackageUpdateDocument = gql`
  mutation MovingServicePackageUpdate($input: Moving__ServicePackageUpdate__Input!) {
    movingServicePackageUpdate(input: $input) {
      status
      error
    }
  }
`;
export type MovingServicePackageUpdateMutationFn = Apollo.MutationFunction<
  MovingServicePackageUpdateMutation,
  MovingServicePackageUpdateMutationVariables
>;

/**
 * __useMovingServicePackageUpdateMutation__
 *
 * To run a mutation, you first call `useMovingServicePackageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingServicePackageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingServicePackageUpdateMutation, { data, loading, error }] = useMovingServicePackageUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovingServicePackageUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MovingServicePackageUpdateMutation,
    MovingServicePackageUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingServicePackageUpdateMutation, MovingServicePackageUpdateMutationVariables>(
    MovingServicePackageUpdateDocument,
    options,
  );
}
export type MovingServicePackageUpdateMutationHookResult = ReturnType<typeof useMovingServicePackageUpdateMutation>;
export type MovingServicePackageUpdateMutationResult = Apollo.MutationResult<MovingServicePackageUpdateMutation>;
export type MovingServicePackageUpdateMutationOptions = Apollo.BaseMutationOptions<
  MovingServicePackageUpdateMutation,
  MovingServicePackageUpdateMutationVariables
>;
export const PickupServicePackageUpdateOrderDocument = gql`
  query PickupServicePackageUpdateOrder($orderID: ID!) {
    order(orderID: $orderID) {
      id
      pickupServicePackageOptions {
        id
        kind
        name
        packageSetEntry {
          id
          fixedCost
        }
      }
      tags
    }
  }
`;

/**
 * __usePickupServicePackageUpdateOrderQuery__
 *
 * To run a query within a React component, call `usePickupServicePackageUpdateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupServicePackageUpdateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupServicePackageUpdateOrderQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function usePickupServicePackageUpdateOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    PickupServicePackageUpdateOrderQuery,
    PickupServicePackageUpdateOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PickupServicePackageUpdateOrderQuery, PickupServicePackageUpdateOrderQueryVariables>(
    PickupServicePackageUpdateOrderDocument,
    options,
  );
}
export function usePickupServicePackageUpdateOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PickupServicePackageUpdateOrderQuery,
    PickupServicePackageUpdateOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PickupServicePackageUpdateOrderQuery, PickupServicePackageUpdateOrderQueryVariables>(
    PickupServicePackageUpdateOrderDocument,
    options,
  );
}
export type PickupServicePackageUpdateOrderQueryHookResult = ReturnType<typeof usePickupServicePackageUpdateOrderQuery>;
export type PickupServicePackageUpdateOrderLazyQueryHookResult = ReturnType<
  typeof usePickupServicePackageUpdateOrderLazyQuery
>;
export type PickupServicePackageUpdateOrderQueryResult = Apollo.QueryResult<
  PickupServicePackageUpdateOrderQuery,
  PickupServicePackageUpdateOrderQueryVariables
>;
export const PickupServicePackageUpdateDocument = gql`
  mutation PickupServicePackageUpdate($input: PickupServicePackageUpdate__Input!) {
    pickupServicePackageUpdate(input: $input) {
      status
      error
    }
  }
`;
export type PickupServicePackageUpdateMutationFn = Apollo.MutationFunction<
  PickupServicePackageUpdateMutation,
  PickupServicePackageUpdateMutationVariables
>;

/**
 * __usePickupServicePackageUpdateMutation__
 *
 * To run a mutation, you first call `usePickupServicePackageUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickupServicePackageUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickupServicePackageUpdateMutation, { data, loading, error }] = usePickupServicePackageUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePickupServicePackageUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PickupServicePackageUpdateMutation,
    PickupServicePackageUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PickupServicePackageUpdateMutation, PickupServicePackageUpdateMutationVariables>(
    PickupServicePackageUpdateDocument,
    options,
  );
}
export type PickupServicePackageUpdateMutationHookResult = ReturnType<typeof usePickupServicePackageUpdateMutation>;
export type PickupServicePackageUpdateMutationResult = Apollo.MutationResult<PickupServicePackageUpdateMutation>;
export type PickupServicePackageUpdateMutationOptions = Apollo.BaseMutationOptions<
  PickupServicePackageUpdateMutation,
  PickupServicePackageUpdateMutationVariables
>;
export const EligibleFacilityDocument = gql`
  query EligibleFacility($itemIDs: [ID!]!) {
    facility(itemIDs: $itemIDs) {
      ...facility
    }
  }
  ${FacilityFragmentDoc}
`;

/**
 * __useEligibleFacilityQuery__
 *
 * To run a query within a React component, call `useEligibleFacilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibleFacilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibleFacilityQuery({
 *   variables: {
 *      itemIDs: // value for 'itemIDs'
 *   },
 * });
 */
export function useEligibleFacilityQuery(
  baseOptions: Apollo.QueryHookOptions<EligibleFacilityQuery, EligibleFacilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EligibleFacilityQuery, EligibleFacilityQueryVariables>(EligibleFacilityDocument, options);
}
export function useEligibleFacilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EligibleFacilityQuery, EligibleFacilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EligibleFacilityQuery, EligibleFacilityQueryVariables>(EligibleFacilityDocument, options);
}
export type EligibleFacilityQueryHookResult = ReturnType<typeof useEligibleFacilityQuery>;
export type EligibleFacilityLazyQueryHookResult = ReturnType<typeof useEligibleFacilityLazyQuery>;
export type EligibleFacilityQueryResult = Apollo.QueryResult<EligibleFacilityQuery, EligibleFacilityQueryVariables>;
export const OrderEligibleServiceTypesDocument = gql`
  query OrderEligibleServiceTypes($orderInput: OrderInput!, $skipPrimaryOrderCheck: Boolean) {
    orderEligibleServiceTypes(orderInput: $orderInput, skipPrimaryOrderCheck: $skipPrimaryOrderCheck) {
      serviceType
      eligible
      price
    }
  }
`;

/**
 * __useOrderEligibleServiceTypesQuery__
 *
 * To run a query within a React component, call `useOrderEligibleServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEligibleServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEligibleServiceTypesQuery({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      skipPrimaryOrderCheck: // value for 'skipPrimaryOrderCheck'
 *   },
 * });
 */
export function useOrderEligibleServiceTypesQuery(
  baseOptions: Apollo.QueryHookOptions<OrderEligibleServiceTypesQuery, OrderEligibleServiceTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderEligibleServiceTypesQuery, OrderEligibleServiceTypesQueryVariables>(
    OrderEligibleServiceTypesDocument,
    options,
  );
}
export function useOrderEligibleServiceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderEligibleServiceTypesQuery, OrderEligibleServiceTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderEligibleServiceTypesQuery, OrderEligibleServiceTypesQueryVariables>(
    OrderEligibleServiceTypesDocument,
    options,
  );
}
export type OrderEligibleServiceTypesQueryHookResult = ReturnType<typeof useOrderEligibleServiceTypesQuery>;
export type OrderEligibleServiceTypesLazyQueryHookResult = ReturnType<typeof useOrderEligibleServiceTypesLazyQuery>;
export type OrderEligibleServiceTypesQueryResult = Apollo.QueryResult<
  OrderEligibleServiceTypesQuery,
  OrderEligibleServiceTypesQueryVariables
>;
export const OrderSignatureRequestsDocument = gql`
  query OrderSignatureRequests($input: SignatureRequestInput!) {
    correctCustomerLoggedIn(input: $input)
    orderSignatures(input: $input) {
      id
      scheduled
      type
      physicallySigned
      status
      account {
        id
        customer {
          id
          firstName
        }
      }
      signatureRequests {
        ...signatureRequest
      }
      childDisposal {
        id
        disposalConfirmationSignedOrderId
        items {
          id
          uuid
        }
        state
      }
    }
    offerInTruckProtection(input: $input)
  }
  ${SignatureRequestFragmentDoc}
`;

/**
 * __useOrderSignatureRequestsQuery__
 *
 * To run a query within a React component, call `useOrderSignatureRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderSignatureRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderSignatureRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderSignatureRequestsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderSignatureRequestsQuery, OrderSignatureRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderSignatureRequestsQuery, OrderSignatureRequestsQueryVariables>(
    OrderSignatureRequestsDocument,
    options,
  );
}
export function useOrderSignatureRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderSignatureRequestsQuery, OrderSignatureRequestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderSignatureRequestsQuery, OrderSignatureRequestsQueryVariables>(
    OrderSignatureRequestsDocument,
    options,
  );
}
export type OrderSignatureRequestsQueryHookResult = ReturnType<typeof useOrderSignatureRequestsQuery>;
export type OrderSignatureRequestsLazyQueryHookResult = ReturnType<typeof useOrderSignatureRequestsLazyQuery>;
export type OrderSignatureRequestsQueryResult = Apollo.QueryResult<
  OrderSignatureRequestsQuery,
  OrderSignatureRequestsQueryVariables
>;
export const BuildAccountCancelIntentDocument = gql`
  mutation BuildAccountCancelIntent($reason: Account__CancelIntent__Reason!, $orderID: ID) {
    build: buildAccountCancelIntent(reason: $reason, orderID: $orderID) {
      status
      error
      accountCancelIntent {
        id
        reason
      }
      retentionOffer {
        ...retentionOffer
      }
    }
  }
  ${RetentionOfferFragmentDoc}
`;
export type BuildAccountCancelIntentMutationFn = Apollo.MutationFunction<
  BuildAccountCancelIntentMutation,
  BuildAccountCancelIntentMutationVariables
>;

/**
 * __useBuildAccountCancelIntentMutation__
 *
 * To run a mutation, you first call `useBuildAccountCancelIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildAccountCancelIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildAccountCancelIntentMutation, { data, loading, error }] = useBuildAccountCancelIntentMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useBuildAccountCancelIntentMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildAccountCancelIntentMutation, BuildAccountCancelIntentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildAccountCancelIntentMutation, BuildAccountCancelIntentMutationVariables>(
    BuildAccountCancelIntentDocument,
    options,
  );
}
export type BuildAccountCancelIntentMutationHookResult = ReturnType<typeof useBuildAccountCancelIntentMutation>;
export type BuildAccountCancelIntentMutationResult = Apollo.MutationResult<BuildAccountCancelIntentMutation>;
export type BuildAccountCancelIntentMutationOptions = Apollo.BaseMutationOptions<
  BuildAccountCancelIntentMutation,
  BuildAccountCancelIntentMutationVariables
>;
export const ChargeEarlyAccountClosureFeeDocument = gql`
  mutation ChargeEarlyAccountClosureFee($sourceID: ID!, $scheduled: DateTime) {
    charge: chargeEarlyAccountClosureFee(sourceID: $sourceID, scheduled: $scheduled) {
      status
      error
    }
  }
`;
export type ChargeEarlyAccountClosureFeeMutationFn = Apollo.MutationFunction<
  ChargeEarlyAccountClosureFeeMutation,
  ChargeEarlyAccountClosureFeeMutationVariables
>;

/**
 * __useChargeEarlyAccountClosureFeeMutation__
 *
 * To run a mutation, you first call `useChargeEarlyAccountClosureFeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeEarlyAccountClosureFeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeEarlyAccountClosureFeeMutation, { data, loading, error }] = useChargeEarlyAccountClosureFeeMutation({
 *   variables: {
 *      sourceID: // value for 'sourceID'
 *      scheduled: // value for 'scheduled'
 *   },
 * });
 */
export function useChargeEarlyAccountClosureFeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChargeEarlyAccountClosureFeeMutation,
    ChargeEarlyAccountClosureFeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChargeEarlyAccountClosureFeeMutation, ChargeEarlyAccountClosureFeeMutationVariables>(
    ChargeEarlyAccountClosureFeeDocument,
    options,
  );
}
export type ChargeEarlyAccountClosureFeeMutationHookResult = ReturnType<typeof useChargeEarlyAccountClosureFeeMutation>;
export type ChargeEarlyAccountClosureFeeMutationResult = Apollo.MutationResult<ChargeEarlyAccountClosureFeeMutation>;
export type ChargeEarlyAccountClosureFeeMutationOptions = Apollo.BaseMutationOptions<
  ChargeEarlyAccountClosureFeeMutation,
  ChargeEarlyAccountClosureFeeMutationVariables
>;
export const ShipmentLabelsDocument = gql`
  query ShipmentLabels($orderId: ID!) {
    shipmentLabels(orderId: $orderId) {
      easyPostShipmentId
      shipmentLabel
      shipmentContents
      carrier
      status
    }
  }
`;

/**
 * __useShipmentLabelsQuery__
 *
 * To run a query within a React component, call `useShipmentLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentLabelsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useShipmentLabelsQuery(
  baseOptions: Apollo.QueryHookOptions<ShipmentLabelsQuery, ShipmentLabelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShipmentLabelsQuery, ShipmentLabelsQueryVariables>(ShipmentLabelsDocument, options);
}
export function useShipmentLabelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentLabelsQuery, ShipmentLabelsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShipmentLabelsQuery, ShipmentLabelsQueryVariables>(ShipmentLabelsDocument, options);
}
export type ShipmentLabelsQueryHookResult = ReturnType<typeof useShipmentLabelsQuery>;
export type ShipmentLabelsLazyQueryHookResult = ReturnType<typeof useShipmentLabelsLazyQuery>;
export type ShipmentLabelsQueryResult = Apollo.QueryResult<ShipmentLabelsQuery, ShipmentLabelsQueryVariables>;
export const DisclaimersForBookingOrderDocument = gql`
  query DisclaimersForBookingOrder($input: OrderInput!) {
    disclaimersForBookingOrder(input: $input) {
      orderCancellationDisclaimer
      accountTerminationDisclaimer
      orderLaborDisclaimer
      authHoldDisclaimer
    }
  }
`;

/**
 * __useDisclaimersForBookingOrderQuery__
 *
 * To run a query within a React component, call `useDisclaimersForBookingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisclaimersForBookingOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisclaimersForBookingOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisclaimersForBookingOrderQuery(
  baseOptions: Apollo.QueryHookOptions<DisclaimersForBookingOrderQuery, DisclaimersForBookingOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisclaimersForBookingOrderQuery, DisclaimersForBookingOrderQueryVariables>(
    DisclaimersForBookingOrderDocument,
    options,
  );
}
export function useDisclaimersForBookingOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DisclaimersForBookingOrderQuery, DisclaimersForBookingOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisclaimersForBookingOrderQuery, DisclaimersForBookingOrderQueryVariables>(
    DisclaimersForBookingOrderDocument,
    options,
  );
}
export type DisclaimersForBookingOrderQueryHookResult = ReturnType<typeof useDisclaimersForBookingOrderQuery>;
export type DisclaimersForBookingOrderLazyQueryHookResult = ReturnType<typeof useDisclaimersForBookingOrderLazyQuery>;
export type DisclaimersForBookingOrderQueryResult = Apollo.QueryResult<
  DisclaimersForBookingOrderQuery,
  DisclaimersForBookingOrderQueryVariables
>;
export const ExtendedServiceAreaOrderDocument = gql`
  query ExtendedServiceAreaOrder($itemIDs: [ID!]!, $addressID: ID!, $orderSubtype: OrderSubtypeEnum!) {
    extendedServiceAreaOrder(itemIDs: $itemIDs, addressID: $addressID, orderSubtype: $orderSubtype)
  }
`;

/**
 * __useExtendedServiceAreaOrderQuery__
 *
 * To run a query within a React component, call `useExtendedServiceAreaOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtendedServiceAreaOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtendedServiceAreaOrderQuery({
 *   variables: {
 *      itemIDs: // value for 'itemIDs'
 *      addressID: // value for 'addressID'
 *      orderSubtype: // value for 'orderSubtype'
 *   },
 * });
 */
export function useExtendedServiceAreaOrderQuery(
  baseOptions: Apollo.QueryHookOptions<ExtendedServiceAreaOrderQuery, ExtendedServiceAreaOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExtendedServiceAreaOrderQuery, ExtendedServiceAreaOrderQueryVariables>(
    ExtendedServiceAreaOrderDocument,
    options,
  );
}
export function useExtendedServiceAreaOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExtendedServiceAreaOrderQuery, ExtendedServiceAreaOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExtendedServiceAreaOrderQuery, ExtendedServiceAreaOrderQueryVariables>(
    ExtendedServiceAreaOrderDocument,
    options,
  );
}
export type ExtendedServiceAreaOrderQueryHookResult = ReturnType<typeof useExtendedServiceAreaOrderQuery>;
export type ExtendedServiceAreaOrderLazyQueryHookResult = ReturnType<typeof useExtendedServiceAreaOrderLazyQuery>;
export type ExtendedServiceAreaOrderQueryResult = Apollo.QueryResult<
  ExtendedServiceAreaOrderQuery,
  ExtendedServiceAreaOrderQueryVariables
>;
export const FacilityInstructionsDocument = gql`
  query FacilityInstructions($articleName: Zendesk__ArticleName!) {
    zendeskCopy(articleName: $articleName)
  }
`;

/**
 * __useFacilityInstructionsQuery__
 *
 * To run a query within a React component, call `useFacilityInstructionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFacilityInstructionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFacilityInstructionsQuery({
 *   variables: {
 *      articleName: // value for 'articleName'
 *   },
 * });
 */
export function useFacilityInstructionsQuery(
  baseOptions: Apollo.QueryHookOptions<FacilityInstructionsQuery, FacilityInstructionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FacilityInstructionsQuery, FacilityInstructionsQueryVariables>(
    FacilityInstructionsDocument,
    options,
  );
}
export function useFacilityInstructionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FacilityInstructionsQuery, FacilityInstructionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FacilityInstructionsQuery, FacilityInstructionsQueryVariables>(
    FacilityInstructionsDocument,
    options,
  );
}
export type FacilityInstructionsQueryHookResult = ReturnType<typeof useFacilityInstructionsQuery>;
export type FacilityInstructionsLazyQueryHookResult = ReturnType<typeof useFacilityInstructionsLazyQuery>;
export type FacilityInstructionsQueryResult = Apollo.QueryResult<
  FacilityInstructionsQuery,
  FacilityInstructionsQueryVariables
>;
export const OrderBaseLaborRateDocument = gql`
  query OrderBaseLaborRate($input: OrderInput!) {
    orderBaseLaborRate(input: $input) {
      ...laborRate
    }
  }
  ${LaborRateFragmentDoc}
`;

/**
 * __useOrderBaseLaborRateQuery__
 *
 * To run a query within a React component, call `useOrderBaseLaborRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBaseLaborRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBaseLaborRateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderBaseLaborRateQuery(
  baseOptions: Apollo.QueryHookOptions<OrderBaseLaborRateQuery, OrderBaseLaborRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderBaseLaborRateQuery, OrderBaseLaborRateQueryVariables>(
    OrderBaseLaborRateDocument,
    options,
  );
}
export function useOrderBaseLaborRateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderBaseLaborRateQuery, OrderBaseLaborRateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderBaseLaborRateQuery, OrderBaseLaborRateQueryVariables>(
    OrderBaseLaborRateDocument,
    options,
  );
}
export type OrderBaseLaborRateQueryHookResult = ReturnType<typeof useOrderBaseLaborRateQuery>;
export type OrderBaseLaborRateLazyQueryHookResult = ReturnType<typeof useOrderBaseLaborRateLazyQuery>;
export type OrderBaseLaborRateQueryResult = Apollo.QueryResult<
  OrderBaseLaborRateQuery,
  OrderBaseLaborRateQueryVariables
>;
export const PerItemPricingDocument = gql`
  query PerItemPricing {
    account {
      perItemPricing
      perItemFee
      baseAppointmentFee
    }
  }
`;

/**
 * __usePerItemPricingQuery__
 *
 * To run a query within a React component, call `usePerItemPricingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerItemPricingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerItemPricingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerItemPricingQuery(
  baseOptions?: Apollo.QueryHookOptions<PerItemPricingQuery, PerItemPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PerItemPricingQuery, PerItemPricingQueryVariables>(PerItemPricingDocument, options);
}
export function usePerItemPricingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PerItemPricingQuery, PerItemPricingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PerItemPricingQuery, PerItemPricingQueryVariables>(PerItemPricingDocument, options);
}
export type PerItemPricingQueryHookResult = ReturnType<typeof usePerItemPricingQuery>;
export type PerItemPricingLazyQueryHookResult = ReturnType<typeof usePerItemPricingLazyQuery>;
export type PerItemPricingQueryResult = Apollo.QueryResult<PerItemPricingQuery, PerItemPricingQueryVariables>;
export const PricingPerItemFeeDocument = gql`
  query PricingPerItemFee($pickupInput: Pricing__PerItemFeeInput!, $returnInput: Pricing__PerItemFeeInput!) {
    subPickupPerItemPricingFee: pricingPerItemFee(input: $pickupInput) {
      ...perItemFee
    }
    subReturnPerItemPricingFee: pricingPerItemFee(input: $returnInput) {
      ...perItemFee
    }
  }
  ${PerItemFeeFragmentDoc}
`;

/**
 * __usePricingPerItemFeeQuery__
 *
 * To run a query within a React component, call `usePricingPerItemFeeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPerItemFeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPerItemFeeQuery({
 *   variables: {
 *      pickupInput: // value for 'pickupInput'
 *      returnInput: // value for 'returnInput'
 *   },
 * });
 */
export function usePricingPerItemFeeQuery(
  baseOptions: Apollo.QueryHookOptions<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>(PricingPerItemFeeDocument, options);
}
export function usePricingPerItemFeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>(
    PricingPerItemFeeDocument,
    options,
  );
}
export type PricingPerItemFeeQueryHookResult = ReturnType<typeof usePricingPerItemFeeQuery>;
export type PricingPerItemFeeLazyQueryHookResult = ReturnType<typeof usePricingPerItemFeeLazyQuery>;
export type PricingPerItemFeeQueryResult = Apollo.QueryResult<PricingPerItemFeeQuery, PricingPerItemFeeQueryVariables>;
export const PricingPolicyNoticeInfoDocument = gql`
  query PricingPolicyNoticeInfo {
    pricingPolicyNoticeInfo {
      ...Pricing__policyNoticeInfo
    }
  }
  ${Pricing__PolicyNoticeInfoFragmentDoc}
`;

/**
 * __usePricingPolicyNoticeInfoQuery__
 *
 * To run a query within a React component, call `usePricingPolicyNoticeInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePricingPolicyNoticeInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePricingPolicyNoticeInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePricingPolicyNoticeInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<PricingPolicyNoticeInfoQuery, PricingPolicyNoticeInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PricingPolicyNoticeInfoQuery, PricingPolicyNoticeInfoQueryVariables>(
    PricingPolicyNoticeInfoDocument,
    options,
  );
}
export function usePricingPolicyNoticeInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PricingPolicyNoticeInfoQuery, PricingPolicyNoticeInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PricingPolicyNoticeInfoQuery, PricingPolicyNoticeInfoQueryVariables>(
    PricingPolicyNoticeInfoDocument,
    options,
  );
}
export type PricingPolicyNoticeInfoQueryHookResult = ReturnType<typeof usePricingPolicyNoticeInfoQuery>;
export type PricingPolicyNoticeInfoLazyQueryHookResult = ReturnType<typeof usePricingPolicyNoticeInfoLazyQuery>;
export type PricingPolicyNoticeInfoQueryResult = Apollo.QueryResult<
  PricingPolicyNoticeInfoQuery,
  PricingPolicyNoticeInfoQueryVariables
>;
export const CustomerPriceDocument = gql`
  query CustomerPrice($orderInput: OrderInput, $shippingOptions: [Shipping__ShippingOptionInput!]!) {
    customerPrice(orderInput: $orderInput, shippingOptions: $shippingOptions) {
      carrier
      deliveryDays
      price
      service
      maxPrice
    }
  }
`;

/**
 * __useCustomerPriceQuery__
 *
 * To run a query within a React component, call `useCustomerPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerPriceQuery({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      shippingOptions: // value for 'shippingOptions'
 *   },
 * });
 */
export function useCustomerPriceQuery(
  baseOptions: Apollo.QueryHookOptions<CustomerPriceQuery, CustomerPriceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerPriceQuery, CustomerPriceQueryVariables>(CustomerPriceDocument, options);
}
export function useCustomerPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerPriceQuery, CustomerPriceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerPriceQuery, CustomerPriceQueryVariables>(CustomerPriceDocument, options);
}
export type CustomerPriceQueryHookResult = ReturnType<typeof useCustomerPriceQuery>;
export type CustomerPriceLazyQueryHookResult = ReturnType<typeof useCustomerPriceLazyQuery>;
export type CustomerPriceQueryResult = Apollo.QueryResult<CustomerPriceQuery, CustomerPriceQueryVariables>;
export const EasypostInboundShippingOptionsDocument = gql`
  query EasypostInboundShippingOptions($input: Shipping__EasyPostShipmentInput!) {
    easypostInboundShippingOptions(input: $input) {
      id
      itemName
      rates {
        ...Shipping__EasyPostRate
      }
      weight
    }
  }
  ${Shipping__EasyPostRateFragmentDoc}
`;

/**
 * __useEasypostInboundShippingOptionsQuery__
 *
 * To run a query within a React component, call `useEasypostInboundShippingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEasypostInboundShippingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEasypostInboundShippingOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEasypostInboundShippingOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EasypostInboundShippingOptionsQuery,
    EasypostInboundShippingOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EasypostInboundShippingOptionsQuery, EasypostInboundShippingOptionsQueryVariables>(
    EasypostInboundShippingOptionsDocument,
    options,
  );
}
export function useEasypostInboundShippingOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EasypostInboundShippingOptionsQuery,
    EasypostInboundShippingOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EasypostInboundShippingOptionsQuery, EasypostInboundShippingOptionsQueryVariables>(
    EasypostInboundShippingOptionsDocument,
    options,
  );
}
export type EasypostInboundShippingOptionsQueryHookResult = ReturnType<typeof useEasypostInboundShippingOptionsQuery>;
export type EasypostInboundShippingOptionsLazyQueryHookResult = ReturnType<
  typeof useEasypostInboundShippingOptionsLazyQuery
>;
export type EasypostInboundShippingOptionsQueryResult = Apollo.QueryResult<
  EasypostInboundShippingOptionsQuery,
  EasypostInboundShippingOptionsQueryVariables
>;
export const EasypostOutboundShippingOptionsDocument = gql`
  query EasypostOutboundShippingOptions($input: Shipping__EasyPostShipmentInput!) {
    easypostOutboundShippingOptions(input: $input) {
      id
      itemID
      rates {
        ...Shipping__EasyPostRate
      }
    }
  }
  ${Shipping__EasyPostRateFragmentDoc}
`;

/**
 * __useEasypostOutboundShippingOptionsQuery__
 *
 * To run a query within a React component, call `useEasypostOutboundShippingOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEasypostOutboundShippingOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEasypostOutboundShippingOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEasypostOutboundShippingOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EasypostOutboundShippingOptionsQuery,
    EasypostOutboundShippingOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EasypostOutboundShippingOptionsQuery, EasypostOutboundShippingOptionsQueryVariables>(
    EasypostOutboundShippingOptionsDocument,
    options,
  );
}
export function useEasypostOutboundShippingOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EasypostOutboundShippingOptionsQuery,
    EasypostOutboundShippingOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EasypostOutboundShippingOptionsQuery, EasypostOutboundShippingOptionsQueryVariables>(
    EasypostOutboundShippingOptionsDocument,
    options,
  );
}
export type EasypostOutboundShippingOptionsQueryHookResult = ReturnType<typeof useEasypostOutboundShippingOptionsQuery>;
export type EasypostOutboundShippingOptionsLazyQueryHookResult = ReturnType<
  typeof useEasypostOutboundShippingOptionsLazyQuery
>;
export type EasypostOutboundShippingOptionsQueryResult = Apollo.QueryResult<
  EasypostOutboundShippingOptionsQuery,
  EasypostOutboundShippingOptionsQueryVariables
>;
export const OutboundShipmentMinDaysDocument = gql`
  query OutboundShipmentMinDays($itemIDs: [ID!]!) {
    outboundShipmentMinDays(itemIDs: $itemIDs)
  }
`;

/**
 * __useOutboundShipmentMinDaysQuery__
 *
 * To run a query within a React component, call `useOutboundShipmentMinDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutboundShipmentMinDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutboundShipmentMinDaysQuery({
 *   variables: {
 *      itemIDs: // value for 'itemIDs'
 *   },
 * });
 */
export function useOutboundShipmentMinDaysQuery(
  baseOptions: Apollo.QueryHookOptions<OutboundShipmentMinDaysQuery, OutboundShipmentMinDaysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutboundShipmentMinDaysQuery, OutboundShipmentMinDaysQueryVariables>(
    OutboundShipmentMinDaysDocument,
    options,
  );
}
export function useOutboundShipmentMinDaysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OutboundShipmentMinDaysQuery, OutboundShipmentMinDaysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutboundShipmentMinDaysQuery, OutboundShipmentMinDaysQueryVariables>(
    OutboundShipmentMinDaysDocument,
    options,
  );
}
export type OutboundShipmentMinDaysQueryHookResult = ReturnType<typeof useOutboundShipmentMinDaysQuery>;
export type OutboundShipmentMinDaysLazyQueryHookResult = ReturnType<typeof useOutboundShipmentMinDaysLazyQuery>;
export type OutboundShipmentMinDaysQueryResult = Apollo.QueryResult<
  OutboundShipmentMinDaysQuery,
  OutboundShipmentMinDaysQueryVariables
>;
export const ShipmentItemQuotesUpsertDocument = gql`
  mutation ShipmentItemQuotesUpsert($inputs: [Shipping__ShipmentItemQuoteInput!]!) {
    shipmentItemQuotesUpsert(inputs: $inputs) {
      status
      error
    }
  }
`;
export type ShipmentItemQuotesUpsertMutationFn = Apollo.MutationFunction<
  ShipmentItemQuotesUpsertMutation,
  ShipmentItemQuotesUpsertMutationVariables
>;

/**
 * __useShipmentItemQuotesUpsertMutation__
 *
 * To run a mutation, you first call `useShipmentItemQuotesUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipmentItemQuotesUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipmentItemQuotesUpsertMutation, { data, loading, error }] = useShipmentItemQuotesUpsertMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useShipmentItemQuotesUpsertMutation(
  baseOptions?: Apollo.MutationHookOptions<ShipmentItemQuotesUpsertMutation, ShipmentItemQuotesUpsertMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShipmentItemQuotesUpsertMutation, ShipmentItemQuotesUpsertMutationVariables>(
    ShipmentItemQuotesUpsertDocument,
    options,
  );
}
export type ShipmentItemQuotesUpsertMutationHookResult = ReturnType<typeof useShipmentItemQuotesUpsertMutation>;
export type ShipmentItemQuotesUpsertMutationResult = Apollo.MutationResult<ShipmentItemQuotesUpsertMutation>;
export type ShipmentItemQuotesUpsertMutationOptions = Apollo.BaseMutationOptions<
  ShipmentItemQuotesUpsertMutation,
  ShipmentItemQuotesUpsertMutationVariables
>;
export const BuildOrderDocument = gql`
  mutation BuildOrder($input: OrderInput!, $sourceID: ID, $skipBookableCheck: Boolean) {
    buildOrder(input: $input, sourceID: $sourceID, skipBookableCheck: $skipBookableCheck) {
      status
      order {
        id
      }
      error
    }
  }
`;
export type BuildOrderMutationFn = Apollo.MutationFunction<BuildOrderMutation, BuildOrderMutationVariables>;

/**
 * __useBuildOrderMutation__
 *
 * To run a mutation, you first call `useBuildOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildOrderMutation, { data, loading, error }] = useBuildOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      sourceID: // value for 'sourceID'
 *      skipBookableCheck: // value for 'skipBookableCheck'
 *   },
 * });
 */
export function useBuildOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildOrderMutation, BuildOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildOrderMutation, BuildOrderMutationVariables>(BuildOrderDocument, options);
}
export type BuildOrderMutationHookResult = ReturnType<typeof useBuildOrderMutation>;
export type BuildOrderMutationResult = Apollo.MutationResult<BuildOrderMutation>;
export type BuildOrderMutationOptions = Apollo.BaseMutationOptions<BuildOrderMutation, BuildOrderMutationVariables>;
export const TermCommitmentBalanceDocument = gql`
  query TermCommitmentBalance($scheduled: DateTime) {
    termCommitmentBalance(scheduled: $scheduled) {
      term
      paidInvoices {
        ...invoice
      }
      owedInvoices {
        date
        amountDue
        summary
      }
      amountDue
    }
  }
  ${InvoiceFragmentDoc}
`;

/**
 * __useTermCommitmentBalanceQuery__
 *
 * To run a query within a React component, call `useTermCommitmentBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermCommitmentBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermCommitmentBalanceQuery({
 *   variables: {
 *      scheduled: // value for 'scheduled'
 *   },
 * });
 */
export function useTermCommitmentBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<TermCommitmentBalanceQuery, TermCommitmentBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TermCommitmentBalanceQuery, TermCommitmentBalanceQueryVariables>(
    TermCommitmentBalanceDocument,
    options,
  );
}
export function useTermCommitmentBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TermCommitmentBalanceQuery, TermCommitmentBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TermCommitmentBalanceQuery, TermCommitmentBalanceQueryVariables>(
    TermCommitmentBalanceDocument,
    options,
  );
}
export type TermCommitmentBalanceQueryHookResult = ReturnType<typeof useTermCommitmentBalanceQuery>;
export type TermCommitmentBalanceLazyQueryHookResult = ReturnType<typeof useTermCommitmentBalanceLazyQuery>;
export type TermCommitmentBalanceQueryResult = Apollo.QueryResult<
  TermCommitmentBalanceQuery,
  TermCommitmentBalanceQueryVariables
>;
export const TermsForBookingOrderDocument = gql`
  query TermsForBookingOrder($input: OrderInput!) {
    terms: termsForBookingOrder(input: $input)
  }
`;

/**
 * __useTermsForBookingOrderQuery__
 *
 * To run a query within a React component, call `useTermsForBookingOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useTermsForBookingOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTermsForBookingOrderQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTermsForBookingOrderQuery(
  baseOptions: Apollo.QueryHookOptions<TermsForBookingOrderQuery, TermsForBookingOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TermsForBookingOrderQuery, TermsForBookingOrderQueryVariables>(
    TermsForBookingOrderDocument,
    options,
  );
}
export function useTermsForBookingOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TermsForBookingOrderQuery, TermsForBookingOrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TermsForBookingOrderQuery, TermsForBookingOrderQueryVariables>(
    TermsForBookingOrderDocument,
    options,
  );
}
export type TermsForBookingOrderQueryHookResult = ReturnType<typeof useTermsForBookingOrderQuery>;
export type TermsForBookingOrderLazyQueryHookResult = ReturnType<typeof useTermsForBookingOrderLazyQuery>;
export type TermsForBookingOrderQueryResult = Apollo.QueryResult<
  TermsForBookingOrderQuery,
  TermsForBookingOrderQueryVariables
>;
export const OrderSupplyKitsDocument = gql`
  query OrderSupplyKits($orderID: ID!) {
    order(orderID: $orderID) {
      id
      scheduled
      serviceType
      retailDeliveryItems {
        id
        quantity
        summedComponentSummaries
      }
    }
  }
`;

/**
 * __useOrderSupplyKitsQuery__
 *
 * To run a query within a React component, call `useOrderSupplyKitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderSupplyKitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderSupplyKitsQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useOrderSupplyKitsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderSupplyKitsQuery, OrderSupplyKitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderSupplyKitsQuery, OrderSupplyKitsQueryVariables>(OrderSupplyKitsDocument, options);
}
export function useOrderSupplyKitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderSupplyKitsQuery, OrderSupplyKitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderSupplyKitsQuery, OrderSupplyKitsQueryVariables>(OrderSupplyKitsDocument, options);
}
export type OrderSupplyKitsQueryHookResult = ReturnType<typeof useOrderSupplyKitsQuery>;
export type OrderSupplyKitsLazyQueryHookResult = ReturnType<typeof useOrderSupplyKitsLazyQuery>;
export type OrderSupplyKitsQueryResult = Apollo.QueryResult<OrderSupplyKitsQuery, OrderSupplyKitsQueryVariables>;
export const BuildTermCommitmentCustomerTicketDocument = gql`
  mutation BuildTermCommitmentCustomerTicket {
    buildTicket: buildTermCommitmentCustomerTicket {
      error
      status
    }
  }
`;
export type BuildTermCommitmentCustomerTicketMutationFn = Apollo.MutationFunction<
  BuildTermCommitmentCustomerTicketMutation,
  BuildTermCommitmentCustomerTicketMutationVariables
>;

/**
 * __useBuildTermCommitmentCustomerTicketMutation__
 *
 * To run a mutation, you first call `useBuildTermCommitmentCustomerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildTermCommitmentCustomerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildTermCommitmentCustomerTicketMutation, { data, loading, error }] = useBuildTermCommitmentCustomerTicketMutation({
 *   variables: {
 *   },
 * });
 */
export function useBuildTermCommitmentCustomerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuildTermCommitmentCustomerTicketMutation,
    BuildTermCommitmentCustomerTicketMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BuildTermCommitmentCustomerTicketMutation,
    BuildTermCommitmentCustomerTicketMutationVariables
  >(BuildTermCommitmentCustomerTicketDocument, options);
}
export type BuildTermCommitmentCustomerTicketMutationHookResult = ReturnType<
  typeof useBuildTermCommitmentCustomerTicketMutation
>;
export type BuildTermCommitmentCustomerTicketMutationResult =
  Apollo.MutationResult<BuildTermCommitmentCustomerTicketMutation>;
export type BuildTermCommitmentCustomerTicketMutationOptions = Apollo.BaseMutationOptions<
  BuildTermCommitmentCustomerTicketMutation,
  BuildTermCommitmentCustomerTicketMutationVariables
>;
export const ShipmentValuationDeclarationDocument = gql`
  query ShipmentValuationDeclaration($orderID: ID!) {
    declaredCoverage(orderID: $orderID) {
      id
      orderId
      coverage {
        id
        name
        deductible
        price
        key
      }
      value
      chargeAmount
    }
    shipmentValuationCoverages(orderID: $orderID) {
      id
      name
      deductible
      price
      key
      summary
      minimumDeclaredValue
      maximumDeclaredValue
    }
  }
`;

/**
 * __useShipmentValuationDeclarationQuery__
 *
 * To run a query within a React component, call `useShipmentValuationDeclarationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentValuationDeclarationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentValuationDeclarationQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useShipmentValuationDeclarationQuery(
  baseOptions: Apollo.QueryHookOptions<ShipmentValuationDeclarationQuery, ShipmentValuationDeclarationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShipmentValuationDeclarationQuery, ShipmentValuationDeclarationQueryVariables>(
    ShipmentValuationDeclarationDocument,
    options,
  );
}
export function useShipmentValuationDeclarationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShipmentValuationDeclarationQuery,
    ShipmentValuationDeclarationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShipmentValuationDeclarationQuery, ShipmentValuationDeclarationQueryVariables>(
    ShipmentValuationDeclarationDocument,
    options,
  );
}
export type ShipmentValuationDeclarationQueryHookResult = ReturnType<typeof useShipmentValuationDeclarationQuery>;
export type ShipmentValuationDeclarationLazyQueryHookResult = ReturnType<
  typeof useShipmentValuationDeclarationLazyQuery
>;
export type ShipmentValuationDeclarationQueryResult = Apollo.QueryResult<
  ShipmentValuationDeclarationQuery,
  ShipmentValuationDeclarationQueryVariables
>;
export const ShipmentValuationUpdateDocument = gql`
  mutation ShipmentValuationUpdate($input: Compliance__ShipmentValuationUpdateInput!) {
    shipmentValuationUpdate(input: $input) {
      error
      status
    }
  }
`;
export type ShipmentValuationUpdateMutationFn = Apollo.MutationFunction<
  ShipmentValuationUpdateMutation,
  ShipmentValuationUpdateMutationVariables
>;

/**
 * __useShipmentValuationUpdateMutation__
 *
 * To run a mutation, you first call `useShipmentValuationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipmentValuationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipmentValuationUpdateMutation, { data, loading, error }] = useShipmentValuationUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShipmentValuationUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<ShipmentValuationUpdateMutation, ShipmentValuationUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShipmentValuationUpdateMutation, ShipmentValuationUpdateMutationVariables>(
    ShipmentValuationUpdateDocument,
    options,
  );
}
export type ShipmentValuationUpdateMutationHookResult = ReturnType<typeof useShipmentValuationUpdateMutation>;
export type ShipmentValuationUpdateMutationResult = Apollo.MutationResult<ShipmentValuationUpdateMutation>;
export type ShipmentValuationUpdateMutationOptions = Apollo.BaseMutationOptions<
  ShipmentValuationUpdateMutation,
  ShipmentValuationUpdateMutationVariables
>;
export const ActivateAccountDocument = gql`
  mutation ActivateAccount($email: String!) {
    activateAccount(email: $email) {
      error
      status
    }
  }
`;
export type ActivateAccountMutationFn = Apollo.MutationFunction<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;

/**
 * __useActivateAccountMutation__
 *
 * To run a mutation, you first call `useActivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateAccountMutation, { data, loading, error }] = useActivateAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useActivateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateAccountMutation, ActivateAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateAccountMutation, ActivateAccountMutationVariables>(
    ActivateAccountDocument,
    options,
  );
}
export type ActivateAccountMutationHookResult = ReturnType<typeof useActivateAccountMutation>;
export type ActivateAccountMutationResult = Apollo.MutationResult<ActivateAccountMutation>;
export type ActivateAccountMutationOptions = Apollo.BaseMutationOptions<
  ActivateAccountMutation,
  ActivateAccountMutationVariables
>;
export const FailedPrepaymentDocument = gql`
  query FailedPrepayment($orderID: ID!) {
    order(orderID: $orderID) {
      failedPrepayment {
        id
        amount
        dueAt
      }
    }
  }
`;

/**
 * __useFailedPrepaymentQuery__
 *
 * To run a query within a React component, call `useFailedPrepaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailedPrepaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailedPrepaymentQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useFailedPrepaymentQuery(
  baseOptions: Apollo.QueryHookOptions<FailedPrepaymentQuery, FailedPrepaymentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FailedPrepaymentQuery, FailedPrepaymentQueryVariables>(FailedPrepaymentDocument, options);
}
export function useFailedPrepaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FailedPrepaymentQuery, FailedPrepaymentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FailedPrepaymentQuery, FailedPrepaymentQueryVariables>(FailedPrepaymentDocument, options);
}
export type FailedPrepaymentQueryHookResult = ReturnType<typeof useFailedPrepaymentQuery>;
export type FailedPrepaymentLazyQueryHookResult = ReturnType<typeof useFailedPrepaymentLazyQuery>;
export type FailedPrepaymentQueryResult = Apollo.QueryResult<FailedPrepaymentQuery, FailedPrepaymentQueryVariables>;
export const MovingPrepaymentPayDocument = gql`
  mutation MovingPrepaymentPay($orderID: ID!, $sourceID: ID!) {
    movingPrepaymentPay(orderID: $orderID, sourceID: $sourceID) {
      status
      error
      order {
        id
        failedPrepayment {
          id
        }
      }
    }
  }
`;
export type MovingPrepaymentPayMutationFn = Apollo.MutationFunction<
  MovingPrepaymentPayMutation,
  MovingPrepaymentPayMutationVariables
>;

/**
 * __useMovingPrepaymentPayMutation__
 *
 * To run a mutation, you first call `useMovingPrepaymentPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingPrepaymentPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingPrepaymentPayMutation, { data, loading, error }] = useMovingPrepaymentPayMutation({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      sourceID: // value for 'sourceID'
 *   },
 * });
 */
export function useMovingPrepaymentPayMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingPrepaymentPayMutation, MovingPrepaymentPayMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingPrepaymentPayMutation, MovingPrepaymentPayMutationVariables>(
    MovingPrepaymentPayDocument,
    options,
  );
}
export type MovingPrepaymentPayMutationHookResult = ReturnType<typeof useMovingPrepaymentPayMutation>;
export type MovingPrepaymentPayMutationResult = Apollo.MutationResult<MovingPrepaymentPayMutation>;
export type MovingPrepaymentPayMutationOptions = Apollo.BaseMutationOptions<
  MovingPrepaymentPayMutation,
  MovingPrepaymentPayMutationVariables
>;
export const BillingInvoicesDocument = gql`
  query BillingInvoices($status: Billing__Invoice__Status) {
    invoices(status: $status) {
      ...Billing__Invoice
    }
  }
  ${Billing__InvoiceFragmentDoc}
`;

/**
 * __useBillingInvoicesQuery__
 *
 * To run a query within a React component, call `useBillingInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingInvoicesQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useBillingInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingInvoicesQuery, BillingInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingInvoicesQuery, BillingInvoicesQueryVariables>(BillingInvoicesDocument, options);
}
export function useBillingInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingInvoicesQuery, BillingInvoicesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingInvoicesQuery, BillingInvoicesQueryVariables>(BillingInvoicesDocument, options);
}
export type BillingInvoicesQueryHookResult = ReturnType<typeof useBillingInvoicesQuery>;
export type BillingInvoicesLazyQueryHookResult = ReturnType<typeof useBillingInvoicesLazyQuery>;
export type BillingInvoicesQueryResult = Apollo.QueryResult<BillingInvoicesQuery, BillingInvoicesQueryVariables>;
export const ResolveDelinquencyDocument = gql`
  mutation ResolveDelinquency($sourceID: ID!, $invoiceIDs: [ID!]!) {
    resolveDelinquency(sourceID: $sourceID, invoiceIDs: $invoiceIDs) {
      status
      error
    }
  }
`;
export type ResolveDelinquencyMutationFn = Apollo.MutationFunction<
  ResolveDelinquencyMutation,
  ResolveDelinquencyMutationVariables
>;

/**
 * __useResolveDelinquencyMutation__
 *
 * To run a mutation, you first call `useResolveDelinquencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveDelinquencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveDelinquencyMutation, { data, loading, error }] = useResolveDelinquencyMutation({
 *   variables: {
 *      sourceID: // value for 'sourceID'
 *      invoiceIDs: // value for 'invoiceIDs'
 *   },
 * });
 */
export function useResolveDelinquencyMutation(
  baseOptions?: Apollo.MutationHookOptions<ResolveDelinquencyMutation, ResolveDelinquencyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResolveDelinquencyMutation, ResolveDelinquencyMutationVariables>(
    ResolveDelinquencyDocument,
    options,
  );
}
export type ResolveDelinquencyMutationHookResult = ReturnType<typeof useResolveDelinquencyMutation>;
export type ResolveDelinquencyMutationResult = Apollo.MutationResult<ResolveDelinquencyMutation>;
export type ResolveDelinquencyMutationOptions = Apollo.BaseMutationOptions<
  ResolveDelinquencyMutation,
  ResolveDelinquencyMutationVariables
>;
export const AcceptPaymentPlanOfferDocument = gql`
  mutation AcceptPaymentPlanOffer($offerID: ID!, $sourceID: ID!) {
    accept: acceptPaymentPlanOffer(offerID: $offerID, sourceID: $sourceID) {
      status
      error
      offer {
        id
        state
        paymentMethod {
          ...PaymentPlan__PaymentMethod
        }
        acceptedAt
      }
    }
  }
  ${PaymentPlan__PaymentMethodFragmentDoc}
`;
export type AcceptPaymentPlanOfferMutationFn = Apollo.MutationFunction<
  AcceptPaymentPlanOfferMutation,
  AcceptPaymentPlanOfferMutationVariables
>;

/**
 * __useAcceptPaymentPlanOfferMutation__
 *
 * To run a mutation, you first call `useAcceptPaymentPlanOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptPaymentPlanOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptPaymentPlanOfferMutation, { data, loading, error }] = useAcceptPaymentPlanOfferMutation({
 *   variables: {
 *      offerID: // value for 'offerID'
 *      sourceID: // value for 'sourceID'
 *   },
 * });
 */
export function useAcceptPaymentPlanOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptPaymentPlanOfferMutation, AcceptPaymentPlanOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptPaymentPlanOfferMutation, AcceptPaymentPlanOfferMutationVariables>(
    AcceptPaymentPlanOfferDocument,
    options,
  );
}
export type AcceptPaymentPlanOfferMutationHookResult = ReturnType<typeof useAcceptPaymentPlanOfferMutation>;
export type AcceptPaymentPlanOfferMutationResult = Apollo.MutationResult<AcceptPaymentPlanOfferMutation>;
export type AcceptPaymentPlanOfferMutationOptions = Apollo.BaseMutationOptions<
  AcceptPaymentPlanOfferMutation,
  AcceptPaymentPlanOfferMutationVariables
>;
export const PaymentPlanOfferDocument = gql`
  query PaymentPlanOffer($id: ID!) {
    offer: paymentPlanOffer(id: $id) {
      ...PaymentPlanDetail__Offer
    }
  }
  ${PaymentPlanDetail__OfferFragmentDoc}
`;

/**
 * __usePaymentPlanOfferQuery__
 *
 * To run a query within a React component, call `usePaymentPlanOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentPlanOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentPlanOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentPlanOfferQuery(
  baseOptions: Apollo.QueryHookOptions<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>(PaymentPlanOfferDocument, options);
}
export function usePaymentPlanOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>(PaymentPlanOfferDocument, options);
}
export type PaymentPlanOfferQueryHookResult = ReturnType<typeof usePaymentPlanOfferQuery>;
export type PaymentPlanOfferLazyQueryHookResult = ReturnType<typeof usePaymentPlanOfferLazyQuery>;
export type PaymentPlanOfferQueryResult = Apollo.QueryResult<PaymentPlanOfferQuery, PaymentPlanOfferQueryVariables>;
export const MovingStorageAddDocument = gql`
  mutation MovingStorageAdd($parentID: ID!, $input: Moving__StorageAddInputType!) {
    movingStorageAdd(parentID: $parentID, input: $input) {
      status
      error
    }
  }
`;
export type MovingStorageAddMutationFn = Apollo.MutationFunction<
  MovingStorageAddMutation,
  MovingStorageAddMutationVariables
>;

/**
 * __useMovingStorageAddMutation__
 *
 * To run a mutation, you first call `useMovingStorageAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMovingStorageAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [movingStorageAddMutation, { data, loading, error }] = useMovingStorageAddMutation({
 *   variables: {
 *      parentID: // value for 'parentID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMovingStorageAddMutation(
  baseOptions?: Apollo.MutationHookOptions<MovingStorageAddMutation, MovingStorageAddMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MovingStorageAddMutation, MovingStorageAddMutationVariables>(
    MovingStorageAddDocument,
    options,
  );
}
export type MovingStorageAddMutationHookResult = ReturnType<typeof useMovingStorageAddMutation>;
export type MovingStorageAddMutationResult = Apollo.MutationResult<MovingStorageAddMutation>;
export type MovingStorageAddMutationOptions = Apollo.BaseMutationOptions<
  MovingStorageAddMutation,
  MovingStorageAddMutationVariables
>;
export const MovingStorageAddOnEligibilityDocument = gql`
  query MovingStorageAddOnEligibility($orderID: ID!) {
    order(orderID: $orderID) {
      id
      storageAddOnEligible
    }
  }
`;

/**
 * __useMovingStorageAddOnEligibilityQuery__
 *
 * To run a query within a React component, call `useMovingStorageAddOnEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovingStorageAddOnEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovingStorageAddOnEligibilityQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *   },
 * });
 */
export function useMovingStorageAddOnEligibilityQuery(
  baseOptions: Apollo.QueryHookOptions<MovingStorageAddOnEligibilityQuery, MovingStorageAddOnEligibilityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovingStorageAddOnEligibilityQuery, MovingStorageAddOnEligibilityQueryVariables>(
    MovingStorageAddOnEligibilityDocument,
    options,
  );
}
export function useMovingStorageAddOnEligibilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MovingStorageAddOnEligibilityQuery,
    MovingStorageAddOnEligibilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovingStorageAddOnEligibilityQuery, MovingStorageAddOnEligibilityQueryVariables>(
    MovingStorageAddOnEligibilityDocument,
    options,
  );
}
export type MovingStorageAddOnEligibilityQueryHookResult = ReturnType<typeof useMovingStorageAddOnEligibilityQuery>;
export type MovingStorageAddOnEligibilityLazyQueryHookResult = ReturnType<
  typeof useMovingStorageAddOnEligibilityLazyQuery
>;
export type MovingStorageAddOnEligibilityQueryResult = Apollo.QueryResult<
  MovingStorageAddOnEligibilityQuery,
  MovingStorageAddOnEligibilityQueryVariables
>;
export const UpdatePlanDocument = gql`
  mutation UpdatePlan($input: Pricing__UpdatePlanInput!) {
    updatePlan(input: $input) {
      status
      error
      account {
        id
        onboardingCostDetails {
          ...laborCostDetails
        }
        finalOrderCostDetails {
          ...laborCostDetails
        }
        upcomingSubscriptionSet {
          storageSubscriptions {
            ...upcomingSubscription
          }
          protectionSubscription {
            ...upcomingSubscription
          }
          nextPeriodEnd
        }
        termCommitment {
          contractEndDate
          term
        }
      }
      allowsServicePackageSelection
    }
  }
  ${LaborCostDetailsFragmentDoc}
  ${UpcomingSubscriptionFragmentDoc}
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const UpcomingStorageOnboardingDocument = gql`
  query UpcomingStorageOnboarding {
    upcomingStorageOnboarding {
      ...upcomingStorageOnboarding
    }
  }
  ${UpcomingStorageOnboardingFragmentDoc}
`;

/**
 * __useUpcomingStorageOnboardingQuery__
 *
 * To run a query within a React component, call `useUpcomingStorageOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingStorageOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingStorageOnboardingQuery({
 *   variables: {
 *   },
 * });
 */
export function useUpcomingStorageOnboardingQuery(
  baseOptions?: Apollo.QueryHookOptions<UpcomingStorageOnboardingQuery, UpcomingStorageOnboardingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UpcomingStorageOnboardingQuery, UpcomingStorageOnboardingQueryVariables>(
    UpcomingStorageOnboardingDocument,
    options,
  );
}
export function useUpcomingStorageOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UpcomingStorageOnboardingQuery, UpcomingStorageOnboardingQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UpcomingStorageOnboardingQuery, UpcomingStorageOnboardingQueryVariables>(
    UpcomingStorageOnboardingDocument,
    options,
  );
}
export type UpcomingStorageOnboardingQueryHookResult = ReturnType<typeof useUpcomingStorageOnboardingQuery>;
export type UpcomingStorageOnboardingLazyQueryHookResult = ReturnType<typeof useUpcomingStorageOnboardingLazyQuery>;
export type UpcomingStorageOnboardingQueryResult = Apollo.QueryResult<
  UpcomingStorageOnboardingQuery,
  UpcomingStorageOnboardingQueryVariables
>;
export const PlanUpdateDocument = gql`
  query PlanUpdate($parentOrderID: ID) {
    account {
      id
      flexerAndMoverOnly
      subscriptionSet {
        storageSubscriptions {
          id
          pricing {
            ...price
          }
        }
        protectionSubscription {
          id
          pricing {
            id
          }
        }
      }
      rateGroup {
        ...rateGroup
      }
    }
    pricingSet(parentOrderID: $parentOrderID) {
      id
      storagePriceEntries {
        ...pricingSetPriceEntry
      }
      protectionPriceEntries {
        ...pricingSetPriceEntry
      }
      quoteID
    }
  }
  ${PriceFragmentDoc}
  ${RateGroupFragmentDoc}
  ${PricingSetPriceEntryFragmentDoc}
`;

/**
 * __usePlanUpdateQuery__
 *
 * To run a query within a React component, call `usePlanUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanUpdateQuery({
 *   variables: {
 *      parentOrderID: // value for 'parentOrderID'
 *   },
 * });
 */
export function usePlanUpdateQuery(baseOptions?: Apollo.QueryHookOptions<PlanUpdateQuery, PlanUpdateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlanUpdateQuery, PlanUpdateQueryVariables>(PlanUpdateDocument, options);
}
export function usePlanUpdateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlanUpdateQuery, PlanUpdateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlanUpdateQuery, PlanUpdateQueryVariables>(PlanUpdateDocument, options);
}
export type PlanUpdateQueryHookResult = ReturnType<typeof usePlanUpdateQuery>;
export type PlanUpdateLazyQueryHookResult = ReturnType<typeof usePlanUpdateLazyQuery>;
export type PlanUpdateQueryResult = Apollo.QueryResult<PlanUpdateQuery, PlanUpdateQueryVariables>;
export const AppointmentFeeOptionsDocument = gql`
  query AppointmentFeeOptions($cuft: Float!, $rateGroupID: ID!, $pricingSetID: ID) {
    appointmentFeeOptions(cuft: $cuft, rateGroupID: $rateGroupID, pricingSetID: $pricingSetID) {
      laborEntry {
        ...pricingSetLaborEntry
      }
      packageSetEntries {
        ...pricingSetPackageSetEntry
      }
      serviceType
    }
  }
  ${PricingSetLaborEntryFragmentDoc}
  ${PricingSetPackageSetEntryFragmentDoc}
`;

/**
 * __useAppointmentFeeOptionsQuery__
 *
 * To run a query within a React component, call `useAppointmentFeeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentFeeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentFeeOptionsQuery({
 *   variables: {
 *      cuft: // value for 'cuft'
 *      rateGroupID: // value for 'rateGroupID'
 *      pricingSetID: // value for 'pricingSetID'
 *   },
 * });
 */
export function useAppointmentFeeOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentFeeOptionsQuery, AppointmentFeeOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentFeeOptionsQuery, AppointmentFeeOptionsQueryVariables>(
    AppointmentFeeOptionsDocument,
    options,
  );
}
export function useAppointmentFeeOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentFeeOptionsQuery, AppointmentFeeOptionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentFeeOptionsQuery, AppointmentFeeOptionsQueryVariables>(
    AppointmentFeeOptionsDocument,
    options,
  );
}
export type AppointmentFeeOptionsQueryHookResult = ReturnType<typeof useAppointmentFeeOptionsQuery>;
export type AppointmentFeeOptionsLazyQueryHookResult = ReturnType<typeof useAppointmentFeeOptionsLazyQuery>;
export type AppointmentFeeOptionsQueryResult = Apollo.QueryResult<
  AppointmentFeeOptionsQuery,
  AppointmentFeeOptionsQueryVariables
>;
export const OrderRescheduleOfferItemAcceptDocument = gql`
  mutation OrderRescheduleOfferItemAccept($input: Order__RescheduleOfferItemAcceptInput!) {
    offerAccept: orderRescheduleOfferItemAccept(input: $input) {
      status
      error
    }
  }
`;
export type OrderRescheduleOfferItemAcceptMutationFn = Apollo.MutationFunction<
  OrderRescheduleOfferItemAcceptMutation,
  OrderRescheduleOfferItemAcceptMutationVariables
>;

/**
 * __useOrderRescheduleOfferItemAcceptMutation__
 *
 * To run a mutation, you first call `useOrderRescheduleOfferItemAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderRescheduleOfferItemAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderRescheduleOfferItemAcceptMutation, { data, loading, error }] = useOrderRescheduleOfferItemAcceptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderRescheduleOfferItemAcceptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderRescheduleOfferItemAcceptMutation,
    OrderRescheduleOfferItemAcceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderRescheduleOfferItemAcceptMutation, OrderRescheduleOfferItemAcceptMutationVariables>(
    OrderRescheduleOfferItemAcceptDocument,
    options,
  );
}
export type OrderRescheduleOfferItemAcceptMutationHookResult = ReturnType<
  typeof useOrderRescheduleOfferItemAcceptMutation
>;
export type OrderRescheduleOfferItemAcceptMutationResult =
  Apollo.MutationResult<OrderRescheduleOfferItemAcceptMutation>;
export type OrderRescheduleOfferItemAcceptMutationOptions = Apollo.BaseMutationOptions<
  OrderRescheduleOfferItemAcceptMutation,
  OrderRescheduleOfferItemAcceptMutationVariables
>;
export const RescheduleOfferDetailsDocument = gql`
  query RescheduleOfferDetails($token: String!) {
    details: rescheduleOfferDetails(token: $token) {
      orderRescheduleOfferItemID
      order {
        ...order
        baseLaborRate {
          ...laborRate
        }
        laborCostDetails {
          ...laborCostDetails
        }
        account {
          id
          state
        }
      }
      coupon {
        ...Account__Marketing__Coupon
      }
      availabilities {
        ...availability
      }
    }
  }
  ${OrderFragmentDoc}
  ${LaborRateFragmentDoc}
  ${LaborCostDetailsFragmentDoc}
  ${Account__Marketing__CouponFragmentDoc}
  ${AvailabilityFragmentDoc}
`;

/**
 * __useRescheduleOfferDetailsQuery__
 *
 * To run a query within a React component, call `useRescheduleOfferDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRescheduleOfferDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRescheduleOfferDetailsQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRescheduleOfferDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RescheduleOfferDetailsQuery, RescheduleOfferDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RescheduleOfferDetailsQuery, RescheduleOfferDetailsQueryVariables>(
    RescheduleOfferDetailsDocument,
    options,
  );
}
export function useRescheduleOfferDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RescheduleOfferDetailsQuery, RescheduleOfferDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RescheduleOfferDetailsQuery, RescheduleOfferDetailsQueryVariables>(
    RescheduleOfferDetailsDocument,
    options,
  );
}
export type RescheduleOfferDetailsQueryHookResult = ReturnType<typeof useRescheduleOfferDetailsQuery>;
export type RescheduleOfferDetailsLazyQueryHookResult = ReturnType<typeof useRescheduleOfferDetailsLazyQuery>;
export type RescheduleOfferDetailsQueryResult = Apollo.QueryResult<
  RescheduleOfferDetailsQuery,
  RescheduleOfferDetailsQueryVariables
>;
export const ReturnInventoryDocument = gql`
  query ReturnInventory($orderID: ID, $query: String) {
    inventory: requestable(orderID: $orderID, query: $query) {
      ... on Item {
        ...inventoryItem
      }
      ... on ItemGroup {
        ...inventoryItemGroup
      }
    }
  }
  ${InventoryItemFragmentDoc}
  ${InventoryItemGroupFragmentDoc}
`;

/**
 * __useReturnInventoryQuery__
 *
 * To run a query within a React component, call `useReturnInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturnInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturnInventoryQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useReturnInventoryQuery(
  baseOptions?: Apollo.QueryHookOptions<ReturnInventoryQuery, ReturnInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReturnInventoryQuery, ReturnInventoryQueryVariables>(ReturnInventoryDocument, options);
}
export function useReturnInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReturnInventoryQuery, ReturnInventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReturnInventoryQuery, ReturnInventoryQueryVariables>(ReturnInventoryDocument, options);
}
export type ReturnInventoryQueryHookResult = ReturnType<typeof useReturnInventoryQuery>;
export type ReturnInventoryLazyQueryHookResult = ReturnType<typeof useReturnInventoryLazyQuery>;
export type ReturnInventoryQueryResult = Apollo.QueryResult<ReturnInventoryQuery, ReturnInventoryQueryVariables>;
export const BuildReviewDocument = gql`
  mutation buildReview($input: BuildReviewInput!) {
    buildReview(input: $input) {
      status
      error
    }
  }
`;
export type BuildReviewMutationFn = Apollo.MutationFunction<BuildReviewMutation, BuildReviewMutationVariables>;

/**
 * __useBuildReviewMutation__
 *
 * To run a mutation, you first call `useBuildReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildReviewMutation, { data, loading, error }] = useBuildReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildReviewMutation, BuildReviewMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildReviewMutation, BuildReviewMutationVariables>(BuildReviewDocument, options);
}
export type BuildReviewMutationHookResult = ReturnType<typeof useBuildReviewMutation>;
export type BuildReviewMutationResult = Apollo.MutationResult<BuildReviewMutation>;
export type BuildReviewMutationOptions = Apollo.BaseMutationOptions<BuildReviewMutation, BuildReviewMutationVariables>;
export const ReviewReasonsDocument = gql`
  query ReviewReasons($reviewableType: Review__ReviewableEnum!, $schedulingID: ID) {
    reviewReasons(reviewableType: $reviewableType, schedulingID: $schedulingID) {
      good
      poor
    }
  }
`;

/**
 * __useReviewReasonsQuery__
 *
 * To run a query within a React component, call `useReviewReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewReasonsQuery({
 *   variables: {
 *      reviewableType: // value for 'reviewableType'
 *      schedulingID: // value for 'schedulingID'
 *   },
 * });
 */
export function useReviewReasonsQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewReasonsQuery, ReviewReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewReasonsQuery, ReviewReasonsQueryVariables>(ReviewReasonsDocument, options);
}
export function useReviewReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewReasonsQuery, ReviewReasonsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewReasonsQuery, ReviewReasonsQueryVariables>(ReviewReasonsDocument, options);
}
export type ReviewReasonsQueryHookResult = ReturnType<typeof useReviewReasonsQuery>;
export type ReviewReasonsLazyQueryHookResult = ReturnType<typeof useReviewReasonsLazyQuery>;
export type ReviewReasonsQueryResult = Apollo.QueryResult<ReviewReasonsQuery, ReviewReasonsQueryVariables>;
export const ReferralDocument = gql`
  query Referral($reviewableID: ID!) {
    referralCode(reviewableID: $reviewableID)
    marketingShare(reviewableID: $reviewableID) {
      url
      text
    }
  }
`;

/**
 * __useReferralQuery__
 *
 * To run a query within a React component, call `useReferralQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralQuery({
 *   variables: {
 *      reviewableID: // value for 'reviewableID'
 *   },
 * });
 */
export function useReferralQuery(baseOptions: Apollo.QueryHookOptions<ReferralQuery, ReferralQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralQuery, ReferralQueryVariables>(ReferralDocument, options);
}
export function useReferralLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralQuery, ReferralQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralQuery, ReferralQueryVariables>(ReferralDocument, options);
}
export type ReferralQueryHookResult = ReturnType<typeof useReferralQuery>;
export type ReferralLazyQueryHookResult = ReturnType<typeof useReferralLazyQuery>;
export type ReferralQueryResult = Apollo.QueryResult<ReferralQuery, ReferralQueryVariables>;
export const ReviewableDocument = gql`
  query Reviewable($reviewableID: ID!) {
    reviewable(reviewableID: $reviewableID) {
      ... on Review__Reviewable__Base {
        id
        type
      }
      ... on Review__Reviewable__Order {
        id
        type
        accountID
      }
      ... on Review__Reviewable__SelfStorageRental {
        id
        type
        facilityName
      }
    }
  }
`;

/**
 * __useReviewableQuery__
 *
 * To run a query within a React component, call `useReviewableQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewableQuery({
 *   variables: {
 *      reviewableID: // value for 'reviewableID'
 *   },
 * });
 */
export function useReviewableQuery(baseOptions: Apollo.QueryHookOptions<ReviewableQuery, ReviewableQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewableQuery, ReviewableQueryVariables>(ReviewableDocument, options);
}
export function useReviewableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewableQuery, ReviewableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewableQuery, ReviewableQueryVariables>(ReviewableDocument, options);
}
export type ReviewableQueryHookResult = ReturnType<typeof useReviewableQuery>;
export type ReviewableLazyQueryHookResult = ReturnType<typeof useReviewableLazyQuery>;
export type ReviewableQueryResult = Apollo.QueryResult<ReviewableQuery, ReviewableQueryVariables>;
export const ShareDocument = gql`
  mutation Share($emails: [String!]!, $source: String!) {
    share(emails: $emails, source: $source) {
      status
      error
    }
  }
`;
export type ShareMutationFn = Apollo.MutationFunction<ShareMutation, ShareMutationVariables>;

/**
 * __useShareMutation__
 *
 * To run a mutation, you first call `useShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMutation, { data, loading, error }] = useShareMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useShareMutation(baseOptions?: Apollo.MutationHookOptions<ShareMutation, ShareMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ShareMutation, ShareMutationVariables>(ShareDocument, options);
}
export type ShareMutationHookResult = ReturnType<typeof useShareMutation>;
export type ShareMutationResult = Apollo.MutationResult<ShareMutation>;
export type ShareMutationOptions = Apollo.BaseMutationOptions<ShareMutation, ShareMutationVariables>;
export const ReferralHistoryDocument = gql`
  query ReferralHistory {
    referralHistory {
      referralCode
      creditsEarned
      referralsCount
    }
  }
`;

/**
 * __useReferralHistoryQuery__
 *
 * To run a query within a React component, call `useReferralHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<ReferralHistoryQuery, ReferralHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralHistoryQuery, ReferralHistoryQueryVariables>(ReferralHistoryDocument, options);
}
export function useReferralHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralHistoryQuery, ReferralHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralHistoryQuery, ReferralHistoryQueryVariables>(ReferralHistoryDocument, options);
}
export type ReferralHistoryQueryHookResult = ReturnType<typeof useReferralHistoryQuery>;
export type ReferralHistoryLazyQueryHookResult = ReturnType<typeof useReferralHistoryLazyQuery>;
export type ReferralHistoryQueryResult = Apollo.QueryResult<ReferralHistoryQuery, ReferralHistoryQueryVariables>;
export const ContactInformationDocument = gql`
  query ContactInformation {
    customer {
      id
      name
      email
      phone
    }
  }
`;

/**
 * __useContactInformationQuery__
 *
 * To run a query within a React component, call `useContactInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<ContactInformationQuery, ContactInformationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactInformationQuery, ContactInformationQueryVariables>(
    ContactInformationDocument,
    options,
  );
}
export function useContactInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactInformationQuery, ContactInformationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactInformationQuery, ContactInformationQueryVariables>(
    ContactInformationDocument,
    options,
  );
}
export type ContactInformationQueryHookResult = ReturnType<typeof useContactInformationQuery>;
export type ContactInformationLazyQueryHookResult = ReturnType<typeof useContactInformationLazyQuery>;
export type ContactInformationQueryResult = Apollo.QueryResult<
  ContactInformationQuery,
  ContactInformationQueryVariables
>;
export const CustomerNameUpdateDocument = gql`
  mutation CustomerNameUpdate($name: String!) {
    customerNameUpdate(name: $name) {
      customer {
        id
        name
      }
    }
  }
`;
export type CustomerNameUpdateMutationFn = Apollo.MutationFunction<
  CustomerNameUpdateMutation,
  CustomerNameUpdateMutationVariables
>;

/**
 * __useCustomerNameUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerNameUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerNameUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerNameUpdateMutation, { data, loading, error }] = useCustomerNameUpdateMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCustomerNameUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerNameUpdateMutation, CustomerNameUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerNameUpdateMutation, CustomerNameUpdateMutationVariables>(
    CustomerNameUpdateDocument,
    options,
  );
}
export type CustomerNameUpdateMutationHookResult = ReturnType<typeof useCustomerNameUpdateMutation>;
export type CustomerNameUpdateMutationResult = Apollo.MutationResult<CustomerNameUpdateMutation>;
export type CustomerNameUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomerNameUpdateMutation,
  CustomerNameUpdateMutationVariables
>;
export const CustomerVerifiedEmailUpdateDocument = gql`
  mutation CustomerVerifiedEmailUpdate($verifiedEmail: String!) {
    customerVerifiedEmailUpdate(verifiedEmail: $verifiedEmail) {
      customer {
        id
        email
      }
    }
  }
`;
export type CustomerVerifiedEmailUpdateMutationFn = Apollo.MutationFunction<
  CustomerVerifiedEmailUpdateMutation,
  CustomerVerifiedEmailUpdateMutationVariables
>;

/**
 * __useCustomerVerifiedEmailUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerVerifiedEmailUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerVerifiedEmailUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerVerifiedEmailUpdateMutation, { data, loading, error }] = useCustomerVerifiedEmailUpdateMutation({
 *   variables: {
 *      verifiedEmail: // value for 'verifiedEmail'
 *   },
 * });
 */
export function useCustomerVerifiedEmailUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerVerifiedEmailUpdateMutation,
    CustomerVerifiedEmailUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerVerifiedEmailUpdateMutation, CustomerVerifiedEmailUpdateMutationVariables>(
    CustomerVerifiedEmailUpdateDocument,
    options,
  );
}
export type CustomerVerifiedEmailUpdateMutationHookResult = ReturnType<typeof useCustomerVerifiedEmailUpdateMutation>;
export type CustomerVerifiedEmailUpdateMutationResult = Apollo.MutationResult<CustomerVerifiedEmailUpdateMutation>;
export type CustomerVerifiedEmailUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomerVerifiedEmailUpdateMutation,
  CustomerVerifiedEmailUpdateMutationVariables
>;
export const CustomerVerifiedPhoneUpdateDocument = gql`
  mutation CustomerVerifiedPhoneUpdate($verifiedPhoneNumber: String!) {
    customerVerifiedPhoneUpdate(verifiedPhoneNumber: $verifiedPhoneNumber) {
      customer {
        id
        phone
      }
    }
  }
`;
export type CustomerVerifiedPhoneUpdateMutationFn = Apollo.MutationFunction<
  CustomerVerifiedPhoneUpdateMutation,
  CustomerVerifiedPhoneUpdateMutationVariables
>;

/**
 * __useCustomerVerifiedPhoneUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerVerifiedPhoneUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerVerifiedPhoneUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerVerifiedPhoneUpdateMutation, { data, loading, error }] = useCustomerVerifiedPhoneUpdateMutation({
 *   variables: {
 *      verifiedPhoneNumber: // value for 'verifiedPhoneNumber'
 *   },
 * });
 */
export function useCustomerVerifiedPhoneUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerVerifiedPhoneUpdateMutation,
    CustomerVerifiedPhoneUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerVerifiedPhoneUpdateMutation, CustomerVerifiedPhoneUpdateMutationVariables>(
    CustomerVerifiedPhoneUpdateDocument,
    options,
  );
}
export type CustomerVerifiedPhoneUpdateMutationHookResult = ReturnType<typeof useCustomerVerifiedPhoneUpdateMutation>;
export type CustomerVerifiedPhoneUpdateMutationResult = Apollo.MutationResult<CustomerVerifiedPhoneUpdateMutation>;
export type CustomerVerifiedPhoneUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomerVerifiedPhoneUpdateMutation,
  CustomerVerifiedPhoneUpdateMutationVariables
>;
export const DocumentsDocument = gql`
  query Documents {
    account {
      firstOnboarding {
        scheduled
        consumerBrochure {
          url
          name
        }
      }
      ingestedDocuments {
        id
        filename
        url
      }
    }
    orders(onlyActive: false) {
      id
      state
      scheduled
      type
      signatures {
        id
        contractUrl
        documentKind
        agreement {
          id
          imgixURL
        }
      }
      coiRequests {
        id
        certificateOfInsurance {
          id
          filename
          url
        }
      }
      ingestedDocuments {
        id
        filename
        url
      }
    }
  }
`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
}
export function useDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
}
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const MfaCodeCheckDocument = gql`
  mutation MFACodeCheck($requestId: ID!, $code: String!) {
    mfaCodeCheck(requestId: $requestId, code: $code) {
      status
      error
    }
  }
`;
export type MfaCodeCheckMutationFn = Apollo.MutationFunction<MfaCodeCheckMutation, MfaCodeCheckMutationVariables>;

/**
 * __useMfaCodeCheckMutation__
 *
 * To run a mutation, you first call `useMfaCodeCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaCodeCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaCodeCheckMutation, { data, loading, error }] = useMfaCodeCheckMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useMfaCodeCheckMutation(
  baseOptions?: Apollo.MutationHookOptions<MfaCodeCheckMutation, MfaCodeCheckMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MfaCodeCheckMutation, MfaCodeCheckMutationVariables>(MfaCodeCheckDocument, options);
}
export type MfaCodeCheckMutationHookResult = ReturnType<typeof useMfaCodeCheckMutation>;
export type MfaCodeCheckMutationResult = Apollo.MutationResult<MfaCodeCheckMutation>;
export type MfaCodeCheckMutationOptions = Apollo.BaseMutationOptions<
  MfaCodeCheckMutation,
  MfaCodeCheckMutationVariables
>;
export const MfaRequestSendDocument = gql`
  mutation MFARequestSend($verificationKind: MFA__VerificationKind!, $verificationValue: String!) {
    mfaRequestSend(verificationKind: $verificationKind, verificationValue: $verificationValue) {
      mfaRequest {
        id
        verificationKind
        verificationValue
      }
      status
      error
    }
  }
`;
export type MfaRequestSendMutationFn = Apollo.MutationFunction<MfaRequestSendMutation, MfaRequestSendMutationVariables>;

/**
 * __useMfaRequestSendMutation__
 *
 * To run a mutation, you first call `useMfaRequestSendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMfaRequestSendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mfaRequestSendMutation, { data, loading, error }] = useMfaRequestSendMutation({
 *   variables: {
 *      verificationKind: // value for 'verificationKind'
 *      verificationValue: // value for 'verificationValue'
 *   },
 * });
 */
export function useMfaRequestSendMutation(
  baseOptions?: Apollo.MutationHookOptions<MfaRequestSendMutation, MfaRequestSendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MfaRequestSendMutation, MfaRequestSendMutationVariables>(MfaRequestSendDocument, options);
}
export type MfaRequestSendMutationHookResult = ReturnType<typeof useMfaRequestSendMutation>;
export type MfaRequestSendMutationResult = Apollo.MutationResult<MfaRequestSendMutation>;
export type MfaRequestSendMutationOptions = Apollo.BaseMutationOptions<
  MfaRequestSendMutation,
  MfaRequestSendMutationVariables
>;
export const PreferencesDocument = gql`
  query Preferences {
    account {
      preferences {
        emailReceipts
      }
    }
  }
`;

/**
 * __usePreferencesQuery__
 *
 * To run a query within a React component, call `usePreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<PreferencesQuery, PreferencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PreferencesQuery, PreferencesQueryVariables>(PreferencesDocument, options);
}
export function usePreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PreferencesQuery, PreferencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PreferencesQuery, PreferencesQueryVariables>(PreferencesDocument, options);
}
export type PreferencesQueryHookResult = ReturnType<typeof usePreferencesQuery>;
export type PreferencesLazyQueryHookResult = ReturnType<typeof usePreferencesLazyQuery>;
export type PreferencesQueryResult = Apollo.QueryResult<PreferencesQuery, PreferencesQueryVariables>;
export const UpdateNotificationPreferencesDocument = gql`
  mutation UpdateNotificationPreferences($input: Settings__UpdateNotificationPreferencesInput!) {
    updateNotificationPreferences(input: $input) {
      status
      error
    }
  }
`;
export type UpdateNotificationPreferencesMutationFn = Apollo.MutationFunction<
  UpdateNotificationPreferencesMutation,
  UpdateNotificationPreferencesMutationVariables
>;

/**
 * __useUpdateNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationPreferencesMutation, { data, loading, error }] = useUpdateNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationPreferencesMutation,
    UpdateNotificationPreferencesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNotificationPreferencesMutation, UpdateNotificationPreferencesMutationVariables>(
    UpdateNotificationPreferencesDocument,
    options,
  );
}
export type UpdateNotificationPreferencesMutationHookResult = ReturnType<
  typeof useUpdateNotificationPreferencesMutation
>;
export type UpdateNotificationPreferencesMutationResult = Apollo.MutationResult<UpdateNotificationPreferencesMutation>;
export type UpdateNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationPreferencesMutation,
  UpdateNotificationPreferencesMutationVariables
>;
export const UpdatePasswordDocument = gql`
  mutation UpdatePassword($input: Settings__UpdatePasswordInput!) {
    updatePassword(input: $input) {
      status
      error
    }
  }
`;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
}
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdatePasswordMutation,
  UpdatePasswordMutationVariables
>;
export const BuildSourceDocument = gql`
  mutation BuildSource($input: Billing__BuildSourceInput!) {
    buildSource(input: $input) {
      error
      status
      source {
        id
      }
    }
  }
`;
export type BuildSourceMutationFn = Apollo.MutationFunction<BuildSourceMutation, BuildSourceMutationVariables>;

/**
 * __useBuildSourceMutation__
 *
 * To run a mutation, you first call `useBuildSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildSourceMutation, { data, loading, error }] = useBuildSourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildSourceMutation, BuildSourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildSourceMutation, BuildSourceMutationVariables>(BuildSourceDocument, options);
}
export type BuildSourceMutationHookResult = ReturnType<typeof useBuildSourceMutation>;
export type BuildSourceMutationResult = Apollo.MutationResult<BuildSourceMutation>;
export type BuildSourceMutationOptions = Apollo.BaseMutationOptions<BuildSourceMutation, BuildSourceMutationVariables>;
export const DefaultSourceDocument = gql`
  mutation DefaultSource($id: ID!) {
    defaultSource(id: $id) {
      status
      error
    }
  }
`;
export type DefaultSourceMutationFn = Apollo.MutationFunction<DefaultSourceMutation, DefaultSourceMutationVariables>;

/**
 * __useDefaultSourceMutation__
 *
 * To run a mutation, you first call `useDefaultSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDefaultSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [defaultSourceMutation, { data, loading, error }] = useDefaultSourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDefaultSourceMutation(
  baseOptions?: Apollo.MutationHookOptions<DefaultSourceMutation, DefaultSourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DefaultSourceMutation, DefaultSourceMutationVariables>(DefaultSourceDocument, options);
}
export type DefaultSourceMutationHookResult = ReturnType<typeof useDefaultSourceMutation>;
export type DefaultSourceMutationResult = Apollo.MutationResult<DefaultSourceMutation>;
export type DefaultSourceMutationOptions = Apollo.BaseMutationOptions<
  DefaultSourceMutation,
  DefaultSourceMutationVariables
>;
export const DelinquentBalanceDocument = gql`
  query DelinquentBalance {
    account {
      delinquentBalance
    }
  }
`;

/**
 * __useDelinquentBalanceQuery__
 *
 * To run a query within a React component, call `useDelinquentBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDelinquentBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDelinquentBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useDelinquentBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<DelinquentBalanceQuery, DelinquentBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DelinquentBalanceQuery, DelinquentBalanceQueryVariables>(DelinquentBalanceDocument, options);
}
export function useDelinquentBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DelinquentBalanceQuery, DelinquentBalanceQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DelinquentBalanceQuery, DelinquentBalanceQueryVariables>(
    DelinquentBalanceDocument,
    options,
  );
}
export type DelinquentBalanceQueryHookResult = ReturnType<typeof useDelinquentBalanceQuery>;
export type DelinquentBalanceLazyQueryHookResult = ReturnType<typeof useDelinquentBalanceLazyQuery>;
export type DelinquentBalanceQueryResult = Apollo.QueryResult<DelinquentBalanceQuery, DelinquentBalanceQueryVariables>;
export const DestroySourceDocument = gql`
  mutation DestroySource($id: ID!) {
    destroySource(id: $id) {
      status
      error
    }
  }
`;
export type DestroySourceMutationFn = Apollo.MutationFunction<DestroySourceMutation, DestroySourceMutationVariables>;

/**
 * __useDestroySourceMutation__
 *
 * To run a mutation, you first call `useDestroySourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySourceMutation, { data, loading, error }] = useDestroySourceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroySourceMutation(
  baseOptions?: Apollo.MutationHookOptions<DestroySourceMutation, DestroySourceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DestroySourceMutation, DestroySourceMutationVariables>(DestroySourceDocument, options);
}
export type DestroySourceMutationHookResult = ReturnType<typeof useDestroySourceMutation>;
export type DestroySourceMutationResult = Apollo.MutationResult<DestroySourceMutation>;
export type DestroySourceMutationOptions = Apollo.BaseMutationOptions<
  DestroySourceMutation,
  DestroySourceMutationVariables
>;
export const SourcesDocument = gql`
  query Sources {
    sources {
      ...source
    }
  }
  ${SourceFragmentDoc}
`;

/**
 * __useSourcesQuery__
 *
 * To run a query within a React component, call `useSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSourcesQuery(baseOptions?: Apollo.QueryHookOptions<SourcesQuery, SourcesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SourcesQuery, SourcesQueryVariables>(SourcesDocument, options);
}
export function useSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourcesQuery, SourcesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SourcesQuery, SourcesQueryVariables>(SourcesDocument, options);
}
export type SourcesQueryHookResult = ReturnType<typeof useSourcesQuery>;
export type SourcesLazyQueryHookResult = ReturnType<typeof useSourcesLazyQuery>;
export type SourcesQueryResult = Apollo.QueryResult<SourcesQuery, SourcesQueryVariables>;
export const BuildCustomerTicketDocument = gql`
  mutation BuildCustomerTicket($input: BuildCustomerTicketInput!) {
    buildCustomerTicket(input: $input) {
      error
      status
      customerTicketID
      zendeskTicketID
    }
  }
`;
export type BuildCustomerTicketMutationFn = Apollo.MutationFunction<
  BuildCustomerTicketMutation,
  BuildCustomerTicketMutationVariables
>;

/**
 * __useBuildCustomerTicketMutation__
 *
 * To run a mutation, you first call `useBuildCustomerTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildCustomerTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildCustomerTicketMutation, { data, loading, error }] = useBuildCustomerTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBuildCustomerTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<BuildCustomerTicketMutation, BuildCustomerTicketMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuildCustomerTicketMutation, BuildCustomerTicketMutationVariables>(
    BuildCustomerTicketDocument,
    options,
  );
}
export type BuildCustomerTicketMutationHookResult = ReturnType<typeof useBuildCustomerTicketMutation>;
export type BuildCustomerTicketMutationResult = Apollo.MutationResult<BuildCustomerTicketMutation>;
export type BuildCustomerTicketMutationOptions = Apollo.BaseMutationOptions<
  BuildCustomerTicketMutation,
  BuildCustomerTicketMutationVariables
>;
export const CustomerTicketCreateDocument = gql`
  query CustomerTicketCreate {
    account {
      customer {
        name
        email
      }
    }
  }
`;

/**
 * __useCustomerTicketCreateQuery__
 *
 * To run a query within a React component, call `useCustomerTicketCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerTicketCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerTicketCreateQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerTicketCreateQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomerTicketCreateQuery, CustomerTicketCreateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomerTicketCreateQuery, CustomerTicketCreateQueryVariables>(
    CustomerTicketCreateDocument,
    options,
  );
}
export function useCustomerTicketCreateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomerTicketCreateQuery, CustomerTicketCreateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomerTicketCreateQuery, CustomerTicketCreateQueryVariables>(
    CustomerTicketCreateDocument,
    options,
  );
}
export type CustomerTicketCreateQueryHookResult = ReturnType<typeof useCustomerTicketCreateQuery>;
export type CustomerTicketCreateLazyQueryHookResult = ReturnType<typeof useCustomerTicketCreateLazyQuery>;
export type CustomerTicketCreateQueryResult = Apollo.QueryResult<
  CustomerTicketCreateQuery,
  CustomerTicketCreateQueryVariables
>;
export const UsageWithDownsizeDocument = gql`
  query UsageWithDownsize {
    account {
      ...accountWithUsageAndUpgradeDowngradeSummary
    }
  }
  ${AccountWithUsageAndUpgradeDowngradeSummaryFragmentDoc}
`;

/**
 * __useUsageWithDownsizeQuery__
 *
 * To run a query within a React component, call `useUsageWithDownsizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageWithDownsizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageWithDownsizeQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsageWithDownsizeQuery(
  baseOptions?: Apollo.QueryHookOptions<UsageWithDownsizeQuery, UsageWithDownsizeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsageWithDownsizeQuery, UsageWithDownsizeQueryVariables>(UsageWithDownsizeDocument, options);
}
export function useUsageWithDownsizeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsageWithDownsizeQuery, UsageWithDownsizeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsageWithDownsizeQuery, UsageWithDownsizeQueryVariables>(
    UsageWithDownsizeDocument,
    options,
  );
}
export type UsageWithDownsizeQueryHookResult = ReturnType<typeof useUsageWithDownsizeQuery>;
export type UsageWithDownsizeLazyQueryHookResult = ReturnType<typeof useUsageWithDownsizeLazyQuery>;
export type UsageWithDownsizeQueryResult = Apollo.QueryResult<UsageWithDownsizeQuery, UsageWithDownsizeQueryVariables>;
export const VirtualWalkthroughSubmitDocument = gql`
  mutation VirtualWalkthroughSubmit($input: VirtualWalkthroughSubmitInput!) {
    virtualWalkthroughSubmit(input: $input) {
      error
      status
    }
  }
`;
export type VirtualWalkthroughSubmitMutationFn = Apollo.MutationFunction<
  VirtualWalkthroughSubmitMutation,
  VirtualWalkthroughSubmitMutationVariables
>;

/**
 * __useVirtualWalkthroughSubmitMutation__
 *
 * To run a mutation, you first call `useVirtualWalkthroughSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVirtualWalkthroughSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [virtualWalkthroughSubmitMutation, { data, loading, error }] = useVirtualWalkthroughSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVirtualWalkthroughSubmitMutation(
  baseOptions?: Apollo.MutationHookOptions<VirtualWalkthroughSubmitMutation, VirtualWalkthroughSubmitMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VirtualWalkthroughSubmitMutation, VirtualWalkthroughSubmitMutationVariables>(
    VirtualWalkthroughSubmitDocument,
    options,
  );
}
export type VirtualWalkthroughSubmitMutationHookResult = ReturnType<typeof useVirtualWalkthroughSubmitMutation>;
export type VirtualWalkthroughSubmitMutationResult = Apollo.MutationResult<VirtualWalkthroughSubmitMutation>;
export type VirtualWalkthroughSubmitMutationOptions = Apollo.BaseMutationOptions<
  VirtualWalkthroughSubmitMutation,
  VirtualWalkthroughSubmitMutationVariables
>;
export const ExperimentsDocument = gql`
  query Experiments {
    laborBillingFormat
    account {
      id
      region {
        id
        name
      }
    }
    pricingSet {
      id
    }
  }
`;

/**
 * __useExperimentsQuery__
 *
 * To run a query within a React component, call `useExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperimentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExperimentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ExperimentsQuery, ExperimentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExperimentsQuery, ExperimentsQueryVariables>(ExperimentsDocument, options);
}
export function useExperimentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExperimentsQuery, ExperimentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExperimentsQuery, ExperimentsQueryVariables>(ExperimentsDocument, options);
}
export type ExperimentsQueryHookResult = ReturnType<typeof useExperimentsQuery>;
export type ExperimentsLazyQueryHookResult = ReturnType<typeof useExperimentsLazyQuery>;
export type ExperimentsQueryResult = Apollo.QueryResult<ExperimentsQuery, ExperimentsQueryVariables>;
export const OrderClassifyDocument = gql`
  query OrderClassify($orderID: ID, $cancelable: Boolean!, $type: OrderTypeEnum) {
    classify(orderID: $orderID, cancelable: $cancelable, type: $type) {
      type
      subtype
    }
  }
`;

/**
 * __useOrderClassifyQuery__
 *
 * To run a query within a React component, call `useOrderClassifyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderClassifyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderClassifyQuery({
 *   variables: {
 *      orderID: // value for 'orderID'
 *      cancelable: // value for 'cancelable'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useOrderClassifyQuery(
  baseOptions: Apollo.QueryHookOptions<OrderClassifyQuery, OrderClassifyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderClassifyQuery, OrderClassifyQueryVariables>(OrderClassifyDocument, options);
}
export function useOrderClassifyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderClassifyQuery, OrderClassifyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderClassifyQuery, OrderClassifyQueryVariables>(OrderClassifyDocument, options);
}
export type OrderClassifyQueryHookResult = ReturnType<typeof useOrderClassifyQuery>;
export type OrderClassifyLazyQueryHookResult = ReturnType<typeof useOrderClassifyLazyQuery>;
export type OrderClassifyQueryResult = Apollo.QueryResult<OrderClassifyQuery, OrderClassifyQueryVariables>;
export const ZendeskCopyDocument = gql`
  query ZendeskCopy($articleName: Zendesk__ArticleName!) {
    zendeskCopy(articleName: $articleName)
  }
`;

/**
 * __useZendeskCopyQuery__
 *
 * To run a query within a React component, call `useZendeskCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useZendeskCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZendeskCopyQuery({
 *   variables: {
 *      articleName: // value for 'articleName'
 *   },
 * });
 */
export function useZendeskCopyQuery(baseOptions: Apollo.QueryHookOptions<ZendeskCopyQuery, ZendeskCopyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZendeskCopyQuery, ZendeskCopyQueryVariables>(ZendeskCopyDocument, options);
}
export function useZendeskCopyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZendeskCopyQuery, ZendeskCopyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZendeskCopyQuery, ZendeskCopyQueryVariables>(ZendeskCopyDocument, options);
}
export type ZendeskCopyQueryHookResult = ReturnType<typeof useZendeskCopyQuery>;
export type ZendeskCopyLazyQueryHookResult = ReturnType<typeof useZendeskCopyLazyQuery>;
export type ZendeskCopyQueryResult = Apollo.QueryResult<ZendeskCopyQuery, ZendeskCopyQueryVariables>;
