import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, COLORS, Column, Grid, SkeletonLoader, Text } from '@clutter/clean';

import {
  AddressWithDetailsFragment,
  OrderServiceTypeEnum,
  OrderTypeEnum,
  useOrderEligibleServiceTypesLazyQuery,
} from '@portal/schema';
import { useOrderInput } from '@portal/components/orders/steps/order_context';
import { IAddress } from '@shared/types';
import { ServiceTypeCard } from '@portal/components/orders/service_type/service_type_card';
import { Message as ErrorMessage } from '@portal/components/shared/error';
import { IMPERSONATOR } from '@portal/config/impersonator';
import { User__Role } from '@admin/schema';

const CenteredGrid = styled(Grid)`
  align-items: center;
`;

const Blurb = styled(Text.Body)`
  max-width: 600px;
  margin-top: 24px;
`;

const TextLink = styled.a`
  font-weight: bold;
`;

const PlaceholderCard: React.FC = () => (
  <Column widths={[12, 12, 6]}>
    <Box.Flex width="100%" border={`1px solid ${COLORS.grayBorder}`} padding="16px" flexDirection="column">
      <Box.Flex width="100%" flexDirection="row" justifyContent="space-between">
        <SkeletonLoader width="20%" height="120px" animationDelay="-30ms" />
        <SkeletonLoader width="80%" padding="0 0 0 16px" height="120px" animationDelay="-30ms" />
      </Box.Flex>
      <Box.Flex width="100%" padding="16px 0 0">
        <SkeletonLoader width="100%" height="60px" />
      </Box.Flex>
    </Box.Flex>
  </Column>
);

const LoadingPlaceholder: React.FC = () => (
  <CenteredGrid wrap>
    <PlaceholderCard />
    <PlaceholderCard />
  </CenteredGrid>
);

const PICKUP_ARTICLE_ID = '1500005817982';
const RETURN_ARTICLE_ID = '10661889613463';

const AvailabilityBlurb: React.FC = () => {
  const { type } = useOrderInput();
  const articleID = type === OrderTypeEnum.Return ? RETURN_ARTICLE_ID : PICKUP_ARTICLE_ID;

  return (
    <Blurb>
      Appointment type availability depends on the address and other details about your request. To learn more about why
      some options may not be available, see our help article on{' '}
      <TextLink target="_blank" href={`https://www.clutter.com/help/articles/${articleID}`}>
        Appointment Types
      </TextLink>
      .
    </Blurb>
  );
};

export const AvailableServiceTypes: React.FC<{
  address?: IAddress;
  facilityAddress?: AddressWithDetailsFragment;
  setServiceType(serviceType: OrderServiceTypeEnum): void;
}> = ({ address, facilityAddress, setServiceType }) => {
  const orderInput = useOrderInput();
  const orderInputWithoutServiceType = orderInput && { ...orderInput, serviceType: undefined };

  const [loadServiceTypes, { data: serviceTypesData, loading }] = useOrderEligibleServiceTypesLazyQuery({
    variables: {
      orderInput: orderInputWithoutServiceType,
      skipPrimaryOrderCheck: !!(IMPERSONATOR && !IMPERSONATOR.roles.includes(User__Role.L1Agent)),
    },
  });

  useEffect(() => {
    const validAddress = address && !!address.street && !!address.city && !!address.state && !!address.zip;
    if (!address || validAddress) {
      loadServiceTypes();
    }
  }, [loadServiceTypes, address]);

  const serviceTypes = serviceTypesData?.orderEligibleServiceTypes ?? [];
  const serviceable = serviceTypes.some((service) => service.eligible);

  if (loading) {
    return <LoadingPlaceholder />;
  }

  if (address && !serviceable) {
    return (
      <>
        <ErrorMessage message={'There are no appointment types available for your request'} />
        <AvailabilityBlurb />
      </>
    );
  }

  return (
    <>
      <CenteredGrid wrap>
        {serviceTypes.map((serviceTypeData) => (
          <ServiceTypeCard
            key={serviceTypeData.serviceType}
            serviceTypeData={serviceTypeData}
            facilityAddress={facilityAddress}
            setServiceType={setServiceType}
            numCards={serviceTypes.length}
          />
        ))}
      </CenteredGrid>
      <AvailabilityBlurb />
    </>
  );
};
